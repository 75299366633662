import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import {
  MobileContainer,
  DesktopContainer,
  Cell,
  Header,
  Row,
  AccordionIcon,
  AccordionContent,
  PaginationContainer,
  NoReportsDiv,
  FormGroupReport,
  ShowingDiv,
  ArrowIconDiv,
} from "./DataTable.component.styles";
import SkeletonLoaderComponent from "./DataTable.skeleton";

const TableComponent = ({
  data = [],
  columns = [],
  columnAccordian = [],
  rowHeight,
  pagination,
  accordion,
  scrollable,
  mobileCheckboxDateTime,
  mobileCheckboxOnly,
  mobileDateTimeOnly,
  mobileActionItemOnly,
  mobileCheckboxAccordion,
  noDataMessage = "No data available.",
  pageSize,
  pageNo,
  totalPageReport,
  nextPage = () => {},
  previousPage = () => {},
  handlePageLength = () => {},
  paginationOptions,
  showLoadingIndicator = true,
  autoScrollBottom = false,
  accordianColumns,
  loading,
  ...rest
}) => {
  const [openAccordion, setOpenAccordion] = React.useState({});
  const [scrollBottom, setScrollBottom] = React.useState(false);

  const toggleAccordion = (rowIndex) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };

  useEffect(() => {
    setOpenAccordion({});
  }, [data]);

  const isMobileView = window.innerWidth <= 1000;

  useEffect(() => {
    // Scroll to bottom when autoScrollBottom is true and data changes
    if (autoScrollBottom && data.length > 0) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [autoScrollBottom, data]);

  // Function to handle changes in input fields
  const handleInputChange = () => {
    if (autoScrollBottom) {
      setScrollBottom(true); // Trigger scrolling to bottom
    }
  };

  if ((loading && showLoadingIndicator) || !data || data.length === 0) {
    return (
      <div>
        {loading && showLoadingIndicator ? (
          <SkeletonLoaderComponent
            columns={columns}
            // rowsPerPage={pageSize || 10}
            rowHeight={parseInt(rowHeight)}
            accordion={accordion}
            pagination={pagination}
          />
        ) : (
          <NoReportsDiv
            className="noReportsDiv"
            style={{
              overflowY: scrollable ? "scroll" : "hidden",
              height: scrollable ? `calc(100vh - ${rowHeight}px)` : "auto",
            }}
          >
            {noDataMessage}
          </NoReportsDiv>
        )}
      </div>
    );
  }

  if (isMobileView) {
    // Render the mobile view
    return (
      <MobileContainer>
        {data.map((row, rowIndex) => (
          <div key={rowIndex} className="container-div">
            <div className="mob-container">
              <div className="first-row">
                <div className="mob-div-left">
                  <div className="">
                    {mobileCheckboxOnly && (
                      <div className="first-column-section">
                        {columns?.map?.((column, colIndex) => {
                          if (colIndex === 0) {
                            return (
                              <div
                                key={colIndex}
                                width={column.width}
                                title={column?.selector?.(row) || column?.selector}
                              >
                                {column?.selector?.(row) || column?.selector}
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mob-div-right">
                  <div className="">
                    {mobileDateTimeOnly &&
                      columns
                        .filter((column) => column.name === "Date, Time")
                        .map((column, colIndex) => (
                          <Cell
                            key={colIndex}
                            width={column.width}
                            title={column?.selector?.(row) || column?.selector}
                          >
                            {column?.selector?.(row) || column?.selector}
                          </Cell>
                        ))}
                  </div>
                </div>
              </div>

              <div className="center-mob-div">
                {mobileCheckboxDateTime && (
                  <div className="CenterDataDiv">
                    {mobileCheckboxDateTime.map((columnInfo, colIndex) => {
                      const column = columns.find((col) => col.name === columnInfo.columnName);
                      if (column) {
                        return (
                          <div
                            key={colIndex}
                            className={columnInfo.className}
                            width={column.width}
                            title={column?.selector?.(row) || column?.selector}
                          >
                            {column?.selector?.(row) || column?.selector}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
              </div>

              <div className="bottomDiv">
                <div className="arrowsSec">
                  <div onClick={() => toggleAccordion(rowIndex)}>
                    {openAccordion[rowIndex] ? (
                      <i className="icon icon-arrow-up" />
                    ) : (
                      <i className="icon icon-arrow-down" />
                    )}
                  </div>
                </div>

                <div className="mob-div">
                  <div className="">
                    {mobileActionItemOnly &&
                      columns
                        .filter((column) => column.name === "Actions")
                        .map((column, colIndex) => (
                          <Cell
                            key={colIndex}
                            width={column.width}
                            title={column?.selector?.(row) || column?.selector}
                          >
                            {column?.selector?.(row) || column?.selector}
                          </Cell>
                        ))}
                  </div>
                </div>
              </div>
            </div>

            {openAccordion[rowIndex] && (
              <div className="accod-container">
                {mobileCheckboxAccordion && (
                  <div className="accord-div">
                    {mobileCheckboxAccordion.map((columnName, colIndex) => {
                      const column = columns.find((col) => col.name === columnName);
                      if (column) {
                        return (
                          <Cell key={colIndex} width={column.width} title={column?.selector?.(row)}>
                            <div className="labelStyle">{column.name}</div>
                            <div className="inputDivStyle">{column?.selector?.(row)}</div>
                          </Cell>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}

                {accordianColumns && (
                  <>
                    {accordianColumns.map((columnName, colIndex) => {
                      const column = columnAccordian.find((col) => col.name === columnName);
                      // Render only if column exists
                      if (column) {
                        return (
                          <div
                            className="accord-div"
                            key={colIndex}
                            width={column.width}
                            title={column?.selector?.(row) || column?.selector}
                          >
                            <div className="labelStyle"> {column.name}</div>
                            <div className="inputDivStyle">{column?.selector?.(row) || column?.selector}</div>
                          </div>
                        );
                      }
                      return null; // Column not found
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        ))}

        {pagination && (
          <PaginationContainer>
            <FormGroupReport onChange={(e) => handlePageLength(e)}>
              <Form.Label>Rows Per Page:</Form.Label>
              <Form.Control as="select" value={pageSize}>
                {paginationOptions.map((option, index) => (
                  <option key={`option_${index}`} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
            </FormGroupReport>

            <ShowingDiv>
              Showing{" "}
              {`${pageSize * (pageNo - 1) || 0} - ${
                parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalPageReport
                  ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
                  : totalPageReport || 0
              } `}
              of {totalPageReport || 0}
            </ShowingDiv>

            <ArrowIconDiv className="left-right-icons">
              <i className="icon icon-arrow-left" onClick={previousPage}></i>
              <i className="icon icon-arrow-right" onClick={nextPage}></i>
            </ArrowIconDiv>
          </PaginationContainer>
        )}
      </MobileContainer>
    );
  }

  return (
    // Render Desktop View
    <DesktopContainer>
      <Header
        style={{
          overflowY: scrollable ? "scroll" : "hidden",
        }}
        className="fontFamilyHeader"
      >
        {columns?.map?.((column, index) => (
          <Cell
            key={index}
            width={column.width}
            centered={column.centered}
            //  onClick={() => handleHeaderClick(column.name)}
          >
            <span className="sortable" title={column.name}>
              {column.name}
              {/* {sortColumn === column.name && <SortIcon>{sortDirection === "asc" ? "⬆️" : "⬇️"}</SortIcon>} */}
            </span>
          </Cell>
        ))}
        {accordion && <Cell width="50">&nbsp;</Cell>}
      </Header>
      <div
        className="rowHeight"
        style={{
          overflowY: scrollable ? "scroll" : "hidden",
          // maxHeight: scrollable ? "calc(100% - 48px)" : "auto",
          height: scrollable ? `calc(100vh - ${rowHeight}px)` : "auto",
        }}
      >
        {data?.map((row, rowIndex) => (
          <div key={`row_${rowIndex}`} title="" className="fontFamilyData" tabindex="0">
            <Row title="">
              {columns?.map?.((column, colIndex) => (
                <Cell
                  key={`col_${colIndex}`}
                  width={column?.width}
                  title={
                    column.showTitle !== false
                      ? column?.title ||
                        (typeof column?.selector?.(row) === "string" ? column?.selector?.(row) : "") ||
                        (typeof column?.selector === "string" ? column?.selector : "") ||
                        ""
                      : null
                  }
                >
                  {column?.selector?.(row) || column?.selector}
                </Cell>
              ))}
              {accordion && (
                <Cell width="50">
                  <AccordionIcon onClick={() => toggleAccordion(rowIndex)}>
                    {openAccordion[rowIndex] ? (
                      <i className="icon icon-arrow-up" />
                    ) : (
                      <i className="icon icon-arrow-down" />
                    )}
                  </AccordionIcon>
                </Cell>
              )}
            </Row>
            {accordion && (
              <AccordionContent isOpen={openAccordion[rowIndex]}>
                <div className="accod-container">
                  {/* Render dynamic accordion fields */}
                  {accordianColumns && (
                    <>
                      {accordianColumns.map((columnName, colIndex) => {
                        const column = columnAccordian.find((col) => col.name === columnName);
                        // Render only if column exists
                        if (column) {
                          return (
                            <div
                              className="accord-div"
                              key={colIndex}
                              width={column.width}
                              title={column?.selector?.(row) || column?.selector}
                            >
                              <div className="labelStyle"> {column.name}</div>
                              <div className="inputDivStyle">
                                {column?.selector?.(row) || column?.selector}
                              </div>
                            </div>
                          );
                        }
                        return null; // Column not found
                      })}
                    </>
                  )}
                </div>
              </AccordionContent>
            )}
          </div>
        ))}
      </div>
      {pagination && (
        <PaginationContainer>
          <FormGroupReport onChange={(e) => handlePageLength(e)}>
            <Form.Label>
              <>Rows Per Page</> :{" "}
            </Form.Label>
            <Form.Control as="select" value={pageSize}>
              {paginationOptions.map((option, index) => (
                <option key={`option_${index}`} value={option.value}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
          </FormGroupReport>

          <ShowingDiv>
            Showing{" "}
            {`${pageSize * (pageNo - 1) || 0} - ${
              parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalPageReport
                ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
                : totalPageReport || 0
            } `}
            of {totalPageReport || 0}
          </ShowingDiv>
          <ArrowIconDiv className="left-right-icons">
            <i className="icon icon-arrow-left" onClick={previousPage}></i>
            <i className="icon icon-arrow-right" onClick={nextPage}></i>
          </ArrowIconDiv>
        </PaginationContainer>
      )}
    </DesktopContainer>
  );
};
TableComponent.propTypes = {
  /**
   * Array of objects representing the data to be displayed in the table.
   */
  data: PropTypes.array,
  /**
   * Array of objects representing the columns configuration for the table.
   */
  columns: PropTypes.array,
  /**
   * Array of objects representing the columnAccordian configuration for the table under accordion.
   */
  columnAccordian: PropTypes.array,
  /**
   * Array of numbers representing options for rows per page in pagination.
   */
  rowsPerPageOptions: PropTypes.array,
  /**
   * Default number of rows per page in pagination.
   */
  defaultRowsPerPage: PropTypes.number,
  /**
   * Height of each row in pixels.
   */
  rowHeight: PropTypes.number,
  /**
   * Boolean indicating whether pagination should be enabled.
   */
  pagination: PropTypes.bool,
  /**
   * Boolean indicating whether accordion functionality should be enabled.
   */
  accordion: PropTypes.bool,
  /**
   * Boolean indicating whether the table should be scrollable.
   */
  scrollable: PropTypes.bool,
  /**
   * Message to display when there is no data to be shown in the table.
   */
  noDataMessage: PropTypes.string,
  /**
   * Function to handle navigating to the previous page in pagination.
   */
  previousPage: PropTypes.func,
  /**
   * Function to handle navigating to the next page in pagination.
   */
  nextPage: PropTypes.func,
  /**
   * Function to handle changes in the number of rows per page.
   */
  handlePageLength: PropTypes.func,
  /**
   * Boolean indicating whether the table should automatically scroll to the bottom when new data is added.
   */
  autoScrollBottom: PropTypes.bool,
};

export default TableComponent;
