import React from "react";
import { Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setAddChecklistModal } from "../../../../redux/modals/action";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";
import ChecklistTemplatesStyle from "../Templates/ChecklistTemplatesStyle.module.css";
import { AddChecklistButton, ContainerDiv } from "../ChecklistManagement.component.styles";

const AddChecklistDropdown = ({ setAddChecklistModal }) => {
  const history = useHistory();

  return (
    <PermissionCheck section="DASHBOARD" permissionName="CHECKLIST_SETTINGS" actionName="add">
      <ContainerDiv className="buttonDiv">
        <AddChecklistButton className="checklistButton">
          <Dropdown className={`${ChecklistTemplatesStyle.addChecklistButton}`}>
            <Dropdown.Toggle id="dropdown-basic">
              <i className="icon icon-add"></i> <Trans>ADD CHECKLIST</Trans>
            </Dropdown.Toggle>
            <Dropdown.Menu className={`${ChecklistTemplatesStyle.addChecklistButtonNested}`}>
              <Dropdown.Item href="#" onClick={() => setAddChecklistModal(true)}>
                <Trans>New Checklist</Trans>
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={() => history.push("/app/checklistmanagement/templates")}>
                <Trans>From Template</Trans>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </AddChecklistButton>
      </ContainerDiv>
    </PermissionCheck>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setAddChecklistModal: (data) => dispatch(setAddChecklistModal(data)),
});

export default connect(null, mapDispatchToProps)(AddChecklistDropdown);
