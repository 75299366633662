import React from "react";
import { Trans } from "react-i18next";
import {
  FileUploaderWrapper,
  FileUploaderDiv,
  HeaderLabel,
  FileUploaderCard,
  DragIconBGComponent,
  ErrorSet,
  TableContainerDiv,
  RowDivFile,
  ColumnFile,
  RowsContainerFile,
  DeleteIconDiv,
} from "./FileUploadCSV.component.styles";

const FileUploaderComponent = ({
  handleDrop,
  handleDrag,
  handlecsvFileUpload,
  csvFileUploadInputRef,
  noFileCsvErrorMsg,
  dragText,
  csvFile,
  setcsvFile,
  setnoFileCsvErrorMsg,
}) => {
  return (
    <FileUploaderWrapper>
      <FileUploaderDiv>
        <HeaderLabel>
          <Trans>User Bulk Upload CSV Files</Trans>
        </HeaderLabel>

        <FileUploaderCard
          onDrop={(e) => handleDrop(e)}
          onDragLeave={() => setDragText(t("Drop your file here, or browse"))}
          onDragOver={(e) => handleDrag(e)}
          className={noFileCsvErrorMsg === "" ? "borderBlue" : "borderRed"}
        >
          <span className="containerSpan">
            <label className="labelContainer">
              <input
                ref={csvFileUploadInputRef}
                onChange={(e) => handlecsvFileUpload(e)}
                type="file"
                accept=".csv"
                className="d-none"
                name="uploadFile"
              />
              <div className="icon icon-file-upload-csv pngLogoIcon"></div>
            </label>
          </span>

          <span className="dropSpan">
            <Trans i18nKey="dropFileInstruction">
              Drop your file here, or{" "}
              <span onClick={() => csvFileUploadInputRef?.current?.click()}>browse</span>
            </Trans>
          </span>
          <span className="dragTextFont">
            <Trans>{dragText}</Trans>
          </span>

          {/* <DragIconBGComponent className="hoverIcon" onClick={() => csvFileUploadInputRef?.current?.click()}>
            <div className="icon icon-add"></div>
          </DragIconBGComponent> */}
        </FileUploaderCard>
        <ErrorSet>{noFileCsvErrorMsg}</ErrorSet>
      </FileUploaderDiv>

      <FileUploaderDiv>
        <TableContainerDiv>
          <RowDivFile isHeader={true}>
            <ColumnFile isHeader={true} align={"left"}>
              <Trans>Document</Trans>
            </ColumnFile>
            <ColumnFile isHeader={true} align={"center"}>
              <Trans>Status</Trans>
            </ColumnFile>
            <ColumnFile isHeader={true} align={"center"}>
              <Trans>Actions</Trans>
            </ColumnFile>
          </RowDivFile>

          <RowsContainerFile>
            <RowDivFile>
              {csvFile ? (
                <>
                  <ColumnFile
                    align={"left"}
                    textleft={"left"}
                    widthResp={"75%"}
                    floatResp={"left"}
                    RmarginResp={"5%"}
                  >
                    <Trans>{csvFile.name}</Trans>
                  </ColumnFile>
                  <ColumnFile
                    align={"center"}
                    textleft={"center"}
                    widthResp={"75%"}
                    floatResp={"left"}
                    RmarginResp={"5%"}
                  >
                    <Trans>Selected</Trans>
                  </ColumnFile>

                  <ColumnFile
                    align={"left"}
                    textleft={"left"}
                    widthResp={"75%"}
                    floatResp={"left"}
                    RmarginResp={"5%"}
                  >
                    <div
                      onClick={() => {
                        setcsvFile(null);
                        csvFileUploadInputRef.current.value = null;
                        setnoFileCsvErrorMsg(t("Please upload a valid csv file"));
                      }}
                      className="text-center"
                      style={{ cursor: "pointer" }}
                    >
                      <DeleteIconDiv className="icon icon-delete" />
                    </div>
                  </ColumnFile>
                </>
              ) : (
                ""
              )}
            </RowDivFile>
          </RowsContainerFile>
        </TableContainerDiv>
      </FileUploaderDiv>
    </FileUploaderWrapper>
  );
};

export default FileUploaderComponent;
