import styled from "styled-components";
export const AssetTypeChecklistWrapper = styled.div`
  width: 95%;
  margin: auto;
  .noReportsDiv {
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    height: 50px !important;
    margin-top: 10px;
  }
`;
export const DeleteIconStyled = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  cursor: pointer;
  text-align: center;
  @media (max-width: 1100px) {
    text-align: left;
    margin-top: 5px;
  }
`;

/*export const RadioWrapper = styled.div`
  text-align: center;
  label,
  div {
    margin-bottom: 0;
  }
`;
*/
export const TableWrapper = styled.div``;

export const DropdownDiv = styled.div`
  display: flex;
  margin: 10px auto;
`;
export const DropdownSelect = styled.div`
  flex: 4;
  label {
    display: none;
  }
`;
export const DeleteButton = styled.div`
  float: right;
  width: 100px;
  align-content: center;
  align-self: center;
  @media (max-width: 1100px) {
    .deleteICon {
      text-align: center;
    }
  }
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
`;

export const NoChecklistAdded = styled.div`
  height: calc(100vh - 550px);
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  text-align: center;
`;

export const AssetTypeDetailsTableDescription = styled.div``;
export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 25% 35% 25% 15%;
  height: ${({ isHeader }) => (isHeader ? "36px" : "36px")};
  align-items: ${({ isHeader }) => (isHeader ? "center" : "center")};
  label {
    margin-bottom: 0px;
  }

  @media (max-width: 1100px) {
    line-height: 24px;
    grid-template-columns: ${({ isHeader }) => (isHeader ? "25% 35% 25% 15%" : "100%")};
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "0px")};
    height: auto;
    padding: ${({ isHeader }) => (isHeader ? "inherit" : "10px")};
    display: ${({ isHeader }) => (isHeader ? "none" : "")};
    border: ${({ isHeader, theme }) =>
      isHeader
        ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
        : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};

    .firstMobileCss {
      font-size: ${({ theme }) => theme.fontSizes.font6} !important;
    }
  }
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  :nth-last-child {
    border-bottom: ${({ isHeader, theme }) =>
      isHeader
        ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
        : "0px solid " + theme.colors.grayL5 || "#CCCCCC"};
  }
`;

export const Column = styled.div`
  padding: ${({ isHeader }) => (isHeader ? "0px" : "0px")};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  background: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  font-size: ${({ isHeader, theme }) =>
    isHeader ? theme.fontSizes.font5 : theme.fontSizes.font7} !important;
  line-height: ${({ isHeader, theme }) => (isHeader ? theme.lineHeights.line2 : "initial")} !important;
  font-family: ${({ isHeader, theme }) =>
    isHeader ? theme.fontFamily.secondaryFont : theme.fontFamily.primaryFont};
  border-top-left-radius: ${({ isHeader }) => (isHeader ? "4px" : "0px")};
  border-top-right-radius: ${({ isHeader }) => (isHeader ? "4px" : "0px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ textleft }) => (textleft ? textleft : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};

  @media (max-width: 1100px) {
    font-size: ${({ isHeader, theme }) =>
      isHeader ? theme.fontSizes.font7 : theme.fontSizes.font7} !important;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  padding: 0 12px;
  justify-content: center;
  @media (max-width: 1100px) {
    padding: 3px 3px;
    justify-content: left;
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;
  /* height: calc(100vh - 450px);*/

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  @media (max-width: 1100px) {
    margin-bottom: 15px;
  }
`;

export const MobileViewText = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    text-align: left;
  }
`;
export const ColumnReorder = styled(Column)`
  @media (max-width: 1100px) {
    text-align: left !important;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
`;

export const ButtonWrapperDiv = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 1100px) {
    display: flex;
  }
`;

export const AddChecklistDivB = styled.div`
  flex: 1;
`;

export const EditCancelButton = styled.div``;
export const NoDataWrapper = styled.div`
  float: left;
  text-align: center;
  width: 100%;
  height: calc(100vh - 523px);
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const SpanDiv = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  color:  ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-weight: 500 !important;
  line-height: 1.2;
`;

export const RowDivN = styled.div`
  display: grid;
  grid-template-columns: 85% 15% !important;

  border-bottom: 0px !important;
  align-items: end;
  @media (max-width: 1100px) {
    align-items: center;
  }
  label {
    margin-bottom: 0px;
  }

  .add_ChecklistD {
    grid-template-columns: initial;
    margin: 10px 0px;
    border-bottom: 0px;
    label {
      display: none;
    }
    @media (max-width: 1100px) {
      margin: 0px 0px;
      border: 0px;
      padding: 0px;
      .drop_Filter {
        height: 34px;
      }
    }
  }

  @media (max-width: 1100px) {
    .firstMobileCss {
      font-size: ${({ theme }) => theme.fontSizes.font6} !important;
    }
  }
`;
export const UpdateCheckbox = styled.div`
  display: inline-block;
  margin-right: 5px;
  @media (max-width: 1100px) {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
`;
