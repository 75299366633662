import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAllChecklist } from "../../../redux/checklists/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import { setEditForm } from "../../../redux/forms/action";
import S3ConfirmationModal from "./Modal/S3ConfirmationModal";
import { AssetPageWrapper, AssetCol, FullWidth, BreakLine } from "./AssetOverviewTab.component.styles";
import DropdownFilterWrapper from "../../../components/DropdownComponent/DropdownFilterWrapper";
import InputField from "../../../components/InputFieldComponent/InputField.component";

function AssetOverviewTab({
  editForm,
  assetOverview,
  worksiteList,
  setShowEditButton,
  statusButton,
  editData,
  setEditData,
  setEditForm,
  validated,
  dropdownWorksiteList,
  dropdownFilterProductList,
  AssetLoading,
  setEditAlertModal,
  getAllChecklist,
  handleAssetNameChange,
  IsSubmitted,
  isSuperAdmin,
}) {
  const [value, setValue] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);

  const { t } = useTranslation();

  const changeFeildValue = (e, i) => {
    let newData = [];
    editData.assetAttributes.map((data, index) => {
      if (i == index) {
        newData.push({
          ...data,
          AssetAttributeValue: e.target.value,
        });
      } else {
        newData.push({
          ...data,
        });
      }
    });
    setEditData({ ...assetOverview, assetAttributes: [...newData] });
  };

  useEffect(() => {
    //Load the checklist
    getAllChecklist({ pageNo: 0, pageSize: 0 }, true);
  }, []);

  useEffect(() => {
    if (editForm) {
      if (assetOverview?.assetAttributes) {
        setEditData({ ...assetOverview, assetAttributes: [...assetOverview?.assetAttributes] });
      }
    }
  }, [editForm]);

  useEffect(() => {
    setValue(0);
    if (assetOverview?.assetAttributes) {
      setEditData({ ...assetOverview, assetAttributes: [...assetOverview?.assetAttributes] });
    }
  }, [assetOverview]);

  const setModalShowClose = () => {
    setModalShow(false);
    setEditData({ ...editData, s3enabled: !isCheckboxChecked });
  };

  return (
    <AssetPageWrapper isSuperAdmin={isSuperAdmin}>
      <FullWidth>
        {/* {!editForm && <div className="alert-modal-overlay" onClick={()=>setEditAlertModal(true)}></div>} */}
        {!AssetLoading && assetOverview ? (
          <Form noValidate validated={validated} id="assetOverviewEdit" className="assetOverviewForm">
            {!editForm && (
              <AssetCol>
                <InputField
                  autofocus
                  label={t("Asset Name")}
                  value={editData ? editData.assetName : ""}
                  onChange={(e) => setEditData({ ...editData, assetName: e.target.value })}
                  disabled={editForm}
                  errorMessage={IsSubmitted && !AssetName && t("Asset Name is required")}
                  fullWidth
                  isError={IsSubmitted && !AssetName}
                  size="medium"
                  minLength={"1"}
                  maxLength={"64"}
                />
              </AssetCol>
            )}

            <AssetCol>
              <DropdownFilterWrapper
                disabled={editForm}
                label={t("SIERA.AI Product")}
                options={dropdownFilterProductList?.map((option) => ({
                  _id: option._id,
                  label: option.name,
                }))}
                required={true}
                // isValidationError={isValidationError}
                value={editData?.productType || "NA"}
                onChange={(value) => {
                  setEditData({ ...editData, productType: value });
                }}
                errorMessage={t("Please select a Product")}
              />
            </AssetCol>
            <AssetCol>
              <DropdownFilterWrapper
                disabled={editForm}
                label={t("Worksite Name")}
                options={
                  dropdownWorksiteList &&
                  dropdownWorksiteList[0] &&
                  dropdownWorksiteList.map((option) => ({
                    _id: option._id,
                    label: option.name,
                  }))
                }
                // required={true}
                // isValidationError={isValidationError}
                value={editData && editData.siteId && editData.siteId._id}
                onChange={(value) => {
                  if (value) {
                    setEditData({
                      ...editData,
                      siteId: {
                        _id: value,
                      },
                    });
                  }
                }}
              />
            </AssetCol>
            <BreakLine />
            {editData &&
              editData.assetAttributes &&
              editData.assetAttributes[0] &&
              editData.assetAttributes.map((data, i) => (
                <AssetCol>
                  <InputField
                    label={data.AssetAttributeName}
                    required={data.AssetAttributeRequired}
                    onChange={(e) => changeFeildValue(e, i)}
                    disabled={editForm}
                    minLength={"1"}
                    maxLength={"64"}
                    value={data.AssetAttributeValue ? data.AssetAttributeValue : ""}
                    InputProps={{ disableUnderline: true }}
                  />
                </AssetCol>
              ))}
          </Form>
        ) : (
          <></>
        )}
      </FullWidth>

      <S3ConfirmationModal
        setModalShow={setModalShow}
        setModalShowClose={setModalShowClose}
        modalShow={modalShow}
        setEditData={setEditData}
        editData={editData}
        isCheckboxChecked={isCheckboxChecked}
      />
    </AssetPageWrapper>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetOverview: state.assets.AssetOverview,
  worksiteList: state.worksites.Worksites,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownFilterProductList: state.dropdownFilters.productList,
  editForm: state.forms.editForm,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  // setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  setEditForm: (data) => dispatch(setEditForm(data)),
  getAllChecklist: (paginationData, isResetState) => dispatch(getAllChecklist(paginationData, isResetState)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetOverviewTab);
