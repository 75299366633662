import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";

import ActionButtons from "./ActionButtons.component";

const ActionBarWrapper = styled.div`
  position: relative;
`;
const ActionBarButtons = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  display: block;
  button {
    border: 0px;
    display: block;
    width: 100%;
    float: left;
    text-align: left;
    color: black;
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.fontSizes.font6};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    i {
      display: none;
    }
  }
`;

function AssetTypeActionBar({ showActionBar }) {
  return (
    <>
      {!showActionBar ? (
        ""
      ) : (
        <ActionBarWrapper>
          <ActionBarButtons>
            <ActionButtons />
          </ActionBarButtons>
        </ActionBarWrapper>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetTypeActionBar));
