/**
 * @author Rakesh Vishwakarma <rakesh@siera.ai>
 *  Dashboard Page Header with Drawer Styling
 */
import styled from "styled-components";
import SieraAiLogoIcon from "../../../assets/favicon.png";

// Cloud Text Styles
export const CloudText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font3} !important;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-style: normal;
  line-height: 50px;
  float: left;
  @media (max-width: 1100px) {
    display: none;
  }
`;
// Company Icon Styles
export const CompanyIcon = styled.div``;

// Profile Styles
export const Profile = styled.div`
  display: flex;
  margin-bottom: 5px;
  margin-left: 12px;
`;
// Profile Image Styles
export const ProfileImg = styled.span`
  width: 100px;
`;

// Profile Name Styles
export const ProfileName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  font-style: normal;
  text-align: left;
  color: #2b2b2b;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 21px;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  }
`;

// Company Name Styles
export const ProfileCompanyName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  font-style: normal;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  }
`;

// Text Content Text Styles
export const ProfileTextContent = styled.span`
  margin-left: 15px;
  overflow: auto;
`;
// User Roles Text Styles
export const UserRole = styled.span`
  text-align: left;
`;

export const MainPageWrapper = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const SidebarWrapper = styled.div`
  width: 20%;
  float: left;
`;

export const PageWrapper = styled.div`
  width: 80%;
  float: right;
`;

export const HeaderTopDiv = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  @media (max-width: 768px) {
    height: 50px;
  }
  height: 55px;
  width: 100%;
  float: left;
  box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
`;

export const TutorialWrapper = styled.div`
  position: relative;
  width: 55px;
  @media (max-width: 768px) {
    width: 100px;
    button {
      display: flex;
      align-items: center;
    }
  }
`;

export const SpanText = styled.div`
  visibility: hidden;
`;

export const IconWrapper = styled.div`
  width: 45px;
  float: right;
  &:hover {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    position: relative;
  }
  &:hover ~ ${SpanText} {
    visibility: visible;
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    margin-top: -2px;
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  }
`;

export const TutorialsIcon = styled.i`
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-size: ${({ theme }) => theme.fontSizes.font4};
  display: block;
  padding-top: 6px;
  cursor: pointer;
  width: 45px;
`;

export const TutorialsMenu = styled.div`
  position: absolute;
  display: ${({ $showIntroMenu }) => ($showIntroMenu ? "block" : "none")};
  background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  border-radius: 1px;
  box-shadow: 0px 3px 6px #00000029;
  width: 348px;
  top: 62px;
  float: right;
  right: -83px;
  z-index: 999;
  @media (max-width: 1100px) {
    right: -2px;
    top: 150px;
  }
  &:after {
    content: " ";
    position: absolute;
    right: 95px;
    top: -12px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
`;

export const TutorialHeader = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-style: normal;
  display: block;
  float: left;
  width: 100%;
  text-align: left;
  padding: 10px 15px;
  box-shadow: 0px 3px 6px #00000029;
  span {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
    font-style: normal;
    text-align: left;
    display: block;
  }
`;

export const SieraAiLogo = styled.div`
  background: url(${SieraAiLogoIcon}) ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} center;
  height: 30px;
  background-size: 60%;
  background-repeat: no-repeat;
  border-radius: 100%;
  width: 30px;
  border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  float: left;
  margin-right: 7px;
`;

export const PlayButton = styled.div`
  height: 30px;
  border-radius: 4px;
  width: 30px;
  background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  float: right;
  text-align: center;
  cursor: pointer;
  i {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-size: ${({ theme }) => theme.fontSizes.font6};
    vertical-align: middle;
    cursor: pointer;
  }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  color: #323232;
  font-style: normal;
  display: block;
  float: left;
  text-align: left;
  padding: 10px 15px;
  line-height: 30px;
  width: 100%;
`;

export const HelpDropdownLanguage = styled.div`
  width: 20%;
  float: right;
  display: flex;
  justify-content: end;
  padding-top: 5px;
  a {
    padding-top: 6px;
  }
`;

export const HeaderCompnayLogo = styled.div`
  width: 70%;
  float: left;
  display: block;
  text-align: left;
  padding-left: 24px;
  svg {
    width: 100px;
    height: 50px;
    float: left;
    margin-right: 7px;
  }
`;

export const ToggleButton = styled.div`
  svg {
    height: 17px;
    width: auto;
    margin-top: 16px;
  }
`;

export const MainContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  padding: 8px 16px 8px 16px;
`;

export const AlertSetting = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  font-size: 80px !important;
`;
export const AlertText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconHoverWrapper = styled.div`
  width: 55px;
  @media (max-width: 768px) {
    width: 100px;
    a {
      display: flex;
      align-items: center;
    }
  }
`;
export const AppMain = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  main {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
`;

// Container for the whole component
export const HelpDropdownLanguageContainer = styled.div`
  position: relative;
`;

// Desktop view styles
export const DesktopView = styled.div`
  display: flex;
  float: right;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Mobile view styles
export const MobileView = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: block;
  }
`;

export const MobileDropdownButton = styled.button`
  display: block;
  width: 25px;
  height: 35px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
  float: right;

  &:before {
    content: "{";
    display: block;
    font-family: "siera-font-icons";
    width: 100%;
    color: white;
    font-size: 30px;
    line-height: 50px;
    right: 10px;
    position: relative;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    `
    &:before {
      content: "L";
      display: block;
      font-family: "siera-font-icons";
      width: 100%;
      color: white;
      font-size: 30px;
      line-height: 50px;
      right: 20px;
      position: relative;
    }

   
  `}
`;

// Dropdown content for mobile view
export const MobileDropdownContent = styled.div`
  display: ${({ $isVisible }) => ($isVisible ? "block" : "none")};
  position: absolute;
  top: 50px;
  right: 0;
  width: auto;
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 5;
  padding: 10px;
  .dropdown {
    display: flex;
    align-items: center;
    .dropdown-toggle::after {
      display: none;
    }
    div:nth-child(1) {
      width: 37px;
    }
  }
`;
