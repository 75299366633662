import styled from "styled-components";

export const ButtonWrapper = styled.button`
  display: ${(props) => (props.$iconPosition === "before" ? "initial" : "flex")};
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: ${(props) => props.$buttonStyles[props.$size].fontSize};
  line-height: ${(props) =>
    props.$size === "medium"
      ? props.$iconOnly
        ? props.theme?.lineHeights?.line6 || "20px"
        : props.theme?.lineHeights?.line5 || "22px"
      : props.$size === "small"
        ? props.$iconOnly
          ? props.theme?.lineHeights?.line6 || "20px"
          : props.theme?.lineHeights?.line7 || "18px"
        : props.$iconOnly
          ? props.theme?.lineHeights?.line4 || "24px"
          : props.theme?.lineHeights?.line4 || "24px"};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  border-radius: 4px;
  cursor: pointer;
  padding: ${(props) =>
    props.$size === "medium"
      ? props.$iconOnly
        ? "9px 9px"
        : "6px 20px"
      : props.$size === "small"
        ? props.$iconOnly
          ? "8px 8px"
          : "6px 15px"
        : props.$iconOnly
          ? "9px 9px"
          : "6px 24px"};
  text-transform: uppercase;
  background-color: ${(props) => props.$buttonStyles[props.$buttonType].backgroundColor};
  color: ${(props) => props.$buttonStyles[props.$buttonType].color};
  border: ${(props) => props.$buttonStyles[props.$buttonType].borderDefault};
  background-position: center;
  transition: background 0.4s;
  /* This is for only icon which is like pdf csv */
  padding: ${(props) => props.$buttonStyles[props.$buttonType].paddingIconBig};
  font-size: ${(props) => props.$buttonStyles[props.$buttonType].fontSize};

  &:focus {
    border: ${(props) => props.$buttonStyles[props.$buttonType].border};
    outline: ${(props) => props.$buttonStyles[props.$buttonType].outline};
  }

  &:hover {
    background: ${(props) => props.$buttonStyles[props.$buttonType].background};
    color: ${(props) => props.$buttonStyles[props.$buttonType].hoverColorText};
  }

  i {
    cursor: pointer;

    margin-right: ${(props) =>
      props.$iconOnly ? (props.$iconOnly ? "0px" : "12px") : props.$iconPosition === "after" ? "0px" : "12px"};
    margin-left: ${(props) =>
      props.$iconOnly ? (props.$iconOnly ? "0px" : "12px") : props.$iconPosition === "after" ? "12px" : "0px"};

    margin-top: ${(props) => (props.$iconOnly ? "0px" : "3px")};
    height: ${(props) => props.$buttonStyles[props.$size].heightIcon};
    width: ${(props) => props.$buttonStyles[props.$size].widthIcon};
    float: left;
    box-sizing: initial;
    /* This is for only icon which is pdf */
    font-size: ${(props) => props.$buttonStyles[props.$buttonType].heightIcon};
    width: ${(props) => props.$buttonStyles[props.$buttonType].widthIcon};
    height: ${(props) => props.$buttonStyles[props.$buttonType].heightIcon};
    color: ${(props) => props.$buttonStyles[props.$buttonType].colorPDF};
  }

  &:active {
    background-color: ${(props) => props.$buttonStyles[props.$buttonType].backgroundColor};
    box-shadow: ${(props) => props.$buttonStyles[props.$buttonType].boxShadowOutlone};
    background-size: 100%;
    transition: background 0s;
    outline: none;
  }

  &:hover > i {
    background-repeat: no-repeat;

    margin-right: ${(props) =>
      props.$iconOnly ? (props.$iconOnly ? "0px" : "12px") : props.$iconPosition === "after" ? "0px" : "12px"};
    margin-left: ${(props) =>
      props.$iconOnly ? (props.$iconOnly ? "0px" : "12px") : props.$iconPosition === "after" ? "12px" : "0px"};

    margin-top: ${(props) => (props.$iconOnly ? "0px" : "3px")};
    height: ${(props) => props.$buttonStyles[props.$size].heightIcon};
    width: ${(props) => props.$buttonStyles[props.$size].widthIcon};
    float: left;
    background-size: contain;

    /* This is for only icon which is pdf */
    font-size: ${(props) => props.$buttonStyles[props.$buttonType].heightIcon};
    width: ${(props) => props.$buttonStyles[props.$buttonType].widthIcon};
    height: ${(props) => props.$buttonStyles[props.$buttonType].heightIcon};
    background-color: ${(props) => props.$buttonStyles[props.$buttonType].backgroundColorHoverPDF};
    color: ${(props) => props.$buttonStyles[props.$buttonType].colorPDFHover};
    border-radius: 4px;
  }
  &:disabled {
    background: ${(props) => props.$buttonStyles[props.$buttonType].disabledBackground};
    cursor: not-allowed;
    color: ${(props) => props.$buttonStyles[props.$buttonType].disabledTextColor};
    border: ${(props) => props.$buttonStyles[props.$buttonType].disabledBorder};

    /* This is for only icon which is like pdf csv */
    font-size: ${(props) => props.$buttonStyles[props.$buttonType].heightIcon};
    width: ${(props) => props.$buttonStyles[props.$buttonType].widthIcon};
    height: ${(props) => props.$buttonStyles[props.$buttonType].heightIcon};
  }
  &:disabled > i {
    background-repeat: no-repeat;
    background-size: contain;
    color: ${(props) => props.$buttonStyles[props.$buttonType].disabledIconColorPDF};
    background-color: transparent;
  }
  &:disabled > i:hover {
    color: ${(props) => props.$buttonStyles[props.$buttonType].disabledIconColorPDF};
    background-color: ${(props) => props.$buttonStyles[props.$buttonType].colorPDFHover};
  }
`;
