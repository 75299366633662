export const tableHeader = [
  { name: "Account", space: 0, fieldName: "accountName" },
  { name: "Worksite", space: 0, fieldName: "worksite" },
  { name: "Asset Type", space: 0, fieldName: "assetType" },
  { name: "Near-Miss By", space: 0, fieldName: "assetId" },
  { name: "Asset Name", space: 0, fieldName: "assetName" },
  { name: "Date, Time", space: 0, fieldName: "createdAt" },
  // { name: "Impact (g)", space: 1, fieldName: "impactG" },
  { name: "Obstacle Type", space: 0, fieldName: "impactSeverity" },
  { name: "Actions", space: 1, fieldName: "inspectionCountMonthly" },
];

export const mobileRowHeader = [
  { name: "Worksite", keyName: "worksite", space: 1, fieldName: "siteName" },
  {
    name: "Near-Miss By",
    keyName: "incidentBy",
    space: 1,
    fieldName: "fullName",
  },
  {
    name: "Asset Impacted",
    keyName: "assetImpacted",
    space: 1,
    fieldName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 1,
    isDate: true,
    fieldName: "impactRecordedAt",
  },
];

export const tableRowData = [
  {
    name: "Account",
    keyName: "accountName",
    space: 0,
    fieldName: "companyName",
  },
  { name: "Worksite", keyName: "worksite", space: 0, fieldName: "siteName" },
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 0,
    fieldName: "AssetTypeName",
  },
  {
    name: "Near-Miss",
    keyName: "incidentBy",
    space: 0,
    fieldName: "fullName",
  },

  {
    name: "Asset Name",
    keyName: "assetImpacted",
    space: 0,
    fieldName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 0,
    isDate: true,
    fieldName: "impactRecordedAt",
  },
  {
    name: "Impact Severity",
    keyName: "impactSeverity",
    isStatus: true,
    space: 0,
    fieldName: "impactSeverity",
    fieldName2: "incidentObject",
  },
];

export const rowPerPageDropdown = [
  { name: 10, value: 10 },
  { name: 25, value: 25 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
];

export const filterByTypeData = [
  { name: "Asset", _id: "asset" },
  { name: "User", _id: "inspectedBy" },
  { name: "Obstacle Type", _id: "obstacleType" },
];

export const FilterByTypeLabelData = {
  asset: "Asset",
  assetType: "Asset Type",
  inspectedBy: "User",
  obstacleType: "Obstacle Type",
};
