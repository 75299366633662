/**
 * TODO:  Create a common filter component for all the reports/Dashboard pages when time permits
 * Move the actions to action file
 * Do code optimization
 * Fix lint issues
 * Once completed, please remove this comment
 */

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import DateRangePicker from "../../../../../components/DatePicker.component";
import DropdownWrapper from "../../../../../components/DropdownComponent/DropdownWrapper";
import "./../../Report.css";
import { apiCall } from "../../../../../utils/apiCall";
import { filterByTypeData, FilterByTypeLabelData } from "../constants";
import Button from "../../../../../components/Button/Button.component";
import DropdownComponent from "../../../../../components/DropdownComponent/Dropdown.component";
import MoreFilterMobileToggle from "../../../../../components/More Filter Button/MoreFilterButton.component";
import { StyledCol } from "../../Reports.styles";
import { getUserTypeList } from "../../../../../redux/userType/action";
import { setNearMissReportFilter } from "../../../../../redux/filters/action";

const Filters = (props) => {
  const {
    isSuperAdmin,
    isSiteAdmin,
    setDropdownAccountId,
    setDropdownWorksiteId,
    dropdownWorksiteList,
    dropdownAccountList,
    setNearMissReportFilter,
    dropdownAssetTypeList,
    setPageNo,
    selectedAccount,
    setSelectedAccount,
    applyChange,
    getUserTypeList,
    userTypeList,
    nearMissFilter,
    showMoreFilter,
    setShowMoreFilter,
  } = props;

  const { t } = useTranslation();
  //const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filterByTypeLabel, setFilterByTypeLabel] = useState("");
  const [accountsList, setAccountsList] = useState([]);
  const [firstFilterData, setFirstFilterData] = useState([]);
  const [secondFilterData, setSecondFilterData] = useState([]);
  const [assetsByAssetType, setAssetsByAssetType] = useState([]);

  const { filterByType, endDate, startDate, worksiteId, accountId, userTypeId, assetTypeId } = nearMissFilter;

  const getUsersByUserType = () => {
    const url = `/user/list/${userTypeId || "all"}/${accountId}/${worksiteId}/all/all/all`;

    apiCall(url).then((resp) => {
      let newData = [];
      let nameSet = new Set();
      resp.data.data.forEach((data) => {
        if (nameSet.has(data && data.fullName)) {
          return;
        }
        nameSet.add(data.inspectorId && data.inspectorId.fullName);
        newData.push({
          _id: data && data._id,
          fullName: data && data.fullName,
        });
      });
      setSecondFilterData(newData);
    });
  };

  const getAssetsByAssetType = () => {
    const url = `/dropdown/assets?accid=${accountId}&siteid=${
      worksiteId || "all"
    }&astpid=${assetTypeId || "all"}`;
    apiCall(url)
      .then((resp) => {
        setAssetsByAssetType(resp?.data?.data?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeFilterByType = (value) => {
    setNearMissReportFilter({
      ...nearMissFilter,
      filterByType: value,
      obstacleType: "all",
      pageNo: 1,
    });
    setFilterByTypeLabel(FilterByTypeLabelData[value]);
    if (value === "asset") {
      setFirstFilterData(dropdownAssetTypeList);
      setSecondFilterData(assetsByAssetType);
    } else if (value === "inspectedBy") {
      setFirstFilterData(userTypeList);
      getUsersByUserType();
    } else if (value === "obstacleType") {
      setSecondFilterData([
        { label: "Pedestrian", _id: "PEDESTRIAN" },
        { label: "Object", _id: "OBJECT" },
      ]);
    }

    setPageNo(1);
  };

  useEffect(() => {
    if (filterByType === "asset") {
      setSecondFilterData(assetsByAssetType);
      setFirstFilterData(dropdownAssetTypeList);
    } else if (nearMissFilter === "inspectedBy") {
      setFirstFilterData(userTypeList);
      getUsersByUserType();
    }
  }, [dropdownAssetTypeList, assetsByAssetType]);

  const changeLastFilter = (value, key, isLeadFilter, resetField) => {
    if (isLeadFilter) {
      setNearMissReportFilter({
        ...nearMissFilter,
        [key]: value,
        [resetField]: "all",
        pageNo: 1,
      });
      return;
    } else {
      setNearMissReportFilter({
        ...nearMissFilter,
        [key]: value,
        pageNo: 1,
      });
    }
  };

  useEffect(() => {
    const accounts = [];
    dropdownAccountList.forEach((acc) => {
      accounts.push({
        value: acc._id,
        label: acc.companyName,
      });
      if (acc._id === accountId) {
        setSelectedAccount({
          value: acc._id,
          label: acc.companyName,
        });
      }
    });
    setAccountsList(accounts);
  }, [dropdownAccountList]);

  useEffect(() => {
    if (!selectedAccount) {
      setShowMoreFilter(false);
    }
  }, [selectedAccount]);

  useEffect(() => {
    getUserTypeList();
  }, []);
  useEffect(() => {
    getUsersByUserType();
  }, [userTypeId]);

  useEffect(() => {
    getAssetsByAssetType();
  }, [assetTypeId]);

  return (
    <MoreFilterMobileToggle>
      <Row>
        {isSuperAdmin && (
          <Col lg={2}>
            <DropdownComponent
              label={t("Account")}
              placeholder={t("Select an Account")}
              options={accountsList}
              value={selectedAccount}
              handleFilter={(selectedData) => {
                setSelectedAccount(selectedData);
                setNearMissReportFilter({
                  ...nearMissFilter,
                  accountId: selectedData.value,
                });
                setDropdownAccountId(selectedData.value);
              }}
              size="medium"
            />
          </Col>
        )}
        {((isSuperAdmin && selectedAccount) || !isSuperAdmin) && (
          <>
            {!isSiteAdmin && (
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: t("Worksite"),
                    name: "worksiteName",
                    key: "name",
                    data: dropdownWorksiteList,
                  }}
                  value={worksiteId}
                  handleFilter={(value) => {
                    setNearMissReportFilter({
                      ...nearMissFilter,
                      worksiteId: value,
                      assetId: null,
                      assetTypeId: null,
                    });
                    setDropdownWorksiteId(value);
                  }}
                />
              </Col>
            )}
            <Col lg={4}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setDate={setNearMissReportFilter}
                filterState={nearMissFilter}
              />
            </Col>
            <StyledCol>
              <Button
                iconClass="filter"
                title={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                onClick={() => setShowMoreFilter(!showMoreFilter)}
                iconOnly
                buttonType={showMoreFilter ? "primary" : "secondary"}
              />
              <span style={{ width: "10px", display: "inline-block" }}>&nbsp;</span>
              <Button
                label={t("Apply Filter")}
                size="medium"
                onClick={applyChange}
                showIcon={false}
                buttonType="primary"
              />
            </StyledCol>
          </>
        )}
      </Row>
      {showMoreFilter && (
        <Row className="mt-2">
          <Col lg={2}>
            <DropdownWrapper
              filter={{
                type: t("Filter By"),
                name: "filterBy",
                placeholder: t("Select a Filter"),
                key: "name",
                data: filterByTypeData,
              }}
              value={filterByType}
              label={t("filter")}
              handleFilter={(e) => changeFilterByType(e)}
              useAsDropdown={true}
            />
          </Col>
          {(filterByType === "asset" || filterByType === "inspectedBy") && (
            <Col lg={2}>
              <DropdownWrapper
                filter={{
                  type: filterByType === "asset" ? "Asset Type" : "User Type",
                  key: filterByType === "asset" ? "AssetTypeName" : "name",
                  data: firstFilterData,
                }}
                value={
                  nearMissFilter[
                    filterByType === "asset"
                      ? "assetTypeId"
                      : filterByType === "obstacleType"
                        ? "obstacleType"
                        : "userTypeId"
                  ]
                }
                label={t("filter")}
                handleFilter={(e) =>
                  changeLastFilter(
                    e,
                    filterByType === "asset" ? "assetTypeId" : "userTypeId",
                    true,
                    filterByType === "asset" ? "assetId" : "inspectorId"
                  )
                }
              />
            </Col>
          )}
          {filterByType && filterByTypeLabel && (
            <Col lg={2}>
              <DropdownWrapper
                filter={{
                  type: `${filterByTypeLabel}`,
                  key:
                    filterByType === "asset"
                      ? "assetName"
                      : filterByType === "inspectedBy"
                        ? "fullName"
                        : "label",
                  data: secondFilterData,
                }}
                value={
                  nearMissFilter[
                    filterByType === "asset"
                      ? "assetId"
                      : filterByType === "obstacleType"
                        ? "obstacleType"
                        : "inspectorId"
                  ]
                }
                label={t("filter")}
                handleFilter={(e) =>
                  changeLastFilter(
                    e,
                    filterByType === "asset"
                      ? "assetId"
                      : filterByType === "obstacleType"
                        ? "obstacleType"
                        : "inspectorId"
                  )
                }
              />
            </Col>
          )}
        </Row>
      )}
    </MoreFilterMobileToggle>
  );
};

const mapStateToProps = (state) => ({
  userTypeList: state.userType.UserTypeList,
  nearMissFilter: state.filters.nearMissFilter,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
});

const mapDispatchToProps = (dispatch) => ({
  getUserTypeList: () => dispatch(getUserTypeList()),
  setNearMissReportFilter: (filterData) => dispatch(setNearMissReportFilter(filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
