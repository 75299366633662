export const csvHeadersData = [
  { label: "Account Name", key: "accountName" },
  { label: "Worksite Name", key: "siteName" },
  { label: "Asset Name", key: "assetName" },
  { label: "User Name", key: "userName" },
  { label: "User Type", key: "userType" },
  { label: "Date/Time", key: "dateTimeFormatted" },
  { label: "Event Category", key: "eventCategory" },
  { label: "Event", key: "systemEvent" },

  { label: "Unlock Method", key: "unlockMethod" },
  { label: "Connection Type", key: "connectionType" },
  { label: "Lock Reason", key: "lockReason" },
  { label: "Config Update Type", key: "configUpdateType" },
  { label: "Email", key: "email" },
  { label: "Platform", key: "platform" },
  { label: "Result", key: "result" },

  { label: "Last Version", key: "lastVer" },
  { label: "New Version", key: "newVer" },
  { label: "Type", key: "type" },
];

export const categoryValueToLabelMap = {
  admin_menu_access: "Admin Menu Access",
  config_update: "Config Update",
  device_shutdown: "Device Shutdown",
  web_auth: "Web Auth",
  internet_activity: "Internet Activity",
  lock_unlock: "Lock/Unlock",
  ota: "OTA",
  web_manage_acc: "Account Management",
  web_manage_asset: "Asset Management",
  web_manage_user: "User Management",
  web_manage_assetType: "Asset Type Management",
  web_manage_userType: "User Type Management",
  web_manage_checklist: "Checklist Management",
};

export const eventValueToLabelMapping = {
  admin_menu_login: "Admin Menu Login",
  config_update: "Config Update",
  web_login: "Login",
  i_connected: "Connected",
  i_disconnected: "Disconnected",
  lock: "Lock",
  unlock: "Unlock",
  device_shutdown: "Device Shutdown",
  restart: "Restart",
  shutdown: "Shutdown",
  ota_update: "OTA Update",
  web_acc_add: "Add Account",
  web_acc_edit: "Edit Account",
  web_acc_status: "Deactivate Account",
  web_site_add: "Add Worksite",
  web_site_edit: "Edit Worksite",
  web_site_status: "Deactivate Worksite",
  web_asset_add: "Add Asset",
  web_asset_edit: "Edit Asset",
  web_asset_status: "Deactivate Asset",
  web_user_add: "Add User",
  web_user_edit: "Edit User",
  web_user_status: "Deactivate User",
  web_assetType_add: "Add Asset Type",
  web_assetType_edit: "Edit Asset Type",
  web_userType_add: "Add User Type",
  web_userType_edit: "Edit User Type",
  web_userType_status: "Deactivate User Type",
  web_checklist_add: "Add Checklist",
  web_checklist_edit: "Edit Checklist",
  web_checklist_status: "Deactivate Checklist",
};
