import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import ChecklistList from "./ChecklistList.component";
import Checklists from "./Checklists.component";
import BreadCrumb from "../../../components/BreadCrumb.component";
import { Trans, useTranslation } from "react-i18next";
import ChecklistTemplatesStyle from "./Templates/ChecklistTemplatesStyle.module.css";
// For Redux and request
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { setAddChecklistModal } from "../../../redux/modals/action";
import { setManagementActionBar, setShowFilters } from "../../../redux/mobileView/action";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
// Button Component
import Button from "../../../components/Button/Button.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import SieraLoader from "../../../components/SieraLoader/SieraLoader.component";
import {
  LoaderOverlay,
  AddChecklistButton,
  ContainerDiv,
  ContentWrapper,
  LeftSection,
  RightSection,
  DesktopView,
  ActionBarButtons,
  ChecklistDetailHeading,
  ActionBarWrapper,
  RightIconImage,
  MobileView,
  ButtonDivEdit,
  ChecklistDescEdit,
} from "./ChecklistManagement.component.styles";
import DropdownComponent from "../../../components/DropdownComponent/Dropdown.component";
import AddChecklistDropdown from "./ChecklistButtonSearch/AddChecklistButton.component";
import SearchBox from "./ChecklistButtonSearch/SearchBoxComponent";
import ActionBarView from "./ChecklistButtonSearch/ActionBarView.component";
import DropdownFilterSection from "./ChecklistButtonSearch/DropdownAccount.component";

const ChecklistManagement = ({
  history,
  loginDetails,
  checklistOverview,
  updateActiveNavItem,
  setAddChecklistModal,
  addChecklistModal,
  checklistLoading,
  showActionBar,
  dropdownAccountList,
  isSuperAdmin,
  setDropdownAccountId,
  dropdownAccountId,
}) => {
  const [editChecklist, setEditChecklist] = useState(false);
  const [filterAccountId, setFilterAccountId] = useState("all");
  const [SearchText, setSearchText] = useState("");
  // const [imageLoaded, setImageLoaded] = useState(false);
  const [showAccountDetail, setShowAccountDetail] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("viewChecklist");
  }, []);

  useEffect(() => {
    if (!addChecklistModal) {
      setEditChecklist(false);
    }
  }, [addChecklistModal]);

  const [showLoader, setShowLoader] = useState(false);
  const [hasShownOnce, setHasShownOnce] = useState(false);

  useEffect(() => {
    if (checklistLoading && !hasShownOnce) {
      setShowLoader(true);
      setHasShownOnce(true);
    } else if (!checklistLoading) {
      setShowLoader(false);
    }
  }, [checklistLoading, hasShownOnce]);

  const [isActionBarVisible, setIsActionBarVisible] = useState(false);

  return (
    <>
      <BreadCrumb />
      {/* {editChecklist ?
        <EditChecklist editChecklist={editChecklist} setEditChecklist={setEditChecklist} /> : */}
      <>
        <DesktopView>
          <AddChecklistDropdown />

          <ContainerDiv>
            {showLoader && (
              <LoaderOverlay>
                <SieraLoader OnlyLoaderIcon size="small" />
              </LoaderOverlay>
            )}
            <ContentWrapper>
              <LeftSection>
                <div>
                  <ChecklistList filterAccountId={filterAccountId} />
                </div>
              </LeftSection>
              <RightSection>
                <DropdownFilterSection
                  dropdownAccountList={dropdownAccountList}
                  dropdownAccountId={dropdownAccountId}
                  setDropdownAccountId={setDropdownAccountId}
                  isSuperAdmin={isSuperAdmin}
                  t={t}
                />

                <ChecklistDescEdit isSuperAdmin={isSuperAdmin}>
                  <Checklists
                    editChecklist={editChecklist}
                    setEditChecklist={setEditChecklist}
                    filterAccountId={filterAccountId}
                    setFilterAccountId={setFilterAccountId}
                  />
                  {/* {checklistOverview && <Row className="editViewCheck">
                {
                  !editChecklist && <button onClick={() => setEditChecklist(true)} className="buttonSubmit ripple">
                    <EditIcon fill='white' width="15px" style={{ marginRight: '10px' }} /><Trans>EDIT</Trans>
                  </button>
                }
              </Row>} */}
                  <PermissionCheck section="DASHBOARD" permissionName="CHECKLIST_SETTINGS" actionName="edit">
                    {checklistOverview && (
                      <ButtonDivEdit>
                        {!editChecklist && (
                          <Button
                            iconClass="edit"
                            label={t("Edit")}
                            onClick={() => setEditChecklist(true)}
                            showIcon
                            buttonType="primary"
                          />
                        )}
                      </ButtonDivEdit>
                    )}
                  </PermissionCheck>
                </ChecklistDescEdit>
              </RightSection>
            </ContentWrapper>
          </ContainerDiv>
        </DesktopView>
        <MobileView>
          {!showAccountDetail ? (
            <ActionBarView
              isActionBarVisible={isActionBarVisible}
              setIsActionBarVisible={setIsActionBarVisible}
            />
          ) : (
            ""
          )}

          <ActionBarWrapper>
            <ActionBarButtons>{!isActionBarVisible ? null : <AddChecklistDropdown />}</ActionBarButtons>
          </ActionBarWrapper>

          {!showAccountDetail ? (
            <>
              <SearchBox
                searchText={SearchText}
                setSearchText={setSearchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <DropdownFilterSection
                dropdownAccountList={dropdownAccountList}
                dropdownAccountId={dropdownAccountId}
                setDropdownAccountId={setDropdownAccountId}
                isSuperAdmin={isSuperAdmin}
                t={t}
              />
            </>
          ) : (
            <>
              <ActionBarView
                isActionBarVisible={isActionBarVisible}
                setIsActionBarVisible={setIsActionBarVisible}
              />
            </>
          )}
          {!showAccountDetail ? (
            <div onClick={() => setShowAccountDetail(true)}>
              <ChecklistList
                filterAccountId={filterAccountId}
                //   setImageLoaded={() => setImageLoaded(false)}
                searchText={SearchText}
                onChange={(e) => setSearchText(e.target.value)}
                setSearchText={setSearchText}
                style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
              />
            </div>
          ) : (
            <>
              <ChecklistDetailHeading>
                <span onClick={() => setShowAccountDetail(false)}>Your Checklist</span>
                <RightIconImage className="icon icon-arrow-right"></RightIconImage>
                {checklistOverview ? checklistOverview?.checklistName : ""}
              </ChecklistDetailHeading>

              <Checklists
                editChecklist={editChecklist}
                setEditChecklist={setEditChecklist}
                filterAccountId={filterAccountId}
                setFilterAccountId={setFilterAccountId}
                //   imageLoaded={imageLoaded}
                //   setImageLoaded={(data) => setImageLoaded(data)}
              />

              <PermissionCheck section="DASHBOARD" permissionName="CHECKLIST_SETTINGS" actionName="edit">
                {checklistOverview && (
                  <ButtonDivEdit>
                    {!editChecklist && (
                      <Button
                        iconClass="edit"
                        label={t("Edit")}
                        onClick={() => setEditChecklist(true)}
                        showIcon
                        buttonType="primary"
                      />
                    )}
                  </ButtonDivEdit>
                )}
              </PermissionCheck>
            </>
          )}
        </MobileView>
      </>
      {/* } */}
    </>
  );
};

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
  loginDetails: state.user.loginDetails,
  checklistOverview: state.checklist.checklistOverview,
  addChecklistModal: state.modals.addChecklistModal,
  checklistLoading: state.checklist.checklistLoading,
  dropdownAccountId: state.dropdownFilters.accountId,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
});

const mapDispatchToProps = (dispatch) => ({
  setManagementActionBar: (data) => dispatch(setManagementActionBar(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setAddChecklistModal: (data) => dispatch(setAddChecklistModal(data)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistManagement));
