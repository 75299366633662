import { countTypes } from "./types";
import { isValidateDateObject } from "../../utils/validations/checkValidation";
import moment from "moment";
import { getMonth } from "../../utils/validations/utilities";
import { apiCall } from "../../utils/apiCall";
import { CacheManager } from "../../utils/cache/CacheManager";
import { CacheRefs } from "../../utils/cache/CacheRefs";

// To set the count
export const getAllCountSuccess = (data) => ({
  type: countTypes.GET_ALL_COUNT_SUCCESS,
  data: data,
});

// To set the get count loading
export const getAllCountLoading = () => ({
  type: countTypes.GET_ALL_COUNT_LOADING,
});

//To get the all count
export const getAllCount = () => {
  return async (dispatch, getState) => {
    dispatch(getAllCountLoading());
    const url = `/account/getCount`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getAllCountSuccess(cacheResult1));
        return;
      }
    }
    apiCall(url)
      .then((response) => {
        if (cache) {
          if (response?.data?.data[0]) {
            cache.set(url, response.data.data[0], CacheRefs.getTtlShort());
          }
        }
        dispatch(getAllCountSuccess(response.data.data[0]));
      })
      .catch((err) => {});
  };
};

// To set the inspection count
export const getInspectionCountSuccess = (data) => ({
  type: countTypes.GET_INSPECTION_COUNT_SUCCESS,
  data: data,
});

// To set the get all count loading
export const getInspectionCountLoading = () => ({
  type: countTypes.GET_INSPECTION_COUNT_LOADING,
});

//To get the inspection count
export const getInspectionCount = (filterData) => {
  return async (dispatch, getState) => {
    dispatch(getInspectionCountLoading());
    let state = getState();
    let isSuperAdmin = state?.user?.isSuperAdmin;
    let isSiteAdmin = state?.user?.isSiteAdmin;

    let { accountId, inspectorId, worksiteId, assetId, assetTypeId, startDate, endDate } = filterData;
    if (!accountId) {
      accountId = "all";
    }
    if (!inspectorId) {
      inspectorId = "all";
    }
    if (!worksiteId) {
      worksiteId = "all";
    }
    if (!assetId) {
      assetId = "all";
    }
    if (!assetTypeId) {
      assetTypeId = "all";
    }
    if (!endDate) {
      endDate = "all";
    }
    if (!startDate) {
      startDate = "all";
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.userData?.accountId;
    }
    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }
    const url = `/checklist/getInspectionCount/${accountId}/${inspectorId}/${worksiteId}/${assetId}/${assetTypeId}/${startDate}/${endDate}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getInspectionCountSuccess(cacheResult1));
        return;
      }
    }
    apiCall(url)
      .then((response) => {
        if (cache) {
          if (response?.data?.InspectionCount) {
            cache.set(url, response.data.InspectionCount, CacheRefs.getTtlShort()); //2 mins ttl = 120000 millis
          }
        }
        dispatch(getInspectionCountSuccess(response.data.InspectionCount));
      })
      .catch((err) => {});
  };
};

// To set the inspection count
export const getIncidentCountSuccess = (data) => ({
  type: countTypes.GET_INCIDENT_COUNT_SUCCESS,
  data: data,
});

// To set the get all count loading
export const getIncidentCountLoading = () => ({
  type: countTypes.GET_INCIDENT_COUNT_LOADING,
});

export const getImpactCount = (filterData) => {
  return async (dispatch, getState) => {
    dispatch(getIncidentCountLoading());
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const isSiteAdmin = state?.user?.isSiteAdmin;

    let { accountId, inspectorId, worksiteId, assetId, assetTypeId, startDate, endDate } = filterData;

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }

    const bodyData = {
      accounts: accountId && accountId !== "all" ? [accountId] : "all",
      sites: worksiteId && worksiteId !== "all" ? [worksiteId] : "all",
      assetTypes: assetTypeId && assetTypeId !== "all" ? [assetTypeId] : "all",
      userTypes: [],
      assets: assetId && assetId !== "all" ? [assetId] : "all",
      users: inspectorId && inspectorId !== "all" ? [inspectorId] : "all",
      severity: ["LOW", "MEDIUM", "HIGH"],
      startDate: startDate,
      endDate: endDate,
    };

    //  const url = `/impact/getImpactCount`;

    /*    Disabling cache as this is causing issue with live data
    const cache = CacheManager.getInstance();
     if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getIncidentCountSuccess(cacheResult1));
        return;
      }
    } */
    try {
      const response = await apiCall(`/impact/getImpactCount`, {
        method: "POST",
        data: bodyData,
      });
      const { total, low, medium, high } = response?.data?.data || {};
      const data = {
        impactCount: {
          totalImpacts: total,
          LowImpacts: low,
          MediumImpacts: medium,
          HighImpacts: high,
        },
      };

      dispatch(getIncidentCountSuccess(data));
    } catch (err) {
      console.error("Error fetching impact count:", err);

      // Handle fallback data if API fails
      const data = {
        impactCount: {
          totalImpacts: 0,
          LowImpacts: 0,
          MediumImpacts: 0,
          HighImpacts: 0,
        },
      };

      console.log("Using fallback impact data:", data);
    }
  };
};

export const getNearMissCount = (filterData) => {
  return async (dispatch, getState) => {
    dispatch(getIncidentCountLoading());
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const isSiteAdmin = state?.user?.isSiteAdmin;

    let { accountId, inspectorId, worksiteId, assetId, assetTypeId, startDate, endDate, obstacleType } =
      filterData || {};

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }

    const bodyData = {
      accounts: accountId && accountId !== "all" ? [accountId] : "all",
      sites: worksiteId && worksiteId !== "all" ? [worksiteId] : "all",
      assetTypes: assetTypeId && assetTypeId !== "all" ? [assetTypeId] : "all",
      userTypes: [],
      assets: assetId && assetId !== "all" ? [assetId] : "all",
      users: inspectorId && inspectorId !== "all" ? [inspectorId || "all"] : "all",
      startDate,
      endDate,
    };

    const response = await apiCall(`/nearmiss/getNearMissCount`, {
      method: "POST",
      data: bodyData,
    });

    const { total = 0, pedestrian = 0, object = 0 } = response?.data?.data || {};
    const data = {
      nearMissCount: {
        totalNearMisses: total,
        nearMissObject: object,
        nearMissPedestrian: pedestrian,
      },
    };

    dispatch(getIncidentCountSuccess(data));
  };
};

export const getUnauthorizedCountLoading = () => ({
  type: countTypes.GET_UNAUTHORIZED_COUNT_LOADING,
});

export const getUnauthorizedCountSuccess = (data) => ({
  type: countTypes.GET_UNAUTHORIZED_COUNT_SUCCESS,
  data,
});
export const getUnauthorizedAccessCount = (filterData) => {
  return async (dispatch, getState) => {
    dispatch(getUnauthorizedCountLoading());
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const isSiteAdmin = state?.user?.isSiteAdmin;

    let { accountId, inspectorId, worksiteId, assetId, assetTypeId, startDate, endDate } = filterData;
    if (!accountId) {
      accountId = "all";
    }
    if (!inspectorId) {
      inspectorId = "all";
    }
    if (!worksiteId) {
      worksiteId = "all";
    }
    if (!assetId) {
      assetId = "all";
    }
    if (!assetTypeId) {
      assetTypeId = "all";
    }
    if (!endDate) {
      endDate = "all";
    }
    if (!startDate) {
      startDate = "all";
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }

    const url = `/impact/unauthorizedCount/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${inspectorId}/${startDate}/${endDate}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getUnauthorizedCountSuccess(cacheResult1));
        return;
      }
    }
    apiCall(url).then(({ data }) => {
      if (cache) {
        if (data?.UnauthorizedCount) {
          cache.set(url, data?.UnauthorizedCount, CacheRefs.getTtlShort());
        }
      }
      dispatch(getUnauthorizedCountSuccess(data?.UnauthorizedCount));
    });
  };
};

export const getGraphData = (filterData) => {
  return async (dispatch, getState) => {
    // dispatch(getInspectionCountLoading());
    const state = getState();
    const isSuperAdmin = state && state.user && state.user.isSuperAdmin;
    const isSiteAdmin = state && state.user && state.user.isSiteAdmin;

    let { accountId, worksiteId, assetTypeId, assetId, inspectorId, startDate, endDate } = filterData;

    if (!accountId) {
      accountId = "all";
    }
    if (!inspectorId) {
      inspectorId = "all";
    }
    if (!worksiteId) {
      worksiteId = "all";
    }
    if (!assetId) {
      assetId = "all";
    }
    if (!assetTypeId) {
      assetTypeId = "all";
    }
    if (!isValidateDateObject(new Date(endDate))) {
      endDate = moment().format("YYYY-MM-DD");
    } else {
      endDate = moment(endDate).format("YYYY-MM-DD");
    }
    if (!isValidateDateObject(new Date(startDate))) {
      startDate = moment().subtract(30, "days").format("YYYY-MM-DD HH:mm:ss");
    } else {
      startDate = moment(startDate).format("YYYY-MM-DD");
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId || state?.user?.loginDetails?.userData?.accountId;
    }

    if (isSiteAdmin) {
      worksiteId =
        state && state.user && state.user.loginDetails && state.user.loginDetails.userData.authorizedSites[0];
    }

    if (!accountId) {
      return;
    }
    const url = `/checklist/inspectiongraph/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${inspectorId}/${startDate}/${endDate}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch({
          type: countTypes.GET_GRAPH_DATA_SUCCESS,
          payload: cacheResult1,
        });
        return;
      }
    }
    apiCall(url)
      .then(({ data }) => {
        const count = [];
        const passed = [];
        const failed = [];
        const timedout = [];
        const date = [];
        if (data?.data) {
          let sortFormat = Number(startDate.split("-")[1]) > Number(endDate.split("-")[1]);
          data.data
            .sort((a, b) => {
              if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return -1;
              } else if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return 1;
              }
              return 0;
            })
            .forEach((dat) => {
              count.push(dat.count ?? 0);
              passed.push(dat.passed ?? 0);
              failed.push(dat.failed ?? 0);
              timedout.push(dat.timedout ?? 0);
              date.push(new Date(`${dat?._id?.day} ${getMonth(dat?._id?.month)} ${dat._id.year}`).getTime());
            });
        }
        const payload = {
          count,
          passed,
          failed,
          timedout,
          date,
        };
        if (cache) {
          cache.set(url, payload, CacheRefs.getTtlShort());
        }
        dispatch({
          type: countTypes.GET_GRAPH_DATA_SUCCESS,
          payload: payload,
        });
      })
      .catch((err) => {
        dispatch({
          type: countTypes.GET_GRAPH_DATA_FAILED,
        });
      });
  };
};

export const getImpactGraphData = (filterData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const isSiteAdmin = state?.user?.isSiteAdmin;

    let { accountId, worksiteId, assetTypeId, assetId, inspectorId, startDate, endDate } = filterData;

    if (!isValidateDateObject(new Date(endDate))) {
      endDate = moment().format("YYYY-MM-DD");
    } else {
      endDate = moment(endDate).format("YYYY-MM-DD");
    }
    if (!isValidateDateObject(new Date(startDate))) {
      startDate = moment().startOf("month").add(1, "second").format("YYYY-MM-DD HH:mm:ss");
    } else {
      startDate = moment(startDate).format("YYYY-MM-DD");
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }
    /*     const url = `/impact/impactgraph/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${inspectorId}/${startDate}/${endDate}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch({
          type: countTypes.GET_INCIDENT_GRAPH_DATA_SUCCESS,
          payload: cacheResult1,
        });
        return;
      }
    } */

    const bodyData = {
      accounts: accountId && accountId !== "all" ? [accountId] : "all",
      sites: worksiteId && worksiteId !== "all" ? [worksiteId] : "all",
      assetTypes: assetTypeId && assetTypeId !== "all" ? [assetTypeId] : "all",
      userTypes: "all",
      assets: assetId && assetId !== "all" ? [assetId] : "all",
      users: inspectorId && inspectorId !== "all" ? [inspectorId] : "all",
      severity: ["LOW", "MEDIUM", "HIGH"],
      startDate,
      endDate,
    };

    const response = await apiCall("/impact/getGraphData", {
      method: "POST",
      data: bodyData,
    });

    const data = response.data.data;
    const count = [];
    const high = [];
    const low = [];
    const medium = [];
    const date = [];

    if (data) {
      let sortFormat = Number(startDate.split("-")[1]) > Number(endDate.split("-")[1]);
      data
        /*  .sort((a, b) => {
          if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
            return -1;
          } else if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
            return 1;
          }
          return 0;
        }) */
        .forEach((dat) => {
          count.push(dat.count ?? 0);
          high.push(dat.high ?? 0);
          low.push(dat.low ?? 0);
          medium.push(dat.mid ?? 0);
          date.push(new Date(`${dat?._id?.day} ${getMonth(dat?._id?.month)} ${dat._id.year}`).getTime());
        });
    }
    const payload = {
      count,
      high,
      low,
      medium,
      date,
    };

    /*  if (cache) {
      cache.set(url, payload, CacheRefs.getTtlShort());
    } */
    dispatch({
      type: countTypes.GET_INCIDENT_GRAPH_DATA_SUCCESS,
      payload: payload,
    });

    /*     apiCall(url)
      .then(({ data }) => {


        const count = [];
        const high = [];
        const low = [];
        const medium = [];
        const date = [];
        if (data?.data) {
          let sortFormat = Number(startDate.split("-")[1]) > Number(endDate.split("-")[1]);
          data.data
            .sort((a, b) => {
              if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return -1;
              } else if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return 1;
              }
              return 0;
            })
            .forEach((dat) => {
              count.push(dat.Count ?? 0);
              high.push(dat.High ?? 0);
              low.push(dat.Low ?? 0);
              medium.push(dat.Mid ?? 0);
              date.push(new Date(`${dat?._id?.day} ${getMonth(dat?._id?.month)} ${dat._id.year}`).getTime());
            });
        }
        const payload = {
          count,
          high,
          low,
          medium,
          date,
        };
        if (cache) {
          cache.set(url, payload, CacheRefs.getTtlShort());
        }
        dispatch({
          type: countTypes.GET_INCIDENT_GRAPH_DATA_SUCCESS,
          payload: payload,
        });
      })
      .catch((err) => {
        dispatch({
          type: countTypes.GET_INCIDENT_GRAPH_DATA_FAILED,
        });
      }); */
  };
};

export const getNearMissGraphData = (filterData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const isSiteAdmin = state?.user?.isSiteAdmin;

    let { accountId, worksiteId, assetTypeId, assetId, inspectorId, startDate, endDate } = filterData;

    if (!isValidateDateObject(new Date(endDate))) {
      endDate = moment().format("YYYY-MM-DD");
    } else {
      endDate = moment(endDate).format("YYYY-MM-DD");
    }
    if (!isValidateDateObject(new Date(startDate))) {
      startDate = moment().startOf("month").add(1, "second").format("YYYY-MM-DD HH:mm:ss");
    } else {
      startDate = moment(startDate).format("YYYY-MM-DD");
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }

    const bodyData = {
      accounts: accountId && accountId !== "all" ? [accountId] : "all",
      sites: worksiteId && worksiteId !== "all" ? [worksiteId] : "all",
      assetTypes: assetTypeId && assetTypeId !== "all" ? [assetTypeId] : "all",
      userTypes: [],
      assets: assetId && assetId !== "all" ? [assetId] : "all",
      users: inspectorId && inspectorId !== "all" ? [inspectorId] : "all",
      startDate: "2021-01-15 00:00:01",
      endDate: "2025-02-14 23:59:59",
    };

    const response = await apiCall("/nearmiss/getNearMissGraphData", {
      method: "POST",
      data: bodyData,
    });

    const data = response.data.data;

    const count = [];
    const pedestrian = [];
    const object = [];
    const date = [];

    if (data) {
      data.forEach((dat) => {
        count.push(dat.count ?? 0);
        pedestrian.push(dat.pedestrian ?? 0);
        object.push(dat.object ?? 0);
        date.push(new Date(`${dat?._id?.day} ${getMonth(dat?._id?.month)} ${dat._id.year}`).getTime());
      });
    }
    const payload = {
      count,
      pedestrian,
      object,
      date,
    };

    dispatch({
      type: countTypes.GET_NEAR_MISS_GRAPH_DATA_SUCCESS,
      payload: payload,
    });

    /*     apiCall(url)
      .then(({ data }) => {
        const count = [];
        const high = [];
        const low = [];
        const medium = [];
        const date = [];
        if (data?.data) {
          let sortFormat = Number(startDate.split("-")[1]) > Number(endDate.split("-")[1]);
          data.data
            .sort((a, b) => {
              if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return -1;
              } else if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return 1;
              }
              return 0;
            })
            .forEach((dat) => {
              count.push(dat.Count ?? 0);
              high.push(dat.High ?? 0);
              low.push(dat.Low ?? 0);
              medium.push(dat.Mid ?? 0);
              date.push(new Date(`${dat?._id?.day} ${getMonth(dat?._id?.month)} ${dat._id.year}`).getTime());
            });
        }
        const payload = {
          count,
          high,
          low,
          medium,
          date,
        };
        if (cache) {
          cache.set(url, payload, CacheRefs.getTtlShort());
        }
        dispatch({
          type: countTypes.GET_INCIDENT_GRAPH_DATA_SUCCESS,
          payload: payload,
        });
      })
      .catch((err) => {
        dispatch({
          type: countTypes.GET_INCIDENT_GRAPH_DATA_FAILED,
        });
      }); */
  };
};

export const getIncidentGraphData = (filterData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const isSiteAdmin = state?.user?.isSiteAdmin;

    let { accountId, worksiteId, assetTypeId, assetId, inspectorId, startDate, endDate } = filterData;

    if (!accountId) {
      accountId = "all";
    }
    if (!inspectorId) {
      inspectorId = "all";
    }
    if (!worksiteId) {
      worksiteId = "all";
    }
    if (!assetId) {
      assetId = "all";
    }
    if (!assetTypeId) {
      assetTypeId = "all";
    }
    if (!isValidateDateObject(new Date(endDate))) {
      endDate = moment().format("YYYY-MM-DD");
    } else {
      endDate = moment(endDate).format("YYYY-MM-DD");
    }
    if (!isValidateDateObject(new Date(startDate))) {
      startDate = moment().startOf("month").add(1, "second").format("YYYY-MM-DD HH:mm:ss");
    } else {
      startDate = moment(startDate).format("YYYY-MM-DD");
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }
    const url = `/impact/impactgraph/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${inspectorId}/${startDate}/${endDate}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch({
          type: countTypes.GET_INCIDENT_GRAPH_DATA_SUCCESS,
          payload: cacheResult1,
        });
        return;
      }
    }
    apiCall(url)
      .then(({ data }) => {
        const count = [];
        const high = [];
        const low = [];
        const medium = [];
        const date = [];
        if (data?.data) {
          let sortFormat = Number(startDate.split("-")[1]) > Number(endDate.split("-")[1]);
          data.data
            .sort((a, b) => {
              if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return -1;
              } else if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return 1;
              }
              return 0;
            })
            .forEach((dat) => {
              count.push(dat.Count ?? 0);
              high.push(dat.High ?? 0);
              low.push(dat.Low ?? 0);
              medium.push(dat.Mid ?? 0);
              date.push(new Date(`${dat?._id?.day} ${getMonth(dat?._id?.month)} ${dat._id.year}`).getTime());
            });
        }
        const payload = {
          count,
          high,
          low,
          medium,
          date,
        };
        if (cache) {
          cache.set(url, payload, CacheRefs.getTtlShort());
        }
        dispatch({
          type: countTypes.GET_INCIDENT_GRAPH_DATA_SUCCESS,
          payload: payload,
        });
      })
      .catch((err) => {
        dispatch({
          type: countTypes.GET_INCIDENT_GRAPH_DATA_FAILED,
        });
      });
  };
};

export const getUnauthorizedGraphData = (filterData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const isSiteAdmin = state?.user?.isSiteAdmin;

    let { accountId, worksiteId, assetTypeId, assetId, inspectorId, startDate, endDate } = filterData;

    if (!accountId) {
      accountId = "all";
    }
    if (!inspectorId) {
      inspectorId = "all";
    }
    if (!worksiteId) {
      worksiteId = "all";
    }
    if (!assetId) {
      assetId = "all";
    }
    if (!assetTypeId) {
      assetTypeId = "all";
    }
    if (!isValidateDateObject(new Date(endDate))) {
      endDate = moment().format("YYYY-MM-DD");
    } else {
      endDate = moment(endDate).format("YYYY-MM-DD");
    }
    if (!isValidateDateObject(new Date(startDate))) {
      startDate = moment().subtract(30, "days").format("YYYY-MM-DD HH:mm:ss");
    } else {
      startDate = moment(startDate).format("YYYY-MM-DD");
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.accountId;
    }

    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }
    const url = `/impact/unAuthorizedgraph/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${startDate}/${endDate}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch({
          type: countTypes.GET_UNAUTHORIZED_GRAPH_DATA_SUCCESS,
          payload: cacheResult1,
        });
        return;
      }
    }
    apiCall(url)
      .then(({ data }) => {
        const count = [];
        const na = [];
        const date = [];
        if (data?.data) {
          let sortFormat = Number(startDate.split("-")[1]) > Number(endDate.split("-")[1]);
          data.data
            .sort((a, b) => {
              if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return -1;
              } else if (sortFormat ? a?._id?.month > b?._id?.month : a?._id?.month < b?._id?.month) {
                return 1;
              }
              return 0;
            })
            .forEach((dat) => {
              count.push(dat.Count ?? 0);
              na.push(dat.NA ?? 0);
              date.push(new Date(`${dat?._id?.day} ${getMonth(dat?._id?.month)} ${dat._id.year}`).getTime());
            });
        }
        const payload = {
          count,
          date,
        };
        if (cache.set(url, payload, CacheRefs.getTtlShort()))
          dispatch({
            type: countTypes.GET_UNAUTHORIZED_GRAPH_DATA_SUCCESS,
            payload: payload,
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: countTypes.GET_UNAUTHORIZED_GRAPH_DATA_FAILED,
        });
      });
  };
};
