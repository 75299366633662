import styled from "styled-components";

// Copyright Text Stylings
export const CopyrightText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  padding-top: 18px;
  padding-bottom: 18px;
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  align-content: center;
  text-align: center;
  width: 100%;
  padding: 0;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
