import React from "react";
import { Row, Col } from "react-bootstrap";
//import DateTimeRangePicker from "../../../../components/DateTimeRangePicker.component";
import DateTimeRangePicker from "../../../../components/DatePickerComponent/DateTimePicker.component";
//import DateTimeRangePicker from "../../../../components/DatePickerComponent/DateTimePickerWrapper";
import ExportToCSV from "./ExportToCSV.component";
import { useTranslation } from "react-i18next";
import errorDescription from "../../../../constant/errorDescription";
import DropdownFilterWrapper from "../../../../components/DropdownComponent/DropdownFilterWrapper";
import DropdownWrapper from "../../../../components/DropdownComponent/DropdownWrapper";
import Button from "../../../../components/Button/Button.component";

const FiltersComponent = ({
  isSuperAdmin,
  isSiteAdmin,
  dropdownAccountList,
  dropdownWorksiteList,
  dropdownAssetList,
  dropdownAccountId,
  dropdownWorksiteId,
  dropdownAssetId,
  localAccountId,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetId,
  setLocalAccountId,
  handleClearAllSelection,
  selectedErrorDescription,
  setSelectedErrorDescription,
  dateDropdown,
  setDateDropdown,
  date,
  setDate,
  dateTimeChange,
  confirmDateTimeChange,
  downloadReportSet,
  errorLogs,
  isDownloadAllSelected,
  loginDetails,
  changeDateTypeDropdown,
  currentTimeZone,
  applyFilters,
}) => {
  const { t } = useTranslation();

  const dateTypeOption = [
    { _id: 2, label: "Today" },
    { _id: 3, label: "Yesterday" },
    { _id: 4, label: "Last 3 Days" },
    { _id: 5, label: "Last 7 Days" },
    { _id: 6, label: "Last 30 Days" },
    { _id: 1, label: "Custom" },
  ];

  return (
    <>
      <Row>
        {isSuperAdmin && (
          <Col lg={3}>
            <DropdownFilterWrapper
              options={dropdownAccountList?.map((data) => ({
                _id: data._id,
                label: data.companyName,
              }))}
              label={t("Account")}
              isValidationError={false}
              value={localAccountId}
              size="medium"
              onChange={(value) => {
                handleClearAllSelection();
                setDropdownAccountId(value);
                setLocalAccountId(value);
              }}
              required={true}
            />
          </Col>
        )}
        {!isSiteAdmin && localAccountId && (
          <Col lg={3}>
            <DropdownWrapper
              filter={{
                type: "Worksite",
                key: "name",
                data: dropdownWorksiteList,
              }}
              value={dropdownWorksiteId}
              label={t("filter")}
              useAsDropdown={false}
              handleFilter={(value) => {
                handleClearAllSelection();
                setDropdownWorksiteId(value);
              }}
            />
          </Col>
        )}
        {localAccountId && (
          <>
            <Col lg={3}>
              <DropdownWrapper
                filter={{
                  type: "Asset",
                  key: "assetName",
                  data: dropdownAssetList,
                }}
                value={dropdownAssetId}
                label={t("filter")}
                useAsDropdown={false}
                handleFilter={(value) => {
                  handleClearAllSelection();
                  setDropdownAssetId(value);
                }}
              />
            </Col>
            <Col lg={3}>
              <DropdownWrapper
                filter={{
                  type: "Error Description",
                  key: "name",
                  data: errorDescription,
                }}
                value={selectedErrorDescription}
                label={t("filter")}
                useAsDropdown={false}
                handleFilter={(value) => {
                  handleClearAllSelection();
                  setSelectedErrorDescription(value);
                }}
              />
            </Col>
          </>
        )}
      </Row>
      {localAccountId && (
        <Row className="mt-1">
          <Col lg={3}>
            <DropdownFilterWrapper
              options={dateTypeOption}
              label={t("Duration")}
              isValidationError={false}
              value={dateDropdown}
              onChange={(value) => changeDateTypeDropdown(value)}
              sortData={false}
              size="medium"
            />
          </Col>
          <Col lg={6}>
            <DateTimeRangePicker
              startDate={date?.startDate}
              endDate={date?.endDate}
              startTime={date?.startTime}
              endTime={date?.endTime}
              setDate={setDate}
              filterState={date}
              dateDisable={dateDropdown !== 1}
              timeDisable={![1, 2].includes(dateDropdown)}
              confirmDateTimeChange={confirmDateTimeChange}
              dateTimeChange={dateTimeChange}
              callback={handleClearAllSelection}
              maxDate={new Date().toISOString().substring(0, 10)}
            />
          </Col>

          <Col lg={3} style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}>
            <Button
              label={t("Apply Filter")}
              size="medium"
              onClick={applyFilters}
              showIcon={false}
              buttonType="primary"
            />
            <ExportToCSV
              downloadReportSet={downloadReportSet}
              reportList={errorLogs}
              isDownloadAllSelected={isDownloadAllSelected}
              loginDetails={loginDetails}
              localAccountId={localAccountId}
              date={date}
              currentTimeZone={currentTimeZone}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default FiltersComponent;
