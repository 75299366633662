import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import List from "../../../components/List";
import { selectAccount, getAccounts, getAccountDetailById } from "../../../redux/accounts/action";
import { setEditAlertModal } from "../../../redux/modals/action";

const AccountListStyled = styled.div`
  /*width: 100%;
  .table {
    display: block;
    overflow-x: hidden;
    tbody {
      display: block;
    }
  }
  tr.tableTr {
    display: block;
  }
  tr.tableTr td {
    display: block;
  }
  .tableHeight {
    overflow-y: scroll;
    height: 100%;
    height: calc(100vh - 305px);
    overflow-x: hidden;
  }
  table tr.tableTr td p {
    width: 80%;
  }*/
`;

const listType = require("../../../constant/listType");

function AccountList({
  accounts,
  selectAccount,
  selectedAccount,
  searchText,
  getAccounts,
  totalCount,
  setImageLoaded,
  AccountLoading,
  getAccountDetailById,
  AccountOverview,
  editForm,
  setEditAlertModal,
}) {
  const { t } = useTranslation();
  const listDetail = {
    label: t("Your Accounts"),
    emptyListText: t("NO_ACCOUNT_FOUND"),
    listType: listType.ACCOUNT,
    heightOffset: 303,
  };

  return (
    <AccountListStyled>
      <>
        <List
          hideHeader={true}
          listDetail={listDetail}
          listData={accounts}
          selectItem={(id) => {
            if (id == (selectedAccount && selectedAccount._id)) {
              return;
            }
            if (editForm) {
              getAccountDetailById(id);
              setImageLoaded(false);
            } else {
              setEditAlertModal(true);
            }
          }}
          selectedId={selectedAccount && selectedAccount._id}
          isFilterChange={searchText}
          loadData={getAccounts}
          totalCount={totalCount}
          searchKey={searchText?.split?.("")?.length > 3 ? searchText : ""}
          dataLoading={AccountLoading}
          overviewDataEmpty={AccountOverview ? false : true}
        />
      </>
    </AccountListStyled>
  );
}

const mapStateToProps = (state) => ({
  accounts: state.accounts.Accounts,
  selectedAccount: state.accounts.AccountOverview,
  totalCount: state.accounts.totalCount,
  AccountLoading: state.accounts.AccountLoading,
  AccountOverview: state.accounts.AccountOverview,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  getAccountDetailById: (id) => dispatch(getAccountDetailById(id)),
  selectAccount: (id) => dispatch(selectAccount(id)),
  getAccounts: (paginationData, isResetState) => dispatch(getAccounts(paginationData, isResetState)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountList);
