import React from "react";
import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
// Landing Routes
import LandingRoutes from "./routes/Landing/landingRoutes";
import DashboardLandingRoutes from "./routes/Dashboard/LandingRoutes";
import useController from "./AppController";
import AppContext from "./AppContext";
import Alert from "./components/Alert";
import ErrorRoutes from "./routes/ErrorRoutes";
import OfflineModal from "./OfflineModal";
import { baseTheme, darkTheme } from "./themes/index";
import "./App.css";
import "./assets/styles/styles.css";
import "./intro-custom.css";
import GlobalStyle from "./themes/globalStyleCSS";

if (import.meta.env.REACT_APP_DISABLE_CONSOLE_LOGS === "true") {
  console.log = () => {};
}
if (import.meta.env.REACT_APP_DISABLE_CONSOLE_ERROR === "true") {
  console.error = () => {};
}

if (import.meta.env.REACT_APP_DISABLE_CONSOLE_WARN === "true") {
  console.warn = () => {};
}
if (import.meta.env.REACT_APP_DISABLE_CONSOLE_INFO === "true") {
  console.info = () => {};
}
if (import.meta.env.REACT_APP_DISABLE_CONSOLE_DEBUG === "true") {
  console.debug = () => {};
}
const { Provider } = AppContext;

export default function App() {
  const { state, dispatch } = useController();

  return (
    <Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      <div className="App" style={{ height: `calc(100% - ${1}px)` }}>
        {state.alert && (
          <Alert
            isSuccess={state.alert.type === "success"}
            isFailed={state.alert.type === "error"}
            label={state.alert.message}
            duration={state.alert.duration}
          />
        )}
        <HashRouter>
          <ThemeProvider theme={baseTheme}>
            <DashboardLandingRoutes />
            <GlobalStyle />
            <LandingRoutes />
            <ErrorRoutes />
          </ThemeProvider>
        </HashRouter>
        <OfflineModal />
      </div>
    </Provider>
  );
}
