//TODO - When time permits, review and optimize this file. Remove unused/commented code. Create cleaner sub components

import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import moment from "moment";
import QrCode from "../../../components/QrCodeAndDownload.component";
import { ReactComponent as UserProfile } from "../../../assets/Profile_Photo.svg";
import UserOverviewTab from "./Tabs/UserOverviewTab.component";
import OperatorCertificatesTab from "./Tabs/operatorCertificate/OperatorCertificatesTab.component";
import UserMultiCard from "./UserMultiCard.component";
import PasswordModal from "./modals/ChangePassword.component";
import ImageUpload from "../../../components/ImageRenderUpload.component";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";
import { setUpdateUserModal } from "../../../redux/modals/action";
import SnackBar from "../SnackBar";
import {
  userStatusUpdate,
  resetUsersState,
  updateUserDetail,
  getUserDetailById,
  getUserExportData,
  updateUserSuccess,
  getAllWorksiteUsersByAccountId,
} from "../../../redux/users/action";
import {
  updateUser,
  updateLoginUserDetail,
  updateAccountOwner,
  updateAccountOwnerResetFlags,
  updateCurrentUser,
} from "../../../redux/user/user.action";
import { setEditForm } from "../../../redux/forms/action";
import { setEditAlertModal } from "../../../redux/modals/action";

import alertConstant from "../../../constant/messages/toaster.json";
import {
  toEditUserName,
  resetUserNameState,
  setSelectedAdminUser,
} from "../../../redux/userManagement/userManagement.action";

import { apiCall } from "../../../utils/apiCall";
import AssetPermissionTab from "./Tabs/assetPermission/AssetPermissionTab.component";
import { getAllassetTypesWithCountByAccountId } from "../../../redux/assetType/assetType.action";
import {
  ManagementPanelWrapper,
  Error,
  ParagraphDiv,
  UserOverviewWrapper,
  UserOverviewMainDiv,
  MainWrapper,
  WrapperDiv,
  WrapperDivDetails,
  FullNameSpan,
  HeadingSpan,
  HeadingDiv,
  MultiCardDiv,
  SwitchWrapper,
  ImageAndDescDiv,
  WrapperDesDiv,
  ImagWrapperDiv,
  StatusHead,
  PinPassDiv,
  PassWrapper,
  PinWrappper,
  StatusMobileRender,
  StatusDesktopRender,
  SpanText,
  IconPassPin,
  TabComponentDiv,
  NoSectionStyle,
} from "./UserOverview.component.styles";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SieraTab from "../../../components/TabComponent/SieraTab.Component";
import { CacheRefs } from "../../../utils/cache/CacheRefs";
import { toValidateUserName, toValidateEmail } from "../../../utils/validations/checkValidation";
import { getUserTypeDetailList, getUserTypeList } from "../../../redux/userType/action";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import ModalPopup from "../../../components/ModalPopupComponent/ModalPopup.component";
import AdminList from "./AdminList.component";
import config from "../../../config";
import VerifyPassword from "./VerifyPassword.component";
import DetailsDescriptions from "../../../components/DetailsDescriptions/DetailsDescriptions.component";

const s3BucketProfileImg = config.s3BucketProfileImg; //User profile image s3 path
const permissionToLabel = require("../../../constant/permissionToLabel");
const qrType = require("../../../constant/qrType");

const UserOverview = ({
  userOverviewDetail,
  loginDetails,
  updateUserDetail,
  success,
  resetUserNameState,
  userStatusUpdate,
  statusSuccess,
  resetUsersState,
  inspectionCount,
  UsersLoading,
  isSuperAdmin,
  UserDetailLoading,
  updateUser,
  getUserDetailById,
  updateUserSuccess,
  setEditForm,
  editForm,
  setEditAlertModal,
  updateLoginUserDetail,
  getAllassetTypesWithCountByAccountId,
  accountListById,
  getUserExportData,
  updateUserSuccessAction,
  getUserTypeDetailList,
  getUserTypeList,
  userTypeDetailList,
  userTypeList,
  getAllWorksiteUsersByAccountId,
  allWorksitesUsersByAccountId,
  updateAccountOwner,
  updateAccountOwnerSuccess,
  updateAccountOwnerLoading,
  updateAccountOwnerResetFlags,
  updateAccountOwnerError,
  setSelectedAdminUser,
  newAdminUserData,
  isOperator,
  passwordPolicy,
  updateCurrentUser,
  currentTimeZone,
}) => {
  const { changeOthersPasswords, changeOwnPasswords, _id: loggedInUserId } = loginDetails?.userData;

  const isSelf = loggedInUserId === userOverviewDetail?._id;

  const shouldShowField = (changeOthersPasswords, changeOwnPasswords) => {
    // Check if the user being viewed is the logged-in user

    if (changeOthersPasswords && changeOwnPasswords) {
      return true; // Show the field all the time if both permissions are enabled
    }

    if (!changeOwnPasswords && isSelf) {
      return false; // Do not show the field if the user is self and changeOwnPasswords is disabled
    }

    if (!changeOthersPasswords && changeOwnPasswords && isSelf) {
      return true; // Show the field if the user is self and changeOwnPasswords is enabled but changeOthersPasswords is disabled
    }
    if (changeOthersPasswords && !isSelf) {
      return true; // Show the field if the user is not self and changeOthersPasswords is enabled
    }
    // In all other cases, do not show the field
    return false;
  };

  const {
    fullName,
    _id,
    status,
    email,
    phone,
    permissions,
    authorizedSites,
    unauthorizedAssetTypes,
    certificates,
  } = userOverviewDetail
    ? userOverviewDetail
    : {
        fullName: "",
        createdDate: "",
        accountId: "",
        _id: "",
        status: "",
        permissions: "",
        authorizedSites: "",
        unauthorizedAssetTypes: [],
        certificates: [],
      };

  const [statusButton, setStatusButton] = useState(status);
  const [image, setImage] = useState({});
  const [userRole, setUserRole] = useState("");
  const [isPin, setIsPin] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [UserName, setUserName] = useState("");
  const [IsEdit, setIsEdit] = useState(false);
  const [isLoadingPasswordChange, setLoadingPasswordChange] = useState(false);
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [isUserDeactivated, setIsUserDeactivated] = useState(false);
  const [IsPasswordChangeSuccess, setIsPasswordChangeSuccess] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsError, setIsError] = useState(false);
  const [updatingImageLoading, setUpdatingImageLoading] = useState(false);
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [userDetails, setUserDetails] = useState({
    email: "",
    phone: "",
    _id: "",
    fullName: "",
    permissions: "",
    authorizedSites: "",
    unauthorizedAssetTypes: [],
    certificates: [],
  });
  const [isPasswordError, setIsPasswordError] = useState(false);

  const [activeTab, setActiveTab] = useState("userOverview");
  //const [disableSwitch, setDisableSwitch] = useState(false);
  const { t } = useTranslation();
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const [invalidPasswordError, setInvalidPasswordError] = useState(false);

  useEffect(() => {
    getUserTypeDetailList();
    getUserTypeList(false, userOverviewDetail?.accountId);
  }, []);

  useEffect(() => {
    getUserTypeList(false, userOverviewDetail?.accountId);
  }, [userOverviewDetail?.accountId]);

  const getUsertypeObj = (id) => {
    const userType = userTypeDetailList?.find?.((userType) => id === userType._id);
    return userType;
  };

  const userType = getUsertypeObj(userOverviewDetail?.userTypeId);

  useEffect(() => {
    if (userType?.accessLevel === "INDIVIDUAL_EMPLOYEE") {
      getAllassetTypesWithCountByAccountId(userOverviewDetail?.accountId);
    }
  }, [userOverviewDetail?.permissions, userOverviewDetail?._id]);

  const getAuthorizedSitesArray = (authorizedSites) => {
    return authorizedSites?.map?.((site) => site._id);
  };

  useEffect(() => {
    if (userOverviewDetail) {
      let {
        fullName,
        createdDate,
        accountId,
        s3Enable,
        _id,
        status,
        email,
        phone,
        permissions,
        authorizedSites,
        unauthorizedAssetTypes,
        certificates,
        timeZone,
      } = userOverviewDetail;

      setUserDetails({
        fullName,
        createdDate,
        accountId,
        s3Enable,
        _id,
        status,
        email,
        phone,
        permissions,
        unauthorizedAssetTypes,
        authorizedSites: getAuthorizedSitesArray(authorizedSites),
        certificates,
        timeZone,
      });

      setStatusButton(status);
    }
  }, [userOverviewDetail]);

  const handleCancelButton = () => {
    setUserDetails({
      email,
      phone,
      _id,
      fullName,
      permissions,
      authorizedSites: getAuthorizedSitesArray(authorizedSites),
      unauthorizedAssetTypes,
      certificates,
    });
  };

  useEffect(() => {
    handleCancelButton();
  }, [editForm]);

  useEffect(() => {
    if (success) {
      setIsEdit(false);
    }
  }, [success]);

  useEffect(() => {
    if (statusSuccess) {
      setStatusButton(!statusButton);
    }
  }, [statusSuccess]);

  useEffect(() => {
    if (statusSuccess) {
      // Update isUserDeactivated based on the current statusButton state
      setIsUserDeactivated(!statusButton);
    }
  }, [statusSuccess, statusButton]);

  useEffect(() => {
    if (updateUserSuccess) {
      setEditForm(!editForm);
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    setUserRole(loginDetails ? loginDetails?.permission?.permissionName : "");
  }, [loginDetails]);

  useEffect(() => {
    if (userOverviewDetail) {
      image.imgData = userOverviewDetail.imgData ? s3BucketProfileImg + userOverviewDetail.imgData : "";
      setImage({ ...image });
    }
    setUserName(userOverviewDetail && userOverviewDetail.fullName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOverviewDetail]);

  useEffect(() => {
    setActiveTab("userOverview");
    //reset on unload of the page
    return () => {
      handleCloseSnackBar();
    };
  }, [UserDetailLoading]);

  const handleUpdatePhoto = (imgDataUpload, id) => {
    setUpdatingImageLoading(true);
    let bodyFormData = new FormData();
    bodyFormData.append("userData", JSON.stringify({ email: userOverviewDetail.email }));
    bodyFormData.append("profileImg", imgDataUpload);

    apiCall(`/user/${id}`, { method: "PUT", data: bodyFormData, type: "file" })
      .then((response) => {
        setUpdatingImageLoading(false);
        setEditForm(true);
        if (response.data.success === true) {
          let imgData = response.data.data.imgData;
          updateUserDetail({ imgData, _id });
          if (userOverviewDetail.isOwner) {
            updateLoginUserDetail({ imgData });
          }
          setImageUploadSuccess(alertConstant.user_updated_successful);
        } else {
          //setUpdateError(response.data.message)
        }
      })
      .catch((error) => {
        //setUpdateError('Internal Error')
        console.log("exception", error);
      });
  };

  const passwordChange = (e, passwordData) => {
    e.preventDefault();
    setLoadingPasswordChange(true);
    let url = "";
    if (!passwordData.pin) {
      url = `/user/password/change/${passwordData.userId}`;
    } else {
      url = `/user/update/pin`;
    }
    apiCall(url, { method: "PUT", data: passwordData })
      .then((response) => {
        setEditForm(true);
        if (response.data.success === true) {
          if (passwordData.pin) {
            updateUserDetail({ pin: passwordData.pin, _id: passwordData.userId });
            setIsPasswordChangeSuccess(alertConstant.pin_update);
          } else {
            setIsPasswordChangeSuccess(alertConstant.password_update);
          }

          setPasswordModal(false);
        } else {
          if (passwordData.pin) {
            setIsPasswordError(t("This PIN exists for another user. Please choose a different PIN."));
          } else {
            if (response?.data?.data?.code === "PASSWORD_ALREADY_USED") {
              setIsPasswordError(t("PASSWORD_HAS_BEEN_USED_BEFORE"));
            } else {
              setIsPasswordError(t(response?.data?.message));
            }
          }
          setLoadingPasswordChange(false);
        }
      })
      .catch((error) => {
        setIsPasswordError(t("Error"));
        setLoadingPasswordChange(false);
        console.log(error);
      });
  };

  const handleCloseSnackBar = () => {
    //setDisableSwitch(false);
    resetUserNameState(false);
    setIsPasswordChangeSuccess(false);
    setIsSuccess(false);
    setIsError(false);
    setImageUploadSuccess(false);
    setIsPasswordError(false);
    resetUsersState(false);
    updateAccountOwnerResetFlags(false);
    setIsUserDeactivated(false);
  };

  useEffect(() => {
    if (statusSuccess) {
      if (statusButton) {
        setIsUserDeactivated(true);
      }
    }
  }, [statusSuccess]);

  const handleMessage = (IsSuccess, IsError, IsPasswordChangeSuccess, statusSuccess, imageUploadSuccess) => {
    if (IsSuccess) {
      return t("User updated successfully");
    } else if (IsPasswordChangeSuccess) {
      return t(IsPasswordChangeSuccess) + ".";
    } else if (IsError) {
      return t("User Details Update Failed. Please try again");
    } else if (statusSuccess) {
      if (statusButton) {
        return t("The user is now active");
      } else if (!statusButton) {
        return t("The user has been deactivated");
      }
    } else if (imageUploadSuccess) {
      return t(imageUploadSuccess);
    } else if (isPasswordError) {
      return isPasswordError;
    }
  };
  const getUserType = (userTypeId) => {
    const userTypeObj = userTypeDetailList?.find?.((userType) => userType._id === userTypeId);
    return userTypeObj?.accessLevel;
  };
  const validationFailingForUserUpdate = () => {
    return (
      toValidateUserName(userDetails?.fullName) ||
      (getUserType(userOverviewDetail.userTypeId) !== "INDIVIDUAL_EMPLOYEE" &&
        toValidateEmail(userDetails?.email))
    );
  };

  const currentUserID = loginDetails && loginDetails.userData && loginDetails.userData._id;
  const selectedUserID = userOverviewDetail && userOverviewDetail._id;

  const isUserSame = currentUserID === selectedUserID;

  const handleUpdateUserPreCheck = () => {
    if (isSelf && userDetails.email !== userOverviewDetail?.email) {
      setShowPasswordModal(true);
    } else {
      handleUpdateUser();
    }
  };

  const handleUpdateUser = (userPassword) => {
    if (validationFailingForUserUpdate()) {
      return;
    }
    setIsLoading(true);

    let bodyFormData = new FormData();

    //console.log({ userDetails, password: userPassword });

    let url = "";

    if (isSelf && userDetails.email !== userOverviewDetail?.email) {
      bodyFormData.append("userData", JSON.stringify({ ...userDetails, password: userPassword }));
      url = `/user/updateprofile/${userDetails?._id}`;
    } else {
      bodyFormData.append("userData", JSON.stringify(userDetails));
      url = `/user/${userDetails?._id}`;
    }

    apiCall(url, { method: "PUT", data: bodyFormData })
      .then((response) => {
        let { email } = userDetails;
        if (response?.data?.success) {
          setShowPasswordModal(false);
          getUserDetailById(response?.data?.data?._id, false);
          if (loginDetails?.sessionData?.userId === userDetails?._id) {
            updateUser({ email });
          }
          userDetails.userId = userDetails?._id;
          updateUserSuccessAction(userDetails);
          if (isUserSame) {
            updateCurrentUser(userDetails);
          }
          setEditForm(true);
          setIsSuccess(true);
          setIsLoading(false);

          //below is to refresh export data
          CacheRefs.removeCacheForUsers();
          getUserExportData();
        } else {
          setIsLoading(false);

          if (response.data?.message?.indexOf("Password is invalid") >= 0) {
            setInvalidPasswordError(true);
          } else {
            setIsError(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  };

  const getUserTypeName = (id) => {
    const userType = userTypeDetailList?.find?.((userType) => id === userType._id);
    return userType?.name;
  };

  const getCommaseparatedWorksites = (sitesArr) =>
    Array.isArray(sitesArr) ? sitesArr?.map?.((site) => site.name).join(", ") : sitesArr;

  const handleAlertModalClose = () => {
    setShowAlert(false);
  };

  const handleAlertModalContinue = () => {
    setShowAlert(false);
    setShowAdminListModal(true);
  };

  const [showAlert, setShowAlert] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showAdminListModal, setShowAdminListModal] = useState(false);

  const handleSelectAccountId = (newAdminId, newAdminName) => {
    setSelectedAdminUser({ adminId: newAdminId, adminName: newAdminName });
    setShowAdminListModal(false);
    setShowConfirmAlert(true);
  };

  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);

  const changeStatus = (e) => {
    if (editForm) {
      const id = e.target.id;
      const status = e.target.checked;

      setIsSwitchDisabled(true);

      if (!status && userOverviewDetail.isOwner) {
        setShowAlert(true);
        getAllWorksiteUsersByAccountId(userOverviewDetail.accountId);
        setIsSwitchDisabled(false);
      } else {
        setTimeout(() => {
          userStatusUpdate(id, status);

          setTimeout(() => {
            setIsSwitchDisabled(false);
          }, 5000);
        }, 1000);
      }
    } else {
      setEditAlertModal(true);
    }
  };

  useEffect(() => {
    return () => {
      setIsUserDeactivated(false);
    };
  }, []);

  const handleAdminListModalClose = () => {
    setShowAdminListModal(false);
  };

  //TODO
  const handleModalConfirm = () => {
    updateAccountOwner(userOverviewDetail._id, newAdminUserData?.adminId);
  };

  const handleModalConfirmClose = () => {
    setShowConfirmAlert(false);
    setShowAdminListModal(true);
  };

  const handleModalSuccessClose = () => {
    updateAccountOwnerResetFlags();
    getUserDetailById(userOverviewDetail._id, false);
  };

  useEffect(() => {
    if (updateAccountOwnerSuccess) {
      setShowAlert(false);
      setShowAdminListModal(false);
      setShowConfirmAlert(false);
    }
  }, [updateAccountOwnerSuccess]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const StatusComponent = () => {
    return (
      <PermissionCheck section="DASHBOARD" permissionName={"USER_MANAGEMENT"} actionName="deactivate">
        {!isUserSame && (
          <>
            <StatusHead>
              <Trans>Status</Trans>
            </StatusHead>

            <SwitchComponent
              id={userOverviewDetail?._id}
              type="checkbox"
              checked={statusButton}
              onChange={changeStatus}
              disabled={isSwitchDisabled}
            />
          </>
        )}
      </PermissionCheck>
    );
  };
  const truncateTextFullName = (text, limit = 20) =>
    text && text.length > limit ? `${text.substring(0, limit)}...` : text;

  return (
    <>
      <ManagementPanelWrapper>
        <UserOverviewWrapper>
          {(UsersLoading || UserDetailLoading) && (
            <UserOverviewMainDiv>
              <MainWrapper>
                <ImageAndDescDiv>
                  <ImagWrapperDiv>
                    <Skeleton circle={true} height={100} width={100} />
                  </ImagWrapperDiv>
                  <WrapperDesDiv>
                    <Skeleton height={100} width={100} />
                    {/* <Skeleton height={20} count={3} /> */}
                  </WrapperDesDiv>
                </ImageAndDescDiv>
                <WrapperDiv>
                  <Skeleton height={100} width="100%" />
                </WrapperDiv>
                <MultiCardDiv>
                  <Skeleton height={100} width="100%" />
                </MultiCardDiv>
                <SwitchWrapper>
                  <Skeleton height={100} width="100%" />
                </SwitchWrapper>
              </MainWrapper>
            </UserOverviewMainDiv>
          )}
          <UserOverviewMainDiv>
            {userOverviewDetail && !UsersLoading && !UserDetailLoading && (
              <MainWrapper>
                <ImageAndDescDiv>
                  <ImagWrapperDiv>
                    <ImageUpload
                      id={userOverviewDetail._id}
                      updateImage={handleUpdatePhoto}
                      imageLoading={updatingImageLoading}
                      src={image.imgData}
                      defaultSrc={UserProfile}
                      key={image.imgData}
                    />
                  </ImagWrapperDiv>

                  <WrapperDesDiv>
                    <DetailsDescriptions customTitle={fullName} title={truncateText(fullName)} />

                    <DetailsDescriptions
                      dataTitle={t("User Type")}
                      date={
                        getUserTypeName(userOverviewDetail.userTypeId) ||
                        (userOverviewDetail.isOwner && "Account Owner")
                      }
                    />

                    <DetailsDescriptions
                      dataTitle={t("Account No")}
                      date={userOverviewDetail && userOverviewDetail.accountNo}
                    />

                    {isSuperAdmin && userOverviewDetail?.accountId && (
                      <DetailsDescriptions
                        dataTitle={t("AccountLabel")}
                        date={accountListById?.[userOverviewDetail?.accountId]?.companyName}
                      />
                    )}

                    <DetailsDescriptions
                      dataTitle={t("PIN")}
                      date={userOverviewDetail && userOverviewDetail.pin}
                    />

                    {(userType?.accessLevel === "INDIVIDUAL_EMPLOYEE" ||
                      userType?.accessLevel === "SINGLE_WORKSITE" ||
                      userType?.accessLevel === "MULTIPLE_WORKSITES") && (
                      <DetailsDescriptions
                        dataTitle={t("Worksite(s)")}
                        customTitle={getCommaseparatedWorksites(userOverviewDetail?.authorizedSites)}
                        date={truncateText(
                          getCommaseparatedWorksites(userOverviewDetail?.authorizedSites),
                          20
                        )}
                      />
                    )}

                    <DetailsDescriptions
                      dataTitle={t("Added On")}
                      date={
                        userOverviewDetail &&
                        moment(userOverviewDetail.createdDate)
                          .utcOffset((currentTimeZone || "-06:00 Central America")?.split?.(" ")[0])
                          .format(" MMMM DD YYYY")
                      }
                    />

                    {/* <WrapperDivDetails>
                      <HeadingDiv>
                        <Trans>User Type</Trans>:
                      </HeadingDiv>
                      <HeadingSpan
                        title={
                          (userOverviewDetail.isOwner && "Account Owner") ||
                          permissionToLabel[userOverviewDetail ? userOverviewDetail.permissions : ""]
                        }
                      >
                        <Trans>
                          {getUserTypeName(userOverviewDetail.userTypeId) ||
                            (userOverviewDetail.isOwner && "Account Owner")}
                        </Trans>
                      </HeadingSpan>
                    </WrapperDivDetails>

                    <WrapperDivDetails>
                      <HeadingDiv>
                        <Trans>Account No</Trans>:
                      </HeadingDiv>
                      <HeadingSpan> {userOverviewDetail && userOverviewDetail.accountNo}</HeadingSpan>
                    </WrapperDivDetails>

                    {isSuperAdmin && userOverviewDetail?.accountId && (
                      <WrapperDivDetails>
                        <HeadingDiv>
                          <Trans>AccountLabel</Trans>
                        </HeadingDiv>
                        <HeadingSpan title={accountListById?.[userOverviewDetail?.accountId]?.companyName}>
                          {accountListById?.[userOverviewDetail?.accountId]?.companyName}
                        </HeadingSpan>
                      </WrapperDivDetails>
                    )}
                    <WrapperDivDetails>
                      <HeadingDiv>
                        <Trans>PIN</Trans>:
                      </HeadingDiv>
                      <HeadingSpan title={userOverviewDetail && userOverviewDetail.pin}>
                        {userOverviewDetail && userOverviewDetail.pin}
                      </HeadingSpan>
                    </WrapperDivDetails> 

                    {(userType?.accessLevel === "INDIVIDUAL_EMPLOYEE" ||
                      userType?.accessLevel === "SINGLE_WORKSITE" ||
                      userType?.accessLevel === "MULTIPLE_WORKSITES") && (
                      <WrapperDivDetails>
                        <HeadingDiv>
                          <Trans>Worksite(s)</Trans>:
                        </HeadingDiv>
                        <HeadingSpan title={getCommaseparatedWorksites(userOverviewDetail?.authorizedSites)}>
                          {truncateText(getCommaseparatedWorksites(userOverviewDetail?.authorizedSites), 20)}
                        </HeadingSpan>
                      </WrapperDivDetails>
                    )}

                    <WrapperDivDetails>
                      <HeadingDiv>
                        <Trans>Added On</Trans>:
                      </HeadingDiv>
                      <HeadingSpan title={userOverviewDetail && userOverviewDetail.createdDate}>
                        {userOverviewDetail &&
                          moment(userOverviewDetail.createdDate)
                            .utcOffset((currentTimeZone || "-06:00 Central America")?.split?.(" ")[0])
                            .format(" MMMM DD YYYY")}
                      </HeadingSpan>
                    </WrapperDivDetails>*/}
                  </WrapperDesDiv>
                </ImageAndDescDiv>
                <WrapperDiv>
                  <StatusMobileRender>
                    <StatusComponent />
                  </StatusMobileRender>
                  <QrCode fgColor="#0D5FBE" qrFor={qrType.USERS} qrDetail={userOverviewDetail} />
                </WrapperDiv>
                <MultiCardDiv>
                  <UserMultiCard data={inspectionCount} />
                </MultiCardDiv>

                <SwitchWrapper>
                  <StatusDesktopRender>
                    <StatusComponent />
                  </StatusDesktopRender>
                  {statusButton && (
                    <PinPassDiv>
                      <PermissionCheck
                        section="DASHBOARD"
                        permissionName={"USER_MANAGEMENT"}
                        actionName="edit"
                      >
                        {userType?.accessLevel !== "INDIVIDUAL_EMPLOYEE" &&
                          shouldShowField(changeOthersPasswords, changeOwnPasswords) && (
                            <PassWrapper
                              onClick={() => {
                                setIsPin(false);
                                setPasswordModal(true);
                              }}
                            >
                              <IconPassPin className="icon icon-change-password"></IconPassPin>
                              <SpanText>
                                <Trans>Change password</Trans>
                              </SpanText>
                            </PassWrapper>
                          )}

                        <PinWrappper
                          onClick={() => {
                            setIsPin(true);
                            setPasswordModal(true);
                          }}
                        >
                          <IconPassPin className="icon icon-change-pin"></IconPassPin>
                          <SpanText>
                            <Trans>Change pin</Trans>
                          </SpanText>
                        </PinWrappper>
                      </PermissionCheck>
                    </PinPassDiv>
                  )}
                </SwitchWrapper>
              </MainWrapper>
            )}

            {/* {userOverviewDetail && !UsersLoading && !UserDetailLoading && (
              <>
                <UserTabBar
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  isOperator={userType?.accessLevel === "INDIVIDUAL_EMPLOYEE"}
                />

                {activeTab === "userOverview" && (
                  <UserOverviewTab
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    userOverviewDetails={userOverviewDetail}
                    handleUpdateUser={handleUpdateUser}
                    activeTab={activeTab}
                  />
                )}

                {activeTab === "operatorCertificates" && (
                  <OperatorCertificatesTab
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    handleUpdateUser={handleUpdateUser}
                    activeTab={activeTab}
                  />
                )}

                {activeTab === "inspectionReports" && <UserInspectionReportTab activeTab={activeTab} />}
                {activeTab === "assetPermissions" && (
                  <AssetPermissionTab
                    editForm={editForm}
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    userOverviewDetails={userOverviewDetail}
                    handleUpdateUser={handleUpdateUser}
                    activeTab={activeTab}
                  />
                )}
              </>
            )} */}
            <TabComponentDiv>
              {userOverviewDetail && !UsersLoading && !UserDetailLoading && (
                <SieraTabs
                  variant="horizontalNew"
                  defaultActiveKey="userOverview"
                  activeKey={activeTab}
                  size="small"
                  onSelect={(key) => setActiveTab(key)}
                >
                  <SieraTab eventKey="userOverview" title={t("User Overview")}>
                    <UserOverviewTab
                      userDetails={userDetails}
                      setUserDetails={setUserDetails}
                      userOverviewDetails={userOverviewDetail}
                      handleUpdateUser={handleUpdateUserPreCheck}
                      activeTab={activeTab}
                      isSelf={isSelf}
                    />
                  </SieraTab>

                  <SieraTab eventKey="operatorCertification" title={t("Operator Certification")}>
                    <OperatorCertificatesTab
                      userDetails={userDetails}
                      setUserDetails={setUserDetails}
                      handleUpdateUser={handleUpdateUser}
                      activeTab={activeTab}
                    />
                  </SieraTab>

                  {/* <SieraTab eventKey="inspectionReports" title="Inspection Reports">
        <UserInspectionReportTab activeTab={activeTab} />
      </SieraTab> */}

                  {userType?.accessLevel === "INDIVIDUAL_EMPLOYEE" && (
                    <SieraTab eventKey="assetPermissions" title={t("Asset Permissions")}>
                      <AssetPermissionTab
                        editForm={editForm}
                        userDetails={userDetails}
                        setUserDetails={setUserDetails}
                        userOverviewDetails={userOverviewDetail}
                        handleUpdateUser={handleUpdateUser}
                        activeTab={activeTab}
                      />
                    </SieraTab>
                  )}
                </SieraTabs>
              )}
            </TabComponentDiv>

            <PasswordModal
              id={userOverviewDetail ? userOverviewDetail._id : ""}
              isPin={isPin}
              changePassword={passwordChange}
              showModal={passwordModal}
              setModal={() => setPasswordModal()}
              isLoadingPasswordChange={isLoadingPasswordChange}
              setLoadingPasswordChange={setLoadingPasswordChange}
              userAccountId={userOverviewDetail?.accountId}
            />
          </UserOverviewMainDiv>
          {!userOverviewDetail && !UsersLoading && !UserDetailLoading && (
            // <>
            //   <div className="noSection">
            //     <div align="center" style={styles.message}>
            //       <Trans>There is no user in your worksite right now</Trans>.
            //     </div>
            //     <div align="center" style={styles.message}>
            //       <Trans>Click '+ ADD USER' to add a User</Trans>
            //     </div>
            //   </div>
            // </>

            <NoSectionStyle>
              <p>
                <Trans>There is no user in your worksite right now</Trans>.
              </p>
              <div className="clickDesktop">
                <Trans>Click '+ ADD USER' to add a User</Trans>
              </div>
              {/* <div className="tapMobile">
                              <Trans>CLICK_ADD_ASSET_BUTTON_MOB</Trans>
              </div> */}
            </NoSectionStyle>
          )}
        </UserOverviewWrapper>
      </ManagementPanelWrapper>
      <SnackBar
        isSuccess={
          (IsSuccess || IsPasswordChangeSuccess || statusSuccess || imageUploadSuccess) && !isUserDeactivated
        }
        isWarning={IsError || isPasswordError}
        isFailed={IsSuccess || isUserDeactivated}
        label={handleMessage(
          IsSuccess,
          IsError,
          IsPasswordChangeSuccess,
          statusSuccess,
          imageUploadSuccess,
          isPasswordError,
          isUserDeactivated
        )}
        handleClose={handleCloseSnackBar}
      />

      <ModalComponent
        show={showAlert}
        variant="AlertModal"
        iconClass="alertsettings"
        paragraphWidth="80%"
        message="DEACTIVATING ACCOUNT OWNER"
        paragraph={
          <ParagraphDiv>
            Caution! You are deactivating <b>{fullName}</b> as the account owner of{" "}
            <b>{accountListById?.[userOverviewDetail?.accountId]?.companyName}</b>. If you wish to continue
            with this action then you must replace another user as the Account Owner for this account. Are you
            sure you wish to continue?
          </ParagraphDiv>
        }
        button1Text="Cancel"
        onClickA={handleAlertModalClose}
        typeA="primary"
        button2Text="Continue"
        typeB="secondary"
        onClickB={handleAlertModalContinue}
      />

      <ModalPopup
        show={showAdminListModal}
        size="lg"
        centered
        backdrop="static"
        title="New Account Owner"
        handleClose={handleAdminListModalClose}
      >
        <AdminList
          companyName={accountListById?.[userOverviewDetail?.accountId]?.companyName}
          adminList={allWorksitesUsersByAccountId}
          handleSelectAccountId={handleSelectAccountId}
        />
      </ModalPopup>

      <ModalComponent
        show={showConfirmAlert}
        variant="AlertModal"
        paragraphWidth="80%"
        iconClass="alertsettings"
        message="Confirm New Account Owner"
        paragraph={
          <ParagraphDiv>
            You have selected <b>{newAdminUserData?.adminName}</b> as the new Account Owner of{" "}
            <b>{accountListById?.[userOverviewDetail?.accountId]?.companyName}</b>. Do you confirm?
            {updateAccountOwnerError && <Error>Error Occurred</Error>}
          </ParagraphDiv>
        }
        button1Text="Confirm"
        onClickA={handleModalConfirm}
        typeA="primary"
        button2Text="Cancel"
        typeB="secondary"
        onClickB={handleModalConfirmClose}
      />

      <ModalComponent
        show={updateAccountOwnerSuccess}
        variant="AlertModal"
        paragraphWidth="80%"
        iconClass="certificate-ok"
        message="New Account Owner Confirmed"
        paragraph={
          <ParagraphDiv>
            Congratulations! <b>{newAdminUserData?.adminName}</b> is now the new account owner of{" "}
            <b>{accountListById?.[userOverviewDetail?.accountId]?.companyName}</b>. An Acknowledgement email
            has also been sent to <b>{newAdminUserData?.adminName}</b>'s email ID.
          </ParagraphDiv>
        }
        button1Text="Continue"
        typeA="primary"
        onClickA={handleModalSuccessClose}
      />
      <SnackBar
        isFailed={updateAccountOwnerError}
        label={"Error Occurred while updating Account Owner. Please try again."}
        handleClose={handleCloseSnackBar}
      />
      <VerifyPassword
        showPasswordModal={showPasswordModal}
        setShowPasswordModal={setShowPasswordModal}
        handleSubmit={handleUpdateUser}
        setInvalidPasswordError={setInvalidPasswordError}
        invalidPasswordError={invalidPasswordError}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userOverviewDetail: state.users.UserOverview,
  loginDetails: state.user.loginDetails,
  loading: state.userManagement.Loading,
  success: state.userManagement.Success,
  statusSuccess: state.users.StatusSuccess,
  statusMessage: state.users.StatusMessage,
  inspectionCount: state.counts.inspectionCount,
  UsersLoading: state.users.UsersLoading,
  isSuperAdmin: state.user.isSuperAdmin,
  UserDetailLoading: state.users.userDetailLoading,
  updateUserSuccess: state.users.updateUserSuccess,
  editForm: state.forms.editForm,
  accountListById: state.dropdownFilters.accountListById,
  generateCSVData: state.users.GeneratingCSVData,
  generateCSVLoading: state.users.GenerateCSVLoading,
  userTypeDetailList: state.userType.userTypeDetailList,
  userTypeList: state.userType.UserTypeList,
  allWorksitesUsersByAccountId: state.users.allWorksitesUsersByAccountId,
  updateAccountOwnerSuccess: state.user.updateAccountOwnerSuccess,
  updateAccountOwnerError: state.user.updateAccountOwnerError,
  updateAccountOwnerLoading: state.user.updateAccountOwnerLoading,
  newAdminUserData: state.userManagement.newAdminUserData,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserDetail: (userDetails) => dispatch(updateUserDetail(userDetails)),
  updateLoginUserDetail: (details) => dispatch(updateLoginUserDetail(details)),
  updateUser: (email) => dispatch(updateUser(email)),
  toEditUserName: (value, id) => dispatch(toEditUserName(value, id)),
  resetUserNameState: () => dispatch(resetUserNameState()),
  userStatusUpdate: (id, status) => dispatch(userStatusUpdate(id, status)),
  resetUsersState: () => dispatch(resetUsersState()),
  setUpdateUserModal: (details) => dispatch(setUpdateUserModal(details)),
  getUserDetailById: (id, bool) => dispatch(getUserDetailById(id, bool)),
  setEditForm: (data) => dispatch(setEditForm(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  getAllassetTypesWithCountByAccountId: (accountId) =>
    dispatch(getAllassetTypesWithCountByAccountId(accountId)),
  getUserExportData: (siteId) => dispatch(getUserExportData(siteId)),
  updateUserSuccessAction: (userDetails) => dispatch(updateUserSuccess(userDetails)),
  getUserTypeDetailList: () => dispatch(getUserTypeDetailList()),
  getUserTypeList: (hideLoading, accId) => dispatch(getUserTypeList(hideLoading, accId)),
  getAllWorksiteUsersByAccountId: (accountId) => dispatch(getAllWorksiteUsersByAccountId(accountId)),
  updateAccountOwner: (currentAccountOwner, newAccountOwner) =>
    dispatch(updateAccountOwner(currentAccountOwner, newAccountOwner)),
  updateAccountOwnerResetFlags: () => dispatch(updateAccountOwnerResetFlags()),
  setSelectedAdminUser: (newAdminUserData) => dispatch(setSelectedAdminUser(newAdminUserData)),
  updateCurrentUser: (data) => dispatch(updateCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserOverview);
