import styled from "styled-components";
import { ReactComponent as CalendarIcon } from "../../../../../src/assets/Calendar.svg";
export const WrapperOperatorForklift = styled.div`
  width: 100%;
  margin: auto;
`;
export const Header = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
`;
export const NoValidMessage = styled.div`
  width: 65%;
  min-height: 230px;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  display: flex;
  margin: auto;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
`;
export const BodyWrapperOperator = styled.div`
  input[type="date"]::-webkit-calendar-picker-indicator {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    opacity: 1;
    display: block;
    background: url(${CalendarIcon});
    width: 16px;
    height: 16px;
    border-width: thin;
  }
`;
export const ButtonWrapperOpCert = styled.div`
  float: left;
  @media (max-width: 1100px) {
    button[title="Back"] {
      float: right;
    }
  }
`;
export const AddCertificateButton = styled.button`
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.font7};
  line-height: ${({ theme }) => theme.lineHeights.line4};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  border-radius: 4px;
  cursor: pointer;
  padding: 7px 20px;
  display: initial;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border: 1px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-position: center;
  -webkit-transition: background 0.8s;
  transition: background 0.8s;
  svg {
    cursor: pointer;
    background-repeat: no-repeat;
    margin-right: 12px;
    margin-top: 3px;
    height: 16px;
    width: 16px;
    float: left;
    background-size: contain;
    box-sizing: initial;
    .cls-1 {
      fill: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    }
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"}
      radial-gradient(
        circle,
        transparent 1%,
        ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"} 1%
      )
      center/15000%;
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    background-size: 100%;
    -webkit-transition: background 0s;
    transition: background 0s;
    outline: none;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
  &:disabled > svg {
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    .cls-1 {
      fill: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    }
  }
  @media (max-width: 1100px) {
    padding: 7px 7px;
    font-size: 0px !important;
    svg {
      padding-left: 9px;
    }
  }
`;
export const DeleteCertificateButton = styled.button`
  margin-left: 10px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.font7};
  line-height: ${({ theme }) => theme.lineHeights.line4};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  border-radius: 4px;
  cursor: pointer;
  padding: 7px 20px;
  display: initial;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border: 2px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-position: center;
  -webkit-transition: background 0.8s;
  transition: background 0.8s;
  svg {
    cursor: pointer;
    background-repeat: no-repeat;
    margin-right: 12px;
    margin-top: 3px;
    height: 16px;
    width: 16px;
    float: left;
    background-size: contain;
    box-sizing: initial;
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"}
      radial-gradient(circle, transparent 1%, ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} 1%)
      center/15000%;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    background-size: 100%;
    -webkit-transition: background 0s;
    transition: background 0s;
    outline: none;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
  &:disabled > svg {
    background: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    background-repeat: no-repeat;
    background-size: contain;
  }
  &:hover > svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
  }
`;

export const InvalidFeedback = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  left: 3px;
  margin-top: 0px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
`;
