import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import ViewAssetTypeOverviewTab from "./ViewAssetTypeOverviewTab.component";

import Skeleton from "react-loading-skeleton";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import {
  AssetTypeTopHeader,
  DetailsWrapper,
  AssetTypeHeadDetail,
  AssetTypeDataDescp,
  AssetTypeTabDiv,
  NoSectionStyle,
  AssetTypePageStyled,
} from "./ViewAssetTypeOverviewTab.component.styles";
import DetailsDescriptions from "../../../components/DetailsDescriptions/DetailsDescriptions.component";

const ViewAssetTypeOverview = (props) => {
  const {
    assetTypeOverview,
    AssetTypeLoading,
    accountListById,
    currentTimeZone,
    loginDetails,
    isSuperAdmin,
  } = props;

  const { t } = useTranslation();

  const { createdAt, AssetTypeCreatedOn } = assetTypeOverview || {};
  const dateOfCreation = createdAt || AssetTypeCreatedOn;
  const timeZone = currentTimeZone || dateOfCreation?.split?.(" ")[0];

  const addedOn =
    assetTypeOverview &&
    dateOfCreation &&
    moment(dateOfCreation).utcOffset(timeZone)?.format(" MMMM DD YYYY");

  const companyName = isSuperAdmin
    ? accountListById?.[assetTypeOverview?.accountId]?.companyName
    : loginDetails?.companyName;

  return (
    <AssetTypePageStyled>
      <>
        <>
          {AssetTypeLoading && (
            <span>
              <Row>
                <Col md={12}>
                  <Skeleton height={20} />
                  <Skeleton height={10} count={2} />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col className="mt-4">
                  <Skeleton height={20} width={100} />
                  <Skeleton height={10} count={4} />
                </Col>
              </Row>
            </span>
          )}
          {assetTypeOverview && !AssetTypeLoading && (
            <>
              <AssetTypeTopHeader>
                <DetailsWrapper>
                  <DetailsDescriptions
                    title={assetTypeOverview ? assetTypeOverview?.AssetTypeName : ""}
                    details={assetTypeOverview ? assetTypeOverview?.AssetTypeDescription : ""}
                    dataTitle={t("Added On")}
                    date={addedOn}
                  />

                  <DetailsDescriptions dataTitle={t("Created By")} date={t("SIERA.AI")} />

                  {companyName && <DetailsDescriptions dataTitle={t("AccountLabel")} date={companyName} />}
                </DetailsWrapper>
              </AssetTypeTopHeader>
              <AssetTypeTabDiv>
                <ViewAssetTypeOverviewTab
                  typeAttributes={assetTypeOverview?.AssetTypeAttributes}
                  assignedChecklists={assetTypeOverview?.checklists}
                />
              </AssetTypeTabDiv>
            </>
          )}

          {!assetTypeOverview && !AssetTypeLoading && (
            <NoSectionStyle>
              <p>
                <Trans>NO_ASSET_TYPE_IN_ACCOUNT</Trans>
              </p>
              <div className="clickDesktop">
                <Trans>CLICK_ADD_ASSET_TYPE_BUTTON</Trans>
              </div>
              <div className="tapMobile">
                <Trans>CLICK_ADD_ASSET_TYPE_BUTTON_MOB</Trans>
              </div>
            </NoSectionStyle>
          )}
        </>
      </>
    </AssetTypePageStyled>
  );
};

const mapStateToProps = (state) => ({
  assetTypeOverview: state.assets.AssetTypeOverview,
  AssetTypeLoading: state.assets.assetTypeDetailLoading,
  accountListById: state.dropdownFilters.accountListById,
  isSuperAdmin: state.user.isSuperAdmin,
  currentTimeZone: state.settings.currentTimeZone,
  loginDetails: state.user.loginDetails,
});

const mapDispatchToProps = (dispatch) => ({});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAssetTypeOverview));
