import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
 @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");

  .heading-01 {
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font1} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line1};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

  .heading-02 {
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font2} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line2};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

    .heading-03 {
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font3} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line3};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

   .heading-04 {
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font4} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line4};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

   .heading-05 {
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font5} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line5};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

   .heading-06 {
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font6} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line6};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }
    .heading-07 {
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line7};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

   .heading-08 {
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line8};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

   .body-01 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font5} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line5};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

    .body-02 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font6} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line6};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

    .body-03 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line7};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

    .body-04 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line8};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }


    .label-01-01 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line7};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

   .label-01-02 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line8};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

  .label-02 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line7};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

   .label-03 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line7};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }


  .button-01 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font5} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line5};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

    .button-02 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font6} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line6};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

   .button-03 {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${({ theme }) => theme.lineHeights.line7};
    font-weight: 400;
    letter-spacing: 0;
    font-style: normal;
  }

  .ellipsisCSS {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export default GlobalStyle;
