import styled from "styled-components";
import { Form } from "react-bootstrap";

export const ForkLiftWrapper = styled.div`
  position: relative;
  width: 579px;
  height: 280px;
  padding-left: 3px;
  input[type="checkbox"] {
    width: 30px;
    height: 30px;
    accent-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    pointer-events: none;
  }
  @media (max-width: 768px) {
    zoom: 0.5;
  }
`;

export const CameraSettingsWrapper = styled.div``;

export const CameraLocationWrapper = styled.div`
  display: flex;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const StyledFormCheckBox = styled(Form.Check)`
  position: absolute;
  top: ${({ top }) => top + "px"};
  left: ${({ left }) => left + "px"};
`;

export const AllCameraPanel = styled.div`
  flex: 50%;
  max-width: 590px;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
`;

export const CameraDropdownWrapper = styled.div`
  width: 170px;
  margin: 10px 25px 10px 0;
  display: flex;
  flex-direction: column;
`;

export const SerialNumber = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const ForkliftImage = styled.img`
  height: 280px;
`;

export const CamPositionWrapper = styled.div`
  position: absolute;
  width: 232px;
  height: 280px;
  left: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckboxesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 168px;
`;

export const CamSetupInfoMessage = styled.div`
  margin-top: 24px;
  text-align: left;
  max-width: 590px;
  h2 {
    color: ${({ isWarning, theme }) =>
      isWarning ? theme.colors.redPrimary || "#DD0000" : theme.colors.themePrimary || "#0D5FBE"};
    border: ${({ isWarning, theme }) =>
      isWarning
        ? `1px solid ${theme.colors.redPrimary || "#DD0000"}`
        : `1px solid ${theme.colors.themePrimary || "#0D5FBE"}`};

    display: block;
    padding: 8px 13px;
    border-radius: 4px;
    font-size: ${({ theme }) => theme.fontSizes.font7};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  }
  h3 {
    strong {
      display: block;
      font-weight: 600;
    }
    a {
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      font-weight: 600;
      white-space: nowrap;
    }
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    display: block;
    padding: 8px 13px;
    border-radius: 4px;
    font-size: ${({ theme }) => theme.fontSizes.font7};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  }
`;

export const PositionLabel = styled.label`
  text-align: left;
  margin-bottom: 2px;
  font-size: ${({ theme }) => theme.fontSizes.font8};
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
`;

export const CamSetupGif = styled.div`
  float: right;
  overflow: hidden;
  width: 55%;
  img {
    width: 92%;
    margin-top: 6%;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
`;
