import styled from "styled-components";
export const AssetPermissionWrapper = styled.div`
  margin-bottom: 20px;
`;
export const Header = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
`;
export const Row = styled.div`
  display: grid;
  grid-template-columns: 33% 33% auto;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
`;
export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader, theme }) =>
    isHeader ? theme.fontSizes.font7 : theme.fontSizes.font7} !important;
  font-family: ${({ isHeader, theme }) =>
    isHeader ? theme.fontFamily.secondaryFont : theme.fontFamily.primaryFont};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  label {
    margin: 0 !important;
  }
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const RowsContainer = styled.div`
  max-height: 200px;
  overflow: auto;
`;

export const AllowDisallowWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const AllowDisallow = styled.div`
  color: ${({ disabled }) => (disabled ? "#ccc" : "#1070e0")};
  padding: 0 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  user-select: none;
`;
