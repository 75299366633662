import styled from "styled-components";

export const RegisterContainer = styled.div``;

export const RegisterDiv = styled.div`
  div {
    margin-bottom: 5px;
  }
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  position: relative;
  text-align: left;
  width: 100%;
  margin: 0 auto 16px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const PasswordErrorMessageContainer = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  position: relative;
  text-align: left;
  width: 100%;
  margin: 0 auto 16px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  margin-bottom: 10px !important;
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
`;

export const LineDIV = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  margin-bottom: 0px !important;
`;
