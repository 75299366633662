/* eslint-disable jsx-a11y/anchor-is-valid */

//TODO- Clean up this file when time permits. Remove unused/commented code, improve/optimize code

import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import { updateActiveNavItem } from "../../../../../redux/sideMenu/menu.action";
import { updateMenu, updateMenuReport } from "../../../../../redux/sideMenu/menu.action";
import { getNearMissCount, getNearMissGraphData } from "../../../../../redux/counts/action";
import { toDownloadFiles } from "../../../../../redux/config/action";
import { setNearMissReportFilter } from "../../../../../redux/filters/action";
import {
  setDropdownAccountId,
  setDropdownWorksiteId,
  getDropdownWorksiteList,
} from "../../../../../redux/dropdownFilters/action";
import DropdownWrapper from "../../../../../components/DropdownComponent/DropdownWrapper";
import DateRangePicker from "../../../../../components/DatePicker.component";
import { getNearMissReports } from "../../../../../redux/reports/action";
import QuickAccess from "../QuickManagement/QuickManagement.component";
import "react-multi-carousel/lib/styles.css";
import Button from "../../../../../components/Button/Button.component";
import DashboardGraph from "../../DashboardGraph";
import { apiCall } from "../../../../../utils/apiCall";
import SieraLoader from "../../../../../components/SieraLoader/SieraLoader.component";
import {
  DashboardContainer,
  CarouselDivCont,
  CarouselDivContainer,
  HighMediumImpactDiv,
  GraphContainer,
  StyledCol,
  LoaderOverlay,
} from "./NearMissDashboard.component.styles";
import PermissionsCheck from "../../../../../components/PermissionCheck/PermissionsCheck";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import DataTableIncident from "./NearMissHighMediumTableWrapper";
import DivContainer from "../../../../../components/DivContanerCompnent/DivContainer.component";
import MoreFilterMobileToggle from "../../../../../components/More Filter Button/MoreFilterButton.component";
import CarouselWrapper from "./../../CarouselWrapper";
import { ThemeContext } from "styled-components";

function NearMissDashboard(props) {
  const [isLoading, setIsLoading] = useState(true);
  const {
    loginDetails,
    isSuperAdmin,
    isSiteAdmin,
    isAccountViewer,
    getNearMissReportListLoading,
    nearMissReportList,
    getNearMissReports,
    updateMenu,
    updateMenuReport,
    getNearMissCount,
    allCount,
    nearMissCount,
    setNearMissReportFilter,
    nearMissFilter,
    dropdownWorksiteList,
    dropdownAccountList,
    setDropdownAccountId,
    dropdownAssetTypeList,
    setDropdownWorksiteId,
    getNearMissGraphData,
    graphData,
    updateActiveNavItem,
    permissions,
    isSingleAccountMultiSiteUser,
    getIncidentCountLoading,
    isIncidentGraphLoading,
    activeNavItem,
    getDropdownWorksiteList,
    userTypeList,
  } = props;
  const { t } = useTranslation();
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filterByTypeLabel, setFilterByTypeLabel] = useState("all");
  const [lastFilterData, setLastFilterData] = useState([]);
  const [lastFilterData2, setLastFilterData2] = useState([]);
  const [managementCountBanner, setManagementCountBanner] = useState([]);
  const [ignoreFilter, setIgnoreFilter] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const themeContext = useContext(ThemeContext);
  const [assetsByAssetType, setAssetsByAssetType] = useState([]);

  const FilterByTypeLabelData = {
    asset: t("Asset"),
    assetType: t("Asset Type"),
    inspectedBy: t("Inspected By"),
    inspectionResult: t("Inspection Result"),
    obstacleType: t("Obstacle Type"),
  };

  /*   let searchedLastHourMeterReading =
    lastHourMeterReadingList &&
    lastHourMeterReadingList?.filter?.(
      (list) => list?.assetName?.toLowerCase?.()?.indexOf?.(searchText.toLowerCase()) !== -1
    ); */

  const { accountId, filterByType, endDate, startDate, userTypeId, worksiteId, assetTypeId } =
    nearMissFilter || {};

  const filterByTypeData = [
    { name: t("Asset"), _id: "asset" },
    { name: t("User"), _id: "inspectedBy" },
    { name: "Obstacle Type", _id: "obstacleType" },
  ];
  const onclickCarousel = (e, item, subSection) => {
    updateActiveNavItem(item?.toLowerCase());
    updateMenu({ section: "management", subSection: subSection });
  };

  useEffect(() => {
    const managementCountBannerLocal = {
      ACCOUNT_MANAGEMENT: {
        name: t("Accounts"),
        iconClass: "accounts",
        type: "ThemePrimary",
        fieldName: "accounts",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Accounts", 0),
      },
      WORKSITE_MANAGEMENT: {
        name: t("Worksites"),
        iconClass: "worksites",
        type: "ThemePrimary",
        fieldName: "sites",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Worksites", 1),
      },
      ASSET_MANAGEMENT: {
        name: t("Assets"),
        iconClass: "assets",
        type: "ThemePrimary",
        fieldName: "assets",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Assets", 2),
      },
      USER_MANAGEMENT: {
        name: t("Users"),
        iconClass: "users",
        type: "ThemePrimary",
        fieldName: "users",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Users", 3),
      },
    };

    const permissionsNameArr = [
      "ACCOUNT_MANAGEMENT",
      "WORKSITE_MANAGEMENT",
      "ASSET_MANAGEMENT",
      "USER_MANAGEMENT",
    ];

    const managementCountBannerUpdated = [];

    if (permissions?.[0]) {
      permissions?.[0]?.permissions?.forEach((permission) => {
        if (permission.name === "REPORTS" && permission.action.view) {
          setViewReport(true);
        }

        if (permissionsNameArr.includes(permission.name)) {
          if (!permission?.action?.view) {
            const bannerObj = managementCountBannerLocal[permission.name];
            delete bannerObj.to;
            delete bannerObj.onClick;
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          } else {
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          }
        }
      });
    }
    setManagementCountBanner(managementCountBannerUpdated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSuperAdmin) {
      setNearMissReportFilter({
        ...nearMissFilter,
        accountId: loginDetails && loginDetails.accountId,
      });
    }
    setIsLoading(true);
    getNearMissCount(nearMissFilter);
    getNearMissGraphData(nearMissFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  useEffect(() => {
    if (!getIncidentCountLoading && !isIncidentGraphLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [getIncidentCountLoading, isIncidentGraphLoading]);

  useEffect(() => {
    setNearMissReportFilter({
      ...nearMissFilter,
      assetTypeId: "all",
      assetId: "all",
      inspectorId: "all",
      // impactSeverity: "highmedium",
      obstacleType: "all",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByType]);

  const handleViewAll = () => {
    setNearMissReportFilter({
      ...nearMissFilter,
      impactSeverity: "all",
      pageSize: 10,
    });
    updateMenuReport({ section: "report", subSection: 2 });
  };

  const changeLastFilter = (value, key, isLeadFilter, resetField) => {
    if (isLeadFilter) {
      setNearMissReportFilter({
        ...nearMissFilter,
        [key]: value,
        [resetField]: "all",
        pageNo: 1,
      });
      return;
    } else {
      setNearMissReportFilter({
        ...nearMissFilter,
        [key]: value,
        pageNo: 1,
      });
    }
  };

  const getUsersByUserType = () => {
    const url = `/user/list/${userTypeId || "all"}/${isSuperAdmin ? accountId : loginDetails.accountId}/${worksiteId}/all/all/all`;

    apiCall(url).then((resp) => {
      let newData = [];
      let nameSet = new Set();
      resp.data.data.forEach((data) => {
        if (nameSet.has(data && data.fullName)) {
          return;
        }
        nameSet.add(data.inspectorId && data.inspectorId.fullName);
        newData.push({
          _id: data && data._id,
          fullName: data && data.fullName,
        });
      });
      setLastFilterData(newData);
    });
  };

  const getAssetsByAssetType = () => {
    const url = `/dropdown/assets?accid=${accountId}&siteid=${
      worksiteId || "all"
    }&astpid=${assetTypeId || "all"}`;
    apiCall(url)
      .then((resp) => {
        setAssetsByAssetType(resp?.data?.data?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUsersByUserType();
  }, [userTypeId]);

  useEffect(() => {
    getAssetsByAssetType();
  }, [assetTypeId]);

  const changeFilterByType = (value) => {
    setNearMissReportFilter({
      ...nearMissFilter,
      filterByType: value,
      pageNo: 1,
    });
    setFilterByTypeLabel(FilterByTypeLabelData[value]);
    if (value === "asset") {
      setLastFilterData(assetsByAssetType);
      setLastFilterData2(dropdownAssetTypeList);
    } else if (value === "inspectedBy") {
      setLastFilterData2(userTypeList);
      getUsersByUserType();
    } else if (value === "obstacleType") {
      setLastFilterData([
        {
          label: "Pedestrian",
          _id: "PEDESTRIAN",
        },
        {
          label: "Object",
          _id: "OBJECT",
        },
      ]);
    }
  };

  useEffect(() => {
    setNearMissReportFilter({
      ...nearMissFilter,
      worksiteId: "all",
      assetId: "all",
      assetTypeId: "all",
      inspectorId: "all",
      impactSeverity: "all",
      obstacleType: "all",
      pageNo: 1,
    });
  }, [accountId]);

  useEffect(() => {
    if (filterByType === "asset") {
      setLastFilterData(assetsByAssetType);
      setLastFilterData2(dropdownAssetTypeList);
    } else if (filterByType === "inspectedBy") {
      getUsersByUserType();
      setLastFilterData2(userTypeList);
    }
  }, [dropdownAssetTypeList, assetsByAssetType]);

  const carouselData = [
    {
      name: t("Total Near-Misses"),
      iconClass: "total-incidents",
      type: "ThemePrimary",
      fieldName: "totalNearMisses",
      state: { impactSeverity: "all" },
      to: "#/app/report",

      onClick: () => {
        setIgnoreFilter(true);
        setNearMissReportFilter({
          ...nearMissFilter,
          //...data.state,
        });
        updateActiveNavItem("nearMissReports");
        updateMenuReport({ section: "report", subSection: 2 });
      },
    },
    {
      name: t("Near-Miss Pedestrian"),
      iconClass: "near-miss-pedestrian",
      type: "RedPrimary",
      fieldName: "nearMissPedestrian",
      state: { incidentType: "Proximity", obstacleType: "Pedestrian" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setNearMissReportFilter({
          ...nearMissFilter,
          filterByType: "incidentType",
          incidentType: "Proximity",
          obstacleType: "Pedestrian",
          impactSeverity: "all",
          // ...data.state,
        });
        updateActiveNavItem("nearMissReports");
        updateMenuReport({ section: "report", subSection: 2 });
      },
    },
    {
      name: t("Near-Miss Object"),
      iconClass: "near-miss-object",
      type: "OrangePrimary",
      fieldName: "nearMissObject",
      state: { incidentType: "Proximity", obstacleType: "Object" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setNearMissReportFilter({
          ...nearMissFilter,
          filterByType: "incidentType",
          incidentType: "Proximity",
          obstacleType: "Object",
          impactSeverity: "all",
          // ...data.state,
        });
        updateActiveNavItem("nearMissReports");
        updateMenuReport({ section: "report", subSection: 2 });
      },
    },
  ];

  const carouselDataNoReportView = [
    {
      name: t("Total"),
      iconClass: "total-incidents",
      type: "ThemePrimary",
      fieldName: "totalNearMisses",
      state: { impactSeverity: "all" },
    },
    {
      name: t("Near-Miss Pedestrian"),
      iconClass: "near-miss-pedestrian",
      type: "RedPrimary",
      fieldName: "nearMissPedestrian",
      state: { incidentType: "Proximity", obstacleType: "Pedestrian" },
    },
    {
      name: t("Near-Miss Object"),
      iconClass: "near-miss-object",
      type: "OrangePrimary",
      fieldName: "nearMissObject",
      state: { incidentType: "Proximity", obstacleType: "Object" },
    },
  ];

  useEffect(() => {
    if (!showMoreFilter) {
      setFilterByTypeLabel("all");
    }
  }, [showMoreFilter]);

  const applyChange = () => {
    if (ignoreFilter) {
      return;
    }
    getNearMissReports({
      ...nearMissFilter,
      pageSize: 10,
    });
    getNearMissCount({ ...nearMissFilter });
    getNearMissGraphData(nearMissFilter);
  };

  useEffect(() => {
    if (activeNavItem === "nearMissReports") {
      setDropdownAccountId(accountId);
      getDropdownWorksiteList();
      setTimeout(() => {
        setDropdownWorksiteId(worksiteId);
      });

      setNearMissReportFilter({
        ...nearMissFilter,
      });
    }
  }, [activeNavItem]);

  return (
    <DashboardContainer>
      {isLoading && (
        <LoaderOverlay>
          <SieraLoader showLabelBelowLogo label="Getting Ready" />
        </LoaderOverlay>
      )}
      <>
        <MoreFilterMobileToggle>
          <>
            <Row>
              {isSuperAdmin && (
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: t("Account"),
                      name: "accountName",
                      key: "companyName",
                      data: dropdownAccountList,
                    }}
                    value={accountId}
                    //  label={t("filter")}
                    handleFilter={(value) => {
                      setNearMissReportFilter({
                        ...nearMissFilter,
                        accountId: value,
                      });
                      setDropdownAccountId(value);
                    }}
                  />
                </Col>
              )}

              {!isSiteAdmin && (
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: t("Worksite"),
                      name: "worksiteName",
                      key: "name",
                      data: dropdownWorksiteList,
                    }}
                    value={worksiteId}
                    // label={t("filter")}
                    handleFilter={(value) => {
                      setDropdownWorksiteId(value);
                      setNearMissReportFilter({
                        ...nearMissFilter,
                        worksiteId: value,
                        assetId: null,
                        assetTypeId: null,
                      });
                    }}
                  />
                </Col>
              )}

              <Col lg={4}>
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  setDate={setNearMissReportFilter}
                  filterState={nearMissFilter}
                />
              </Col>
              <StyledCol>
                <Button
                  iconClass="filter"
                  title={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                  label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                  onClick={() => setShowMoreFilter(!showMoreFilter)}
                  iconOnly
                  buttonType={showMoreFilter ? "primary" : "secondary"}
                />
                <span style={{ width: "10px", display: "inline-block" }}>&nbsp;</span>
                <Button
                  label={t("Apply Filter")}
                  size="medium"
                  onClick={applyChange}
                  showIcon={false}
                  buttonType="primary"
                />
              </StyledCol>
            </Row>
            {showMoreFilter && (
              <Row>
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: t("Filter By"),
                      name: "filterBy",
                      key: "name",
                      placeholder: t("Select a Filter"),
                      data: filterByTypeData,
                    }}
                    value={filterByType}
                    handleFilter={(value) => changeFilterByType(value)}
                    useAsDropdown={true}
                  />
                </Col>
                {filterByTypeLabel !== "all" && (
                  <>
                    {(filterByType === "asset" || filterByType === "inspectedBy") && (
                      <Col lg={2}>
                        <DropdownWrapper
                          filter={{
                            type: `${filterByType === "asset" ? "Asset Type" : t("User Type")}`,
                            key: filterByType === "asset" ? "AssetTypeName" : "name",
                            data: lastFilterData2,
                          }}
                          value={nearMissFilter[filterByType === "asset" ? "assetTypeId" : "userTypeId"]}
                          useAsDropdown={false}
                          label={t("filter")}
                          handleFilter={(e) =>
                            changeLastFilter(
                              e,
                              filterByType === "asset" ? "assetTypeId" : "userTypeId",
                              true,
                              filterByType === "asset" ? "assetId" : "inspectorId"
                            )
                          }
                          disabled={
                            filterByType === "assetType" &&
                            isSuperAdmin &&
                            (accountId === "all" || !accountId)
                          }
                        />
                      </Col>
                    )}
                    <Col lg={2}>
                      <DropdownWrapper
                        filter={{
                          type: `${filterByTypeLabel === t("Inspected By") ? t("User") : filterByTypeLabel}`,
                          key:
                            filterByType === "asset"
                              ? "assetName"
                              : filterByType === "assetType"
                                ? "AssetTypeName"
                                : filterByType === "obstacleType"
                                  ? "label"
                                  : "fullName",
                          data: lastFilterData,
                        }}
                        value={
                          nearMissFilter[
                            filterByType === "asset"
                              ? "assetId"
                              : filterByType === "assetType"
                                ? "assetTypeId"
                                : filterByType === "obstacleType"
                                  ? "obstacleType"
                                  : "inspectorId"
                          ]
                        }
                        useAsDropdown={false}
                        label={t("filter")}
                        handleFilter={(e) =>
                          changeLastFilter(e, filterByType === "asset" ? "assetId" : "inspectorId")
                        }
                        disabled={
                          filterByType === "assetType" && isSuperAdmin && (accountId === "all" || !accountId)
                        }
                      />
                    </Col>
                  </>
                )}
              </Row>
            )}
          </>
        </MoreFilterMobileToggle>

        <Row className="mt-2">
          <Col lg={8}>
            <CarouselDivCont>
              <CarouselWrapper
                key={viewReport ? "viewReportMode" : "defaultMode"}
                viewReport={viewReport}
                countData={nearMissCount}
                updateActiveNavItem={updateActiveNavItem}
                setIncidentReportFilter={setNearMissReportFilter}
                incidentReportFilter={nearMissFilter}
                carouselData={carouselData}
                carouselDataNoReportView={carouselDataNoReportView}
                maxItemsInView={3}
              />
            </CarouselDivCont>
          </Col>
          <Col lg={4}>
            <CarouselDivContainer>
              <CarouselWrapper
                viewReport={false}
                allCount={allCount}
                managementCountBanner={managementCountBanner}
                size="small"
              />
            </CarouselDivContainer>
          </Col>
        </Row>
        <Row>
          <Col lg={isAccountViewer ? 12 : 8}>
            <GraphContainer>
              <DashboardGraph
                title={t("Near-Miss Trends")}
                series={[
                  {
                    name: t("Total"),
                    data: graphData?.count ?? [],
                  },
                  {
                    name: t("Near-Miss Pedestrian"),
                    data: graphData?.pedestrian ?? [],
                  },
                  {
                    name: t("Near-Miss Objects"),
                    data: graphData?.object ?? [],
                  },
                ]}
                colors={[
                  themeContext?.colors?.themePrimary || "#0D5FBE",
                  themeContext?.colors?.redPrimary || "#DD0000",
                  themeContext?.colors?.orangePrimary || "#DD9000",
                  themeContext?.colors?.greenPrimary || "#11A70B",
                ]}
                date={graphData?.date}
                startDate={startDate}
                endDate={endDate}
              />
            </GraphContainer>

            <PermissionCheck section={"DASHBOARD"} permissionName={"REPORTS"} actionName={"view"}>
              <HighMediumImpactDiv>
                <DivContainer
                  showViewAll={!!nearMissReportList && nearMissReportList.length > 0}
                  heading={t("Near-Miss Pedestrian and Near-Miss Object Incidents")}
                  to="/app/report"
                  onClick={() => handleViewAll()}
                >
                  <DataTableIncident
                    data={nearMissReportList}
                    isSiteAdmin={isSiteAdmin}
                    isSingleAccountMultiSiteUser={isSingleAccountMultiSiteUser}
                    loading={getNearMissReportListLoading}
                  />
                </DivContainer>
              </HighMediumImpactDiv>
            </PermissionCheck>
          </Col>
          <PermissionsCheck
            section="DASHBOARD"
            permissionNames={[
              "ASSET_MANAGEMENT",
              "ASSET_TYPE_MANAGEMENT",
              "WORKSITE_MANAGEMENT",
              "USER_MANAGEMENT",
            ]}
            actionName="add"
            operator="OR"
          >
            <Col lg={isAccountViewer ? 12 : 4} className="mt-2">
              <DivContainer heading={t("Quick Management Access")} showViewAll={false}>
                <QuickAccess />
              </DivContainer>
              {/* Below code is commented as part of SIER-3209 temporarily*/}
              {/* <HourMeterReading
            getLastHourMeterListing={getLastHourMeterListing}
            lastHourMeterReadingLoading={lastHourMeterReadingLoading}
            reportFilter={nearMissFilter}
            lastHourMeterReadingList={lastHourMeterReadingList}
          /> */}
            </Col>
          </PermissionsCheck>
        </Row>
      </>
    </DashboardContainer>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isSiteAdmin: state.user.isSiteAdmin,
  isAccountViewer: state.user.isAccountViewer,
  getNearMissReportListLoading: state.reports.getNearMissReportListLoading,
  nearMissReportList: state.reports.nearMissReportList,
  lastHourMeterReadingList: state.reports.lastHourMeterReadingList,
  lastHourMeterReadingLoading: state.reports.getLastHourMeterListLoading,
  allCount: state.counts.allCount,
  getIncidentCountLoading: state.counts.getIncidentCountLoading,

  nearMissCount: state.counts.nearMissCount,

  nearMissFilter: state.filters.nearMissFilter,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  graphData: state.counts.nearMissGraphData,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  fileDownloadLoading: state.config.fileDownloadLoading,
  permissions: state.user?.loginDetails?.userData?.permissions,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
  isIncidentGraphLoading: state.user.isIncidentGraphLoading,
  activeNavItem: state.sideMenu.activeNavItem,
  userTypeList: state.userType.UserTypeList,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  getNearMissReports: (filterData) => dispatch(getNearMissReports(filterData)),
  getNearMissGraphData: (filterData) => dispatch(getNearMissGraphData(filterData)),
  updateMenuReport: (menuDetails) => dispatch(updateMenuReport(menuDetails)),
  getNearMissCount: (filterData) => dispatch(getNearMissCount(filterData)),
  setNearMissReportFilter: (filterData) => dispatch(setNearMissReportFilter(filterData)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NearMissDashboard));
