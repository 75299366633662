import { useState } from "react";
import {
  Wrapper,
  OTPFieldWrapper,
  OTPField,
  Container,
  ButtonsWrapper,
  OTPInfo,
  InfoText,
  ErrorMessageWrapper,
  ErrorContainer,
} from "./OTPInputs.styles";
import Button from "../../../components/Button/Button.component";
import { Trans, useTranslation } from "react-i18next";
import Countdown from "./Countdown.component";
import CountdownDate from "./CountdownDate.component";

const TARGET_TIME_IN_MINUTES = 1;
const OTPInputs = (props) => {
  const {
    fieldsCount,
    resendOtp,
    submitOtp,
    errorMessage,
    setErrorMessage,
    email,
    isOtpLoading,
    isOtpResendLoading,
    isTooManyRequests,
    expiringAt,
    setIsTooManyRequests,
    setExpiringAt,
    setShowOtpField,
    isTooManyResendRequests,
    setIsTooManyResendRequests,
    resetMFAFlags,
  } = props;
  const [otp, setOtp] = useState(new Array(fieldsCount).fill(""));
  const [resendOtpActive, setResendOtpActive] = useState(false);
  const { t } = useTranslation();
  const [startTimer, setStartTimer] = useState(false);

  const getPartialEmail = (email) => {
    const emailPartsArr = email.split("@");
    const visiblePart = emailPartsArr[0].substring(emailPartsArr[0].length - 3);
    const invisiblePart = emailPartsArr[0]
      .substring(0, emailPartsArr[0].length - 3)
      .split("")
      .fill("*")
      .join("");

    return invisiblePart + visiblePart + "@" + emailPartsArr[1];
  };
  const otpInfoText = t("OTP_INFO_TEXT", { fieldsCount, maskedEmail: getPartialEmail(email) });
  const handleOnChange = (e, index) => {
    let value = e.target.value;
    if (value && value.length > 1) {
      value = e.target.value = value.substring(1, 2);
    }
    if (value && value.length === 1 && (parseInt(value) || parseInt(value) === 0)) {
      const tempOtp = [...otp];
      tempOtp[index] = value;
      setOtp(tempOtp);
      const nextElementSibling = e.target.nextElementSibling || null;

      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    } else {
      const tempOtp = [...otp];
      tempOtp[index] = "";
      setOtp(tempOtp);
    }
  };

  const timerExpiredCallBack = () => {
    setResendOtpActive(true);
  };

  const dateExpiredExpired = () => {
    setResendOtpActive(true);
    setIsTooManyRequests(false);
    setIsTooManyResendRequests(false);
    setExpiringAt(null);
  };

  const pasteHandler = (e, index) => {
    if (!isTooManyRequests) {
      e.clipboardData.items[0].getAsString((text) => {
        if (text && parseInt(text)) {
          if (text.length >= fieldsCount) {
            setOtp(text.substring(0, fieldsCount).split(""));
          } else {
            let tempOtp = [...otp];
            for (let i = index, j = 0; i < fieldsCount; i++, j++) {
              tempOtp[i] = text[j];
            }
            setOtp(tempOtp);
          }
        }
      });
      e.preventDefault();
    }
  };

  const sendOtp = () => {
    setOtp(new Array(fieldsCount).fill(""));
    setStartTimer(true);
    setResendOtpActive(false);
    resendOtp();
  };

  const backtoLogin = () => {
    resetMFAFlags();
    setOtp(new Array(fieldsCount).fill(""));
    setErrorMessage(null);
    setShowOtpField(false);
  };

  return (
    <Container>
      <InfoText>{otpInfoText}</InfoText>
      <Wrapper>
        <OTPFieldWrapper>
          {otp.map((data, index) => (
            <OTPField
              placeholder="-"
              type="text"
              tabIndex={index + 1}
              value={data}
              onChange={(e) => handleOnChange(e, index)}
              onPaste={(e) => pasteHandler(e, index)}
              key={index}
              disabled={isTooManyRequests}
            />
          ))}
        </OTPFieldWrapper>
      </Wrapper>

      {(isTooManyRequests || isTooManyResendRequests) && expiringAt ? (
        <ErrorMessageWrapper className="text-danger login-valid-l">
          {isTooManyResendRequests
            ? "Too many resend attempts! Please try to login after"
            : "Too many incorrect attempts! Please try to login after"}
          <CountdownDate
            targetDate={new Date(expiringAt).getTime()}
            dateExpiredCallback={dateExpiredExpired}
          />
        </ErrorMessageWrapper>
      ) : (
        <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>
      )}

      {!isTooManyRequests && (
        <ButtonsWrapper>
          <Button
            style={{ width: "100%" }}
            label={
              isOtpLoading ? (
                <div className="spinner-border spinner-border-sm" role="status"></div>
              ) : (
                t("Submit Otp")
              )
            }
            showIcon={false}
            buttonType="primary"
            onClick={() => submitOtp(otp.join(""))}
            disabled={otp.join("").length !== fieldsCount}
          />

          <Button
            style={{ width: "100%", marginTop: "8px" }}
            label={
              isOtpResendLoading ? (
                <div className="spinner-border spinner-border-sm" role="status"></div>
              ) : (
                t("Resend Otp")
              )
            }
            showIcon={false}
            buttonType="secondary"
            disabled={!resendOtpActive || isTooManyResendRequests}
            onClick={sendOtp}
          />
          <ErrorContainer>
            {!resendOtpActive && !isTooManyRequests && !isTooManyResendRequests && (
              <OTPInfo>
                (You can request for OTP again after{" "}
                <Countdown
                  targetTimeInMinutes={TARGET_TIME_IN_MINUTES}
                  timerExpiredCallBack={timerExpiredCallBack}
                  startTimer={startTimer}
                  setStaartTimer={setStartTimer}
                />{" "}
                seconds)
              </OTPInfo>
            )}
          </ErrorContainer>
        </ButtonsWrapper>
      )}
      <ButtonsWrapper>
        <Button
          style={isTooManyRequests ? { width: "92%" } : { width: "92%" }}
          label={t("Back to Login")}
          showIcon={false}
          type={isTooManyRequests ? "primary" : "ghost"}
          onClick={backtoLogin}
        />
      </ButtonsWrapper>
    </Container>
  );
};

OTPInputs.defaultProps = {
  fieldsCount: 6,
};

export default OTPInputs;
