import styled from "styled-components";

export const ChecklistTabBarMenu = styled.div`
  width: 100%;
`;

export const ChecklistTabSection = styled.div``;

export const SectionnameChecklist = styled.div`
  background: 0% 0% no-repeat padding-box padding-box ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  border-radius: 4px 4px 0px 0px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  text-align: left;
  padding: 4px 8px;
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  margin-top: 2px;
  .headerColor {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  @media (max-width: 1100px) {
    margin-top: 8px;
  }
`;

export const SectionChecklistTableWrapper = styled.div`
  @media (max-width: 1100px) {
    display: flex;
  }
`;

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 25% 15% 15% 15% 15% 15%;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  margin-right: 1%;
  @media (max-width: 1100px) {
    display: block;
    border-bottom: 4px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    padding: 10px 0px;
  }
`;

export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 4px 8px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader, theme }) =>
    isHeader ? theme.fontSizes.font7 : theme.fontSizes.font7} !important;
  font-family: ${({ isHeader, theme }) =>
    isHeader ? theme.fontFamily.secondaryFont : theme.fontFamily.primaryFont};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  label {
    margin: 0 !important;
  }
  @media (max-width: 1100px) {
    display: ${({ isHeader }) => (isHeader ? "none;" : "block;")};
    text-align: right;
    span,
    label {
      text-align: right;
      float: right;
    }
  }
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;
  height: ${({ isSuperAdmin, isDetailsEmpty }) => {
    if (isSuperAdmin && isDetailsEmpty) {
      return "calc(100vh - 537px)";
    }
    if (isSuperAdmin && !isDetailsEmpty) {
      return "calc(100vh - 565px)";
    }
    if (!isSuperAdmin && isDetailsEmpty) {
      return "calc(100vh - 450px)";
    }
    return "calc(100vh - 478px)";
  }};
  @media (max-width: 1100px) {
    width: 100%;
    height: auto;
  }
`;

export const MobileHead = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    width: auto;
    float: left;
    text-align: left;
  }
`;
export const ChecklistTabDiv = styled.div`
  ${RowsContainer} {
    height: calc(100vh - 468px);
  }
`;
