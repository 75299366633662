import styled from "styled-components";

export const SettingsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 3px;
  width: 100%;
  margin-left: 0% !important;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%;
  }
`;

export const SettingsContainer = styled.div`
  height: calc(100vh - 325px);
  overflow: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const SettingsContainerAS = styled.div`
  overflow: hidden;
  height: calc(100vh - 260px);
  overflow: auto;
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const OnOffText = styled.div`
  margin: 0rem 0px 0 0;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const OnOffTextDiv = styled.div`
  margin: 0rem 10px 0 0;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const SettingsFooter = styled.div`
  overflow: hidden;
  margin: 8px 16px;
  text-align: left;
  .update-button-s2 {
    margin: 0px !important;
    padding: 0px !important;
    width: 100%;
    display: flex;
    .worksite-names {
      padding-right: 0px;
      flex: 1;
      float: left;
      text-align: left;
      display: block !important;
      max-width: initial;
      padding: 0px !important;
    }
    .col-md-8 {
      padding-right: 0px;
      flex: 1;
    }

    @media (max-width: 430px) {
      .worksite-names button {
        padding: 6px 7px;
      }
      .col-md-8 button {
        padding: 6px 7px;
      }
      .apptoall {
        position: relative;
        right: 0;
        left: 100px;
        width: 230px;
      }
    }
  }
`;

export const SettingsBody = styled.div`
  margin: 8px 16px;
  text-align: left;
`;

export const SettingsHeaderTop = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-bottom: 0.2rem;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  }
`;

export const SettingDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const SettingTableDescription = styled.div``;
export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 65% 15% 5% 15%;
  height: 36px;
  @media (max-width: 1100px) {
    grid-template-columns: 38% 28% 3% 31%;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "25px")};
    height: auto;
  }
`;

export const Column = styled.div`
  padding: ${({ isHeader }) => (isHeader ? "8px 0px" : "0px")};
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: ${({ isHeader, theme }) =>
    isHeader ? theme.fontSizes.font5 : theme.fontSizes.font7} !important;
  font-family: ${({ isHeader, theme }) =>
    isHeader ? theme.fontFamily.secondaryFont : theme.fontFamily.primaryFont};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: left;
  text-align: left;
  label {
    margin: 0 !important;
  }
  @media (max-width: 768px) {
    font-size: ${({ isHeader, theme }) =>
      isHeader ? theme.fontSizes.font7 : theme.fontSizes.font8} !important;
  }
`;

export const RowsContainer = styled.div`
  max-height: 200px;
  overflow: auto;
  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }
`;

export const SettingDotIconLow = styled.i`
  font-size: ${({ theme }) => theme.fontSizes.font5};
  color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  margin: 2px 9px -4px 0px;
  padding: 0;
  float: left;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.font7};

    margin: 2px 6px -4px 1px;
  }
`;

export const SettingDotIconMed = styled.i`
  font-size: ${({ theme }) => theme.fontSizes.font5};
  color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
  margin: 2px 9px -4px 0px;
  padding: 0;
  float: left;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.font7};

    margin: 2px 6px -4px 1px;
  }
`;

export const SettingDotIconHigh = styled.i`
  font-size: ${({ theme }) => theme.fontSizes.font6};
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  margin: 2px 9px -4px 0px;
  padding: 0;
  float: left;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.font7};
    margin: 2px 6px -4px 1px;
  }
`;

export const SettingContent = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  display: flex;
  align-items: center;
  height: 35px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  }
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;
export const Gtext = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin: 0px 4px;
  line-height: 26px;
`;

export const RowDivError = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin: 0px 4px;
  line-height: 26px;
`;

export const ErrorInputNumbers = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  line-height: 26px;
  text-align: right;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const BorderLineDiv = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  width: 100%;
  margin: 5px auto;
`;

export const SettingsAlertMessege = styled.div``;

export const ErrorMessageDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  line-height: 26px;
  text-align: right;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const WarningMessageDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  line-height: 26px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  margin-top: 5px;
`;

export const AlertSettingIcon = styled.i`
  font-size: ${({ theme }) => theme.fontSizes.font5};
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  margin: 3px 5px -6px 0px;
  padding: 0px;
  float: left;
`;
export const AndAboveText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  line-height: 26px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  margin-top: 5px;
`;

export const WarningMessageText = styled.div``;

export const InformationIconM = styled.i`
  font-size: ${({ theme }) => theme.fontSizes.font5};
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  margin: 3px 5px -6px 0px;
  padding: 0px;
  float: left;
`;
export const InformationTextM = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  line-height: 26px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  margin-top: 5px;
`;
export const InformationMessage = styled.div``;

export const ImageTextContainer = styled.div`
  float: left;
  padding-top: 16px;
  width: 100%;
  padding-bottom: 16px;
`;
export const TextBoxLeft = styled.div`
  float: left;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7};
`;
export const ImageForkliftRight = styled.div`
  float: left;
  margin-left: 60px;
  img {
    width: 300px;
  }
  @media (max-width: 1100px) {
    margin-top: 8px;
    margin: auto;
    width: auto;
  }
  @media (max-width: 700px) {
    img {
      margin-top: 8px;
    }
  }
`;

export const LowTextBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  padding: 2px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  float: left;
  clear: both;
`;
export const MediumTextBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
  padding: 2px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  float: left;
  clear: both;
`;
export const HighTextBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  padding: 2px 10px;
  border-radius: 4px;
  float: left;
  clear: both;
`;

/* Access Control Setting Styles */

export const AccessControlSetting = styled.div``;
export const HeadingTabACS = styled.div`
  padding: 8px 0px;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  align-items: center;
  justify-content: left;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
`;
export const ACSSecondDiv = styled.div`
  display: flex;
`;
export const LeftDivACS = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  text-align: left;
  line-height: 40px;
`;
export const RightSwitchDiv = styled.div`
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: end;
  span {
    /* margin-right: 3px;*/
  }
  label {
    margin: 0px !important;
  }
`;
export const ThirdACSDiv = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const LeftACSDiv = styled.div`
  flex: 1;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  text-align: left;
  line-height: 31px;
  span {
    position: relative;
    top: -3px;
  }
`;
export const IconTimer = styled.i`
  font-size: ${({ theme }) => theme.fontSizes.font5};
  margin-right: 3px;
`;
export const RightACSDiv = styled.div`
  text-align: right;
  display: flex;
  justify-content: end;
  span {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    text-align: left;
    line-height: 31px;
    margin-left: 10px;
  }
  @media (max-width: 768px) {
    display: block;
    overflow: hidden;
    justify-content: initial;
    input.form-control {
      width: 80px;
      float: left;
    }
    span {
      float: left;
      margin: 0;
    }
  }
`;

export const InputStyledACS = styled.input`
  display: inline-block;
  position: relative;
  text-align: right;
  width: 120px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  height: 32px;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  margin-right: 10px;
`;

export const SelectStyledACS = styled.select`
  position: relative;
  text-align: center;
  width: 110px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  height: 32px;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.font6};
  padding: 0px;
  margin: 0px;
`;

export const CameraTabNavContainer = styled.div`
  position: relative;
  padding: 8px 16px;
  nav a {
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    a.nav-item {
      padding: 4px 8px;
      font-size: 10px;
      line-height: ${({ theme }) => theme.lineHeights.line4};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .tab-content {
      .s3-setting-tab {
        margin-top: 20px;
        float: left;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
`;

export const NoAssetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 200px)" : "calc(100vh - 270px)")};
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  margin-bottom: 0px;
  p {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 260px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoDataFound = styled.div`
  height: ${({ isAccountOwner }) => (isAccountOwner ? "calc(100vh - 270px)" : "calc(100vh - 200px)")};
`;

export const NoDataS3Settings = styled.div`
  height: calc(100vh - 200px);
`;

export const S3SettingsWrapper = styled.div`
  height: calc(100vh - 270px);
`;
