import styled from "styled-components";
export const AssetTypeOverviewWrapper = styled.div`
  width: 95%;
  margin: auto;
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
`;

export const ContainerRow = styled.div`
  margin: auto;
`;


export const FormContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  @media (max-width: 1100px) {
    padding: 8px 12px;
    display: block;
  }
`;

export const FormItem = styled.div`
  flex: 1 1 48%;
  width: 50%;
  max-width: 49%;
  position: relative;
  @media (max-width: 1100px) {
    width: 100%;
    max-width: 100%;
  }
`;