import styled from "styled-components";

export const AssetBulkUploadForm = styled.div`
  text-align: left;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: end;
  margin-top: 15px;
  width: 100%;
  padding: 0px 15px;
`;

export const RowWrapper = styled.div`
  display: flex;
  padding: 0px 15px;
  margin-top: 15px;
  width: 100%;
  text-align: left;
`;

export const AccessLevelsText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7};
  line-height: 1.5;
  font-weight: 400;
  border-left: 2px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  padding-left: 5px;
  ul {
    margin: 0;
    padding-left: 10px;
  }
  ul.dashed {
    list-style-type: none;
  }
  ul.dashed > li {
    text-indent: -5px;
    margin-right: 10px;
    display: flex;
  }
  ul.dashed > li:before {
    content: "---";
    text-indent: -5px;
    margin-right: 15px;
    letter-spacing: -2px;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
`;

export const AccessLevelDescription = styled.p`
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
