// src/components/MobileTabBarSection.jsx
import React from "react";
import DropdownComponent from "../../../../components/DropdownComponent/Dropdown.component";
import { useTranslation } from "react-i18next";
import {
  MobileTabBar,
  TabViewDiv,
  IconActionBar,
  IconDiv,
  CanIconDiv,
  CancelIcon,
  DotIconBack,
  DotIcon,
} from "../ChecklistManagement.component.styles";
import { useHistory, useLocation } from "react-router-dom";

const MobileTabBarSection = ({ isActionBarVisible, setIsActionBarVisible }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const options = [
    { value: "/app/checklistmanagement", label: t("Your Checklists") },
    { value: "/app/checklistmanagement/templates", label: t("View Checklists") },
  ];

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      history.push(selectedOption.value);
    }
  };
  return (
    <MobileTabBar>
      <TabViewDiv>
        <DropdownComponent
          options={options}
          value={options.find((option) => option.value === location.pathname)}
          onChange={handleSelectChange}
        />
      </TabViewDiv>
      <IconActionBar>
        <IconDiv onClick={() => setIsActionBarVisible(!isActionBarVisible)}>
          {isActionBarVisible ? (
            <CanIconDiv>
              <CancelIcon className="icon icon-cancel" />
            </CanIconDiv>
          ) : (
            <DotIconBack>
              <DotIcon className="icon icon-ellipsis-v" />
            </DotIconBack>
          )}
        </IconDiv>
      </IconActionBar>
    </MobileTabBar>
  );
};

export default MobileTabBarSection;
