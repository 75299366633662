export const tableHeader = [
  { name: "Account", space: 0, fieldName: "accountName" },
  { name: "Request Type", space: 0, fieldName: "requestType" },
  { name: "Request Date Range", space: 0, fieldName: "requestDataRange" },
  { name: "Requested By", space: 0, fieldName: "requestBy" },
  { name: "Requested At", space: 0, fieldName: "requestedAt" },
  { name: "Completed At", space: 0, fieldName: "completedAt" },
  { name: "Status", space: 0, fieldName: "status" },
];

export const mobileRowHeader = [
  { name: "Worksite", keyName: "worksite", space: 1, fieldName: "siteName" },
  {
    name: "Incident By",
    keyName: "incidentBy",
    space: 1,
    fieldName: "fullName",
  },
  {
    name: "Asset Impacted",
    keyName: "assetImpacted",
    space: 1,
    fieldName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 1,
    isDate: true,
    fieldName: "impactRecordedAt",
  },
];

export const tableRowData = [
  {
    name: "Account",
    keyName: "accountName",
    space: 0,
    fieldName: "companyName",
  },
  { name: "Worksite", keyName: "worksite", space: 0, fieldName: "siteName" },
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 0,
    fieldName: "AssetTypeName",
  },
  {
    name: "Incident By",
    keyName: "incidentBy",
    space: 0,
    fieldName: "fullName",
  },

  {
    name: "Asset Name",
    keyName: "assetImpacted",
    space: 0,
    fieldName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 0,
    isDate: true,
    fieldName: "impactRecordedAt",
  },
  {
    name: "Impact Severity",
    keyName: "impactSeverity",
    isStatus: true,
    space: 0,
    fieldName: "impactSeverity",
    fieldName2: "incidentObject",
  },
];

export const rowPerPageDropdown = [
  { name: 10, value: 10 },
  { name: 25, value: 25 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
];

export const filterByTypeData = [
  { name: "Asset", _id: "asset" },
  { name: "Asset Type", _id: "assetType" },
  { name: "User", _id: "inspectedBy" },
  { name: "Incident Type", _id: "incidentType" },
];

export const FilterByTypeLabelData = {
  asset: "Asset",
  assetType: "Asset Type",
  inspectedBy: "User",
  incidentType: "Incident Type",
};
