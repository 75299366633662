import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";
import { BreadcrumbStyled, BackButton } from "./BreadCrumbComponent/Breadcrumb.component.styles";
import { withRouter } from "react-router-dom";
import navigationConstant from "../constant/navigationConstant";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

// redux
import { connect } from "react-redux";

const CustomBreadcrumb = (props) => {
  // created a json array to render breadcrumb navigation.
  let intitalPath = "/app";
  let paths = props.location.pathname.split("/"); // path taken from location
  let navigationData = [];
  paths.forEach((path, i) => {
    if (i === 0) return; // at 0 th index it got empty array so return it
    if (path === "app") return;
    intitalPath = intitalPath + "/" + path;
    navigationData.push({ name: path, link: intitalPath });
  });

  const history = useHistory();

  const [historyLength, setHistoryLength] = useState(null);

  useEffect(() => {
    console.debug("history.length", history.length);
    setHistoryLength(history.length);
  }, [history.length]);

  const { breadcrumbItems, size = "small", ...rest } = props;
  const isHomeActive = navigationData.length === 0 || props.location.pathname === "/app/dashboard";

  if (navigationData.length > 5) {
    const breadcrumbItemsMenu = (
      <Dropdown className="dropdownStyle">
        <Dropdown.Toggle variant="secondary" id="breadcrumb-dropdown-toggle">
          ...
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {navigationData.slice(0, -2).map((nav, i) => (
            <Dropdown.Item key={i + 1} href={`/nav${i + 1}`}>
              {navigationConstant[nav.name]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );

    return (
      <BreadcrumbStyled size={size}>
        <Breadcrumb {...rest}>
          {historyLength > 3 ? (
            <Breadcrumb.Item
              onClick={() => history.goBack()}
              className={`backButtonStyle ${isHomeActive ? "active" : ""}`}
            >
              <BackButton size={size} className="icon icon-back-button" />
            </Breadcrumb.Item>
          ) : (
            ""
          )}
          <Breadcrumb.Item
            onClick={() => props.history.push(`/app/dashboard`)}
            className={`homeBreadcrumb ${isHomeActive ? "active" : ""}`}
            active={isHomeActive}
          >
            <Trans>Home</Trans>
          </Breadcrumb.Item>
          {breadcrumbItemsMenu}
          <Breadcrumb.Item href={`/item${breadcrumbItems.length - 2}`}>
            {breadcrumbItems[breadcrumbItems.length - 2]}
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/item${breadcrumbItems.length - 1}`} active>
            <b> {breadcrumbItems[breadcrumbItems.length - 1]}</b>
          </Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbStyled>
    );
  } else {
    return (
      <BreadcrumbStyled size={size}>
        <Breadcrumb {...rest}>
          {
            historyLength > 3 ? (
              <Breadcrumb.Item
                onClick={() => history.goBack()}
                className={`backButtonStyle ${isHomeActive ? "active" : ""}`}
              >
                <BackButton size={size} className="icon icon-back-button" />
              </Breadcrumb.Item>
            ) : null // Instead of an empty string
          }
          <Breadcrumb.Item
            onClick={() => props.history.push(`/app/dashboard`)}
            className={`homeBreadcrumb ${isHomeActive ? "active" : ""}`}
            active={isHomeActive}
          >
            <Trans>Home</Trans>
          </Breadcrumb.Item>
          {navigationData.map((nav, i) => {
            if (!navigationConstant[nav.name]) {
              return null; // Instead of an empty string
            }
            return (
              <Breadcrumb.Item
                onClick={() => {
                  props.history.push(nav.link);
                  if (nav.name === "billing") {
                    props.action();
                  }
                }}
                key={i + 1}
                active={i === navigationData.length - 1}
              >
                <b>
                  <Trans>{navigationConstant[nav.name]}</Trans>
                </b>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </BreadcrumbStyled>
    );
  }
};

CustomBreadcrumb.propTypes = {
  breadcrumbItems: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  action: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({});

const CustomBreadcrumbWithRouter = withRouter(CustomBreadcrumb);
export default connect(null, mapDispatchToProps)(CustomBreadcrumbWithRouter);
