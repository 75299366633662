import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import DataTable from "../../../../../../components/DataTableComponent/DataTable.component";
import {
  tableHeader,
  rowPerPageDropdown,
  mobileCheckboxAccordion,
  mobileCheckboxDateTime,
  accordionFields,
} from "./constants";

import { StyledSpan, LoaderOverlay, StatusContainer, StatusIcon } from "./LoginRecordsDataTable.styles";
import { getTimezoneShortCode } from "../../../../../../utils/timezoneConvert";
import SieraLoader from "../../../../../../components/SieraLoader/SieraLoader.component";

const DataTableWrapper = ({
  isSuperAdmin,
  isSiteAdmin,
  handlePageLength,
  previousPage,
  nextPage,
  pageSize,
  pageNo,
  rowHeight,
  data,
  totalCount,
  currentTimeZone,
  isLoadingRecords,
  worksiteList,
  assetList,
}) => {
  const { t } = useTranslation();
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })
    .filter((column) => column.show)
    .map((data) => {
      if (data.name === "Account") {
        return {
          name: data.name,
          selector: (row) => row?.accountId?.companyName || "NA",
        };
      }
      if (data.name === "Worksite") {
        return {
          name: data.name,
          selector: (row) => {
            return row?.siteId?.name || "NA";
          },
        };
      }

      if (data.name === "Asset") {
        return {
          name: data.name,
          selector: (row) => row?.assetId?.assetName || "NA",
        };
      }

      if (data.name === "User") {
        return {
          name: data.name,
          selector: (row) => row?.userId?.fullName || "NA",
        };
      }

      if (data.name === "Date/Time") {
        return {
          name: data.name,
          selector: (row) =>
            row?.startDate
              ? `${moment(row?.startDate)
                  .utcOffset(currentTimeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm:ss A")} ${getTimezoneShortCode(currentTimeZone)}`
              : "-",
        };
      }

      if (data.name === "Asset Status") {
        return {
          name: data.name,
          selector: (row) => {
            const status = row?.event;

            return (
              <StatusContainer status={status}>
                {status === "lock" ? "Locked" : status === "unlock" ? "Unlocked" : "NA"}
                <StatusIcon status={status}>
                  {status === "lock" ? (
                    <i className="icon icon-vehicle-lock" />
                  ) : status === "unlock" ? (
                    <i className="icon icon-vehicle-unlock" />
                  ) : null}
                </StatusIcon>
              </StatusContainer>
            );
          },
        };
      }

      if (data.name === "Unlock Method") {
        return {
          name: data.name,
          selector: (row) => {
            return capitalizeFirstLetter(row?.data?.unLockMethod || "NA");
          },
        };
      }

      return {
        name: data.name,
        selector: (row) => row?.data?.fieldName || "NA",
      };
    });

  // This column accordion only
  const columnAccordion = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })

    .map((data) => {
      if (data.name === "User Type") {
        return {
          name: data.name,
          selector: (row) => row?.userId?.userTypeId?.name || "NA",
        };
      }

      if (data.name === "Unlock Method") {
        return {
          name: data.name,
          selector: (row) => {
            const unLockMethod =
              row?.unLockMethod === "Email" || row?.unLockMethod === "email"
                ? "Email-Password"
                : row?.unLockMethod;
            return row?.event === "lock" ? "N-A" : capitalizeFirstLetter(unLockMethod || "NA");
          },
        };
      }

      if (data.name === "Lock Reason") {
        return {
          name: data.name,
          selector: (row) => {
            return row?.event === "unlock" ? "N-A" : capitalizeFirstLetter(row?.lockReason || "NA");
          },
        };
      }

      if (data.name === "Platform") {
        return {
          name: data.name,
          selector: (row) => {
            return capitalizeFirstLetter(row?.platform || "NA");
          },
        };
      }

      return {
        name: data.name,
        selector: (row) => row?.data?.fieldName || "NA",
      };
    });

  if (!Array.isArray(data)) {
    return (
      <StyledSpan height={rowHeight}>
        <Trans>NO_LOCK_UNLOCK_LOGS_AVAILABLE</Trans>
      </StyledSpan>
    );
  }

  return (
    <>
      {isLoadingRecords && (
        <LoaderOverlay>
          <SieraLoader OnlyLoaderIcon size="small" />
        </LoaderOverlay>
      )}
      <DataTable
        title=""
        columns={columns}
        data={data}
        pagination
        responsive
        dense
        rowsPerPageOptions={[10, 25, 50, 100]}
        //  loading={false}
        rowHeight={355}
        scrollable={true}
        fixedHeader
        noDataMessage={t("NO_LOCK_UNLOCK_LOGS_AVAILABLE")}
        pageSize={pageSize}
        pageNo={pageNo}
        handlePageLength={handlePageLength}
        paginationOptions={rowPerPageDropdown}
        previousPage={previousPage}
        nextPage={nextPage}
        accordion={true}
        columnAccordian={columnAccordion}
        accordianColumns={accordionFields}
        mobileCheckboxDateTime={mobileCheckboxDateTime}
        mobileCheckboxAccordian={mobileCheckboxAccordion}
        totalPageReport={totalCount}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  currentTimeZone: state.settings.currentTimeZone,
  dropdownAccountList: state.dropdownFilters.accountList,
  worksiteList: state.modalDropdownFilters.worksiteList,
  assetList: state.dropdownFilters.assetList,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DataTableWrapper);
