import styled from "styled-components";

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 200px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddChecklistButton = styled.div`
  float: left;
  text-align: left;
  button.dropdown-toggle {
    background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
`;

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ButtonLink = styled.div`
  display: block;
  overflow: hidden;
  text-align: left;
  width: 100%;
  margin-top: 8px;
  button {
    line-height: 24px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  flex: 1;
  width: 25%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const RightSection = styled.div`
  flex: 3;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DesktopView = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none !important;
  }
`;

export const MobileView = styled.div`
  div[themecontext] {
    width: 100%;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: block;
  float: left;
  width: 100%;
  text-align: left;
  /* margin: 10px 0px;*/

  button {
    margin-right: 10px;
  }
`;

export const ActionBarWrapper = styled.div`
  position: relative;
`;
export const ActionBarButtons = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  display: block;
  .buttonDiv {
    margin-top: 2px;
    width: 100%;
    float: left;
    .checklistButton {
      width: 100%;
      button {
        background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
        border: 0px;
        display: block;
        width: 100%;
        float: left;
        text-align: left;
        color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
        text-transform: capitalize;
        font-size: ${({ theme }) => theme.fontSizes.font6};
        font-family: ${({ theme }) => theme.fontFamily.primaryFont};
        i {
          display: none;
        }
      }
    }
  }
  a {
    text-align: left;
    width: 100%;
    display: block;
    button {
      width: 100%;
      text-align: left;
    }
  }
`;

export const SearchBoxWrapper = styled.div`
  flex: 1;
`;

export const SearchFilter = styled.div`
  display: flex;
  margin-top: 10px;
`;
export const FilterWrapper = styled.div`
  flex: 0;
`;

export const CloseBackDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
`;
export const FilterBackDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
export const FilterIconDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const CloseIconDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const FilterOnclickDes = styled.div``;
export const FilterDesWrapper = styled.div`
  margin-bottom: 10px;
  @media (max-width: 1100px) {
    .col {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const UserMobileListWrapper = styled.div`
  .tableHeight {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    height: auto;
    @media (max-width: 768px) {
      max-height: calc(100vh - 360px);
    }
  }
`;

export const ChecklistDetailHeading = styled.div`
  text-align: left;
  float: left;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  line-height: 45px;
  padding-left: 9px;
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  font-weight: 500;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;

  span {
    font-weight: 500;
  }

  img {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const RightIconImage = styled.div`
  /* font-size: 20px !important; */
  display: inline;
  line-height: 12px;
  margin-left: 5px;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const MobileTabBar = styled.div`
  display: none;
  @media screen and (max-width: 1100px) {
    display: flex;
    margin: 10px auto;
    .select__single-value {
      font-weight: bold !important;
    }
    svg path {
      filter: invert(1);
    }
  }
`;
export const TabViewDiv = styled.div`
  flex: 1;
`;
export const DotIcon = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
export const IconActionBar = styled.div``;
export const IconDiv = styled.div``;
export const CanIconDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
`;
export const DotIconBack = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
export const CancelIcon = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;
export const ButtonDivEdit = styled.div`
  float: right;
  padding: 8px 16px;
  width: 100%;
  text-align: right;
`;

export const ChecklistDescEdit = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  overflow: hidden;
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? "initial" : "100%")};
`;
