import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import S3CameraSettings from "../../Settings/S3CameraSettingNew/S3CameraSettings";
import {
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
  setDropdownAssetId,
  getDropdownAssetList,
  getDropdownAssetTypeByWorksite,
} from "../../../../redux/dropdownFilters/action";
import { useTranslation, Trans } from "react-i18next";
import SettingsHeader from "../common/SettingsHeader.component";

import { SettingsWrapper, NoAssetWrapper, NoDataS3Settings, S3SettingsWrapper } from "../Settings.Styles";

const S3Setting = ({
  vehicleAlertSetting,
  isSuperAdmin,
  assetTypeList,
  assetList,
  updateDropdownAssetId,
  dropdownAssetId,
  dropdownAccountId,
  dropdownWorksiteId,
  dropdownAssetTypeId,
  getDropdownAssetList,
}) => {
  const [vehicleAlertSettingForm, setVehicleAlertSettingForm] = useState(""); //Do we need this? Investigate
  const [assetsForAssetType, setAssetsForAssetType] = useState([]);
  const { t } = useTranslation();
  const [selectedAssetId, setSelectedAssetId] = useState(null);

  useEffect(() => {
    if (!vehicleAlertSetting) {
      return;
    }
    setVehicleAlertSettingForm({ ...vehicleAlertSetting });
  }, [vehicleAlertSetting]);

  /*   useEffect(() => {
    if (isSuperAdmin) {
      setDropdownAccountId(dropdownAccountList?.[0] && dropdownAccountList?.[0]?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAccountList]); */

  /*   useEffect(() => {
    if (dropdownWorksiteList) {
      setDropdownWorksiteId(dropdownWorksiteList?.[0] && dropdownWorksiteList?.[0]?._id);
    } else {
      setDropdownWorksiteId(null);
    }
    getDropdownAssetTypeByWorksite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownWorksiteList]); */

  /* useEffect(() => {
    if (assetTypeList && assetTypeList.length > 0) {
      setDropdownAssetTypeId(assetTypeList[0]?._id);
    } else {
      setDropdownAssetTypeId(null);
    }
  }, [assetTypeList]); */

  useEffect(() => {
    if (assetsForAssetType && assetsForAssetType.length > 0) {
      updateDropdownAssetId(assetsForAssetType[0]?._id);
    } else {
      updateDropdownAssetId(null);
    }
  }, [assetsForAssetType]);

  useEffect(() => {
    const assets = assetList?.filter((asset) => asset?.assetTypeId?._id === dropdownAssetTypeId);
    setAssetsForAssetType(assets ? assets : []);
  }, [dropdownAssetTypeId, assetList]);

  useEffect(() => {
    getDropdownAssetList();
  }, [dropdownAssetTypeId]);

  const handleAssetIdFilter = (assetId, worksiteId, assetTypeId) => {
    //setDropdownAssetTypeId(assetId);
    //setDropdownWorksiteId(worksiteId);
  };

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("DEFINE_THE_LOCATION_OF_CAMERAS_AND_THE_ACTIVE_DETECTION_AREA_AROUND_THE_ASSET")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
      />
      {/* <Row>
        {isSuperAdmin && (
          <Col lg={3}>
            <Dropdown
              filter={{
                type: "Account",
                name: "companyName",
                key: "companyName",
                data: dropdownAccountList,
              }}
              value={dropdownAccountId}
              label={t("filter")}
              useAsDropdown={true}
              handleFilter={(value) => setDropdownAccountId(value)}
            />
          </Col>
        )}
        {!isSiteAdmin && (
          <Col lg={3}>
            <Dropdown
              filter={{
                type: "Worksite",
                key: "name",
                data: dropdownWorksiteList,
                placeholder: t("Select a worksite"),
              }}
              value={dropdownWorksiteId || true}
              label={t("filter")}
              useAsDropdown={true}
              handleFilter={(value) => setDropdownWorksiteId(value)}
            />
          </Col>
        )}
        <Col lg={3}>
          <Dropdown
            filter={{
              type: "Asset Type",
              key: "AssetTypeName",
              data: assetTypeList,
              placeholder: t("Select an asset type"),
            }}
            value={dropdownAssetTypeId || true}
            label={t("filter")}
            useAsDropdown={true}
            handleFilter={(value) => setDropdownAssetTypeId(value)}
          />
        </Col>

        <Col lg={3}>
          <Dropdown
            filter={{
              type: "Asset",
              key: "assetName",
              data: assetsForAssetType,
              placeholder: t("Select an asset"),
            }}
            value={dropdownAssetId || true}
            label={t("filter")}
            useAsDropdown={true}
            handleFilter={(value) => {
              updateDropdownAssetId(value);
            }}
          />
        </Col>
      </Row> */}
      {/*     <Row>
        <Col lg={12}>*/}
      {isSuperAdmin && !dropdownAccountId ? (
        <NoDataS3Settings>
          <NoAssetWrapper>
            <p>
              <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
            </p>
          </NoAssetWrapper>
        </NoDataS3Settings>
      ) : (
        <S3SettingsWrapper>
          <S3CameraSettings
            showCameraSetting={assetTypeList?.length > 0}
            dropdownAssetTypeId={dropdownAssetTypeId}
            dropdownAssetId={dropdownAssetId}
            dropdownWorksiteId={dropdownWorksiteId}
            dropdownAccountId={dropdownAccountId}
          />
        </S3SettingsWrapper>
      )}
      {/*  </Col>*/}
      {/* <Col lg={8}>
          <CameraSettings />
          <TimeToCollision accountId={dropdownAccountId} assetTypeId={dropdownAssetTypeId} siteId={dropdownWorksiteId} />
        </Col> */}
      {/* <Col style={{ marginTop: "10px" }}>
          <VehicleAlert
            accountId={dropdownAccountId}
            vehicleAlertSetting={vehicleAlertSetting}
            setVehicleAlertSetting={(data) => {
              setVehicleAlertSettingForm((prevState) => ({
                ...prevState,
                ...data,
              }));
            }}
          />
        </Col> */}
      {/*  </Row>*/}
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters?.settings?.accountList,
  dropdownWorksiteList: state.dropdownFilters?.settings?.worksiteList,
  updateTimeToCollisionSuccess: state.config.updateTimeToCollisionSuccess,
  updateVehicleAlertSettingSuccess: state.config.updateVehicleAlertSettingSuccess,
  vehicleAlertSetting: state.config.vehicleAlertSetting,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  dropdownWorksiteId: state.dropdownFilters?.settings?.worksiteId,
  dropdownAssetTypeId: state.dropdownFilters?.settings?.assetTypeId,
  dropdownAssetId: state.dropdownFilters?.settings?.assetId,
  assetTypeList: state.dropdownFilters?.settings?.assetTypeList,
  assetList: state.dropdownFilters?.settings?.assetList,
  isSiteAdmin: state.user.isSiteAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setDropdownAssetTypeId: (assetTypeId) => dispatch(setDropdownAssetTypeId(assetTypeId)),
  updateDropdownAssetId: (assetId) => dispatch(setDropdownAssetId(assetId)),
  getDropdownAssetList: () => dispatch(getDropdownAssetList()),
  getDropdownAssetTypeByWorksite: () => dispatch(getDropdownAssetTypeByWorksite()),
});

export default connect(mapStateToProps, mapDispatchToProps)(S3Setting);
