import styled from "styled-components";

export const SelectedItemsWrapper = styled.div`
  border: 1px solid #cecece;
  border-top: 0px;
  margin-top: 4px;
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const ClearAllButton = styled.div`
  float: left;
  display: block;
  width: 100%;
  padding: 5px 10px 3px 10px;
  color: ${({ theme }) => theme.colors.linkColor || "#1070E0"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  text-decoration: underline;
  cursor: pointer;
`;

export const SelectedItemText = styled.div`
  max-width: 375px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RemoveItem = styled.div`
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A)"};
`;

export const SelectedItem = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 4px 4px;
  line-height: ${({ theme }) => theme.lineHeights.line6};
  margin: 2px;
  background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
