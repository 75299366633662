export const errorLogDetails = [
  { name: "Asset Name", space: 2, fieldName: "assetName", show: true },
  { name: "Error Description", space: 2, fieldName: "errorDescription", show: true },
  { name: "Screen Name", space: 2, fieldName: "ScreenName", show: true },
  { name: "Worksite", space: 2, fieldName: "siteName", show: true },
  { name: "Date, Time", space: 2, fieldName: "timeRecordedAt", isDate: true, show: true },
  { name: "App Version", space: 1, fieldName: "appVersion", show: true },
  { name: "S3 Version", space: 1, fieldName: "s3Version", show: true },

  { name: "Account", space: 3, fieldName: "accountName" },
  { name: "Account ID", space: 3, fieldName: "accountId" },
  { name: "Worksite", space: 3, fieldName: "siteName" },
  { name: "Worksite ID", space: 3, fieldName: "siteId", isNested: true, nestedField: "_id" },
  { name: "Asset", space: 3, fieldName: "assetName" },
  { name: "Asset ID", space: 3, fieldName: "assetId" },
  { name: "Error Type", space: 3, fieldName: "errorCategory" },
  { name: "Error Description", space: 3, fieldName: "errorDescription" },
  { name: "Date, Time", space: 3, fieldName: "timeRecordedAt", isDate: true },
  { name: "User Logged In", space: 3, fieldName: "userLoggedIN", isLoginFeild: true },
  { name: "First and Last Name", space: 3, fieldName: "userName" },
  { name: "User ID", space: 3, fieldName: "userId" },
  { name: "App Version", space: 3, fieldName: "appVersion" },
  { name: "S3 Version", space: 3, fieldName: "s3Version" },
  { name: "Bluetooth Version", space: 3, fieldName: "bluetoothVersion" },
  { name: "Screen Name", space: 3, fieldName: "ScreenName" },
  { name: "Camera Position - Disconnected", space: 3, fieldName: "disconnectedCameras", isArray: 1 },
  { name: "Camera Position - Occluded", space: 3, fieldName: "occludedCameras", isArray: 1 },
  { name: "Camera Position - Upside Down", space: 3, fieldName: "orientedCameras", isArray: 1 },
];

// This below code is for mobile accordian view render
export const mobileCheckboxAccordian = [
  "Account",
  "Account ID",
  "Worksite",
  "Worksite ID",
  "Asset",
  "Asset ID",
  "Error Type",
  "Error Description",
  "Date, Time",
  "User Logged In",
  "First and Last Name",
  "User ID",
  "App Version",
  "S3 Version",
  "Bluetooth Version",
  "Screen Name",
  "Camera Position - Disconnected",
  "Camera Position - Occluded",
  "Camera Position - Upside Down",
];

//This is for desktop Accordian only
export const accordianColumns = [
  "Account",
  "Account ID",
  "Worksite",
  "Worksite ID",
  "Asset",
  "Asset ID",
  "Error Type",
  "Error Description",
  "Date, Time",
  "User Logged In",
  "First and Last Name",
  "User ID",
  "App Version",
  "S3 Version",
  "Bluetooth Version",
  "Screen Name",
  "Camera Position - Disconnected",
  "Camera Position - Occluded",
  "Camera Position - Upside Down",
];
// This below code is for mobile view render
export const mobileCheckboxDateTime = [
  { columnName: "Account", className: "accountStyle" },
  { columnName: "Worksite", className: "worksiteStyle" },
  { columnName: "Asset Name", className: "worksiteStyle" },
  { columnName: "User", className: "worksiteStyle" },
  { columnName: "Login Time", className: "reportStatusStyle" },
  { columnName: "Logout Time", className: "reportStatusStyle" },
  { columnName: "Duration", className: "worksiteStyle" },
];
