import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PermissionCheck = (props) => {
  const { permissions, children, section = "DASHBOARD", permissionName, actionName } = props;
  const currentSectionObj = permissions?.find?.((permission) => permission.section === section);
  const currentPermissionObj = currentSectionObj?.permissions?.find(
    (permissionObj) => permissionObj.name === permissionName
  );
  const currentPermission = currentPermissionObj?.action[actionName];

  return <>{currentPermission ? children : null}</>;
};

const mapStateToProps = (state) => ({
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({});

PermissionCheck.propTypes = {
  permissions: PropTypes.array,
  children: PropTypes.element,
  section: PropTypes.string,
  permissionName: PropTypes.string,
  actionName: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionCheck);
