import React, { useEffect, useState } from "react";
import moment from "moment";
import config from "../../../../config";

import { getTimezoneShortCode } from "../../../../utils/timezoneConvert";

import { Trans } from "react-i18next";
import ScoutLogo from "../../../../assets/logo/Scout_Logo.png";
import { ReactComponent as ReportArrowDown } from "../../../../assets/Report_Arrow_Down.svg";
import "./report.css";
import {
  ReportDivWrap,
  ReportPageWrapper,
  ReportTopSection,
  PageNumber,
  SieraLogo,
  EmailHelpText,
  Anchor,
  ReportName,
  ReportDataSection,
  ReportHeaderTop,
  ReportStatus,
  ReportCenterData,
  ReportRightSec,
  VehicleDetailsData,
  VehicleDatNA,
  ReportPageFooter,
  PageMoreDetails,
  ReportCenterDataTableImg,
  UnauthorizedButton,
} from "../../../../components/ReportViewComponent/ReportViewComponent.Style";
import MapView from "./MapView.component";
const s3BucketImpactImage = config.s3BucketImpactImage;

function UnauthorizedAccessReportView() {
  const [reportData, setReportData] = useState(null);
  const [position, setPosition] = useState(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("unauthorizedReportObject"));
    setReportData(data);
    setPosition(data?.location?.coordinates || null);
    //setPosition([12.8430288, 77.6470668]);
    document.title = "Unauthorized Access Report";
  }, []);
  const timeZone = localStorage.getItem("currentTimezone") || reportData?.timeZone;
  return (
    <ReportDivWrap>
      {reportData ? (
        <>
          <ReportPageWrapper>
            <ReportTopSection>
              <PageNumber>Page 1</PageNumber>
              <SieraLogo src={ScoutLogo} alt="" />
              <EmailHelpText>
                <Trans> For support email us at</Trans>
                <Anchor href="mailto:support@siera.ai"> support@siera.ai</Anchor>
              </EmailHelpText>
              <EmailHelpText>
                <Trans> or visit our Helpdesk&nbsp;</Trans>
                <Anchor href="https://support.siera.ai" target="_blank">
                  https://support.siera.ai
                </Anchor>
              </EmailHelpText>
            </ReportTopSection>

            <ReportName>
              <Trans>Unauthorized Access Report</Trans>
            </ReportName>
            <ReportDataSection>
              <ReportHeaderTop>
                <Trans>Summary</Trans>
                <ReportStatus>
                  <UnauthorizedButton>
                    <Trans>Unauthorized Access</Trans>
                  </UnauthorizedButton>
                </ReportStatus>
              </ReportHeaderTop>

              <ReportCenterData>
                <Trans>Worksite</Trans>
                <ReportRightSec>{reportData.siteId && reportData.siteName}</ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Date | Time | Timezone</Trans>
                <ReportRightSec>
                  {moment(reportData.impactRecordedAt)
                    .utcOffset(timeZone.split(" ")[0])
                    .format("dddd, MMMM Do YYYY, h:mm:ss a")}{" "}
                  | {getTimezoneShortCode(timeZone)}
                </ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Asset Name</Trans>
                <ReportRightSec>{reportData.assetId && reportData.assetName}</ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Incident By</Trans>
                <ReportRightSec>
                  <Trans>Unauthorized User</Trans>
                </ReportRightSec>
              </ReportCenterData>
            </ReportDataSection>

            <br />

            <ReportHeaderTop>
              <Trans>Vehicle Details</Trans>
            </ReportHeaderTop>

            <VehicleDetailsData>
              <>
                <ReportCenterData>
                  <Trans>Vehicle Location</Trans>
                  <ReportRightSec>
                    {position && position?.length === 2 && (position[0] !== 0 || position[1] !== 0)
                      ? position.join(", ")
                      : "NA"}
                  </ReportRightSec>
                </ReportCenterData>
                {position && position?.length === 2 && (position[0] !== 0 || position[1] !== 0) && (
                  <MapView position={position} />
                )}
              </>
            </VehicleDetailsData>

            {(typeof reportData?.impactImage == "string" && reportData?.impactImage !== "") ||
            (typeof reportData?.impactImage == "object" && reportData?.impactImage?.length === 0) ? (
              ""
            ) : (
              <PageMoreDetails>
                <ReportArrowDown />
                <p>
                  <Trans>Please proceed to Page 2 for more details</Trans>
                </p>
                <ReportArrowDown />
              </PageMoreDetails>
            )}
            <ReportPageFooter>
              <Trans>Proudly Built in Austin, TX. Copyright </Trans>
              {new Date().getFullYear()}.&nbsp;
              <Trans>Stocked Robotics, Inc. dba SIERA.AI</Trans>
            </ReportPageFooter>
          </ReportPageWrapper>
          {(typeof reportData?.impactImage == "string" && reportData?.impactImage !== "") ||
          (typeof reportData?.impactImage == "object" && reportData?.impactImage?.length === 0) ? (
            ""
          ) : (
            <ReportPageWrapper>
              <ReportTopSection>
                <PageNumber>Page 2</PageNumber>
                <SieraLogo src={ScoutLogo} alt="" />
                <EmailHelpText>
                  For support email us at
                  <Anchor href="mailto:support@siera.ai"> support@siera.ai</Anchor>
                </EmailHelpText>
                <EmailHelpText>
                  or visit our Helpdesk&nbsp;
                  <Anchor href="https://support.siera.ai" target="_blank">
                    https://support.siera.ai
                  </Anchor>
                </EmailHelpText>
              </ReportTopSection>

              <ReportDataSection>
                <ReportHeaderTop>
                  <Trans>Photo of Operator</Trans>
                </ReportHeaderTop>
              </ReportDataSection>
              <ReportCenterDataTableImg>
                {typeof reportData?.impactImage == "string" ? (
                  <img src={s3BucketImpactImage + "" + reportData?.impactImage} width="100%" alt="" />
                ) : (
                  reportData?.impactImage?.map((image) => (
                    <>
                      <img src={s3BucketImpactImage + "" + image} alt="" />
                    </>
                  ))
                )}

                {reportData?.impactMedia?.map?.((image) => (
                  <>
                    <img
                      src={s3BucketImpactImage + "" + image?.impactImageFile}
                      alt={image?.impactImageFile}
                      width="100%"
                    />
                  </>
                ))}
              </ReportCenterDataTableImg>

              <ReportPageFooter>
                <Trans>Proudly Built in Austin, TX. Copyright </Trans>
                {new Date().getFullYear()}.&nbsp;
                <Trans>Stocked Robotics, Inc. dba SIERA.AI</Trans>
              </ReportPageFooter>
            </ReportPageWrapper>
          )}
        </>
      ) : (
        <VehicleDatNA>No Report available</VehicleDatNA>
      )}
    </ReportDivWrap>
  );
}

export default UnauthorizedAccessReportView;
