import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { languageConstant } from "../../../constant/i18n/language";
import { useTranslation, withTranslation, Trans } from "react-i18next";
import { changeLanguage } from "../../../redux/config/action";
import styled from "styled-components";
import FlagImage from "./FlagImage.component";

const SpanText = styled.div`
  visibility: hidden;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  position: relative;
  text-align: center;
  opacity: 0;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  transition: opacity 0.3s ease-in-out 0s;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  @media (max-width: 1100px) {
    opacity: 1;
    visibility: visible;
  }
`;

const IconWrapper = styled.div`
  width: 55px;
  &:hover ~ ${SpanText} {
    visibility: visible;
    opacity: 1;
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    margin-top: -2px;
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  }
  button {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    margin-top: 8px;
    padding: 0px 8px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 4px;
    :after {
      display: none;
    }
    :hover {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
    }
  }
`;

const LanguageSelectorDropdown = ({ changeLanguage, selectedLanguage }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage && selectedLanguage.value);
  }, [selectedLanguage]);

  return (
    <>
      <Dropdown>
        <IconWrapper>
          <Dropdown.Toggle value={0} variant="" id="dropdown-basic">
            <FlagImage
              Component={languageConstant[selectedLanguage.id].flag}
              height="20px"
              paddingBottom="3px"
            />
          </Dropdown.Toggle>
        </IconWrapper>
        <SpanText className="textLang">
          <Trans>Language</Trans>
        </SpanText>
        <Dropdown.Menu value={0}>
          {languageConstant.map((data, i) => (
            <Dropdown.Item onClick={() => changeLanguage(data)} id={data.id} key={i}>
              <FlagImage Component={data.flag} height="30px" /> {data.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLanguage: state.config.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (selectedLanguage) => dispatch(changeLanguage(selectedLanguage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectorDropdown);
