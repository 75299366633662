import React from "react";
import DropdownFilter from "../../../../components/DropdownComponent/DropdownWrapper";
import { ChecklistDropdown } from "../ChecklistOverview.component.styles";

const DropdownFilterSection = ({
  dropdownAccountList,
  dropdownAccountId,
  setDropdownAccountId,
  isSuperAdmin,
  t,
}) => {
  return (
    isSuperAdmin && (
      <ChecklistDropdown className="DropdownFilterAccount">
        <DropdownFilter
          filter={{
            type: "Account",
            name: "companyName",
            key: "companyName",
            data: dropdownAccountList,
          }}
          value={dropdownAccountId}
          label={t("filter")}
          handleFilter={(value) => setDropdownAccountId(value)}
        />
      </ChecklistDropdown>
    )
  );
};

export default DropdownFilterSection;
