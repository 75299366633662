/**
 * TODO:  Create a common filter component for all the reports/Dashboard pages when time permits
 * Do code optimization
 * Fix lint issues
 * Once completed, please remove this comment
 * Create common components
 */

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  exportAllImpactCSV,
  getZipDownloadImpactReport,
  exportAllImpactCSVResetState,
  resetGetZipFileImpactReport,
} from "../../../../redux/reports/action";
import { setDropdownAccountId, setDropdownWorksiteId } from "../../../../redux/dropdownFilters/action";
import { setNearMissReportFilter } from "../../../../redux/filters/action";
import "./../Report.css";
import { getNearMissReports } from "../../../../redux/reports/action";
import { toDownloadFiles } from "../../../../redux/config/action";
import DownloadAllReportModal from "../../Modals/DownloadAllReportModal";
import Filters from "./components/Filters.component";
import { rowPerPageDropdown } from "./constants";
import SaveToOptions from "./components/SaveToOptions.component";
import TableWrapper from "./TableWrapper/TableWrapper.component";
import { ImpactReportDiv } from "../Reports.styles";
import { FiltersWrapperTop, FilterWrap } from "../Report.component.styles";

function NearMissReports(props) {
  const {
    loginDetails,
    isSuperAdmin,
    isSiteAdmin,
    setDropdownAccountId,
    setDropdownWorksiteId,
    getNearMissReports,
    nearMissReportList,
    getNearMissReportListLoading,
    totalReportCount,
    getZipDownloadImpactReport,
    getZipFileImpactReportLoading,
    dropdownAssetList,
    nearMissFilter,
    setNearMissReportFilter,
    dropdownFilterAccountId,
    dropdownFilterWorksiteId,
    exportAllImpactCSV,
    exportAllImpactCSVLoading,
    exportAllImpactCSVSuccess,
    exportAllImpactCSVResetState,
    currentTimeZone,
    getZipFileImpactReportError,
    resetGetZipFileImpactReport,
  } = props;

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(nearMissFilter?.pageSize || 10);

  const [downloadReportSet, addDownloadReportSet] = useState(new Set());
  const [isAllSelected, setSelectAll] = useState(false);
  const [incidentTypeFilter, setIncidentTypeFilter] = useState("all");
  const [isDownloadAllSelected, setIsDownloadAllSelected] = useState(false);
  const [showDownloadAllReportModal, setShowDownloadAllReportModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const { accountId, filterByType, obstacleType } = nearMissFilter;

  useEffect(() => {
    setIncidentTypeFilter("all");
  }, [filterByType]);

  const handleCheckboxClick = (e) => {
    const newDownloadReportSet = new Set(downloadReportSet);
    if (e.target.checked) {
      newDownloadReportSet.add(e.target.value);
    } else {
      newDownloadReportSet.delete(e.target.value);
    }
    addDownloadReportSet(newDownloadReportSet);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newDownloadReportSet = new Set(downloadReportSet);
      nearMissReportList &&
        nearMissReportList.forEach((data, i) => {
          newDownloadReportSet.add(data._id);
        });
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(true);
    } else {
      const newDownloadReportSet = new Set();
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    setNearMissReportFilter({
      ...nearMissFilter,
      assetId: "all",
      assetTypeId: "all",
      inspectorId: "all",
      impactSeverity: "all",
      pageNo: 1,
    });
  }, [dropdownFilterAccountId]);

  const handlePageLength = (e) => {
    setPageSize(e.target.value);
    setNearMissReportFilter({
      ...nearMissFilter,
      pageSize: e.target.value,
      pageNo: 1,
    });
    addDownloadReportSet(new Set());
    setPageNo(1);
  };

  useEffect(() => {
    setNearMissReportFilter({ ...nearMissFilter, worksiteId: "all" });
    if (accountId === "all") {
      setSelectedAccount(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    setNearMissReportFilter({
      ...nearMissFilter,
      assetTypeId: "all",
      assetId: "all",
      inspectorId: "all",
      impactSeverity: "all",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByType]);

  const nextPage = () => {
    if (pageNo > Math.ceil((totalReportCount || 0) / pageSize) - 1) {
      return;
    }
    let newPage = pageNo + 1;
    setNearMissReportFilter({ ...nearMissFilter, pageNo: newPage });
    setPageNo(newPage);
    addDownloadReportSet(new Set());
  };

  const previousPage = () => {
    if (pageNo <= 1) {
      return;
    }
    let newPage = pageNo - 1;
    setNearMissReportFilter({ ...nearMissFilter, pageNo: newPage });
    setPageNo(newPage);
    addDownloadReportSet(new Set());
  };

  useEffect(() => {
    getNearMissReports(nearMissFilter);
  }, [nearMissFilter.pageNo, nearMissFilter.pageSize]);

  useEffect(() => {
    if (!isSuperAdmin) {
      setNearMissReportFilter({
        ...nearMissFilter,
        accountId: loginDetails && loginDetails.accountId,
      });
    }
    getNearMissReports(nearMissFilter);
  }, [loginDetails]);

  useEffect(() => {
    const startResultNumber = pageSize * (pageNo - 1);
    const endResultNumber =
      parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalReportCount
        ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
        : totalReportCount;
    const currentTotalReports = endResultNumber - startResultNumber;

    if (downloadReportSet.size === currentTotalReports && currentTotalReports !== 0) {
      setSelectAll(true);
    } else {
      setIsDownloadAllSelected(false);
      setSelectAll(false);
    }
  }, [downloadReportSet]);

  useEffect(() => {
    if (!showDownloadAllReportModal) {
      addDownloadReportSet(new Set());
    }
  }, [showDownloadAllReportModal]);

  useEffect(() => {
    if (exportAllImpactCSVSuccess) {
      setShowDownloadAllReportModal(true);
      exportAllImpactCSVResetState();
    }
  }, [exportAllImpactCSVSuccess]);

  const applyChange = () => {
    nearMissFilter.obstacleType = obstacleType || "all";

    getNearMissReports(nearMissFilter);
    addDownloadReportSet(new Set());
  };

  const [showMoreFilter, setShowMoreFilter] = useState(false);

  return (
    <>
      <FiltersWrapperTop>
        <FilterWrap>
          <Filters
            isSuperAdmin={isSuperAdmin}
            isSiteAdmin={isSiteAdmin}
            setDropdownAccountId={setDropdownAccountId}
            setDropdownWorksiteId={setDropdownWorksiteId}
            incidentReportFilter={nearMissFilter}
            setIncidentReportFilter={setNearMissReportFilter}
            dropdownAssetList={dropdownAssetList}
            loginDetails={loginDetails}
            dropdownFilterWorksiteId={dropdownFilterWorksiteId}
            setIncidentTypeFilter={setIncidentTypeFilter}
            incidentTypeFilter={incidentTypeFilter}
            setPageNo={setPageNo}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            applyChange={applyChange}
            showMoreFilter={showMoreFilter}
            setShowMoreFilter={setShowMoreFilter}
          />
        </FilterWrap>
        <SaveToOptions
          downloadReportSet={downloadReportSet}
          getZipDownloadImpactReport={getZipDownloadImpactReport}
          loginDetails={loginDetails}
          exportAllImpactCSV={exportAllImpactCSV}
          incidentReportFilter={nearMissFilter}
          getImpactReportListing={getNearMissReports}
          addDownloadReportSet={addDownloadReportSet}
          getZipFileImpactReportLoading={getZipFileImpactReportLoading}
          isDownloadAllSelected={isDownloadAllSelected}
          exportAllImpactCSVLoading={exportAllImpactCSVLoading}
          inspectionReportList={nearMissReportList}
          currentTimeZone={currentTimeZone}
          getZipFileReportFailed={getZipFileImpactReportError}
          resetZipFileReport={resetGetZipFileImpactReport}
        />
      </FiltersWrapperTop>

      <ImpactReportDiv>
        <TableWrapper
          data={nearMissReportList}
          isSiteAdmin={isSiteAdmin}
          isSuperAdmin={isSuperAdmin}
          handleSelectAll={handleSelectAll}
          handleCheckboxClick={handleCheckboxClick}
          downloadReportSet={downloadReportSet}
          isAllSelected={isAllSelected}
          handlePageLength={handlePageLength}
          pageSize={pageSize}
          paginationOptions={rowPerPageDropdown}
          pageNo={pageNo}
          totalPageReport={totalReportCount}
          previousPage={previousPage}
          nextPage={nextPage}
          selectedAccount={selectedAccount}
          rowHeight={370}
          loading={getNearMissReportListLoading}
          showMoreFilter={showMoreFilter}
        />
      </ImpactReportDiv>
      <DownloadAllReportModal
        showDownloadAllReportModal={showDownloadAllReportModal}
        setShowDownloadAllReportModal={setShowDownloadAllReportModal}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  nearMissFilter: state.filters.nearMissFilter,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  nearMissReportList: state.reports.nearMissReportList,
  getNearMissReportListLoading: state.reports.getNearMissReportListLoading,
  totalReportCount: state.reports.nearMissReportTotalCount,
  exportAllImpactCSVLoading: state.reports.exportAllImpactCSVLoading,
  exportAllImpactCSVSuccess: state.reports.exportAllImpactCSVSuccess,
  getZipFileImpactReportLoading: state.reports.getZipFileImpactReportLoading,
  currentTimeZone: state.settings.currentTimeZone,
  getZipFileImpactReportError: state.reports.getZipFileImpactReportError,
});

const mapDispatchToProps = (dispatch) => ({
  getNearMissReports: (filterData) => dispatch(getNearMissReports(filterData)),
  getZipDownloadImpactReport: (filterReportArray) => dispatch(getZipDownloadImpactReport(filterReportArray)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  setNearMissReportFilter: (filterData) => dispatch(setNearMissReportFilter(filterData)),
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
  exportAllImpactCSV: () => dispatch(exportAllImpactCSV()),
  exportAllImpactCSVResetState: () => dispatch(exportAllImpactCSVResetState()),
  resetGetZipFileImpactReport: () => dispatch(resetGetZipFileImpactReport()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NearMissReports));
