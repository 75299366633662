import styled from "styled-components";

export const ButtonLocked = styled.div`
  & button {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    border: 1.3px solid ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    &:hover {
      background: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
      color: #ffffff;
    }
  }
`;
export const ButtonUnLocked = styled.div`
  & button {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    border: 1.3px solid ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    &:hover {
      background: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    }
  }
`;

export const ManagementPanelWrapper = styled.div`
  margin-top: 8px;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  /*  .card {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  header {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .bg-white {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .assetTableszOver {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .buttontabpanels span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .title-style,
  .asset-type-size,
  .asset-type-size span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .assetTableView p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  label {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  .input-group-text {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  input.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  p.statusStyle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .noSection p {
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  }*/
`;

export const AssetOverviewCard = styled.div`
  position: relative;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  z-index: 0;
  display: block;
  height: calc(100vh - 314px);
  @media (max-width: 1100px) {
    height: 100%;
    display: block;
  }
`;

export const AssetOverviewCardBody = styled.div`
  padding: 8px 16px;
  @media (max-width: 1100px) {
    padding: 4px 8px;
  }
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 20% 30%;
  @media (max-width: 1100px) {
    width: 100%;
    display: block;
  }
`;
export const WrapperDiv = styled.div`
  flex: 1;
  padding: 0px 5px;
  @media (max-width: 1100px) {
    float: right;
    text-align: end;
    margin-right: 10px;
  }
`;

export const WrapperDivDetails = styled.div`
  float: left;
  display: flex;
`;
export const FullNameSpan = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font3} !important;
  line-height: 30px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 1100px) {
    font-size: ${({ theme }) => theme.fontSizes.font3} !important;
    line-height: ${({ theme }) => theme.lineHeights.line4};
  }
`;
export const HeadingSpan = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  line-height: ${({ theme }) => theme.lineHeights.line6};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 2px;
  @media (max-width: 1100px) {
  }
`;
export const HeadingDiv = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  line-height: ${({ theme }) => theme.lineHeights.line6};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (max-width: 1100px) {
  }
`;

export const SwitchWrapper = styled.div`
  text-align: right;
  padding-left: 10px;
  flex: 0;
  label {
    margin: 0px !important;
  }
  @media (max-width: 1100px) {
    display: block;
    clear: both;
    width: 100%;
    margin: 10px 0px !important;
    overflow: hidden;
    text-align: left;
    float: left;
  }
`;

export const ImageAndDescDiv = styled.div`
  flex: 1;
  display: flex;
  @media (max-width: 1100px) {
    width: 50%;
    display: block;
    float: left;
    margin-top: 15px;
  }
`;
export const WrapperDesDiv = styled.div`
  padding: 0px 10px;
  display: inline-block;
  width: 100%;
  overflow: hidden;
`;
export const ImagWrapperDiv = styled.div`
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const StatusHead = styled.div`
  @media (max-width: 1100px) {
    display: none;
  }
`;
export const StatusMobileRender = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    label {
      margin: 10px 0px !important;
    }
  }
`;
export const StatusDesktopRender = styled.div`
  display: block;
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const TabComponentDiv = styled.div`
  .nav a {
    padding: 7px 0px;
  }
  .nav-tabs {
  }
  .nav-link {
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  }
  .tab-content {
    padding: 0px 0px 0px 0px;
  }
  @media (max-width: 1100px) {
    clear: both;
    width: 95%;
    margin: auto;
  }
`;

export const NoSectionStyle = styled.div`
  display: block;
  height: calc(100vh - 360px);
  align-content: center;
  clear: both;
  display: block;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  p {
    margin-bottom: 0px;
  }
  .clickDesktop {
    display: block;
    @media (max-width: 1100px) {
      display: none;
    }
  }
  .tapMobile {
    display: none;
    @media (max-width: 1100px) {
      display: block;
    }
  }
`;

export const ButtonWrapperCol = styled.div`
  flex: 1;
  float: left;
  display: inline-flex;

  .col {
    padding-right: 0px;
  }
`;

export const ButtonWrapperRow = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    ${ButtonWrapperCol} {
      &:last-child {
        flex: 2;
      }
    }
  }
`;

export const ButtonWrapperAdjust = styled.div`
  /*  margin-left: 10px; */
`;
