import styled from "styled-components";
export const AssetTypeDetailsWrapper = styled.div`
  width: 95%;
  margin: auto;
`;
export const DeleteIconStyled = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  cursor: pointer;
  text-align: center;
  @media (max-width: 1100px) {
    text-align: left;
    padding: 10px 0px;
  }
`;

export const ErrorMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
`;

export const RadioButtonContainer = styled.div`
  display: flex;
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
`;

export const AssetTypeDetailsDiv = styled.div`
  .userTypeDetailsHeight {
    overflow-y: auto;
    /*height: calc(100vh - 515px);*/
    height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 516px)" : "calc(100vh - 500px)")};
    @media (max-width: 1100px) {
      height: auto;
    }
  }
`;

export const AssetTypeDetailsTableDescription = styled.div``;
export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 35% 15%;
  line-height: ${({ theme }) => theme.lineHeights.line2};

  label {
    margin-bottom: 0px;
  }
  padding: 5px 0px;
  @media (max-width: 1100px) {
    line-height: ${({ theme }) => theme.lineHeights.line4};
    grid-template-columns: ${({ isHeader }) => (isHeader ? "25% 25% 25% 25%" : "100%")};
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "0px")};
    height: auto;
    padding: ${({ isHeader }) => (isHeader ? "inherit" : "10px")};
    display: ${({ isHeader }) => (isHeader ? "none" : "")};
    border: ${({ isHeader, theme }) =>
      isHeader
        ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
        : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};

    .firstMobileCss {
      font-size: ${({ theme }) => theme.fontSizes.font6} !important;
    }
  }
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  :nth-last-child {
    border-bottom: ${({ isHeader, theme }) =>
      isHeader
        ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
        : "0px solid " + theme.colors.grayL5 || "#CCCCCC"};
  }
`;

export const Column = styled.div`
  padding: ${({ isHeader }) => (isHeader ? "0px" : "0px")};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  background: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  font-size: ${({ isHeader, theme }) =>
    isHeader ? theme.fontSizes.font5 : theme.fontSizes.font7} !important;
  font-family: ${({ isHeader, theme }) =>
    isHeader ? theme.fontFamily.secondaryFont : theme.fontFamily.primaryFont};
  border-top-left-radius: ${({ isHeader }) => (isHeader ? "4px" : "0px")};
  border-top-right-radius: ${({ isHeader }) => (isHeader ? "4px" : "0px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ textleft }) => (textleft ? textleft : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  width: ${({ inputWidth }) => (inputWidth ? inputWidth : "100%")};

  @media (max-width: 1100px) {
    font-size: ${({ isHeader, theme }) =>
      isHeader ? theme.fontSizes.font7 : theme.fontSizes.font7} !important;
    padding: ${({ inputWidth }) => (inputWidth ? "5px 0px" : "initial")};
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  padding: 0 12px;
  @media (max-width: 1100px) {
    padding: 0 12px;
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;
  /* height: calc(100vh - 450px);*/

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  @media (max-width: 1100px) {
    margin-bottom: 15px;
  }
`;

export const MobileViewText = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
  }
`;
export const ColumnReorder = styled(Column)`
  @media (max-width: 1100px) {
    &:nth-child(3) {
      order: 4;
    }
    &:nth-child(4) {
      order: 3;
    }
  }
`;

export const ButtonWrapperDiv = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 1100px) {
    display: flex;
  }
`;

export const AddChecklistDivB = styled.div`
  flex: 1;
`;

export const EditCancelButton = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    display: flex;
  }
`;
