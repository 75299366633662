import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import CustomStepper from "../../../../../components/StepperComponent/Stepper.Component";
import AddAssetTypeDetails from "./AddAssetTypeDetails.component";
import AddAssetTypeOverview from "./AddAssetTypeOverview.component";
import AddAssetTypeChecklist from "./AddAssetTypeChecklist.component";
import ModalComponent from "../../../../../components/ModalPopupComponent/ModalPopup.component";

function AddAssetTypeModal(props) {
  const { t } = useTranslation();
  const [dropdownAccountId, setAccountId] = useState("");
  const [validated, setValidated] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [addAssetTypeModalData, setAddAssetTypeModalData] = useState({
    assetTypeName: "",
    AssetTypeDescription: "",
    AssetTypeAttributes: [
      {
        id: 1234,
        AssetAttributeName: t("Make"),
        AssetAttributeRequired: false,
        AssetAttributeDescription: t("Manufacturer of the asset"),
        disabled: false,
        removeDelete: true,
      },
      {
        id: 2345,
        AssetAttributeName: t("Model"),
        AssetAttributeRequired: false,
        AssetAttributeDescription: t("Model Type"),
        disabled: false,
        removeDelete: true,
      },
      {
        id: 3456,
        AssetAttributeName: t("Serial Number"),
        AssetAttributeRequired: false,
        AssetAttributeDescription: t("Manufacturer’s serial number"),
        disabled: false,
        removeDelete: true,
      },
    ],
  });

  const steps = [
    { iconClass: "assettypeoverview", heading: t("Asset Type Overview") },
    { iconClass: "assettypedetails", heading: t("Asset Type Detail") },
    { iconClass: "assettypechecklistsicon", heading: t("Asset Type Checklists") },
  ];

  const getAddAssetTypeModalStep = (step, setActiveStep, handleCloseModal) => {
    switch (step) {
      case 0:
        return (
          <AddAssetTypeOverview
            addAssetTypeModalData={addAssetTypeModalData}
            setAddAssetTypeModalData={setAddAssetTypeModalData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setAccountId={setAccountId}
            dropdownAccountId={dropdownAccountId}
            validated={validated}
            setValidated={setValidated}
          />
        );
      case 1:
        return (
          <AddAssetTypeDetails
            addAssetTypeModalData={addAssetTypeModalData}
            setAddAssetTypeModalData={setAddAssetTypeModalData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            dropdownAccountId={dropdownAccountId}
            handleCloseModal={handleCloseModal}
            setIsError={props.setIsError}
            setIsSuccess={props.setIsSuccess}
            validated={validated}
            setValidated={setValidated}
          />
        );
      case 2:
        return (
          <AddAssetTypeChecklist
            dropdownAccountId={dropdownAccountId}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            addAssetTypeModalData={addAssetTypeModalData}
            setAddAssetTypeModalData={setAddAssetTypeModalData}
            setIsError={props.setIsError}
            setIsSuccess={props.setIsSuccess}
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        return <AddAssetTypeOverview />;
    }
  };

  /*   useEffect(() => {
    console.log(addAssetTypeModalData);
  }, [addAssetTypeModalData]); */

  useEffect(() => {
    resetModalData();
  }, []);

  const resetModalData = () => {
    setActiveStep(0);
    setAccountId("");
    setAddAssetTypeModalData({
      assetTypeName: "",
      assetTypeDescription: "",
      AssetTypeAttributes: [
        {
          id: 1234,
          AssetAttributeName: t("Make"),
          AssetAttributeRequired: false,
          AssetAttributeDescription: t("Manufacturer of the asset"),
          disabled: false,
          removeDelete: true,
        },
        {
          id: 2345,
          AssetAttributeName: t("Model"),
          AssetAttributeRequired: false,
          AssetAttributeDescription: t("Model Type"),
          disabled: false,
          removeDelete: true,
        },
        {
          id: 3456,
          AssetAttributeName: t("Serial Number"),
          AssetAttributeRequired: false,
          AssetAttributeDescription: t("Manufacturer’s serial number"),
          disabled: false,
          removeDelete: true,
        },
      ],
    });
  };

  const handleModalCloseCustom = () => {
    resetModalData();
    setValidated(false);
    props.toogleModalClose();
  };

  return (
    <ModalComponent
      show={props.show}
      title={t("Add Asset Type")}
      handleClose={handleModalCloseCustom}
      withHeader
    >
      <CustomStepper className="mt-2 mb-4" allSteps={steps} activeStep={activeStep} />
      {getAddAssetTypeModalStep(activeStep, setActiveStep, handleModalCloseCustom)}
    </ModalComponent>
  );
}

AddAssetTypeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toogleModalClose: PropTypes.func.isRequired,
  setIsError: PropTypes.func.isRequired,
  setIsSuccess: PropTypes.func.isRequired,
};

export default AddAssetTypeModal;
