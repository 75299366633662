import styled from "styled-components";

export const UserTypeListingDiv = styled.div`

`;

export const ListUserTypeDiv = styled.div`
  clear: both;
  width: 100%;
  float: left;
  border-bottom: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  padding: 10px;
`;

export const DotIcon = styled.i`
  float: left;
  position: relative;
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  top: 4px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
`;

export const UserTypeName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
`;
