import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import AssetOverview from "./AssetOverview.component";
import AssetFilters from "./AssetFilters.component";
import { setEditAlertModal } from "../../../redux/modals/action";
import AssetsCard from "./AssetsCard.component";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import AssetReactivation from "./AssetReactivation.component";
import { resetAllAssetStatus } from "../../../redux/assets/action";
import {
  getDropdownWorksiteList,
  getDropdownAccountList,
  getDropdownProductList,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
} from "../../../redux/dropdownFilters/action";

const Assets = ({
  loginDetails,
  assetTotalCount,
  getDropdownWorksiteList,
  getDropdownProductList,
  dropdownWorksiteList,
  dropdownAssetTypeList,
  getDropdownAccountList,
  dropdownAccountList,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
  dropdownAccountId,
  dropdownWorksiteId,
  dropdownAssetTypeId,
  setEditAlertModal,
  editForm,
  editFormSecond,
  isSuperAdmin,
  showWorksiteDropdown,
  assetStatusData,
  assetStatusFailed,
  resetAllAssetStatus,
  assetOverview,
}) => {
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    getDropdownWorksiteList();
    getDropdownAccountList();
    getDropdownProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assetStatusFailed) {
      setShowAlertModal(true);
    }
  }, [assetStatusFailed]);

  const handleModalClose = () => {
    setShowAlertModal(false);
    resetAllAssetStatus();
  };

  return (
    <>
      <div>
        <Row>
          <AssetFilters
            isSuperAdmin={isSuperAdmin}
            dropdownAccountList={dropdownAccountList}
            dropdownWorksiteList={dropdownWorksiteList}
            dropdownAssetTypeList={dropdownAssetTypeList}
            dropdownAccountId={dropdownAccountId}
            dropdownWorksiteId={dropdownWorksiteId}
            dropdownAssetTypeId={dropdownAssetTypeId}
            setDropdownAccountId={setDropdownAccountId}
            setDropdownWorksiteId={setDropdownWorksiteId}
            setDropdownAssetTypeId={setDropdownAssetTypeId}
            setEditAlertModal={setEditAlertModal}
            showWorksiteDropdown={showWorksiteDropdown}
            editForm={editForm}
          />

          {!showWorksiteDropdown && <Col md={3}></Col>}
          <Col md={3}>
            <AssetsCard count={assetTotalCount || 0} />
          </Col>
        </Row>
      </div>

      <AssetOverview />

      <ModalComponent
        show={showAlertModal}
        variant="AlertModal"
        handleClose={handleModalClose}
        iconClass="alertsettings"
        message="Cannot Activate Asset"
        paragraph={
          <AssetReactivation id={assetOverview ? assetOverview._id : ""} assetStatusData={assetStatusData} />
        }
        button1Text="Continue"
        button2Text=""
        onClickA={handleModalClose}
        typeA="primary"
        typeB="ghost"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetList: state.assets.Assets,
  assetTotalCount: state.assets.totalCount,
  worksiteList: state.worksites.Worksites,
  accountList: state.accounts.Accounts,
  dropdownAccountId: state.dropdownFilters.accountId,
  dropdownWorksiteId: state.dropdownFilters.worksiteId,
  dropdownAssetTypeId: state.dropdownFilters.assetTypeId,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAccountList: state.dropdownFilters.accountList,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
  isSuperAdmin: state.user.isSuperAdmin,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
  assetStatusData: state.assets.assetStatusData,
  assetStatusFailed: state.assets.assetStatusFailed,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getDropdownProductList: () => dispatch(getDropdownProductList()),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setDropdownAssetTypeId: (assetTypeId) => dispatch(setDropdownAssetTypeId(assetTypeId)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  resetAllAssetStatus: () => dispatch(resetAllAssetStatus()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assets));
