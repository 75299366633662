import styled from "styled-components";
export const DownloadStyled = styled.div`
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
`;

export const AssetBulkUploadTableView = styled.div`
  margin-bottom: 20px;
  margin-top: 5px;
`;

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 33% 53% 13%;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  margin-right: 2%;
  @media (max-width: 1100px) {
    display: block;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "25px")};
  }
`;

export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader, theme }) =>
    isHeader ? theme.fontSizes.font7 : theme.fontSizes.font7} !important;
  font-family: ${({ isHeader, theme }) =>
    isHeader ? theme.fontFamily.secondaryFont : theme.fontFamily.primaryFont};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  label {
    margin: 0 !important;
  }
  @media (max-width: 1100px) {
    display: ${({ isHeader }) => (isHeader ? "none;" : "block;")};
    width: ${({ widthResp }) => (widthResp ? widthResp : "75%")};
    float: ${({ floatResp }) => (floatResp ? floatResp : "left")};
    margin-right: ${({ RmarginResp }) => (RmarginResp ? RmarginResp : "5%")};
    padding: 7px;
  }
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
`;

export const RowsContainer = styled.div`
  overflow: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  height: calc(100vh - 530px);
  @media (max-width: 1100px) {
    margin-bottom: 50px;
  }
`;

export const DownloadAnchor = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  height: calc(100vh - 530px);
`;

export const NoAssetDivView = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  position: relative;
  top: -80px;
  p {
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  }
`;
