import React from "react";
import styled from "styled-components";

export const TopHeaderDetailsDiv = styled.div`
  width: 100%;
`;
export const TopTittleName = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  padding-bottom: 8px;
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const TopDetails = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  padding-bottom: 8px;
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const DateStyleDiv = styled.div`
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  text-align: left;
  padding-bottom: 4px;
  display: ${({ show }) => (show ? "flex" : "none")};
`;
export const LeftHeading = styled.div``;
export const RightHeading = styled.div`
  margin-left: 4px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

const DetailsDescriptions = ({ title, details, date, dataTitle, customTitle }) => {
  return (
    <TopHeaderDetailsDiv>
      <TopTittleName show={title} className="heading-03 ellipsisCSS" title={customTitle || title}>
        {title}
      </TopTittleName>

      <TopDetails show={details} title={customTitle || details} className="body-02 ellipsisCSS">
        {details}
      </TopDetails>

      <DateStyleDiv show={date} title={customTitle || date} className="body-03 ellipsisCSS">
        <LeftHeading>{dataTitle} :</LeftHeading>
        <RightHeading>{date}</RightHeading>
      </DateStyleDiv>
    </TopHeaderDetailsDiv>
  );
};

export default DetailsDescriptions;
