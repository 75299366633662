import { countTypes } from "./types";
import { initialState } from "./initialState";

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case countTypes.GET_ALL_COUNT_LOADING:
      return {
        ...state,
        getAllCountLoading: true,
      };

    case countTypes.GET_ALL_COUNT_SUCCESS:
      return {
        ...state,
        getAllCountLoading: false,
        allCount: action.data,
      };

    case countTypes.GET_INSPECTION_COUNT_LOADING:
      return {
        ...state,
        getInspectionCountLoading: true,
      };

    case countTypes.GET_INSPECTION_COUNT_SUCCESS:
      return {
        ...state,
        getInspectionCountLoading: false,
        inspectionCount: action.data,
      };

    case countTypes.GET_UNAUTHORIZED_COUNT_LOADING:
      return {
        ...state,
        getUnauthorizedCountLoading: true,
      };

    case countTypes.GET_UNAUTHORIZED_COUNT_SUCCESS:
      return {
        ...state,
        getUnauthorizedCountLoading: false,
        unauthorizedCount: action.data,
      };

    case countTypes.GET_GRAPH_DATA_LOADING:
      return {
        ...state,
        graphData: {
          ...(state?.graphData ?? {}),
          loading: true,
        },
      };
    case countTypes.GET_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        graphData: {
          ...action.payload,
          loading: true,
        },
      };

    case countTypes.GET_GRAPH_DATA_FAILED:
      return {
        ...state,
        graphData: {
          loading: false,
        },
      };
    case countTypes.GET_INCIDENT_GRAPH_DATA_LOADING:
      return {
        ...state,
        incidentGraphData: {
          ...(state?.graphData ?? {}),
          loading: true,
        },
      };
    case countTypes.GET_INCIDENT_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        incidentGraphData: {
          ...action.payload,
          loading: true,
        },
      };

    case countTypes.GET_NEAR_MISS_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        nearMissGraphData: {
          ...action.payload,
          loading: true,
        },
      };

    case countTypes.GET_INCIDENT_GRAPH_DATA_FAILED:
      return {
        ...state,
        incidentGraphData: {
          loading: false,
        },
      };
    case countTypes.GET_UNAUTHORIZED_GRAPH_DATA_LOADING:
      return {
        ...state,
        unauthorizedGraphData: {
          ...(state?.graphData ?? {}),
          loading: true,
        },
      };
    case countTypes.GET_UNAUTHORIZED_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        unauthorizedGraphData: {
          ...action.payload,
          loading: true,
        },
      };

    case countTypes.GET_UNAUTHORIZED_GRAPH_DATA_FAILED:
      return {
        ...state,
        unauthorizedGraphData: {
          loading: false,
        },
      };
    case countTypes.GET_INCIDENT_COUNT_LOADING:
      return {
        ...state,
        getIncidentCountLoading: true,
      };

    case countTypes.GET_INCIDENT_COUNT_SUCCESS:
      return {
        ...state,
        getIncidentCountLoading: false,
        ...action.data,
      };

    // Returning default state
    default:
      return state;
  }
};
