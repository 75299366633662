import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import AccountOverviewTab from "./AccountOverviewTab.component";
import { setEditAlertModal } from "../../../redux/modals/action";
// Custom Carousel Component
import CarouselComponent from "../../../components/CarouselComponent/Carousel.component";
import { resetAccountNameState } from "../../../redux/accounts/action";
import SnackBar from "../SnackBar";
import {
  AccountPageStyled,
  CarouselWrapperDiv,
  AccountTypeTopHeader,
  AccountTypeHeadDetail,
  DetailsWrapper,
  AccountTypeDataDescp,
  AccountTabTabDiv,
  NoSectionStyle,
  Rows,
  Cols,
  LeftCol,
  RightCol,
} from "./AccountOverview.component.styles";
import DetailsDescriptions from "../../../components/DetailsDescriptions/DetailsDescriptions.component";

const AccountOverview = ({
  AccountOverview,
  imageLoaded,
  setImageLoaded,
  AccountLoading,
  AccountDetailLoading,
  editForm,
  UpdateAccountSuccess,
  resetAccountNameState,
  currentTimeZone,
}) => {
  const { t } = useTranslation();
  const [editOverview, setEditOverview] = useState(true);
  const [accountDetails, setAccountDetails] = useState({});

  const carouselData = [
    {
      name: "Worksites",
      iconClass: "worksites",
      fieldName: "sites",
      type: "ThemePrimary",
      iconColor: "GrayL4",
    },
    {
      name: "Assets",
      iconClass: "assets",
      fieldName: "asset",
      type: "ThemePrimary",
      iconColor: "GrayL4",
    },
    {
      name: "Users",
      iconClass: "users",
      fieldName: "users",
      type: "ThemePrimary",
      iconColor: "GrayL4",
    },
  ];

  const countData = {
    sites: AccountOverview?.sites?.length || 0,
    asset: AccountOverview?.asset?.length || 0,
    users: AccountOverview?.users?.length || 0,
  };

  useEffect(() => {
    let { companyName, enterpriseUrl, unitType, timeZone } = AccountOverview
      ? AccountOverview
      : { companyName: "" };
    setAccountDetails({
      companyName,
      enterpriseUrl,
      unitType,
      timeZone,
    });
    setEditOverview(true);
  }, [AccountOverview, editForm]);

  const truncateText = (text, limit = 24) =>
    text && text.length > limit ? `${text.substring(0, limit)}...` : text;

  useEffect(() => {
    if (UpdateAccountSuccess) {
      setTimeout(() => {
        resetAccountNameState();
      }, 3000);
    }
  }, [UpdateAccountSuccess, resetAccountNameState]);

  const handleMessage = () => {
    if (UpdateAccountSuccess) {
      return t("ACCOUNT_NAME_UPDATE");
    }
  };

  const handleCloseSnackBar = () => {
    resetAccountNameState();
  };

  return (
    <>
      <AccountPageStyled>
        <>
          {(AccountLoading || AccountDetailLoading) && (
            <Rows>
              <Cols>
                <LeftCol>
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={10} width="100%" count={1} />
                </LeftCol>
                <RightCol>
                  <Skeleton height={55} width="100%" />
                </RightCol>
              </Cols>
            </Rows>
          )}

          {AccountDetailLoading && <Skeleton height="90vh" width="100%" />}
          {AccountOverview && !AccountLoading && !AccountDetailLoading && (
            <Rows>
              <Cols>
                <LeftCol>
                  <AccountTypeTopHeader>
                    <DetailsWrapper>
                      <DetailsDescriptions
                        title={truncateText(AccountOverview?.companyName || AccountOverview?.email)}
                        details={AccountOverview && AccountOverview.companyDescription}
                        dataTitle={t("Added On")}
                        date={moment(AccountOverview?.createdAt)
                          .utcOffset(currentTimeZone.split(" ")[0])
                          .format(" MMMM DD YYYY")}
                      />
                    </DetailsWrapper>
                  </AccountTypeTopHeader>
                </LeftCol>
                <RightCol>
                  {/* <MultiCards /> */}
                  <CarouselWrapperDiv>
                    <CarouselComponent
                      responsive={{
                        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
                        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
                        tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
                        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
                      }}
                      countData={countData}
                      carouselData={carouselData}
                      autoPlay={false}
                      autoPlaySpeed={5000}
                      slidesToSlide={1}
                      size="small"
                      pagination={false}
                    />
                  </CarouselWrapperDiv>
                </RightCol>
              </Cols>
            </Rows>
          )}
          {!AccountDetailLoading && AccountOverview && (
            <AccountTabTabDiv>
              <AccountOverviewTab
                accountDetails={accountDetails}
                setAccountDetails={setAccountDetails}
                setEditOverview={setEditOverview}
                editOverview={editOverview}
                imageLoaded={imageLoaded}
                setImageLoaded={(data) => setImageLoaded(data)}
              />
            </AccountTabTabDiv>
          )}
          {!AccountOverview && !AccountLoading && !AccountDetailLoading && (
            <NoSectionStyle>
              <p>
                <Trans>NO_ACCOUNT_IN_ACCOUNT</Trans>
              </p>
              {/* <Trans>CLICK_ADD_ACCOUNT_BUTTON</Trans> */}
            </NoSectionStyle>
          )}
        </>
      </AccountPageStyled>
      <SnackBar isSuccess={UpdateAccountSuccess} label={handleMessage()} handleClose={handleCloseSnackBar} />
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  AccountOverview: state.accounts.AccountOverview,
  AccountLoading: state.accounts.AccountLoading,
  AccountDetailLoading: state.accounts.accountDetailLoading,
  editForm: state.forms.editForm,
  UpdateAccountSuccess: state.accounts.UpdateAccountSuccess,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  resetAccountNameState: () => dispatch(resetAccountNameState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountOverview));
