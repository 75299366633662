export const countTypes = {
  //For count
  GET_ALL_COUNT_SUCCESS: "GET_ALL_COUNT_SUCCESS",
  GET_ALL_COUNT_LOADING: "GET_ALL_COUNT_LOADING",
  GET_INSPECTION_COUNT_SUCCESS: "GET_INSPECTION_COUNT_SUCCESS",
  GET_INSPECTION_COUNT_LOADING: "GET_INSPECTION_COUNT_LOADING",
  GET_GRAPH_DATA_LOADING: "GET_GRAPH_DATA_LOADING",
  GET_GRAPH_DATA_SUCCESS: "GET_GRAPH_DATA_SUCCESS",
  GET_GRAPH_DATA_FAILED: "GET_GRAPH_DATA_FAILED",
  GET_INCIDENT_COUNT_SUCCESS: "GET_INCIDENT_COUNT_SUCCESS",
  GET_INCIDENT_COUNT_LOADING: "GET_INCIDENT_COUNT_LOADING",
  GET_INCIDENT_GRAPH_DATA_LOADING: "GET_INCIDENT_GRAPH_DATA_LOADING",
  GET_INCIDENT_GRAPH_DATA_SUCCESS: "GET_INCIDENT_GRAPH_DATA_SUCCESS",
  GET_INCIDENT_GRAPH_DATA_FAILED: "GET_INCIDENT_GRAPH_DATA_FAILED",
  GET_UNAUTHORIZED_COUNT_LOADING: "GET_UNAUTHORIZED_COUNT_LOADING",
  GET_UNAUTHORIZED_COUNT_SUCCESS: "GET_UNAUTHORIZED_COUNT_SUCCESS",
  GET_UNAUTHORIZED_GRAPH_DATA_LOADING: "GET_UNAUTHORIZED_GRAPH_DATA_LOADING",
  GET_UNAUTHORIZED_GRAPH_DATA_SUCCESS: "GET_UNAUTHORIZED_GRAPH_DATA_SUCCESS",
  GET_UNAUTHORIZED_GRAPH_DATA_FAILED: "GET_UNAUTHORIZED_GRAPH_DATA_FAILED",
  GET_NEAR_MISS_GRAPH_DATA_SUCCESS: "GET_NEAR_MISS_GRAPH_DATA_SUCCESS",
};
