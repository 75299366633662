import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { useTranslation, Trans } from "react-i18next";
import DataTableInspection from "../../../../../components/DataTableComponent/DataTable.component";
import { tableHeader, paginationOptions } from "../constants";
import { toDownloadFiles } from "../../../../../redux/config/action";
import awsConstant from "../../../../../constant/awsFolder.json";
import PopoverCustom from "../../../../../components/PopoverCustom.component";
import ReportDownload from "../../ReportShare/ReportShareModal.component";
import Checkbox from "../../../../../components/Checkbox/Checkbox.component";
import { getTimezoneShortCode } from "../../../../../utils/timezoneConvert";
import { PassFail, StyledSpan, LoaderOverlay } from "./TableWrapper.component.styles";
import SieraLoader from "../../../../../components/SieraLoader/SieraLoader.component";

const InspectionReportsDataTable = ({
  data,
  loginDetails,
  isSuperAdmin,
  isSiteAdmin,
  toDownloadFiles,
  setIsSuccess,
  setIsError,
  fileDownloadLoading,
  handleCheckboxClick,
  handleSelectAll,
  downloadReportSet,
  isAllSelected,
  totalInspectionReport,
  handlePageLength,
  previousPage,
  nextPage,
  pageSize,
  pageNo,
  selectedAccount,
  rowHeight,
  currentTimeZone,
  loading,
  showMoreFilter,
}) => {
  const { t } = useTranslation();
  const [loadingReportId, setLoadingReportId] = useState("");

  const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.inspectionReport, `${id}.pdf`);
  };

  const viewReportClickHandler = (e, row) => {
    localStorage.setItem("inspectionReportObject", JSON.stringify(row));
    window.open("/#/app/inspection-report", "_blank");
  };

  const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  };

  const ActionCell = ({ row }) => {
    return (
      <>
        {fileDownloadLoading && row._id === loadingReportId ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <div className="action-div">
            <i title="View" onClick={(e) => viewReportClickHandler(e, row)} className="icon icon-view"></i>
            <i
              title="Download"
              onClick={() => downloadReportApiCall(row._id)}
              className="icon icon-download"
            ></i>
            {/* <i className="icon icon-share"></i> */}
            <i title="Share">
              <span style={{ display: "block" }}>
                <PopoverCustom
                  children={
                    <ReportDownload
                      userData={userData}
                      reportDetails={{
                        accountId: data?.accountId,
                        accountName: data?.accountId?.companyName,
                        reportType: 1,
                        reportId: row._id,
                      }}
                      setIsSuccess={setIsSuccess}
                      setIsError={setIsError}
                    />
                  }
                />
              </span>
            </i>
          </div>
        )}
      </>
    );
  };

  const columnsWithCheckboxes = [
    {
      name: <Checkbox type="checkbox" onClick={(e) => handleSelectAll(e)} checked={isAllSelected} />,
      width: 34,
      selector: (row) => (
        <Checkbox
          onClick={(e) => handleCheckboxClick(e)}
          value={row._id}
          checked={downloadReportSet.has(row._id)}
        />
      ),
    },
  ];

  const columns = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })

    .map((data) => {
      if (data.name === "Account") {
        return {
          name: t(data.name),
          selector: (row) => row?.accountId?.companyName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Worksite") {
        return {
          name: t(data.name),
          selector: (row) => row.siteId?.name || "NA",
          sortable: true,
        };
      }
      if (data.name === "Asset Type") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetId?.assetTypeId?.AssetTypeName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Asset Name") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetId?.assetName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Inspected By") {
        return {
          name: t(data.name),
          selector: (row) => row?.inspectorId?.fullName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Actions") {
        return {
          name: t(data.name),
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }
      if (data.name === "Date, Time") {
        return {
          name: t(data.name),
          selector: (row) => {
            const inspectionDate = row?.inspectionAt;
            const timeZone = currentTimeZone || row?.siteId?.timeZone || "NA";

            const formattedDate = !inspectionDate
              ? "NA"
              : `${moment(inspectionDate)
                  .utcOffset(timeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm:ss A")} ${getTimezoneShortCode(timeZone)}`;

            return <span title={formattedDate}>{formattedDate}&nbsp;</span>;
          },
          sortable: true,
        };
      }

      if (data.name === "Report Status") {
        return {
          name: t(data.name),
          selector: (row) => {
            const status = row?.status || "NA";
            const checklistName = row?.checklistId?.checklistName || "NA";

            let note = "NA";

            if (row?.response) {
              for (const response of row.response) {
                for (const question of response?.questions || []) {
                  if (question.note) {
                    note = question.note;
                    break;
                  }
                }
                if (note !== "NA") break;
              }
            }

            const statusMap = {
              passed: { className: "PassBorderDiv", message: "Pass" },
              failed: { className: "FailBorderDiv", message: "Fail" },
              timedout: { className: "TimedOutBorderDiv", message: "Timed Out" },
            };

            if (status in statusMap) {
              const { className, message } = statusMap[status];
              const tooltipContent = (
                <Tooltip id={`tooltip-${status}`}>
                  <span>{checklistName}</span>
                </Tooltip>
              );

              return (
                <PassFail>
                  <div id="button-container" className={className}>
                    <OverlayTrigger placement="top" overlay={tooltipContent}>
                      <div>
                        <span id="nameUserStyle">{checklistName}</span>
                        <span id="statusStyle" title={message}>
                          {message}
                          {note !== "NA" && (
                            <>
                              <i className="icon icon-note-icon"></i>
                            </>
                          )}
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                </PassFail>
              );
            }

            return status; // If status is not within expected values
          },
          sortable: true,
        };
      }

      return {
        name: t(data.name),
        selector: () => data.fieldName,
        sortable: true,
      };
    });

  columns.unshift(...columnsWithCheckboxes);

  const mobileCheckboxDateTime = [
    { columnName: "Account", className: "accountStyle" },
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Report Status", className: "reportStatusStyle" },
  ];

  const mobileCheckboxAccordian = ["Asset Type", "Asset Name", "Inspected By"];

  if (!Array.isArray(data)) {
    return <StyledSpan height={rowHeight}>No impact reports available</StyledSpan>;
  }

  return (
    <>
      {loading && (
        <LoaderOverlay>
          <SieraLoader OnlyLoaderIcon size="small" />
        </LoaderOverlay>
      )}

      {!selectedAccount && isSuperAdmin ? (
        <StyledSpan height={rowHeight}>
          <Trans>PLEASE_SELECT_AN_ACCOUNT_TO_VIEW_THE_REPORT</Trans>
        </StyledSpan>
      ) : (
        <DataTableInspection
          title=""
          columns={columns}
          data={data}
          pagination
          responsive
          dense
          rowsPerPageOptions={[10, 25, 50, 100]}
          // loading={loading}
          rowHeight={showMoreFilter ? "392" : "328"}
          scrollable={true}
          fixedHeader
          mobileCheckboxOnly
          mobileDateTimeOnly
          mobileActionItemOnly
          mobileCheckboxDateTime={mobileCheckboxDateTime}
          mobileCheckboxAccordion={mobileCheckboxAccordian}
          noDataMessage={t("No Inspection Reports Available")}
          pageSize={pageSize}
          pageNo={pageNo}
          totalPageReport={totalInspectionReport}
          handlePageLength={handlePageLength}
          paginationOptions={paginationOptions}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  totalInspectionReport: state.reports.inspectionReportTotalCount,
  fileDownloadLoading: state.config.fileDownloadLoading,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionReportsDataTable));
