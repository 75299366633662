import { userTypes } from "./types";
import { userTypes as ut } from "../user/user.types";
import { initialState } from "./initialState";

const getFormattedDate = (string) => {
  const date = new Date(string);
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const yyyy = date.getFullYear();

  return `${mm}/${dd}/${yyyy}`;
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    // For Worksites List
    case userTypes.GET_ALL_USERS_LOADING:
      return {
        ...state,
        UsersLoading: true,
        UsersSuccess: false,
        UsersError: false,
      };

    case userTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        Users: state?.Users.map((user) => {
          if (user._id === action.data.userId) {
            return { ...user, ...action.data };
          }
          return user;
        }),
      };

    case userTypes.GET_ALL_USERS_SUCCESS:
      if (state.Users?.length === action.data.count && !action.isResetState) {
        return {
          ...state,
          UsersLoading: false,
          UsersSuccess: true,
          UsersError: false,
        };
      }
      let newUsers =
        action.isResetState || action.pageNo === 1 ? action.data.data : [...state.Users, ...action.data.data];
      return {
        ...state,
        UsersLoading: false,
        UsersSuccess: true,
        UsersError: false,
        totalCount: action.data.count,
        Users: newUsers,
      };

    case userTypes.GET_ALL_USERS_ERROR:
      return {
        ...state,
        UsersLoading: false,
        UsersSuccess: false,
        UsersError: true,
      };

    case userTypes.GET_USER_DETAIL_LOADING:
      return {
        ...state,
        userDetailSuccess: false,
        userDetailLoading: true,
        userDetailError: false,
      };

    case userTypes.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetailSuccess: true,
        userDetailLoading: false,
        userDetailError: false,
        UserOverview: action.data,
      };

    case userTypes.GET_USER_DETAIL_ERROR:
      return {
        userDetailSuccess: false,
        userDetailLoading: false,
        userDetailError: true,
      };

    // For Adding New user
    case userTypes.POST_NEW_USER_LOADING:
      return {
        ...state,
        AddUserLoading: true,
        AddUserSuccess: false,
        AddUserError: false,
      };

    case userTypes.POST_NEW_USER_SUCCESS:
      return {
        ...state,
        AddUserLoading: false,
        AddUserSuccess: true,
        AddUserError: false,
        totalCount: (state.totalCount ? state.totalCount : state.Users) + 1,
        Users: [...state.Users, action.data.data],
        UserMessage: action.data && action.data.message,
        addUserSuccessFlag: true,
        newlyAddedUser: action.data.data,
      };

    case userTypes.RESET_USERS_SUCCESS_FLAG:
      return {
        ...state,
        addUserSuccessFlag: false,
      };

    case userTypes.POST_NEW_USER_ERROR:
      return {
        ...state,
        AddUserLoading: false,
        AddUserSuccess: false,
        AddUserError: true,
        UserMessage: action.err && action.err.message,
      };

    case userTypes.UPDATE_USER_DETAIL:
      let userUpdated = { ...state.UserOverview };
      for (var key in action.data) {
        if (key != "_id") {
          userUpdated[key] = action.data[key];
        }
      }
      let userListUpdated = [...state.Users];
      userListUpdated.forEach((user, index) => {
        if (user._id == action.data._id && action.data.fullName) {
          userListUpdated[index].fullName = action.data.fullName;
        }
      });
      return {
        ...state,
        Users: userListUpdated,
        UserOverview: userUpdated,
      };

    case userTypes.RESET_ADD_USER_STATE:
      return {
        ...state,
        AddUserLoading: false,
        AddUserSuccess: false,
        AddUserError: false,
        UserMessage: "",
      };
    // For Worksite Status
    case userTypes.USER_STATUS_UPDATE_LOADING:
      return {
        ...state,
        StatusLoading: true,
        StatusSuccess: false,
        StatusError: false,
      };

    case userTypes.USER_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        StatusLoading: false,
        StatusSuccess: true,
        StatusError: false,
        StatusMessage: action.data && action.data.message,
        Users: state.Users.map((user) => {
          if (user._id === action.data.id) {
            return {
              ...user,
              status: !user.status,
            };
          }
          return user;
        }),

        UserOverview: {
          ...state.UserOverview,
          status: action.data?.status,
        },
      };

    case ut.UPDATE_ACCOUNT_OWNER_SUCCESS: {
      return {
        ...state,
        Users: state.Users.map((user) => {
          if (user._id === action.data) {
            return {
              ...user,
              status: !user.status,
            };
          }
          return user;
        }),
      };
    }

    case userTypes.USER_STATUS_UPDATE_FAILED:
      return {
        ...state,
        StatusLoading: false,
        StatusSuccess: false,
        StatusError: false,
        StatusFailed: true,
        StatusMessage: action.data?.message,
        StatusData: action.data?.data,
      };

    case userTypes.USER_STATUS_UPDATE_ERROR:
      return {
        ...state,
        StatusLoading: false,
        StatusSuccess: false,
        StatusError: true,
      };

    //For selected user
    case userTypes.SELECTED_USER:
      return {
        ...state,
        UserOverview: state.Users.find((user) => user._id === action.data),
      };

    //For reseting the state
    case userTypes.RESET_USERS_STATE:
      return {
        ...state,
        StatusLoading: false,
        StatusSuccess: false,
        StatusFailed: false,
        StatusError: false,
        StatusMessage: "",
        AddUserLoading: false,
        AddUserSuccess: false,
        AddUserError: false,
        UserMessage: "",
        updateUserSuccess: false,
      };

    //For user bulk upload
    case userTypes.USER_BULK_UPLOAD_TEMPLATE_LOADING:
      return {
        ...state,
        UserBulkUploadLoading: true,
        UserBulkUploadSuccess: false,
        UserBulkUploadError: false,
      };

    case userTypes.USER_BULK_UPLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        UserBulkUploadLoading: false,
        UserBulkUploadSuccess: true,
        UserBulkUploadError: false,
        UserBulkUploadTemplates: [...action.data],
      };

    case userTypes.USER_BULK_UPLOAD_TEMPLATE_ERROR:
      return {
        ...state,
        UserBulkUploadLoading: false,
        UserBulkUploadSuccess: false,
        UserBulkUploadError: true,
      };
    // Returning default state

    //TODO: Move these to appropriate reducers when time permits
    case userTypes.GET_USERS_BY_SITES_SUCCESS:
      return {
        ...state,
        usersBySites: action.data,
        usersBySitesLoading: false,
      };

    case userTypes.GET_USERS_BY_SITES_LOADING:
      return {
        ...state,
        usersBySitesLoading: true,
      };

    case userTypes.GET_ASSET_TYPES_BY_SITES_SUCCESS:
      return {
        ...state,
        assetTypesBySites: action.data,
        assetTypesBySitesLoading: false,
      };

    case userTypes.GET_ASSET_TYPES_BY_SITES_LOADING:
      return {
        ...state,
        assetTypesBySitesLoading: true,
      };

    case userTypes.GET_ASSETS_BY_ASSET_TYPES_SUCCESS:
      //console.log("GET_ASSETS_BY_ASSET_TYPES_SUCCESS-- called", action.data);
      return {
        ...state,
        assetsByAssetTypes: action.data,
        assetsByAssetTypesLoading: false,
      };
    case userTypes.GET_ASSETS_BY_ASSET_TYPES_LOADING:
      return {
        ...state,
        assetsByAssetTypesLoading: true,
      };
    // To generate CSV
    case userTypes.GET_EXPORT_DATA_LOADING:
      return {
        ...state,
        GenerateCSVLoading: true,
        GenerateCSVSuccess: false,
        GenerateCSVError: false,
        GeneratingCSVData: [],
      };

    case userTypes.GET_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        GenerateCSVLoading: false,
        GenerateCSVSuccess: true,
        GenerateCSVError: false,
        GeneratingCSVData: action?.data?.flatMap?.((user) => {
          if (user?.certificates?.length === 0) {
            return {
              fullName: user?.fullName || "",
              usertype: user?.userTypeId?.name || "",
              worksite:
                user?.userTypeId?.accessLevel == "ALL_WORKSITES" ||
                user?.userTypeId?.accessLevel == "SINGLE_ACCOUNT"
                  ? "ALL"
                  : user?.authorizedSites?.map?.((site) => site?.name).join(", "),
              email: user?.email || "",
              phone: user?.phone,
            };
          } else {
            const userArray = user?.certificates?.map?.((certificate, index) => ({
              fullName: index > 0 ? "" : user?.fullName || "",
              usertype: index > 0 ? "" : user?.userTypeId?.name || "",
              worksite:
                index > 0
                  ? ""
                  : user?.userTypeId?.accessLevel == "ALL_WORKSITES" ||
                      user?.userTypeId?.accessLevel == "SINGLE_ACCOUNT"
                    ? "ALL"
                    : user?.authorizedSites?.map?.((site) => site?.name).join(", "),
              email: index > 0 ? "" : user?.email || "",
              phone: index > 0 ? "" : user?.phone,

              certificateValidity: new Date(certificate?.expiryDate) < new Date() ? "Expired" : "Active",
              certificateIssueDate: certificate?.issueDate ? getFormattedDate(certificate?.issueDate) : "",
              certificateValidTill: certificate?.expiryDate ? getFormattedDate(certificate?.expiryDate) : "",
              certifiedClassOfVehicle: certificate?.certifiedVehicleClasses?.join?.(", ") || "",
              certifyingOrganization: certificate?.certifyingOrganization || "",
              certificateNumber: certificate?.certificateNumber || "",
            }));

            return userArray;
          }
        }),
      };

    case userTypes.GET_EXPORT_DATA_ERROR:
      return {
        ...state,
        GenerateCSVLoading: false,
        GenerateCSVSuccess: false,
        GenerateCSVError: true,
      };

    case userTypes.GET_ALL_WORKSITES_USERS_LOADING:
      return {
        ...state,
        allWorksitesUsersLoading: true,
        allWorksitesUsersSuccess: false,
        allWorksitesUsersError: false,
      };

    case userTypes.GET_ALL_WORKSITES_USERS_SUCCESS:
      return {
        ...state,
        allWorksitesUsersLoading: false,
        allWorksitesUsersSuccess: true,
        allWorksitesUsersError: false,
        allWorksitesUsersByAccountId: action.data,
      };

    case userTypes.GET_ALL_WORKSITES_USERS_ERROR:
      return {
        ...state,
        allWorksitesUsersLoading: false,
        allWorksitesUsersSuccess: false,
        allWorksitesUsersError: true,
        allWorksitesUsersByAccountId: [],
      };

    default:
      return state;
  }
};
