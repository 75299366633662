import styled from "styled-components";
export const ChangePinChangePass = styled.div`
  width: 95%;
  margin: auto;
  text-align: left;
`;

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  float: left;
  width: 100%;
  text-align: left;
`;

export const ContainerRow = styled.div`
  padding: 25px;
  margin: auto;
  overflow: hidden;
`;
export const ComponentDivSty = styled.div`
  color: ${({ isError }) => (isError ? "red" : "#323232")};
  background: #f2f2f2;
  padding: 0 10px 5px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
`;

export const InfoDivStyle = styled.div`
  div:first-child {
    width: 100%;
  }
  .icon-information-tooltip {
    width: auto !important;
  }
  button {
    pointer-events: none;
  }
`;

export const Icon = styled.div`
  margin-right: 5px;
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSizes.font5};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
