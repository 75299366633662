//TODO - When time permits, review and optmize this file. Remove unused/commented code. Create cleaner sub components

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import UserList from "./UserList.component";
import Users from "./Users.component";
import { setEditAlertModal } from "../../../redux/modals/action";
import UserRequestAccessOverview from "./UserRequestAccessOverview.component";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

//custom components
import SearchBox from "../../../components/SearchBox/Searchbox.component";

import SieraLoader from "../../../components/SieraLoader/SieraLoader.component";

import {
  LoaderOverlay,
  ContainerDiv,
  ContentWrapper,
  LeftSection,
  RightSection,
} from "./UserManagement.component.styles";

const UserManagement = ({ pendingList, setEditAlertModal, editForm, editFormSecond, UsersLoading }) => {
  const [SearchText, setSearchText] = useState("");
  const [UserTypeFilter, setUserTypeFilter] = useState("all");
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  const [showLoader, setShowLoader] = useState(false);
  const [hasShownOnce, setHasShownOnce] = useState(false);

  useEffect(() => {
    if (UsersLoading && !hasShownOnce) {
      setShowLoader(true);
      setHasShownOnce(true);
    } else if (!UsersLoading) {
      setShowLoader(false);
    }
  }, [UsersLoading, hasShownOnce]);

  return (
    <ContainerDiv>
      {showLoader && (
        <LoaderOverlay>
          <SieraLoader OnlyLoaderIcon size="small" />
        </LoaderOverlay>
      )}
      <ContentWrapper>
        <LeftSection>
          <SearchBox
            label={t("Your Users")}
            placeholder={t("Search a User")}
            onChange={(e) => setSearchText(e.target.value)}
            searchText={SearchText}
            setSearchText={setSearchText}
          />

          <UserList
            pendingList={pendingList}
            style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
            searchText={SearchText}
            setSearchText={setSearchText}
            userTypeFilter={UserTypeFilter}
            setImageLoaded={(data) => setImageLoaded(data)}
            setShowUserDetail={() => {}}
          />
        </LeftSection>
        {pendingList ? (
          <RightSection>
            <UserRequestAccessOverview />
          </RightSection>
        ) : (
          <RightSection>
            <Users
              pendingList={pendingList}
              searchText={SearchText}
              setSearchText={setSearchText}
              imageLoaded={imageLoaded}
              setImageLoaded={(data) => setImageLoaded(data)}
            />
          </RightSection>
        )}
      </ContentWrapper>
    </ContainerDiv>
  );
};

const mapStateToProps = (state) => ({
  assetTypes: state.assets.AssetTypes,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
  UsersLoading: state.users.UsersLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
