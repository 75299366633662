import styled from "styled-components";

export const Severity = styled.div`
  border: 1px solid
    ${({ severity, theme }) =>
      severity === "PEDESTRIAN"
        ? theme.colors.redPrimary || "#DD0000"
        : severity === "OBJECT"
          ? theme.colors.orangePrimary || "#DD9000"
          : "transparent"};
  padding: 2px 8px;
  border-radius: 4px;
  text-align: center;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  display: flex;
  align-items: center;

  span {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  i {
    font-size: ${({ theme }) => theme.fontSizes.font4};
    color: ${({ severity, theme }) =>
      severity === "PEDESTRIAN"
        ? theme.colors.redPrimary || "#DD0000"
        : severity === "OBJECT"
          ? theme.colors.orangePrimary || "#DD9000"
          : "transparent"};
  }
`;

export const NoReportsDiv = styled.div`
  font-size: 14px !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  overflow-y: scroll;
  height: calc(-565px + 100vh);
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const NearMissReportTable = styled.div`
  .noReportsDiv {
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  }
`;
