import { filterType } from "./types";

// To set the filter
export const setInspectionReportFilter = (data) => ({
  type: filterType.SET_INSPECTION_REPORT_FILTER,
  data: data,
});

// To reset the filter
export const resetInspectionReportFilter = (data) => ({
  type: filterType.RESET_INSPECTION_REPORT_FILTER,
});

// To set the incident filter
export const setIncidentReportFilter = (data) => ({
  type: filterType.SET_INCIDENT_REPORT_FILTER,
  data: data,
});

// To reset the incident filter
export const resetIncidentReportFilter = (data) => ({
  type: filterType.RESET_INCIDENT_REPORT_FILTER,
});

export const setNearMissReportFilter = (data) => ({
  type: filterType.SET_NEAR_MISS_REPORT_FILTER,
  data: data,
});

// To reset the incident filter
export const resetNearMissReportFilter = (data) => ({
  type: filterType.RESET_NEAR_MISS_REPORT_FILTER,
});

// To set the incident filter
export const setUnauthorizedAccessFilter = (data) => ({
  type: filterType.SET_UNAUTHORIZED_ACCESS_FILTER,
  data: data,
});

// To reset the incident filter
export const resetUnthorizedAccessFilter = (data) => ({
  type: filterType.RESET_UNAUTHORIZED_ACCESS_FILTER,
});
