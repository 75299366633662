import { reportTypes } from "./types";
import config from "../../../src/config";
import { saveAs } from "file-saver";
import axios from "axios";
import { apiCall } from "../../utils/apiCall";
import { CacheManager } from "../../utils/cache/CacheManager";
import { CacheRefs } from "../../utils/cache/CacheRefs";

const baseUrl = config.baseUrl;
//To load report listing
const getInspectionReportListLoading = () => ({
  type: reportTypes.GET_INSPECTION_REPORT_LIST_LOADING,
});

const getInspectionReportListSuccess = (data) => ({
  type: reportTypes.GET_INSPECTION_REPORT_LIST_SUCCESS,
  data,
});

const getInspectionReportListError = (err) => ({
  type: reportTypes.GET_INSPECTION_REPORT_LIST_ERROR,
  err,
});

const getUnauthorizedListLoading = () => ({
  type: reportTypes.GET_UNAUTHORIZED_LIST_LOADING,
});

const getUnauthorizedListSuccess = (data) => ({
  type: reportTypes.GET_UNAUTHORIZED_LIST_SUCCESS,
  data,
});

const getUnauthorizedListError = (err) => ({
  type: reportTypes.GET_UNAUTHORIZED_LIST_ERROR,
  err,
});

//To get the report listing
export const getInspectionReportListing = (filterData) => {
  return async (dispatch, getState) => {
    let state = getState();
    let isSiteAdmin = state?.user?.isSiteAdmin;
    let isSuperAdmin = state?.user?.isSuperAdmin;

    let {
      accountId,
      worksiteId,
      assetTypeId,
      assetId,
      inspectorId,
      startDate,
      endDate,
      pageNo,
      pageSize,
      status,
      checklistId,
    } = filterData;
    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }
    // apiCall(
    //   `/asset/inspectionreports/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${inspectorId}/${startDate}/${endDate}/${pageNo}/${pageSize}/${status}`
    // )
    //   .then((response) => {
    //     dispatch(getInspectionReportListSuccess(response.data));
    //   })
    //   .catch((err) => {
    //     dispatch(getInspectionReportListError(err));
    //   });
    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.userData?.accountId;
    }
    if (!accountId || accountId === "all") {
      dispatch(clearInspectionReportListingSuccess());
      return;
    }
    dispatch(getInspectionReportListLoading());
    const filters = {
      accounts: accountId === "all" ? "all" : [accountId],
      sites: worksiteId === "all" ? "all" : [worksiteId],
      assetTypes: assetTypeId === "all" ? "all" : [assetTypeId],
      assets: assetId === "all" ? "all" : [assetId],
      users: inspectorId === "all" ? "all" : [inspectorId],
      status: status === "all" ? "all" : [status],
      startDate: startDate,
      endDate: endDate,
      pageNo: pageNo,
      pageSize: pageSize,
      checklistId: !checklistId || checklistId === "all" ? "all" : [checklistId],
    };
    const url = `/inspection/getInspectionReports`;
    /* 
 Causing problem with cache when user comes from Dashboard SIER-7406
    const key = url + "?" + JSON.stringify(filters);
    
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(key);
      if (cacheResult1) {
        dispatch(getInspectionReportListSuccess(cacheResult1));
        return;
      }
    } */
    apiCall(url, {
      method: "POST",
      data: filters,
    })
      .then((response) => {
        /*         if (cache) {
          if (response?.data?.data?.length > 0) {
            cache.set(key, response.data, CacheRefs.getTtlShort());
          }
        } */
        dispatch(getInspectionReportListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getInspectionReportListError(err));
      });
  };
};

//To load zip file report
const getZipFileReportLoading = () => ({
  type: reportTypes.GET_ZIP_FILE_REPORT_LOADING,
});

const getZipFileReportSuccess = () => ({
  type: reportTypes.GET_ZIP_FILE_REPORT_SUCCESS,
});

const getZipFileReportFailed = () => ({
  type: reportTypes.GET_ZIP_FILE_REPORT_FAILED,
});

export const resetZipFileReport = () => ({
  type: reportTypes.RESET_GET_ZIP_FILE_REPORT,
});

//To get zip file report
export const getZipDownloadReport = (filterReport) => {
  return async (dispatch, getState) => {
    dispatch(getZipFileReportLoading());
    const token = getState()?.user?.loginDetails?.token;

    const axiosOptions = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };

    axios(`${baseUrl}/common/downloadReports`, {
      method: "POST",
      data: { reports: filterReport },
      ...axiosOptions,
    })
      .then((response) => {
        dispatch(getZipFileReportSuccess());
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const filename = "Inspection_Reports.zip";
        saveAs(blob, filename);
      })
      .catch((err) => {
        console.log("Error occurred", err);
        dispatch(getZipFileReportFailed());
      });
  };
};

//To load report listing
const getImpactReportListLoading = () => ({
  type: reportTypes.GET_IMPACT_REPORT_LIST_LOADING,
});

const getImpactReportListSuccess = (data) => ({
  type: reportTypes.GET_IMPACT_REPORT_LIST_SUCCESS,
  data,
});

const getUnauthorizedUseReportLoading = () => ({
  type: reportTypes.GET_UNAUTORIZED_USE_REPORT_LOADING,
});

const getUnauthorizedUseReportSuccess = (data) => ({
  type: reportTypes.GET_UNAUTORIZED_USE_REPORT_SUCCESS,
  data,
});

const getImpactReportListError = (err) => ({
  type: reportTypes.GET_IMPACT_REPORT_LIST_ERROR,
  err,
});

export const getImpactReportListingNew = (filterData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSiteAdmin = state && state.user && state.user.isSiteAdmin;
    const isSuperAdmin = state?.user?.isSuperAdmin;
    let {
      accountId,
      worksiteId,
      assetTypeId,
      assetId,
      inspectorId,
      startDate,
      endDate,
      pageNo,
      pageSize,
      impactSeverity,
      obstacleType,
      incidentType,
    } = filterData;

    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.userData?.accountId;
    }

    if (!accountId || accountId === "all") {
      dispatch(clearImpactReportListingSuccess());
      return;
    }

    dispatch(getImpactReportListLoading());

    const severity =
      impactSeverity === "all" ? ["all"] : Array.isArray(impactSeverity) ? impactSeverity : [impactSeverity];

    const bodyData = {
      accounts: [accountId],
      sites: [worksiteId],
      assetTypes: [assetTypeId],
      userTypes: ["all"],
      assets: [assetId],
      users: [inspectorId || "all"],
      severity: severity,
      startDate: startDate || "",
      endDate: endDate || "",
      pageNo: 1,
      pageSize: 10,
    };

    const response = await apiCall(`/impact/getImpactReports`, {
      method: "POST",
      data: bodyData,
    });

    //console.log("response.data.data---------getImpactReportListingNew------------------", response.data.data);

    dispatch(getImpactReportListSuccess(response.data));

    /* const url = `/impact/impactreports/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${
      inspectorId ? inspectorId : "all"
    }/${startDate}/${endDate}/${pageNo}/${pageSize}/${impactSeverity}/${obstacleType}/${incidentType}`; */

    /*  const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getImpactReportListSuccess(cacheResult1));
        return;
      }
    } */
    /*     apiCall(url)
      .then((response) => {
        if (cache) {
          if (response?.data?.data?.length > 0) {
            cache.set(url, response.data, CacheRefs.getTtlShort());
          }
        }
        dispatch(getImpactReportListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getImpactReportListError(err));
      }); */
  };
};

const getNerMissReportListLoading = () => ({
  type: reportTypes.GET_NEAR_MISS_REPORT_LIST_LOADING,
});

const getNearMissReportListSuccess = (data) => ({
  type: reportTypes.GET_NEAR_MISS_REPORT_LIST_SUCCESS,
  data,
});

export const clearNearMissReportListingSuccess = () => ({
  type: reportTypes.CLEAR_NEAR_MISS_REPORT_LIST_SUCCESS,
});

export const getNearMissReports = (filterData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSiteAdmin = state && state.user && state.user.isSiteAdmin;
    const isSuperAdmin = state?.user?.isSuperAdmin;
    let {
      accountId,
      worksiteId,
      assetTypeId,
      assetId,
      inspectorId,
      startDate,
      endDate,
      pageNo,
      pageSize,
      obstacleType,
      incidentType,
    } = filterData;

    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.userData?.accountId;
    }

    if (!accountId || accountId === "all") {
      dispatch(clearNearMissReportListingSuccess());
      return;
    }

    dispatch(getNerMissReportListLoading());

    const bodyData = {
      accounts: accountId && accountId !== "all" ? [accountId] : "all",
      sites: worksiteId && worksiteId !== "all" ? [worksiteId] : "all",
      assetTypes: assetTypeId && assetTypeId !== "all" ? [assetTypeId] : "all",
      userTypes: [],
      assets: assetId && assetId !== "all" ? [assetId] : "all",
      users: inspectorId && inspectorId !== "all" ? [inspectorId] : "all",
      nearMissCategory: obstacleType && obstacleType !== "all" ? [obstacleType] : "all",
      startDate: startDate,
      endDate: endDate,
      pageNo: pageNo || 1,
      pageSize: pageSize || 10,
    };

    const response = await apiCall(`/nearmiss/getNearMissReports`, {
      method: "POST",
      data: bodyData,
    });

    //console.log("response.data.data---------getImpactReportListingNew------------------", response.data.data);

    dispatch(getNearMissReportListSuccess(response.data));

    /* const url = `/impact/impactreports/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${
      inspectorId ? inspectorId : "all"
    }/${startDate}/${endDate}/${pageNo}/${pageSize}/${impactSeverity}/${obstacleType}/${incidentType}`; */

    /*  const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getImpactReportListSuccess(cacheResult1));
        return;
      }
    } */
    /*     apiCall(url)
      .then((response) => {
        if (cache) {
          if (response?.data?.data?.length > 0) {
            cache.set(url, response.data, CacheRefs.getTtlShort());
          }
        }
        dispatch(getImpactReportListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getImpactReportListError(err));
      }); */
  };
};

//To get the report listing
export const getImpactReportListing = (filterData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSiteAdmin = state && state.user && state.user.isSiteAdmin;
    const isSuperAdmin = state?.user?.isSuperAdmin;
    let {
      accountId,
      worksiteId,
      assetTypeId,
      assetId,
      inspectorId,
      startDate,
      endDate,
      pageNo,
      pageSize,
      impactSeverity,
      obstacleType,
      incidentType,
    } = filterData;
    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }

    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.userData?.accountId;
    }

    if (!accountId || accountId === "all") {
      dispatch(clearImpactReportListingSuccess());
      return;
    }

    dispatch(getImpactReportListLoading());

    const url = `/impact/impactreports/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${
      inspectorId ? inspectorId : "all"
    }/${startDate}/${endDate}/${pageNo}/${pageSize}/${impactSeverity}/${obstacleType}/${incidentType}`;
    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getImpactReportListSuccess(cacheResult1));
        return;
      }
    }
    apiCall(url)
      .then((response) => {
        console.log(
          "response.data.data---------getImpactReportListingNew------------------",
          response.data.data
        );

        if (cache) {
          if (response?.data?.data?.length > 0) {
            cache.set(url, response.data, CacheRefs.getTtlShort());
          }
        }
        dispatch(getImpactReportListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getImpactReportListError(err));
      });
  };
};

export const getUnauthorizedUseReports = (filterData) => {
  return async (dispatch, getState) => {
    dispatch(getUnauthorizedUseReportLoading());
    const state = getState();

    const isSiteAdmin = state?.user?.isSiteAdmin;
    const isSuperAdmin = state?.user?.isSuperAdmin;
    let {
      accountId,
      worksiteId,
      assetTypeId,
      assetId,
      inspectorId,
      startDate,
      endDate,
      pageNo,
      pageSize,
      impactSeverity,
    } = filterData;
    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }
    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.userData?.accountId;
    }

    const url = `/impact/unauthorizedreports/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${
      inspectorId ? inspectorId : "all"
    }/${startDate}/${endDate}/${pageNo}/${pageSize}/${impactSeverity}`;

    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getUnauthorizedUseReportSuccess(cacheResult1));
        return;
      }
    }
    apiCall(url)
      .then(({ data }) => {
        const formattedData = {
          ...data,
          data: data?.data?.map((dat) => ({
            ...dat,
            accountName: dat?.accountId?.companyName,
            siteName: dat?.siteId?.name,
            timeZone: dat?.siteId?.timeZone,
            assetType: dat?.assetTypeId?.AssetTypeName,
            assetName: dat?.assetId?.assetName,
          })),
        };
        if (cache) {
          if (formattedData?.data?.length > 0) {
            cache.set(url, formattedData, CacheRefs.getTtlShort());
          }
        }
        dispatch(getUnauthorizedUseReportSuccess(formattedData));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getUnauthorizedReports = (filterData) => {
  return async (dispatch, getState) => {
    const state = getState();
    const isSiteAdmin = state?.user?.isSiteAdmin;
    const isSuperAdmin = state?.user?.isSuperAdmin;
    let {
      accountId,
      worksiteId,
      assetTypeId,
      assetId,
      inspectorId,
      startDate,
      endDate,
      pageNo,
      pageSize,
      impactSeverity,
    } = filterData;
    if (isSiteAdmin) {
      worksiteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }
    if (!isSuperAdmin) {
      accountId = state?.user?.loginDetails?.userData?.accountId;
    }
    if (!accountId || accountId === "all") {
      dispatch(clearUnauthorizedReportListingSuccess());
      return;
    }

    dispatch(getUnauthorizedListLoading());
    apiCall(
      `/impact/listUnauthorized/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${startDate}/${endDate}/${pageNo}/${pageSize}`
    )
      .then((response) => {
        dispatch(
          getUnauthorizedListSuccess({
            ...response.data,
            data: response?.data?.data?.map((dat) => ({
              ...dat,
              accountName: dat?.companyName,
              assetType: dat?.AssetTypeName,
            })),
          })
        );
      })
      .catch((err) => {
        dispatch(getUnauthorizedListError(err));
      });
  };
};

//To load zip file report
const getZipFileImpactReportLoading = () => ({
  type: reportTypes.GET_ZIP_FILE_IMPACT_REPORT_LOADING,
});

const getZipFileImpactReportSuccess = () => ({
  type: reportTypes.GET_ZIP_FILE_IMPACT_REPORT_SUCCESS,
});

const getZipFileImpactReportError = () => ({
  type: reportTypes.GET_ZIP_FILE_IMPACT_REPORT_ERROR,
});

export const resetGetZipFileImpactReport = () => ({
  type: reportTypes.RESET_GET_ZIP_FILE_IMPACT_REPORT,
});

//To get zip file report
export const getZipDownloadImpactReport = (filterReport) => {
  return async (dispatch, getState) => {
    dispatch(getZipFileImpactReportLoading());
    let token = getState?.()?.user?.loginDetails?.token;
    const axiosOptions = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    axios(`${baseUrl}/common/downloadImpactReportZip`, {
      method: "POST",
      data: { reports: filterReport },
      ...axiosOptions,
    })
      .then((response) => {
        dispatch(getZipFileImpactReportSuccess());
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const filename = "Impact_Reports.zip";
        saveAs(blob, filename);
      })
      .catch((err) => {
        dispatch(getZipFileImpactReportError());
      });
  };
};

export const getZipDownloadUnauthorizedAccess = (filterReport) => {
  return async (dispatch, getState) => {
    dispatch(getZipFileImpactReportLoading());
    let token = getState?.()?.user?.loginDetails?.token;
    const axiosOptions = {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
    };
    axios(`${baseUrl}/common/downloadUnauthorizedReportZip`, {
      method: "POST",
      data: { reports: filterReport },
      ...axiosOptions,
    })
      .then((response) => {
        dispatch(getZipFileImpactReportSuccess());
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const filename = "Unauthorized_Reports.zip";
        saveAs(blob, filename);
      })
      .catch((err) => {
        console.log("Error occurred", err);
      });
  };
};

//To load report listing
const getLastHourMeterListLoading = () => ({
  type: reportTypes.GET_LAST_HOUR_METER_LIST_LOADING,
});

const getLastHourMeterListSuccess = (data) => ({
  type: reportTypes.GET_LAST_HOUR_METER_LIST_SUCCESS,
  data,
});

export const updateHourMeterListItem = (data) => ({
  type: reportTypes.UPDATE_HOUR_METER_LIST_ITEM,
  data,
});

//To get the report listing
export const getLastHourMeterListing = (accountId) => {
  return async (dispatch, getState) => {
    dispatch(getLastHourMeterListLoading());
    apiCall(`/asset/assetStatus/${accountId}`)
      .then((data) => {
        dispatch(getLastHourMeterListSuccess(data?.data?.data));
      })
      .catch((err) => {
        // dispatch(getLastHourMeterListError(err));
      });

    // axios
    //   .get(
    //     `${baseUrl}/asset/lastmeterreading/${accountId}/${assetId}/${worksiteId}`,
    //     {
    //       headers: headers,
    //     }
    //   )
    //   .then((response) => {
    //     console.log(response)
    //     if(!response.data.success){
    //       dispatch(refreshToken())
    //     }else{
    //       dispatch(getLastHourMeterListSuccess(response.data.data));
    //     }
    //   })
    //   .catch((err) => {
    //     // dispatch(getLastHourMeterListError(err));
    //   });
  };
};

const exportAllCSVInspectionLoading = () => ({
  type: reportTypes.EXPORT_ALL_INSPECTION_CSV_LOADING,
});

const exportAllInspectionCSVSuccess = () => ({
  type: reportTypes.EXPORT_ALL_INSPECTION_CSV_SUCCESS,
});

const exportAllInspectionCSVError = () => ({
  type: reportTypes.EXPORT_ALL_INSPECTION_CSV_ERROR,
});

export const exportAllInspectionCSVResetState = () => ({
  type: reportTypes.RESET_ALL_INSPECTION_CSV_STATE,
});

export const exportAllInspectionCSV = () => {
  return async (dispatch, getState) => {
    const state = getState();

    let { accountId, worksiteId, assetTypeId, assetId, inspectorId, startDate, endDate, status } =
      state.filters.inspectionReportFilter;

    if (!state.user?.isSuperAdmin) {
      accountId = state.user?.loginDetails?.accountId;
    }
    const userWorksiteId = state.user.isSiteAdmin
      ? state.user?.loginDetails?.userData?.authorizedSites?.[0]
      : worksiteId;

    dispatch(exportAllCSVInspectionLoading());
    apiCall(
      `/asset/csvinspectionreports/${accountId}/${userWorksiteId}/${assetTypeId}/${assetId}/${inspectorId}/${startDate}/${endDate}/${status}`
    )
      .then((data) => {
        dispatch(exportAllInspectionCSVSuccess());
      })
      .catch((err) => {
        dispatch(exportAllInspectionCSVError());
      });
  };
};

const exportAllCSVImpactLoading = () => ({
  type: reportTypes.EXPORT_ALL_IMPACT_CSV_LOADING,
});

const exportAllImpactCSVSuccess = () => ({
  type: reportTypes.EXPORT_ALL_IMPACT_CSV_SUCCESS,
});

const exportAllImpactCSVError = () => ({
  type: reportTypes.EXPORT_ALL_IMPACT_CSV_ERROR,
});

export const exportAllImpactCSVResetState = () => ({
  type: reportTypes.EXPORT_ALL_IMPACT_CSV_ERROR,
});

export const exportAllImpactCSV = () => {
  return async (dispatch, getState) => {
    let state = getState();

    let {
      accountId,
      worksiteId,
      assetTypeId,
      assetId,
      inspectorId,
      startDate,
      endDate,
      impactSeverity,
      obstacleType,
      incidentType,
    } = state.filters.incidentReportFilter;

    if (!state.user.isSuperAdmin) {
      accountId = state.user.loginDetails.accountId;
    }

    dispatch(exportAllCSVImpactLoading());
    apiCall(
      `/impact/csvimpactreports/${accountId}/${worksiteId}/${assetTypeId}/${assetId}/${inspectorId}/${startDate}/${endDate}/${impactSeverity}/${obstacleType}/${incidentType}`
    )
      .then((data) => {
        dispatch(exportAllImpactCSVSuccess());
      })
      .catch((err) => {
        dispatch(exportAllImpactCSVError());
      });
  };
};

//action to clear report listing for inspections
export const clearInspectionReportListingSuccess = () => ({
  type: reportTypes.CLEAR_INSPECTION_REPORT_LIST_SUCCESS,
});

//action to clear report listing for inspections
export const clearImpactReportListingSuccess = () => ({
  type: reportTypes.CLEAR_IMPACT_REPORT_LIST_SUCCESS,
});

//action to clear report listing for inspections
export const clearUnauthorizedReportListingSuccess = () => ({
  type: reportTypes.CLEAR_UNAUTHORIZED_REPORT_LIST_SUCCESS,
});

export const clearInspectionReportListing = () => {
  return (dispatch) => {
    dispatch(clearInspectionReportListingSuccess);
  };
};
