import styled from "styled-components";
export const AntTabAssetType = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  button.Mui-selected span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  button.MuiButtonBase-root span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  span.MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .card_assetBody {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .assetType_sec {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .MuiFormControlLabel-label.Mui-disabled {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .noSection_cD p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.assetTablesz {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.assetTablesz thead {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.assetTablesz thead th {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .add-asset-checklist-select .deleteBoxright svg .cls-1 {
    fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  table.assetTablesz tbody td {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  td.text-right svg .cls-1 {
    /* fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;*/
  }
`;

export const AssetTypeTopHeader = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    padding: 5px 10px;
    border-radius: 4px;
  }
`;

export const DetailsWrapper = styled.div`
  flex: 1 1 90%;
`;

export const AssetTypeTabDiv = styled.div`
  clear: both;
  margin: 10px auto;
  float: left;
  width: 100%;
  .select__control {
    @media (max-width: 1100px) {
      margin-bottom: 20px;
      /* width: 94%; */
    }
  }
`;

export const ActionWrapper = styled.div`
  flex: 1 1 10%;
  p.statusStyle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    @media (max-width: 1100px) {
      display: none;
    }
  }
`;

export const AssetTypeDataDescp = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  color: ${({ theme }) => theme.colors.grayL2 || "#434343"};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  span {
  }
`;

export const AssetTypeHeadDetail = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font3} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  padding-top: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const AssetTypePageStyled = styled.div`
  clear: both;
  width: 100%;
  float: left;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 4px;
  padding: 4px 16px;
`;

export const NoSectionStyle = styled.div`
  display: block;
  height: calc(100vh - 216px);
  align-content: center;
  clear: both;
  display: block;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  p {
    margin-bottom: 0px;
  }
  .clickDesktop {
    display: block;
    @media (max-width: 1100px) {
      display: none;
    }
  }
  .tapMobile {
    display: none;
    @media (max-width: 1100px) {
      display: block;
    }
  }
`;
