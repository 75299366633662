import { connect } from "react-redux";
import PropTypes from "prop-types";

const PermissionsCheck = (props) => {
  const { permissions, children, section = "DASHBOARD", permissionNames, actionName, operator } = props;

  const currentSectionObj = permissions?.find?.((permission) => permission.section === section);

  if (!currentSectionObj) {
    return null;
  }

  const hasPermissions = permissionNames.some((permissionName) => {
    const currentPermissionObj = currentSectionObj.permissions.find(
      (permissionObj) => permissionObj.name === permissionName
    );
    const currentPermission = currentPermissionObj?.action[actionName];
    return currentPermission;
  });

  if (operator === "AND") {
    return hasPermissions ? children : null;
  } else if (operator === "OR") {
    return hasPermissions ? children : null;
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({});

// PermissionsCheck.defaultProps = {
//   section: "DASHBOARD",
// };

PermissionsCheck.propTypes = {
  permissions: PropTypes.array,
  children: PropTypes.element,
  section: PropTypes.string,
  permissionNames: PropTypes.arrayOf(PropTypes.string),
  actionName: PropTypes.string,
  operator: PropTypes.oneOf(["AND", "OR"]),
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsCheck);
