import styled from "styled-components";

export const ManagementRow = styled.div``;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 200px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  flex: 1;
  width: 25%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const RightSection = styled.div`
  flex: 3;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
