import styled from "styled-components";

export const DesktopView = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

export const ViewAssetTypeMainWrapper = styled.div``;

export const ButtonsContainer = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  margin: 8px 0px;

  button {
    margin-right: 10px;
  }
`;

export const AssetTypeMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const AssetTypeListing = styled.div`
  flex: 1;
  width: 25%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AssetTypeDataList = styled.div`
  flex: 3;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SearchContainer = styled.div`
  div {
  }
`;

export const SearchBoxWrapper = styled.div`
  flex: 1;
`;

export const SearchFilter = styled.div`
  display: flex;
`;
export const FilterWrapper = styled.div`
  flex: 0;
`;

export const CloseBackDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
`;
export const FilterBackDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
export const FilterIconDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const CloseIconDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const FilterOnclickDes = styled.div``;
export const FilterDesWrapper = styled.div``;

export const AssetMobileListWrapper = styled.div`
  .tableHeight {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    height: auto;
    @media (max-width: 769px) {
      max-height: calc(100vh - 300px);
    }
  }
`;

export const AssetTypeDetailHeading = styled.div`
  text-align: left;
  float: left;
  display: flex;
  width: 100%;
  background-color: #0d5fbe;
  color: #fff;
  line-height: 45px;
  padding-left: 9px;
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  font-weight: 500;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  span {
    font-weight: 500;
  }

  img {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const RightIconImage = styled.div`
  /* font-size: ${({ theme }) => theme.fontSizes.font4} !important; */
  display: inline;
  line-height: 12px;
  margin-left: 5px;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 100px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;