import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import UserBulkUploadForm from "../../User/UserBulkUploadForm.component";
import UserManagement from "../../User/UserManagement.component";
import AddUserStepper from "../../User/AddUserStepper.component";
import { userList, defaultUserOverview } from "../../../../redux/userManagement/userManagement.action";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { setModalWaitingToOpen, setAddUserModal, setEditAlertModal } from "../../../../redux/modals/action";
import { setShowFilters } from "../../../../redux/mobileView/action";

import Button from "../../../../components/Button/Button.component";

import UserActionBar from "../../User/UserActionBar.component";
import UsersCard from "../../User/UsersCard.component";
import SearchBox from "../../../../components/SearchBox/Searchbox.component";
import UserFilters from "../../User/UserFilters.component";
import UserList from "../../User/UserList.component";
import UserOverview from "../../User/UserOverview.component";
import { ReactComponent as rightIcon } from "../../../../assets/rightIconwhite.svg";

import CommonSuccessMessage from "../../common/CommonSuccessMessage.component";
import {
  ModalContentWrapperDiv,
  UserBulkUploadModal,
  UserPanelStyled,
  FilterIconStyled,
  DesktopView,
  MobileView,
  ButtonsContainer,
  ActionBarWrapper,
  ActionBarButtons,
  SearchBoxWrapper,
  SearchFilter,
  FilterWrapper,
  FilterIconDiv,
  CloseIconDiv,
  FilterBackDiv,
  CloseBackDiv,
  FilterOnclickDes,
  FilterDesWrapper,
  UserMobileListWrapper,
  UserTypeDetailHeading,
  RightIconImage,
} from "./userPanel.component.styles";
import ModalPopup from "../../../../components/ModalPopupComponent/ModalPopup.component";

function CustomizedTabs(props) {
  const {
    history,
    setAddUserModal,
    updateActiveNavItem,
    setModalWaitingToOpen,
    editForm,
    setEditAlertModal,
    setShowFilters,
    showFilters,
    userName,
    showActionBar,
    isSuperAdmin,
  } = props;

  const [worksiteBulkUploadShow, setWorksiteBulkUploadShow] = useState(false);
  const [pendingList, setPendingList] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [UserTypeFilter, setUserTypeFilter] = useState("all");
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1100);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    updateActiveNavItem("users");

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <UserPanelStyled>
      {isMobile ? (
        <MobileView>
          {!showUserDetail && showActionBar && (
            <ActionBarWrapper>
              <ActionBarButtons>
                <UserActionBar
                  pendingList={pendingList}
                  setPendingList={setPendingList}
                  setWorksiteBulkUploadShow={setWorksiteBulkUploadShow}
                  history={history}
                  props={props}
                />
              </ActionBarButtons>
            </ActionBarWrapper>
          )}
          {!showUserDetail ? (
            <>
              <UsersCard />
              <SearchFilter>
                <SearchBoxWrapper>
                  <SearchBox
                    label={"Your User"}
                    placeholder={t("Search a User")}
                    searchText={SearchText}
                    setSearchText={setSearchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </SearchBoxWrapper>

                <FilterWrapper onClick={handleToggle}>
                  {isOpen ? (
                    <CloseBackDiv>
                      <CloseIconDiv className="icon icon-cancel" />
                    </CloseBackDiv>
                  ) : (
                    <FilterBackDiv>
                      <FilterIconDiv className="icon icon-filter" />
                    </FilterBackDiv>
                  )}
                </FilterWrapper>
              </SearchFilter>

              <FilterOnclickDes>
                {isOpen && (
                  <FilterDesWrapper>
                    <UserFilters />
                  </FilterDesWrapper>
                )}
              </FilterOnclickDes>

              <UserMobileListWrapper>
                <UserList
                  pendingList={pendingList}
                  style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
                  searchText={SearchText}
                  setSearchText={setSearchText}
                  userTypeFilter={UserTypeFilter}
                  setImageLoaded={(data) => setImageLoaded(data)}
                  setShowUserDetail={() => setShowUserDetail(true)}
                />
              </UserMobileListWrapper>
            </>
          ) : (
            <>
              <UserTypeDetailHeading>
                <span onClick={() => setShowUserDetail(false)}>Your Users</span>{" "}
                {/* <img height="15" src={rightIcon} /> */}
                <RightIconImage className="icon icon-arrow-right"></RightIconImage>
                {` ${userName || ""}`}
              </UserTypeDetailHeading>
              <UserOverview imageLoaded={imageLoaded} setImageLoaded={(data) => setImageLoaded(data)} />
            </>
          )}
        </MobileView>
      ) : (
        <DesktopView>
          <ButtonsContainer>
            <UserActionBar
              pendingList={pendingList}
              setPendingList={setPendingList}
              setWorksiteBulkUploadShow={setWorksiteBulkUploadShow}
              history={history}
              props={props}
            />
          </ButtonsContainer>
          <UserManagement pendingList={pendingList} />
        </DesktopView>
      )}
      <UserCentralModal
        onHide={() => {
          setModalWaitingToOpen(false);
          setAddUserModal(false);
        }}
        toogleModalClose={() => {
          setModalWaitingToOpen(false);
          setAddUserModal(false);
        }}
        setIsSuccess={setIsSuccess}
        updateUserDetails={props.updateUserDetails}
        isUpdate={props.isUpdate}
      />
      <WoksiteBulkUploadModal
        show={worksiteBulkUploadShow}
        onHide={() => setWorksiteBulkUploadShow(false)}
        toogleModalClose={() => setWorksiteBulkUploadShow(false)}
      />
      <CommonSuccessMessage />
    </UserPanelStyled>
  );
}

function WoksiteBulkUploadModal(props) {
  const { t } = useTranslation();
  return (
    <ModalPopup
      {...props}
      size="lg"
      centered
      backdrop="static"
      title={t("User Bulk Upload")}
      handleClose={props.toogleModalClose}
      withBorderBottom="true"
    >
      <UserBulkUploadModal>
        <UserBulkUploadForm handleCancelModal={props.toogleModalClose} />
      </UserBulkUploadModal>
    </ModalPopup>
  );
}

export function UserCentralModal(props) {
  const { t } = useTranslation();
  const title = props.isUpdate ? t("Update User") : t("Add User");
  return (
    <ModalPopup
      {...props}
      size="lg"
      centered
      backdrop="static"
      title={title}
      handleClose={props.toogleModalClose}
    >
      <AddUserStepper
        {...props}
        toogleModalClose={props.toogleModalClose}
        setIsSuccess={props.setIsSuccess}
        setIsError={props.setIsError}
        updateUserDetails={props.isUpdate && props.updateUserDetails}
        isUpdate={props.isUpdate}
      />
    </ModalPopup>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  users: state.users.Users,
  addUserSuccess: state.users.AddUserSuccess,
  addUserSuccessFlag: state.users.addUserSuccessFlag,
  userMessage: state.users.UserMessage,
  usersLoading: state.users.UsersLoading,
  showFilters: state.mobileView.showFilters,
  userName: state?.users?.UserOverview?.fullName,
  showActionBar: state.mobileView.showManagementActionBar,
  updateUserDetails: state.modals.userDetails,
  isUpdate: state.modals.isUpdate,
  editForm: state.forms.editForm,
  newlyAddedUser: state.assets.newlyAddedUser,
});

const mapDispatchToProps = (dispatch) => ({
  userList: (users) => dispatch(userList(users)),
  defaultUserOverview: () => dispatch(defaultUserOverview()),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setShowFilters: (data) => dispatch(setShowFilters(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomizedTabs));
