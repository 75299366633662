//TODO - Clean up, Refactor,  Optimize this file when time permits and remove this comment afterwards

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toValidateWorksiteName } from "../../../utils/validations/checkValidation";
import timeZones from "../../../constant/backendTimezones";

import { Container, Form } from "react-bootstrap";
import { postWorksite } from "../../../redux/worksites/action";
import { Trans, useTranslation } from "react-i18next";
import { setAddSiteModal } from "../../../redux/modals/action";
import validationConstant from "../../../constant/messages/validation.json";
import {
  DeleteIconStyled,
  ShiftDurationMessage,
  ShiftDurationWrapper,
  ShiftDurationSettingWrapper,
  ShiftDurationH,
  ModalPopupWrapper,
  HeadingContainer,
  ShiftDurButton,
  FormContainer,
  FormItem,
} from "./WorksiteOverviewForm.styles";
import WorksiteButtonsPanel from "../Management/panels/worksite/WorksiteButtonsPanel";

import CustomInputField from "../../../components/InputFieldComponent/InputFieldWrapper.component";
import DropdownFilterWrapper from "../../../components/DropdownComponent/DropdownFilterWrapper";

const { timezone_required } = validationConstant;

function WorkSiteOverviewForm({
  loginDetails,
  addWorksite,
  history,
  toogleModalClose,
  userDetails,
  setIsSuccess,
  setIsError,
  postWorksite,
  addWorksiteLoading,
  accountList,
  isSuperAdmin,
  dropdownAccountList,
  setAddSiteModal,
  activeStep,
  setActiveStep,
  ...props
}) {
  const [worksiteDetails, setWorksiteDetails] = useState({
    timeZone: timeZones[0]?._id,
  });
  const [validated, setValidated] = useState(false);
  const [accountIdForAdmin, setAccountIdForAdmin] = useState("");
  const [isValidationError, setValidationError] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [shiftSettings, setShiftSettings] = useState(null);
  const { t } = useTranslation();

  // Add
  const handleSubmit = (event) => {
    event.preventDefault();
    if (addWorksiteLoading) {
      return;
    }
    setIsSubmitClicked(true);
    if (toValidateWorksiteName(worksiteDetails?.name)) {
      setValidated(true);
      return;
    }
    const shiftDurationPattern = /^([0-9])*$/g;
    if (activeStep === 1) {
      if (shiftSettings && !shiftDurationPattern.test(shiftSettings.shiftDuration)) {
        setValidated(true);
        return;
      }
    }

    if (isValidationError) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    const { name, siteId, address, timeZone } = worksiteDetails;
    const actualDetails = {
      siteId: siteId,
      name: name,
      address: address,
      timeZone: timeZone,
      s3Enable: true,
      shiftSettings: shiftSettings,
    };
    if (isSuperAdmin) {
      actualDetails.accountId = accountIdForAdmin;
    } else {
      actualDetails.accountId = loginDetails.userData.accountId;
    }

    if (activeStep === 0) {
      setActiveStep(1);
    } else {
      postWorksite(actualDetails, () => {
        // eslint-disable-next-line no-unused-expressions
        setAddSiteModal(false);
      });
    }
    event.preventDefault();
  };

  const validateSiteData = (accountIdForAdmin) => {
    let accountId = accountIdForAdmin;
    let errors = [];
    if (!accountId && isSuperAdmin) {
      errors.push("Account");
    }
    return errors;
  };

  useEffect(() => {
    if (!isSubmitClicked) {
      return;
    }
    let isError = validateSiteData(accountIdForAdmin);
    if (isError[0]) {
      setValidationError(isError);
    } else {
      setValidationError(false);
    }
  }, [accountIdForAdmin, isSubmitClicked]);

  const handleInvalidCharsInput = (e) => {
    const invalidChars = ["-", "+", "e", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const shiftDurationBlurHandler = (e) => {
    const shiftDuration = e.target.value > 24 ? 24 : Math.floor(e.target.value);
    setShiftSettings({
      ...shiftSettings,
      shiftDuration,
    });
  };

  const deleteShiftDuration = () => {
    setShiftSettings(null);
  };

  return (
    <ModalPopupWrapper>
      <Container>
        <HeadingContainer>
          {activeStep === 0 ? <Trans>Worksite Overview</Trans> : <Trans>Worksite Shifts</Trans>}
        </HeadingContainer>

        <Form noValidate validated={validated} onSubmit={handleSubmit} className="login-email">
          <>
            {activeStep === 0 ? (
              <FormContainer>
                {isSuperAdmin && (
                  <FormItem>
                    <DropdownFilterWrapper
                      options={dropdownAccountList?.map((data) => ({
                        _id: data._id,
                        value: data._id,
                        label: data.companyName,
                      }))}
                      label={t("Account")}
                      isError={isValidationError}
                      isValidationError={isValidationError}
                      value={accountIdForAdmin}
                      onChange={(value) => setAccountIdForAdmin(value)}
                      placeholder="Select an Account"
                      errorMessage={t("This field is required")}
                    />
                  </FormItem>
                )}
                <FormItem>
                  <CustomInputField
                    label={t("Worksite")}
                    value={worksiteDetails?.name}
                    onChange={(e) =>
                      setWorksiteDetails({ ...worksiteDetails, name: e.target.value, siteId: e.target.value })
                    }
                    type="text"
                    minLength={1}
                    maxLength={64}
                    placeholder={t("Please enter your Worksite Name")}
                    isError={validated && toValidateWorksiteName(worksiteDetails?.name)}
                    errorMessage={t(toValidateWorksiteName(worksiteDetails?.name))}
                    required
                    autofocus
                  />
                </FormItem>
                <FormItem>
                  <CustomInputField
                    label={<Trans>Address</Trans>}
                    value={worksiteDetails?.address}
                    onChange={(e) => setWorksiteDetails({ ...worksiteDetails, address: e.target.value })}
                    type="text"
                    minLength={1}
                    maxLength={128}
                    placeholder={t("Enter Your Address")}
                    autofocus
                  />
                </FormItem>
                <FormItem>
                  <DropdownFilterWrapper
                    showLabel={true}
                    label={t("Timezone")}
                    options={timeZones?.map((option, i) => ({
                      _id: option._id,
                      value: option._id,
                      label: option.label,
                    }))}
                    required={true}
                    value={worksiteDetails?.timeZone || (timeZones && timeZones[0] && timeZones[0]._id)}
                    // value={worksiteDetails?.timeZone}
                    onChange={(value) =>
                      setWorksiteDetails({
                        ...worksiteDetails,
                        timeZone: value,
                      })
                    }
                    feedback={t(timezone_required)}
                  />
                </FormItem>
              </FormContainer>
            ) : (
              <>
                <>
                  <ShiftDurationWrapper>
                    {shiftSettings ? (
                      <>
                        <ShiftDurationSettingWrapper>
                          <ShiftDurButton>
                            <CustomInputField
                              label={t("Shift Duration")}
                              onChange={(e) =>
                                setShiftSettings({
                                  ...shiftSettings,
                                  shiftDuration: e.target.value > 24 ? 24 : e.target.value,
                                })
                              }
                              value={shiftSettings.shiftDuration}
                              type="number"
                              min={1}
                              max={24}
                              placeholder={t("Enter shift duration")}
                              onKeyDown={handleInvalidCharsInput}
                              onBlur={shiftDurationBlurHandler}
                              autofocus
                            />
                            <ShiftDurationH>hours</ShiftDurationH>
                          </ShiftDurButton>
                          <DeleteIconStyled className="icon icon-delete" onClick={deleteShiftDuration} />
                        </ShiftDurationSettingWrapper>
                      </>
                    ) : (
                      <ShiftDurationMessage>
                        <p>
                          <Trans>NO_SHIFT_DEFINED_WORKSITE</Trans>
                          <br />
                          <Trans>CLICK_ON_ADD_SHIFT_DURATION_BUTTON</Trans>
                        </p>
                      </ShiftDurationMessage>
                    )}
                  </ShiftDurationWrapper>
                </>
              </>
            )}

            <WorksiteButtonsPanel
              addWorksiteLoading={addWorksiteLoading}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              shiftSettings={shiftSettings}
              setShiftSettings={setShiftSettings}
            />
          </>
        </Form>
      </Container>
    </ModalPopupWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  postWorksite: (data, cb) => dispatch(postWorksite(data, cb)),
  setAddSiteModal: (data) => dispatch(setAddSiteModal(data)),
});
const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  userDetails: state.user.userDetails,
  addWorksiteLoading: state.worksites.AddWorksiteLoading,
  accountList: state.accounts.Accounts,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkSiteOverviewForm));
