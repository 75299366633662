import styled from "styled-components";
export const ManagementPanelWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};

  div[title="tab"] {
  }
  .nav {
    margin: 0;
  }
  .nav-link {
  }
`;

export const WorksiteOverviewDiv = styled.div`
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 514px)" : "calc(100vh - 494px)")};
  margin-bottom: 10px;

  @media (max-width: 1100px) {
    height: 100%;
    margin: 15px 0px;
  }
`;
