import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import List from "../../../../../components/List";
import {
  getUserTypeList,
  getUserTypeDetailById,
  getSuperAdminUserTypes,
} from "../../../../../redux/userType/action";
import { UserTypeListingDiv } from "./UserTypeList.component.styles";

const listType = require("../../../../../constant/listType");

function UserTypeList({
  userTypeList,
  filterAccountId,
  totalCount,
  getUserTypeDetailById,
  getUserTypeList,
  UserTypeDetail,
  UserTypeListLoading,
  accountId,
  isSuperAdmin,
  accessLevel,
  superAdminList,
  getSuperAdminUserTypes,
  setShowUserTypeDetail,
}) {
  useEffect(() => {
    if (userTypeList?.[0]?._id) {
      getUserTypeDetailById(userTypeList?.[0]?._id);
    }
  }, [userTypeList?.length]);

  const { t } = useTranslation();

  const listData =
    isSuperAdmin && accessLevel === "ALL_ACCOUNTS"
      ? superAdminList
      : isSuperAdmin && (!accountId || accountId === "all")
        ? []
        : userTypeList;

  const listDetail = {
    label: t("Your User Types"),
    emptyListText: t("NO_USERS_TYPE_FOUND"),
    listType: listType.USER_TYPE,
    heightOffset: 294,
  };

  return (
    <List
      hideHeader={true}
      listDetail={listDetail}
      listData={listData}
      selectItem={(id) => {
        getUserTypeDetailById(id);
        setShowUserTypeDetail(true);
      }}
      selectedId={UserTypeDetail && UserTypeDetail._id}
      isFilterChange={filterAccountId}
      loadData={
        isSuperAdmin
          ? accessLevel === "SINGLE_ACCOUNT"
            ? getUserTypeList
            : getSuperAdminUserTypes
          : getUserTypeList
      }
      totalCount={isSuperAdmin && accessLevel !== "SINGLE_ACCOUNT" ? listData?.length : totalCount}
      dataLoading={UserTypeListLoading}
      showActiveInactiveTabs={true}
    />
  );
}
const mapStateToProps = (state) => ({
  userTypeList: state.userType.UserTypeList,
  UserTypeDetail: state.userType.UserTypeDetail,
  totalCount: state.userType.UserTypeList.length,
  UserTypeListLoading: state.userType.UserTypeListLoading,
  accountId: state.dropdownFilters.accountId,
  isSuperAdmin: state.user.isSuperAdmin,
  superAdminList: state.userType.superAdminList,
});

const mapDispatchToProps = (dispatch) => ({
  getUserTypeList: () => dispatch(getUserTypeList()),
  getUserTypeDetailById: (id) => dispatch(getUserTypeDetailById(id)),
  getSuperAdminUserTypes: (superAdminUserTypeId) => dispatch(getSuperAdminUserTypes(superAdminUserTypeId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTypeList));
