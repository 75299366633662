import React from "react";

import styled from "styled-components";

const InputFieldStyle = (theme) => ({
  small: {
    fontSize: theme.fontSizes.font8,
    labelFontSize: theme.fontSizes.font8,
    iconFontsize: theme.fontSizes.font5,
    iconTop: "4px",
    iconLeft: "4px",
    iconRight: "4px",
    inputLeft: "8px",
    lineHeight: "30px",
    errorFontSize: theme.fontSizes.font8,
  },
  medium: {
    fontSize: theme.fontSizes.font7,
    labelFontSize: theme.fontSizes.font8,
    iconFontsize: theme.fontSizes.font4,
    iconTop: "5px",
    iconLeft: "4px",
    iconRight: "5px",
    inputLeft: "10px",
    lineHeight: "34px",
    errorFontSize: theme.fontSizes.font8,
  },
  large: {
    fontSize: theme.fontSizes.font6,
    labelFontSize: theme.fontSizes.font7,
    iconFontsize: theme.fontSizes.font3,
    iconTop: "4px",
    iconLeft: "8px",
    iconRight: "8px",
    inputLeft: "12px",
    lineHeight: "38px",
    errorFontSize: theme.fontSizes.font6,
  },
});

export const InputContent = styled.div`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  overflow: hidden;
  width: 98%;
  display: flex;
  float: left;
  position: relative;
  height: auto;
  background-clip: padding-box;
  border: ${({ $isError, border, theme }) =>
    $isError
      ? "1px solid " + theme.colors.redPrimary || "#DD0000"
      : border || "1px solid " + theme.colors.grayL3 || "#676767"};
  border-radius: 0.25rem;
  &:hover {
    border: ${({ $isError, border, theme }) =>
      $isError
        ? "1px solid " + theme.colors.redPrimary || "#DD0000"
        : border || "1px solid " + theme.colors.grayL1 || "#323232"};
  }
  &:focus {
    /* border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"}; */
    border: ${({ $isError, border, theme }) =>
      $isError
        ? "1px solid " + theme.colors.redPrimary || "#DD0000"
        : border || "1px solid " + theme.colors.themePrimary || "#0D5FBE"};
    &:hover {
      /*  border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"}; */
      border: ${({ $isError, border, theme }) =>
        $isError
          ? "1px solid " + theme.colors.redPrimary || "#DD0000"
          : border || "1px solid " + theme.colors.themePrimary || "#0D5FBE"};
    }
  }
  &:focus-within {
    /*  border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};*/
    border: ${({ $isError, border, theme }) =>
      $isError
        ? "1px solid " + theme.colors.redPrimary || "#DD0000"
        : border || "1px solid " + theme.colors.themePrimary || "#0D5FBE"};

    input {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    }
  }
  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    pointer-events: none;
  }
`;
export const IconLeft = styled.i`
  position: relative;
  top: ${(props) => InputFieldStyle(props.theme)[props.size].iconTop};
  left: ${(props) => InputFieldStyle(props.theme)[props.size].iconLeft};
  font-size: ${(props) => InputFieldStyle(props.theme)[props.size].iconFontsize};
  color: ${({ iconColor, theme }) => theme.colors[iconColor] || "#333"};
`;

export const IconRight = styled.i`
  position: relative;
  top: ${(props) => InputFieldStyle(props.theme)[props.size].iconTop};
  right: ${(props) => InputFieldStyle(props.theme)[props.size].iconRight};
  font-size: ${(props) => InputFieldStyle(props.theme)[props.size].iconFontsize};
  color: ${({ iconColor, theme }) => theme.colors[iconColor] || "#333"};
`;

export const TextareaWrapper = styled.textarea`
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  position: relative;
  margin-left: ${(props) => InputFieldStyle(props.theme)[props.size].inputLeft};
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  font-size: ${(props) => InputFieldStyle(props.theme)[props.size].fontSize};
  line-height: ${(props) => InputFieldStyle(props.theme)[props.size].lineHeight};
  background-clip: padding-box;
  border: 0px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus{
    border: 0px !important;
  }
  &:hover{
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  &:active{
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  &:focus-visible {
    outline: none;
    border: none;
    & ~ ${InputContent} {
    border: 1px solid green;
  }
  }
  [readonly] {
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      &:focus {
        border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  } 
  ::placeholder
    {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
`;

export const InputWrapper = styled.input`
  color:${({ theme }) => theme.colors.grayL3 || "#676767"};
  position: relative;
  margin-left: ${(props) => InputFieldStyle(props.theme)[props.size].inputLeft};
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  font-size: ${(props) => InputFieldStyle(props.theme)[props.size].fontSize};
  line-height: ${(props) => InputFieldStyle(props.theme)[props.size].lineHeight};
  background-clip: padding-box;
  border: 0px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover{
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  &:active{
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  &:focus-visible {
    outline: none;
    border: none;
    & ~ ${InputContent} {
    border: 1px solid green;
  }
}
  [readonly] {
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      &:focus {
        border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    }
    ::placeholder
    {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
 
`;

export const Label = styled.label`
  position: relative;
  width: 100%;
  text-align: left;
  margin-bottom: 2px;
  font-size: ${(props) => InputFieldStyle(props.theme)[props.size].labelFontSize};
  float: left;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family:  ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  font-size: ${(props) => InputFieldStyle(props.theme)[props.size].errorFontSize};
  overflow: hidden;
  clear: both;
  text-align: left;
}
`;

export const InputComponent = styled.div`
  /* margin-bottom: 1rem; */
  font-family:  ${({ theme }) => theme.fontFamily.primaryFont};
  overflow: hidden;
  position: relative;
  width: 102%;
  &[disabled] {
    pointer-events: none;
    ${Label} {
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
    }
    ${InputWrapper} {
      border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      ::placeholder {
        color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      }
    }
    .icon {
      color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    }
    ${InputContent} {
      border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    }
    ${ErrorText} {
      display: none;
    }
  }
  &[readonly] {
    ${InputContent} {
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
      }
      &:focus-within {
        border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
      }
    }
  }
`;

export const Asterisk = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  position: absolute;
  right: 15px;
  top: 0px;
`;
