import React, { useState, useEffect } from "react";
import DataTable from "../../../../components/DataTableComponent/DataTable.component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import { toDownloadFiles } from "../../../../redux/config/action";
import { getTimezoneShortCode } from "../../../../utils/timezoneConvert";

import {
  ImpactSeverty,
  ImpactGDiv,
  NoReportsDiv,
  ImpactReportTable,
} from "./ImpactTableWrapper.component.styles";
import { getImpactReportListingNew } from "../../../../redux/reports/action";
import awsConstant from "../../../../constant/awsFolder.json";

const ImpactTableWrapper = ({
  loginDetails,
  isSiteAdmin,
  toDownloadFiles,
  fileDownloadLoading,
  isSingleAccountMultiSiteUser,
  setIsSuccess,
  setIsError,
  currentTimeZone,
  loading,
  getImpactReportListingNew,
  assetOverview,
  impactReportList,
  dropdownAccountId,
  isSuperAdmin,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 3);

    getImpactReportListingNew({
      accountId: isSuperAdmin ? dropdownAccountId : "all",
      assetId: assetOverview?._id || "all",
      assetTypeId: assetOverview?.assetTypeId?._id || "all",
      inspectorId: "all",
      impactSeverity: "all",
      worksiteId: "all",
      pageNo: 1,
      pageSize: 10,
      startDate: date,
      endDate: new Date(),
      userTypes: "all",
    });
  }, []);

  const tableHeader = [
    { name: "Account", space: 2, fieldName: "accountName" },
    { name: "Worksite", space: 1, fieldName: "worksite" },
    { name: "Impact By", space: 1, fieldName: "assetId" },
    { name: "Asset Name", space: 1, fieldName: "assetName" },
    { name: "Date, Time", space: 1, fieldName: "createdAt" },
    { name: "Impact (g)", space: 1, fieldName: "impactG" },
    { name: "Severity", space: 1, fieldName: "impactSeverity" },
    { name: "Actions", space: 2, fieldName: "inspectionCountMonthly" },
  ];

  const [loadingReportId, setLoadingReportId] = useState("");

  const viewReportClickHandler = (e, row) => {
    if (
      row?.severity?.toLowerCase?.() === "low" ||
      row?.severity?.toLowerCase?.() === "medium" ||
      row?.severity?.toLowerCase?.() === "high"
    ) {
      localStorage.setItem("impactReportObject", JSON.stringify(row));
      window.open("/#/app/impact-report", "_blank");
    } else {
      localStorage.setItem("nearMissReportObject", JSON.stringify(row));
      window.open("/#/app/near-miss-report", "_blank");
    }
  };

  const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.impactReport, `${id}.pdf`);
  };

  const ActionCell = ({ row }) => {
    return (
      <>
        {fileDownloadLoading && row._id === loadingReportId ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <div className="action-div">
            <i
              title="View"
              onClick={(e) => viewReportClickHandler(e, row)}
              className="icon icon-view"
              style={{ textAlign: "right", marginRight: "5px" }}
            ></i>

            <i
              title="Download"
              onClick={() => downloadReportApiCall(row._id)}
              className="icon icon-download"
              style={{ marginLeft: "5px" }}
            ></i>
            {/* <i className="icon icon-share"></i>   <i title="Share"></i> */}
          </div>
        )}
      </>
    );
  };

  const columns = tableHeader
    .filter(
      (data) =>
        !(
          (isSingleAccountMultiSiteUser && data.name === "Account") ||
          (isSiteAdmin && (data.name === "Worksite" || data.name === "Account"))
        )
    )

    .map((data) => {
      if (data.name === "Account") {
        return {
          name: t(data.name),
          selector: (row) => row?.accountId?.companyName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Worksite") {
        return {
          name: t(data.name),
          selector: (row) => row?.siteId?.name || "NA",
          sortable: true,
        };
      }

      if (data.name == "Impact By") {
        return {
          name: t(data.name),
          selector: (row) => row?.userId.fullName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Asset Name") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetId?.assetName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Date, Time") {
        return {
          name: t(data.name),
          selector: (row) => {
            const timeZone = currentTimeZone || row?.siteId?.timeZone || "NA";
            const formattedDate = !row?.createdAt
              ? "NA"
              : `${moment(row?.createdAt)
                  .utcOffset(timeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm:ss A")} ${getTimezoneShortCode(timeZone)}`;

            return <span title={formattedDate}>{formattedDate}&nbsp;</span>;
          },
          sortable: true,
        };
      }

      if (data.name === "Impact (g)") {
        return {
          name: t(data.name),
          centered: true,
          selector: (row) => {
            const impactG = row?.impactG || "NA";
            return (
              <ImpactGDiv title={impactG} impactG={impactG}>
                {impactG}
              </ImpactGDiv>
            );
          },
          sortable: true,
        };
      }

      if (data.name === "Severity") {
        return {
          name: t(data.name),
          selector: (row) => {
            const severity = row?.severity || "NA";
            return (
              <ImpactSeverty title={severity} severity={severity}>
                <span>{severity}</span> <i className="icon icon-impact"></i>
              </ImpactSeverty>
            );
          },
          sortable: true,
        };
      }

      if (data.name === "Actions") {
        return {
          name: t(data.name),
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }

      return {
        name: data.name,
        selector: (row) => row[data.fieldName],
        sortable: true,
      };
    });
  // columns.unshift(...columnsWithCheckboxes);

  const mobileCheckboxDateTime = [
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Impact By", className: "inspectedByStyle" },
    { columnName: "Asset Name", className: "assetNameStyle" },
  ];

  const mobileCheckboxAccordion = ["Asset Name", "Impact (g)", "Severity"];

  if (!Array.isArray(impactReportList)) {
    return (
      <NoReportsDiv>
        <Trans>No Impact Reports Available</Trans>
      </NoReportsDiv>
    );
  }

  return (
    <ImpactReportTable>
      <DataTable
        title=""
        columns={columns}
        data={impactReportList}
        mobileCheckboxDateTime={mobileCheckboxDateTime}
        mobileCheckboxAccordion={mobileCheckboxAccordion}
        responsive
        dense
        loading={loading}
        rowHeight={565}
        scrollable={true}
        fixedHeader
        mobileCheckboxOnly
        mobileDateTimeOnly
        mobileActionItemOnly
        noDataMessage={t("No Impact Reports Available")}
        rowsPerPageOptions={[5]}
      />
    </ImpactReportTable>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  fileDownloadLoading: state.config.fileDownloadLoading,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
  currentTimeZone: state.settings.currentTimeZone,
  impactReportList: state.reports.impactReportList,
  dropdownAccountId: state.dropdownFilters.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
  getImpactReportListingNew: (filterData) => dispatch(getImpactReportListingNew(filterData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImpactTableWrapper));
