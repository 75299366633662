//Cleanup this file when time permits and remove this comment

import React, { useState, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import List from "../../../components/List";
import { selectChecklistOverview, getAllChecklist } from "../../../redux/checklists/action";

//custom components
import SearchBox from "../../../components/SearchBox/Searchbox.component";
import ChecklistSearchBox from "./ChecklistButtonSearch/SearchBoxComponent";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
const CheckListStyled = styled.div`
  @media (max-width: 768px) {
    .searchBox {
      display: none;
    }
  }
`;
const ListingStyle = styled.div`
  height: 100%;
  overflow-x: hidden;
  width: 100%;
  overflow-x: hidden;

  .tableHeight {
    display: block !important;
    overflow-y: scroll;
    height: calc(100vh - 264px);
    @media (max-width: 768px) {
      height: calc(100vh - 330px);
    }
  }
  .tableListSec {
    display: block;
    width: 100%;
  }
  tr.tableTr {
    display: block;
  }

  tr.tableTr td {
    display: block;
    padding: 8px 16px;
  }

  tr.tableTr p {
    margin: initial;
    width: 99%;
    line-height: 18px;
  }
`;

const listType = require("../../../constant/listType");

function ChecklistList({
  checklistData,
  selectChecklistOverview,
  filterAccountId,
  checklistOverview,
  getAllChecklist,
  totalCount,
  checklistLoading,
}) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  const listDetail = {
    label: t("Your Checklists"),
    emptyListText: t("NO_CHECKLISTS_FOUND"),
    listType: listType.CHECKLIST,
    heightOffset: 263,
  };

  useEffect(() => {
    selectChecklistOverview();
  }, [checklistData]);

  return (
    <CheckListStyled>
      <ChecklistSearchBox searchText={searchText} setSearchText={setSearchText} />
      <ListingStyle>
        <List
          hideHeader={true}
          listDetail={listDetail}
          listData={checklistData}
          selectItem={(id) => selectChecklistOverview(id)}
          selectedId={checklistOverview && checklistOverview._id}
          isFilterChange={filterAccountId}
          loadData={getAllChecklist}
          totalCount={totalCount}
          searchKey={searchText.length > 3 ? searchText : ""}
          dataLoading={checklistLoading}
        />
      </ListingStyle>
    </CheckListStyled>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklistData: state.checklist.checklists,
  checklistOverview: state.checklist.checklistOverview,
  totalCount: state.checklist.totalCount,
  checklistLoading: state.checklist.checklistLoading,
});

const mapDispatchToProps = (dispatch) => ({
  selectChecklistOverview: (checklist) => dispatch(selectChecklistOverview(checklist)),
  getAllChecklist: (paginationData, isResetState) => dispatch(getAllChecklist(paginationData, isResetState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistList);
