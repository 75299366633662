/* eslint-disable jsx-a11y/anchor-is-valid */

//TODO- Clean up this file when time permits. Remove unused/commented code, improve/optimize code

import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import { updateActiveNavItem } from "../../../../../redux/sideMenu/menu.action";
import { updateMenu, updateMenuReport } from "../../../../../redux/sideMenu/menu.action";
import { getImpactCount, getImpactGraphData } from "../../../../../redux/counts/action";
import { setIncidentReportFilter } from "../../../../../redux/filters/action";
import {
  setDropdownAccountId,
  setDropdownWorksiteId,
  getDropdownWorksiteList,
} from "../../../../../redux/dropdownFilters/action";
import DropdownWrapper from "../../../../../components/DropdownComponent/DropdownWrapper";
import DateRangePicker from "../../../../../components/DatePicker.component";
import { getImpactReportListingNew } from "../../../../../redux/reports/action";
import QuickAccess from "../QuickManagement/QuickManagement.component";
import "react-multi-carousel/lib/styles.css";
import Button from "../../../../../components/Button/Button.component";
import DashboardGraph from "../../DashboardGraph";
import { apiCall } from "../../../../../utils/apiCall";
import SieraLoader from "../../../../../components/SieraLoader/SieraLoader.component";

import { getUserTypeList } from "../../../../../redux/userType/action";

import {
  DashboardContainer,
  CarouselContainer,
  CarouselDiv,
  HighMediumImpactDiv,
  GraphContainer,
  StyledCol,
  LoaderOverlay,
  Container,
  ContentWrapper,
  LeftSection,
  RightSection,
} from "./IncidentDashboard.component.styles";
import PermissionsCheck from "../../../../../components/PermissionCheck/PermissionsCheck";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import DataTableIncident from "./IncidentHighMediumTableWrapper";
import DivContainer from "../../../../../components/DivContanerCompnent/DivContainer.component";
import MoreFilterMobileToggle from "../../../../../components/More Filter Button/MoreFilterButton.component";
import CarouselDataInspection from "./IncidentCarouselWrapper";
import { ThemeContext } from "styled-components";

function IncidentDashboard(props) {
  const [isLoading, setIsLoading] = useState(true);
  const {
    loginDetails,
    isSuperAdmin,
    isSiteAdmin,
    getImpactReportListLoading,
    impactReportList,
    getImpactReportListing,
    updateMenu,
    updateMenuReport,
    getImpactCount,
    allCount,
    getAllCountLoading,
    impactCount,
    setIncidentReportFilter,
    incidentReportFilter,
    dropdownWorksiteList,
    dropdownAccountList,
    setDropdownAccountId,
    dropdownAssetTypeList,
    setDropdownWorksiteId,
    getImpactGraphData,
    graphData,
    updateActiveNavItem,
    permissions,
    isSingleAccountMultiSiteUser,
    getIncidentCountLoading,
    isIncidentGraphLoading,
    activeNavItem,
    getDropdownWorksiteList,
    userTypeList,
    getUserTypeList,
  } = props;
  const { t } = useTranslation();
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filterByTypeLabel, setFilterByTypeLabel] = useState("all");

  const [managementCountBanner, setManagementCountBanner] = useState([]);
  const [ignoreFilter, setIgnoreFilter] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const themeContext = useContext(ThemeContext);

  const [lastFilterData, setLastFilterData] = useState([]);
  const [lastFilterData2, setLastFilterData2] = useState([]);

  const [assetsByAssetType, setAssetsByAssetType] = useState([]);

  useEffect(() => {
    getImpactReportListing({
      ...incidentReportFilter,
      impactSeverity: ["HIGH", "MEDIUM"],
      pageSize: 10,
    });
    getUserTypeList();
  }, []);

  const FilterByTypeLabelData = {
    asset: t("Asset"),
    assetType: t("Asset Type"),
    inspectedBy: t("Inspected By"),
    inspectionResult: t("Inspection Result"),
  };

  const { accountId, filterByType, worksiteId } = incidentReportFilter;

  const filterByTypeData = [
    { name: t("Asset"), _id: "asset" },
    /* { name: t("Asset Type"), _id: "assetType" }, */
    { name: t("User"), _id: "inspectedBy" },
  ];
  const onclickCarousel = (e, item, subSection) => {
    updateActiveNavItem(item?.toLowerCase());
    updateMenu({ section: "management", subSection: subSection });
  };

  useEffect(() => {
    const managementCountBannerLocal = {
      ACCOUNT_MANAGEMENT: {
        name: t("Accounts"),
        iconClass: "accounts",
        type: "ThemePrimary",
        fieldName: "accounts",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Accounts", 0),
      },
      WORKSITE_MANAGEMENT: {
        name: t("Worksites"),
        iconClass: "worksites",
        type: "ThemePrimary",
        fieldName: "sites",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Worksites", 1),
      },
      ASSET_MANAGEMENT: {
        name: t("Assets"),
        iconClass: "assets",
        type: "ThemePrimary",
        fieldName: "assets",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Assets", 2),
      },
      USER_MANAGEMENT: {
        name: t("Users"),
        iconClass: "users",
        type: "ThemePrimary",
        fieldName: "users",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Users", 3),
      },
    };

    const permissionsNameArr = [
      "ACCOUNT_MANAGEMENT",
      "WORKSITE_MANAGEMENT",
      "ASSET_MANAGEMENT",
      "USER_MANAGEMENT",
    ];

    const managementCountBannerUpdated = [];

    if (permissions?.[0]) {
      permissions?.[0]?.permissions?.forEach((permission) => {
        if (permission.name === "REPORTS" && permission.action.view) {
          setViewReport(true);
        }

        if (permissionsNameArr.includes(permission.name)) {
          if (!permission?.action?.view) {
            const bannerObj = managementCountBannerLocal[permission.name];
            delete bannerObj.to;
            delete bannerObj.onClick;
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          } else {
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          }
        }
      });
    }
    setManagementCountBanner(managementCountBannerUpdated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSuperAdmin) {
      setIncidentReportFilter({
        ...incidentReportFilter,
        accountId: loginDetails && loginDetails.accountId,
      });
    }
    setIsLoading(true);
    getImpactCount(incidentReportFilter);
    getImpactGraphData(incidentReportFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  useEffect(() => {
    if (!getAllCountLoading && !getIncidentCountLoading && !isIncidentGraphLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [getAllCountLoading, getIncidentCountLoading, isIncidentGraphLoading]);

  useEffect(() => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      assetTypeId: "all",
      assetId: "all",
      inspectorId: "all",
      userTypeId: "all",
      // impactSeverity: "highmedium",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentReportFilter.filterByType]);

  const handleViewAll = () => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      impactSeverity: "all",
      pageSize: 10,
    });
    updateMenuReport({ section: "report", subSection: 1 });
  };

  const changeLastFilter = (value, key, isLeadFilter, resetField) => {
    if (isLeadFilter) {
      setIncidentReportFilter({
        ...incidentReportFilter,
        [key]: value,
        [resetField]: "all",
        pageNo: 1,
      });
      return;
    } else {
      setIncidentReportFilter({
        ...incidentReportFilter,
        [key]: value,
        pageNo: 1,
      });
    }
  };

  const getUsersByUserType = () => {
    const url = `/user/list/${incidentReportFilter.userTypeId || "all"}/${isSuperAdmin ? accountId : loginDetails.accountId}/${incidentReportFilter.worksiteId}/all/all/all`;

    apiCall(url).then((resp) => {
      let newData = [];
      let nameSet = new Set();
      resp.data.data.forEach((data) => {
        if (nameSet.has(data && data.fullName)) {
          return;
        }
        nameSet.add(data.inspectorId && data.inspectorId.fullName);
        newData.push({
          _id: data && data._id,
          fullName: data && data.fullName,
        });
      });
      setLastFilterData(newData);
    });
  };

  const getAssetsByAssetType = () => {
    const url = `/dropdown/assets?accid=${accountId}&siteid=${
      incidentReportFilter.worksiteId || "all"
    }&astpid=${incidentReportFilter.assetTypeId || "all"}`;
    apiCall(url)
      .then((resp) => {
        setAssetsByAssetType(resp?.data?.data?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUsersByUserType();
  }, [incidentReportFilter.userTypeId]);

  useEffect(() => {
    getAssetsByAssetType();
  }, [incidentReportFilter.assetTypeId]);

  const changeFilterByType = (value) => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      filterByType: value,
      pageNo: 1,
    });
    setFilterByTypeLabel(FilterByTypeLabelData[value]);
    if (value === "asset") {
      setLastFilterData(assetsByAssetType);
      setLastFilterData2(dropdownAssetTypeList);
    } else if (value === "inspectedBy") {
      setLastFilterData2(userTypeList);
      getUsersByUserType();
    }
  };

  useEffect(() => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      worksiteId: "all",
      assetId: "all",
      assetTypeId: "all",
      inspectorId: "all",
      impactSeverity: "all",
      userTypeId: "all",
      pageNo: 1,
    });
  }, [incidentReportFilter.accountId]);

  useEffect(() => {
    if (incidentReportFilter.filterByType === "asset") {
      setLastFilterData(assetsByAssetType);
      setLastFilterData2(dropdownAssetTypeList);
    } else if (incidentReportFilter.filterByType === "inspectedBy") {
      setLastFilterData2(userTypeList);
      getUsersByUserType();
      //const userType = incidentReportFilter.userTypeId;
    }
  }, [dropdownAssetTypeList, assetsByAssetType]);

  const carouselData = [
    {
      name: t("Total Impacts"),
      iconClass: "total-incidents",
      type: "ThemePrimary",
      fieldName: "totalImpacts",
      state: { impactSeverity: "all" },
      to: "#/app/report",

      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          //...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
    {
      name: t("High Impacts"),
      iconClass: "incident-high",
      type: "RedPrimary",
      fieldName: "HighImpacts",
      state: { impactSeverity: "high" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          filterByType: "incidentType",
          impactSeverity: "high",
          incidentType: "Impact",
          // ...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
    {
      name: t("Medium Impacts"),
      iconClass: "incident-medium",
      type: "OrangePrimary",
      fieldName: "MediumImpacts",
      state: { impactSeverity: "medium" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          filterByType: "incidentType",
          impactSeverity: "medium",
          incidentType: "Impact",
          // ...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
    {
      name: t("Low Impacts"),
      iconClass: "incident-low",
      type: "GreenPrimary",
      fieldName: "LowImpacts",
      state: { impactSeverity: "low" },
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setIncidentReportFilter({
          ...incidentReportFilter,
          filterByType: "incidentType",
          impactSeverity: "low",
          incidentType: "Impact",
          // ...data.state,
        });
        updateActiveNavItem("incidentReports");
        updateMenuReport({ section: "report", subSection: 1 });
      },
    },
  ];

  const carouselDataNoReportView = [
    {
      name: t("Total Incidents"),
      iconClass: "total-incidents",
      type: "ThemePrimary",
      fieldName: "totalImpacts",
      state: { impactSeverity: "all" },
    },
    {
      name: t("High Impacts"),
      iconClass: "incident-high",
      type: "RedPrimary",
      fieldName: "HighImpacts",
      state: { impactSeverity: "high" },
    },
    {
      name: t("Medium Impacts"),
      iconClass: "incident-medium",
      type: "OrangePrimary",
      fieldName: "MediumImpacts",
      state: { impactSeverity: "medium" },
    },
    {
      name: t("Low Impacts"),
      iconClass: "incident-low",
      type: "GreenPrimary",
      fieldName: "LowImpacts",
      state: { impactSeverity: "low" },
    },
  ];

  useEffect(() => {
    if (!showMoreFilter) {
      setFilterByTypeLabel("all");
    }
  }, [showMoreFilter]);

  const applyChange = () => {
    if (ignoreFilter) {
      return;
    }
    getImpactReportListing({
      ...incidentReportFilter,
      impactSeverity: ["HIGH", "MEDIUM"],
      pageSize: 10,
    });
    getImpactCount({ ...incidentReportFilter });
    getImpactGraphData(incidentReportFilter);
  };

  useEffect(() => {
    if (activeNavItem === "incidentReports") {
      setDropdownAccountId(incidentReportFilter.accountId);
      getDropdownWorksiteList();
      setTimeout(() => {
        setDropdownWorksiteId(incidentReportFilter.worksiteId);
      });

      setIncidentReportFilter({
        ...incidentReportFilter,
      });
    }
  }, [activeNavItem]);

  return (
    <DashboardContainer>
      {isLoading && (
        <LoaderOverlay>
          <SieraLoader showLabelBelowLogo label="Getting Ready" />
        </LoaderOverlay>
      )}
      <>
        <MoreFilterMobileToggle>
          <>
            <Row>
              {isSuperAdmin && (
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: t("Account"),
                      name: "accountName",
                      key: "companyName",
                      data: dropdownAccountList,
                    }}
                    value={accountId}
                    //  label={t("filter")}
                    handleFilter={(value) => {
                      setIncidentReportFilter({
                        ...incidentReportFilter,
                        accountId: value,
                      });
                      setDropdownAccountId(value);
                    }}
                  />
                </Col>
              )}

              {!isSiteAdmin && (
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: t("Worksite"),
                      name: "worksiteName",
                      key: "name",
                      data: dropdownWorksiteList,
                    }}
                    value={worksiteId}
                    // label={t("filter")}
                    handleFilter={(value) => {
                      setDropdownWorksiteId(value);
                      setIncidentReportFilter({
                        ...incidentReportFilter,
                        worksiteId: value,
                        assetId: null,
                        assetTypeId: null,
                      });
                    }}
                  />
                </Col>
              )}

              <Col lg={4}>
                <DateRangePicker
                  startDate={incidentReportFilter.startDate}
                  endDate={incidentReportFilter.endDate}
                  setDate={setIncidentReportFilter}
                  filterState={incidentReportFilter}
                />
              </Col>
              <StyledCol>
                <Button
                  iconClass="filter"
                  title={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                  label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                  onClick={() => setShowMoreFilter(!showMoreFilter)}
                  iconOnly
                  buttonType={showMoreFilter ? "primary" : "secondary"}
                />
                <span style={{ width: "10px", display: "inline-block" }}>&nbsp;</span>
                <Button
                  label={t("Apply Filter")}
                  size="medium"
                  onClick={applyChange}
                  showIcon={false}
                  buttonType="primary"
                />
              </StyledCol>
            </Row>
            {showMoreFilter && (
              <Row className="mt-2">
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: t("Filter By"),
                      name: "filterBy",
                      key: "name",
                      placeholder: t("Select a Filter"),
                      data: filterByTypeData,
                    }}
                    value={incidentReportFilter.filterByType}
                    handleFilter={(value) => changeFilterByType(value)}
                    useAsDropdown={true}
                  />
                </Col>
                {filterByTypeLabel !== "all" && (
                  <>
                    <Col lg={2}>
                      <DropdownWrapper
                        filter={{
                          type: `${filterByType === "asset" ? "Asset Type" : t("User Type")}`,
                          key: filterByType === "asset" ? "AssetTypeName" : "name",
                          data: lastFilterData2,
                        }}
                        value={incidentReportFilter[filterByType === "asset" ? "assetTypeId" : "userTypeId"]}
                        useAsDropdown={false}
                        label={t("filter")}
                        handleFilter={(e) =>
                          changeLastFilter(
                            e,
                            filterByType === "asset" ? "assetTypeId" : "userTypeId",
                            true,
                            filterByType === "asset" ? "assetId" : "inspectorId"
                          )
                        }
                        disabled={
                          filterByType === "assetType" &&
                          isSuperAdmin &&
                          (incidentReportFilter.accountId === "all" || !incidentReportFilter.accountId)
                        }
                      />
                    </Col>
                    <Col lg={2}>
                      <DropdownWrapper
                        filter={{
                          type: `${filterByTypeLabel === t("Inspected By") ? t("User") : filterByTypeLabel}`,
                          key: filterByType === "asset" ? "assetName" : "fullName",
                          data: lastFilterData,
                        }}
                        value={incidentReportFilter[filterByType === "asset" ? "assetId" : "inspectorId"]}
                        useAsDropdown={false}
                        label={t("filter")}
                        handleFilter={(e) =>
                          changeLastFilter(e, filterByType === "asset" ? "assetId" : "inspectorId")
                        }
                        disabled={
                          filterByType === "assetType" &&
                          isSuperAdmin &&
                          (incidentReportFilter.accountId === "all" || !incidentReportFilter.accountId)
                        }
                      />
                    </Col>
                  </>
                )}
              </Row>
            )}
          </>
        </MoreFilterMobileToggle>

        <Container>
          <ContentWrapper>
            <LeftSection>
              <CarouselContainer>
                <CarouselDataInspection
                  viewReport={viewReport}
                  incidentCount={impactCount}
                  updateActiveNavItem={updateActiveNavItem}
                  setIncidentReportFilter={setIncidentReportFilter}
                  incidentReportFilter={incidentReportFilter}
                  carouselData={carouselData}
                  carouselDataNoReportView={carouselDataNoReportView}
                />
              </CarouselContainer>
            </LeftSection>
            <RightSection>
              <CarouselDiv>
                <CarouselDataInspection
                  viewReport={false}
                  allCount={allCount}
                  managementCountBanner={managementCountBanner}
                  size="small"
                />
              </CarouselDiv>
            </RightSection>
          </ContentWrapper>
        </Container>
        <Container>
          <ContentWrapper>
            <LeftSection>
              <GraphContainer>
                <DashboardGraph
                  title={t("Impact Trends")}
                  series={[
                    {
                      name: t("Total Impacts"),
                      data: graphData?.count ?? [],
                    },
                    {
                      name: t("High Impacts"),
                      data: graphData?.high ?? [],
                    },
                    {
                      name: t("Medium Impacts"),
                      data: graphData?.medium ?? [],
                    },
                    {
                      name: t("Low Impacts"),
                      data: graphData?.low ?? [],
                    },
                  ]}
                  colors={[
                    themeContext?.colors?.themePrimary || "#0D5FBE",
                    themeContext?.colors?.redPrimary || "#DD0000",
                    themeContext?.colors?.orangePrimary || "#DD9000",
                    themeContext?.colors?.greenPrimary || "#11A70B",
                  ]}
                  date={graphData?.date}
                  startDate={incidentReportFilter.startDate}
                  endDate={incidentReportFilter.endDate}
                />
              </GraphContainer>

              <PermissionCheck section={"DASHBOARD"} permissionName={"REPORTS"} actionName={"view"}>
                <HighMediumImpactDiv>
                  <DivContainer
                    showViewAll={!!impactReportList && impactReportList.length > 0}
                    heading={t("High and Medium Impact Incidents")}
                    to="/app/report"
                    onClick={() => handleViewAll()}
                  >
                    <DataTableIncident
                      data={impactReportList}
                      isSiteAdmin={isSiteAdmin}
                      isSingleAccountMultiSiteUser={isSingleAccountMultiSiteUser}
                      loading={getImpactReportListLoading}
                    />
                  </DivContainer>
                </HighMediumImpactDiv>
              </PermissionCheck>
            </LeftSection>
            <PermissionsCheck
              section="DASHBOARD"
              permissionNames={[
                "ASSET_MANAGEMENT",
                "ASSET_TYPE_MANAGEMENT",
                "WORKSITE_MANAGEMENT",
                "USER_MANAGEMENT",
              ]}
              actionName="add"
              operator="OR"
            >
              <RightSection>
                <DivContainer heading={t("Quick Management Access")} showViewAll={false}>
                  <QuickAccess />
                </DivContainer>
                {/* Below code is commented as part of SIER-3209 temporarily*/}
                {/* <HourMeterReading
            getLastHourMeterListing={getLastHourMeterListing}
            lastHourMeterReadingLoading={lastHourMeterReadingLoading}
            reportFilter={incidentReportFilter}
            lastHourMeterReadingList={lastHourMeterReadingList}
          /> */}
              </RightSection>
            </PermissionsCheck>
          </ContentWrapper>
        </Container>
      </>
    </DashboardContainer>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isSiteAdmin: state.user.isSiteAdmin,
  getImpactReportListLoading: state.reports.getImpactReportListLoading,
  impactReportList: state.reports.impactReportList,
  lastHourMeterReadingList: state.reports.lastHourMeterReadingList,
  lastHourMeterReadingLoading: state.reports.getLastHourMeterListLoading,
  allCount: state.counts.allCount,
  getAllCountLoading: state.counts.getAllCountLoading,
  getIncidentCountLoading: state.counts.getIncidentCountLoading,
  impactCount: state.counts.impactCount,
  incidentReportFilter: state.filters.incidentReportFilter,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  graphData: state.counts.incidentGraphData,
  permissions: state.user?.loginDetails?.userData?.permissions,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
  isIncidentGraphLoading: state.user.isIncidentGraphLoading,
  activeNavItem: state.sideMenu.activeNavItem,
  userTypeList: state.userType.UserTypeList,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  getImpactReportListing: (filterData) => dispatch(getImpactReportListingNew(filterData)),
  getImpactGraphData: (filterData) => dispatch(getImpactGraphData(filterData)),
  updateMenuReport: (menuDetails) => dispatch(updateMenuReport(menuDetails)),
  getImpactCount: (filterData) => dispatch(getImpactCount(filterData)),
  setIncidentReportFilter: (filterData) => dispatch(setIncidentReportFilter(filterData)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getUserTypeList: () => dispatch(getUserTypeList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncidentDashboard));
