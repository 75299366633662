/**
 * @author Rakesh Vishwakarma <rakesh@siera.ai>
 *  Dashboard Page Header with Drawer Styling
 */
import styled from "styled-components";

export const TimezoneWrapper = styled.div`
  position: relative;
  width: auto;
  float: right;
  @media (max-width: 768px) {
    width: 100px;
    button {
      display: flex;
      align-items: center;
    }
  }
`;

export const TimezoneMenuWrapper = styled.div`
  position: absolute;
  display: ${({ $showTimezoneMenu }) => ($showTimezoneMenu ? "block" : "none")};
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border-radius: 1px;
  box-shadow: 0px 3px 6px #00000029;
  width: 348px;
  top: 62px;
  float: right;
  right: -83px;
  z-index: 999;
  @media (max-width: 1100px) {
    right: -2px;
    top: 150px;
  }
  &:after {
    content: " ";
    position: absolute;
    right: 95px;
    top: -12px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
`;

export const MenuItem = styled.div`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-style: normal;
  display: block;
  float: left;
  text-align: left;
  padding: 10px 15px;
  width: 100%;
`;

export const TimeSelected = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  display: flex;
  align-items: center;
  margin-left: 4px;
  &:before {
    content: "${(props) => props.timeZone}";
  }
`;

export const TimezoneDiv = styled.div``;

export const IconDrop = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  margin-left: 4px;
`;
