import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Input from "../../../../components/Input";
import InputField from "../../../../components/InputFieldComponent/InputFieldWrapper.component";
import { DropContainer } from "./CustomFeilds.styles";

const Phone = (props) => {
  const { formData, setFormData, selectedUserType, phoneErrorMessage } = props;
  const { t } = useTranslation();
  const customField = selectedUserType?.customFields.find((field) => field.type === "PHONE");

  return (
    <DropContainer>
      <InputField
        label={t("Phone")}
        type="phone"
        inputType="number"
        id="phone"
        variant="standard"
        name="phone"
        value={formData?.phone}
        required={customField.isRequired}
        onChange={(e) => {
          setFormData({
            ...formData,
            phone: e.target.value,
          });
        }}
        errorMessage={phoneErrorMessage}
        isError={phoneErrorMessage}
      />
      {/* {customField?.isRequired && <p className="asterisk_user">*</p>} */}
    </DropContainer>
  );
};

export default Phone;
