import styled from "styled-components";
export const WrapperDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  border-radius: 4px;
  @media (max-width: 1100px) {
    margin-bottom: 10px;
  }
`;
export const UserIconconDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font2} !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
`;
export const MainWraper = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;
export const SpanCount = styled.div`
  display: contents;
  font-size: ${({ theme }) => theme.fontSizes.font3} !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
`;
export const SpanHead = styled.div``;
