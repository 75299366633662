// React Library Imports
import React, { useState, useEffect } from "react";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

// Bootstrap Imports
import { Row, Col, Form, Table, Accordion, Card } from "react-bootstrap";

// Redux and Multiple Language Support
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { getAssetStatus } from "../../../redux/assetStatus/action";
import {
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
  setDropdownProductType,
  getDropdownAssetTypeList,
} from "../../../redux/dropdownFilters/action";

// Button Component
import Button from "../../../components/Button/Button.component";

//Custom Components
import BreadCrumb from "../../../components/BreadCrumb.component";
import DropdownFilter from "../../../components/DropdownFilter.component";

// Assets Imports
import { ReactComponent as ExpandMoreIcon } from "../../../assets/drawer_assets/ExpandMoreIcon.svg";
import { ReactComponent as ExpandLessIcon } from "../../../assets/drawer_assets/ExpandLessIcon.svg";
import { ReactComponent as Dot } from "../../../assets/icons/Dot.svg";
import { ReactComponent as ArrowRight } from "../../../assets/ArrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/ArrowLeft.svg";
import { ReactComponent as InspectionPerformed } from "../../../assets/Blue_Inspections_Performed.svg";
import { ReactComponent as ImpactIcon } from "../../../assets/Impact-Icon.svg";
import { ReactComponent as ObjectDetectionIcon } from "../../../assets/Proximity_Object_Icon.svg";
import { ReactComponent as PedestrianIcon } from "../../../assets/Proximity_Pedestrian_Icon.svg";
import { ReactComponent as UnauthorizedIcon } from "../../../assets/Unauthorized_User.svg";
import { getTimezoneShortCode } from "../../../utils/timezoneConvert";
import "./AssetStatus.css";
import { tableHeader, tableRowData, tableRowDetailData } from "./constants";

const getLastActivityIcon = (lastActivityType, impactSeverity, incidentObject) => {
  lastActivityType = lastActivityType?.toLowerCase?.();
  impactSeverity = impactSeverity?.toLowerCase?.();
  incidentObject = incidentObject?.toLowerCase?.();

  if (lastActivityType === "inspection") {
    return <InspectionPerformed width="22px" fill="red" />;
  }

  if (lastActivityType === "impact") {
    switch (incidentObject) {
      case "object":
        return <ObjectDetectionIcon style={{ marginLeft: "3px", fill: "#DD9000" }} width={"16px"} />;
      case "pedestrian":
        return <PedestrianIcon style={{ marginLeft: "3px", fill: "#DD0000" }} width={"16px"} />;
    }

    switch (impactSeverity) {
      case "high":
        return <ImpactIcon style={{ marginLeft: "3px", fill: "#DD0000" }} width={"20px"} />;
      case "medium":
        return <ImpactIcon style={{ marginLeft: "3px", fill: "#DD9000" }} width={"20px"} />;
      case "low":
        return <ImpactIcon style={{ marginLeft: "3px", fill: "#11A70B" }} width={"20px"} />;
    }

    return <ImpactIcon style={{ marginLeft: "3px", fill: "#000000" }} width={"20px"} />;
  }

  if (lastActivityType === "unauthorized") {
    return <UnauthorizedIcon style={{ marginLeft: "3px", fill: "#000000" }} width={"16px"} />;
  }
};

function AssetStatus(props) {
  const {
    history,
    isSuperAdmin,
    isSiteAdmin,
    updateActiveNavItem,
    dropdownAccountList,
    dropdownWorksiteList,
    dropdownFilterProductList,
    dropdownAssetTypeList,
    getAssetStatus,
    assetStatusList,
    dropdownFilterAccountId,
    dropdownFilterWorksiteId,
    dropdownFilterAssetTypeId,
    setDropdownAccountId,
    setDropdownWorksiteId,
    setDropdownAssetTypeId,
    setDropdownProductType,
    assetStatusTotalCount,
    assetStatusLoading,
    getDropdownAssetTypeList,
    showWorksiteDropdown,
    currentTimeZone,
  } = props;

  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  /*   const [durationSelector, setDurationSelector] = useState("today");
  const [activeAssets, setActiveAssets] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0); */
  const [currentActiveKey, setCurrentActiveKey] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  // const [totalCount, setTotalCount] = useState(100);
  const [productType, setProductType] = useState("all");
  const toggleDropdown = (eventKey) => {
    if (eventKey !== currentActiveKey) {
      setCurrentActiveKey(eventKey);
    } else {
      setCurrentActiveKey(0);
    }
  };

  const handlePageLength = (e) => {
    setPageSize(e.target.value);
  };

  const previousPage = () => {
    if (pageNo <= 1) {
      return;
    }
    setPageNo(pageNo - 1);
  };

  const nextPage = () => {
    if (pageNo > Math.ceil((assetStatusTotalCount || 0) / pageSize) - 1) {
      return;
    }
    setPageNo(pageNo + 1);
    //   let newPage = pageNo + 1;
  };

  useEffect(() => {
    getAssetStatus({ pageNo, pageSize });
    updateActiveNavItem("assetstatus");
  }, []);

  useEffect(() => {
    getAssetStatus({ pageNo, pageSize });
  }, [
    dropdownFilterAccountId,
    dropdownFilterWorksiteId,
    productType,
    dropdownFilterAssetTypeId,
    pageNo,
    pageSize,
  ]);

  useEffect(() => {
    setPageNo(1);
    setSearchText("");
  }, [dropdownFilterAccountId, dropdownFilterWorksiteId, productType, dropdownFilterAssetTypeId, pageSize]);

  /** Add all initial api calls to get data from backend */
  useEffect(() => {
    if (dropdownAssetTypeList.length === 0) {
      getDropdownAssetTypeList();
    }
  }, []);

  useEffect(() => {
    // let searchData = searchText || "all"
    getAssetStatus({ pageNo, pageSize, searchText });
    // if (searchText.length > 2) {
    //     getAssetStatus({ pageNo, pageSize, searchText })
    // }else{
    //     getAssetStatus({pageNo, pageSize, searchText: ""})
    // }
  }, [searchText]);

  return (
    <>
      {/* BreadCrumb */}
      <BreadCrumb />
      <br></br>
      {isSuperAdmin && (
        <Row>
          <Col className="text-left">
            <Button
              label={t("Error Logs")}
              onClick={() => history.push("/app/error-logs")}
              showIcon={false}
              buttonType="primary"
            />
          </Col>
        </Row>
      )}

      {/* Filters and searchbar */}
      <Row style={{ marginTop: "10px" }}>
        {isSuperAdmin && (
          <Col lg={2}>
            <DropdownFilter
              filter={{
                type: "Account",
                name: "accountName",
                key: "companyName",
                data: dropdownAccountList,
              }}
              value={dropdownFilterAccountId}
              label={t("filter")}
              handleFilter={(value) => {
                // setLocalFilters({ ...localFilters, "accountId": value })
                setDropdownAccountId(value);
              }}
            />
          </Col>
        )}
        {showWorksiteDropdown && (
          <Col lg={2}>
            <DropdownFilter
              filter={{
                type: "Worksite",
                name: "worksiteName",
                key: "name",
                data: dropdownWorksiteList,
              }}
              value={dropdownFilterWorksiteId}
              label={t("filter")}
              handleFilter={(value) => {
                // setLocalFilters({ ...localFilters, "worksiteId": value })
                setDropdownWorksiteId(value);
              }}
            />
          </Col>
        )}
        {
          <Col lg={3}>
            <DropdownFilter
              filter={{
                type: "SIERA.AI Product",
                name: "name",
                key: "name",
                data: dropdownFilterProductList,
              }}
              value={productType}
              label={t("filter")}
              handleFilter={(value) => {
                setProductType(value);
                setDropdownProductType(value);
                // setLocalFilters({ ...localFilters, "productId": value })
              }}
            />
          </Col>
        }
        {
          <Col lg={2}>
            <DropdownFilter
              filter={{
                type: "Asset Type",
                name: "_id",
                key: "AssetTypeName",
                data: dropdownAssetTypeList,
              }}
              value={dropdownFilterAssetTypeId}
              label={t("filter")}
              handleFilter={(value) => {
                // setLocalFilters({ ...localFilters, "assetTypeId": value })
                setDropdownAssetTypeId(value);
              }}
            />
          </Col>
        }

        {
          <Col lg={3}>
            <Form.Group className="selectBoxDiv hide-detail search-box-view mt-3">
              <input
                id="searchQueryInput"
                maxLength={64}
                minLength={1}
                type="text"
                name="searchQueryInput"
                placeholder={t("Search an Asset")}
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
              />
              <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
                <svg
                  style={{ width: "24px", height: "24px", transform: "rotate(84deg)" }}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#666666"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </button>
            </Form.Group>
          </Col>
        }
      </Row>

      <Row style={{ padding: "10px" }}>
        <Table borderless className="text-kanit asset-status-table">
          <thead
            style={{
              backgroundColor: "#F2F2F2",
              fontSize: "28px",
              border: "0px solid",
            }}
          >
            <tr>
              <th className="biling_head ">
                <Row className="ml-0 mr-0">
                  {tableHeader.map((data) => (
                    <>
                      {data.fieldName == "accountName" ? (
                        isSuperAdmin ? (
                          <Col lg={data.space} md={2} className="text-left m-0 p-0">
                            <Trans>{data.name}</Trans>
                          </Col>
                        ) : (
                          ""
                        )
                      ) : data.fieldName == "worksiteName" ? (
                        isSiteAdmin ? (
                          " "
                        ) : (
                          <Col lg={data.space} md={2} className="text-left m-0 p-0">
                            <Trans>{data.name}</Trans>
                          </Col>
                        )
                      ) : (
                        <Col lg={data.space} md={2} className="text-left m-0 p-0">
                          <Trans>{data.name}</Trans>
                        </Col>
                      )}
                    </>
                  ))}
                </Row>
              </th>
            </tr>
          </thead>
          <div className="billing-height  text-open-sans">
            {assetStatusLoading ? (
              <>
                {["", "", "", "", "", "", "", "", "", ""].map(() => (
                  <tr className="tableTr" style={{ cursor: "pointer" }}>
                    <td>
                      <Skeleton count={1} height={20} />
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <Accordion activeKey={currentActiveKey}>
                {assetStatusList?.length > 0 &&
                  assetStatusList.map((assetStatus) => (
                    <Card className="asset-status-card" key={assetStatus.assetId}>
                      <Accordion.Toggle
                        className="border-0"
                        as={Card}
                        eventKey={assetStatus.assetId}
                        style={{ cursor: "pointer", padding: "8px 0px", marginBottom: "-15px" }}
                        onClick={() => {
                          toggleDropdown(assetStatus.assetId);
                        }}
                      >
                        <Row className="ml-2 mr-2 asset-status-table">
                          {tableRowData.map((rowData, i) => (
                            <>
                              {/* For rendering asset status red dot */}
                              {rowData.fieldName == "accountName" ? (
                                isSuperAdmin ? (
                                  <Col
                                    lg={rowData?.space}
                                    md={rowData?.space}
                                    className="text-left m-0 p-0 text_size_bill asset-status-para"
                                  >
                                    <span style={{ width: "24px", marginRight: "5px", float: "left" }}>
                                      {rowData["isDate"]
                                        ? getLastActivityIcon(
                                            assetStatus?.lastActivityType,
                                            assetStatus["impactSeverity"],
                                            assetStatus["incidentObject"]
                                          )
                                        : ""}
                                    </span>
                                    <p className="bill_respo  mb-0">
                                      <Trans>
                                        {rowData["isDate"]
                                          ? assetStatus[rowData?.fieldName] &&
                                            assetStatus[rowData?.fieldName] !== "NA"
                                            ? `${moment(assetStatus[rowData?.fieldName])
                                                .utcOffset(assetStatus["timeZone"])
                                                .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(
                                                currentTimeZone || assetStatus["timeZone"]
                                              )}`
                                            : "NA"
                                          : rowData?.name}
                                      </Trans>
                                    </p>
                                    <h7 className="text_Repo_elips text-ellip-asset">
                                      <span
                                        title={
                                          rowData["isDate"]
                                            ? assetStatus[rowData?.fieldName] &&
                                              assetStatus[rowData?.fieldName] !== "NA"
                                              ? `${moment(assetStatus[rowData?.fieldName])
                                                  .utcOffset(assetStatus["timeZone"])
                                                  .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(
                                                  currentTimeZone || assetStatus["timeZone"]
                                                )}`
                                              : "NA"
                                            : assetStatus[rowData?.fieldName]
                                        }
                                      >
                                        {rowData["isDate"]
                                          ? assetStatus[rowData?.fieldName] &&
                                            assetStatus[rowData?.fieldName] !== "NA"
                                            ? `${moment(assetStatus[rowData?.fieldName])
                                                .utcOffset(assetStatus["timeZone"])
                                                .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(
                                                currentTimeZone || assetStatus["timeZone"]
                                              )}`
                                            : "NA"
                                          : assetStatus[rowData?.fieldName]}
                                      </span>
                                    </h7>
                                  </Col>
                                ) : (
                                  ""
                                )
                              ) : rowData.fieldName == "worksiteName" ? (
                                isSiteAdmin ? (
                                  " "
                                ) : (
                                  <Col
                                    lg={rowData?.space}
                                    md={rowData?.space}
                                    className="text-left m-0 p-0 text_size_bill asset-status-para"
                                  >
                                    <span style={{ width: "24px", marginRight: "5px", float: "left" }}>
                                      {rowData["isDate"]
                                        ? getLastActivityIcon(
                                            assetStatus?.lastActivityType,
                                            assetStatus["impactSeverity"],
                                            assetStatus["incidentObject"]
                                          )
                                        : ""}
                                    </span>
                                    <p className="bill_respo  mb-0">
                                      <Trans>
                                        {rowData["isDate"]
                                          ? assetStatus[rowData?.fieldName] &&
                                            assetStatus[rowData?.fieldName] !== "NA"
                                            ? `${moment(assetStatus[rowData?.fieldName])
                                                .utcOffset(assetStatus["timeZone"])
                                                .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(
                                                currentTimeZone || assetStatus["timeZone"]
                                              )}`
                                            : "NA"
                                          : rowData?.name}
                                      </Trans>
                                    </p>
                                    <h7 className="text_Repo_elips text-ellip-asset">
                                      <span
                                        title={
                                          rowData["isDate"]
                                            ? assetStatus[rowData?.fieldName] &&
                                              assetStatus[rowData?.fieldName] !== "NA"
                                              ? `${moment(assetStatus[rowData?.fieldName])
                                                  .utcOffset(assetStatus["timeZone"])
                                                  .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(
                                                  currentTimeZone || assetStatus["timeZone"]
                                                )}`
                                              : "NA"
                                            : assetStatus[rowData?.fieldName]
                                        }
                                      >
                                        {rowData["isDate"]
                                          ? assetStatus[rowData?.fieldName] &&
                                            assetStatus[rowData?.fieldName] !== "NA"
                                            ? `${moment(assetStatus[rowData?.fieldName])
                                                .utcOffset(assetStatus["timeZone"])
                                                .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(
                                                currentTimeZone || assetStatus["timeZone"]
                                              )}`
                                            : "NA"
                                          : assetStatus[rowData?.fieldName]}
                                      </span>
                                    </h7>
                                  </Col>
                                )
                              ) : rowData?.fieldName === "assetStatus" ? (
                                <>
                                  <Col
                                    lg={1}
                                    md={1}
                                    className="text-left m-0 p-0 text_size_bill asset-status-span"
                                  >
                                    <span>Status</span>
                                    <p>
                                      {assetStatus["status"] ? (
                                        <Dot style={{ height: "11px" }} className="mr-2" fill={"#8bc34a"} />
                                      ) : (
                                        <Dot style={{ height: "11px" }} className="mr-2" fill={"red"} />
                                      )}
                                    </p>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col
                                    lg={rowData?.space}
                                    md={rowData?.space}
                                    className="text-left m-0 p-0 text_size_bill"
                                  >
                                    <span style={{ width: "24px", marginRight: "5px", float: "left" }}>
                                      {rowData["isDate"]
                                        ? getLastActivityIcon(
                                            assetStatus["lastActivityType"],
                                            assetStatus["impactSeverity"],
                                            assetStatus["incidentObject"]
                                          )
                                        : ""}
                                    </span>
                                    <p className="bill_respo mb-0">
                                      <Trans>
                                        {rowData["isDate"]
                                          ? assetStatus[rowData?.fieldName] &&
                                            assetStatus[rowData?.fieldName] !== "NA"
                                            ? `${rowData?.name}`
                                            : "NA"
                                          : rowData?.name}
                                      </Trans>
                                    </p>
                                    <h7 className="text_Repo_elips text-ellip-asset">
                                      <span
                                        className="last-activity-icon-m"
                                        style={{ width: "24px", marginRight: "5px", float: "left" }}
                                      >
                                        {rowData["isDate"]
                                          ? getLastActivityIcon(
                                              assetStatus["lastActivityType"],
                                              assetStatus["impactSeverity"],
                                              assetStatus["incidentObject"]
                                            )
                                          : ""}
                                      </span>
                                      <span
                                        title={
                                          rowData["isDate"]
                                            ? assetStatus[rowData?.fieldName] &&
                                              assetStatus[rowData?.fieldName] !== "NA"
                                              ? `${moment(assetStatus[rowData?.fieldName])
                                                  .utcOffset(assetStatus["timeZone"])
                                                  .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(
                                                  currentTimeZone || assetStatus["timeZone"]
                                                )}`
                                              : "NA"
                                            : assetStatus[rowData?.fieldName]
                                        }
                                      >
                                        {rowData["isDate"]
                                          ? assetStatus[rowData?.fieldName] &&
                                            assetStatus[rowData?.fieldName] !== "NA"
                                            ? `${moment(assetStatus[rowData?.fieldName])
                                                .utcOffset(assetStatus["timeZone"])
                                                .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(
                                                currentTimeZone || assetStatus["timeZone"]
                                              )}`
                                            : "NA"
                                          : assetStatus[rowData?.fieldName]}
                                      </span>
                                    </h7>

                                    {i === tableRowData?.length - 1 ? (
                                      currentActiveKey === assetStatus.assetId ? (
                                        <span style={{ float: "right" }}>
                                          <ExpandMoreIcon />
                                        </span>
                                      ) : (
                                        <span
                                          className="asset-status-arrow asset-status-span"
                                          style={{ float: "right" }}
                                        >
                                          <ExpandLessIcon />
                                        </span>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </>
                              )}
                            </>
                          ))}
                          {/* Expand more row option */}
                          <Col lg={1} md={1} className="text-right m-0 p-0 text_size_bill">
                            {}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={assetStatus.assetId}>
                        <Card.Body style={{ backgroundColor: "#F6F6F6" }} className="border-0 pl-4 pr-4 pb-4">
                          <Row>
                            {tableRowDetailData.map((rowData, i) => (
                              <>
                                {assetStatus[rowData?.fieldName] !== undefined && (
                                  <Col lg={3} md={3} className="text-left m-0 p-2 text_size_bill">
                                    <p className="m-0 p-0">{rowData?.label}</p>
                                    <p className="m-0 p-2" style={{ background: "#fff" }}>
                                      {rowData["isDate"]
                                        ? assetStatus[rowData?.fieldName] !== "NA"
                                          ? `${moment(assetStatus[rowData?.fieldName])
                                              .utcOffset(assetStatus["timeZone"])
                                              .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(
                                              currentTimeZone || assetStatus["timeZone"]
                                            )}`
                                          : "NA"
                                        : assetStatus[rowData?.fieldName]}
                                    </p>
                                  </Col>
                                )}
                              </>
                            ))}
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                {assetStatusList?.length == 0 && (
                  <p style={{ margin: "10px" }}>
                    <Trans>No matching records found</Trans>
                  </p>
                )}
              </Accordion>
            )}
          </div>
          <hr className="m-0 p-0" />
          <div className="text-center pagina_Report">
            <div className="pageRow">
              <Form.Group className="selectBoxDiv" onChange={(e) => handlePageLength(e)}>
                <Form.Label>
                  <Trans>Rows Per Page</Trans> :{" "}
                </Form.Label>
                <Form.Control as="select" value={pageSize} className="selectBox">
                  {[
                    { name: 10, value: 10 },
                    { name: 25, value: 25 },
                    { name: 50, value: 50 },
                    { name: 100, value: 100 },
                  ].map((option) => (
                    <option key={option.name} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="pageRow_Num">
              <p className="text-muted">
                <Trans>Showing</Trans>
                {` ${pageSize * (pageNo - 1) || 0} - ${
                  parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < assetStatusTotalCount
                    ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
                    : assetStatusTotalCount || 0
                } `}{" "}
                <Trans>of</Trans> {assetStatusTotalCount || 0}{" "}
              </p>
            </div>
            <div className="pageRow_Arrow">
              <ArrowLeft
                onClick={() => previousPage()}
                style={{
                  cursor: "pointer",
                  width: 20,
                  marginRight: 10,
                  transform: "rotate(180deg)",
                }}
                fill="#0d5fbe"
              />
              <ArrowRight
                onClick={() => nextPage()}
                style={{ cursor: "pointer", width: 20, marginRight: 10 }}
                fill="#0d5fbe"
              />
            </div>
          </div>
        </Table>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isSuperAdmin: state.user.isSuperAdmin,
    isSiteAdmin: state.user.isSiteAdmin,
    dropdownFilterAccountId: state.dropdownFilters.accountId,
    dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
    dropdownFilterAssetTypeId: state.dropdownFilters.assetTypeId,
    dropdownAccountList: state.dropdownFilters.accountList,
    dropdownWorksiteList: state.dropdownFilters.worksiteList,
    dropdownFilterProductList: state.dropdownFilters.productList,
    dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
    assetStatusList: state.assetStatus.assetStatusList,
    assetStatusTotalCount: state.assetStatus.assetStatusCount,
    assetStatusLoading: state.assetStatus.assetStatusLoading,
    showWorksiteDropdown: state.user.showWorksiteDropdown,
    currentTimeZone: state.settings.currentTimeZone,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  getAssetStatus: (data) => dispatch(getAssetStatus(data)),
  setDropdownAccountId: (data) => dispatch(setDropdownAccountId(data)),
  setDropdownWorksiteId: (data) => dispatch(setDropdownWorksiteId(data)),
  setDropdownAssetTypeId: (data) => dispatch(setDropdownAssetTypeId(data)),
  setDropdownProductType: (data) => dispatch(setDropdownProductType(data)),
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetStatus);
