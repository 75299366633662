//TODO - Clean up, Refactor,  Optimize this file when time permits and remove this comment afterwards

import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Trans } from "react-i18next";
import handleViewport from "react-in-viewport";
import { connect } from "react-redux";
//import "react-select-search/style.css";
import Skeleton from "react-loading-skeleton";

import { ReactComponent as LockIcon } from "../assets/icons/Locked.svg";
import { ReactComponent as UnLockedIcon } from "../assets/icons/Unlocked.svg";
import {
  ManagementLeftSidebarMenu,
  CheckboxDiv,
  Tab,
  StyledTable,
  StyledThead,
  StyledTr,
  StyledTd,
  StyledTbody,
  StyledTh,
  StyledH5,
  PStyled,
  DotStyled,
  TableTr,
  SpanStyle,
  InspectButtonWrapper,
  EmptyListStyle,
  ButtonEditTemplate,
} from "./List.component.styles";
import PermissionCheck from "./PermissionCheck/PermissionCheck";

const listType = require("../constant/listType");

const icons = {
  Lock: LockIcon,
  Unlock: UnLockedIcon,
};

const Block = (props) => {
  const { forwardedRef } = props;
  return (
    <div className="viewport-block p-2" ref={forwardedRef}>
      <Skeleton count={1} />
    </div>
  );
};

const ViewportBlock = handleViewport(Block /** options: {}, config: {} **/);

function List({
  listDetail,
  listData,
  selectItem,
  handleInspect,
  selectedId,
  loadData,
  totalCount,
  dropdownAccountId,
  dropdownWorksiteId,
  addAssetModal,
  addSiteModal,
  addUserModal,
  searchKey,
  dropdownAssetTypeId,
  dropdownAccessLevel,
  dataLoading,
  updateEditTemplate,
  iconSpace,
  showLockKey,
  iconContainerClass,
  falseIconContainerClass,
  trueIconContainerClass,
  boolIconList,
  onIconClick,
  hideHeader,
  isSuperAdmin,
  showActiveInactiveTabs,
}) {
  const [loaderInView, setLoaderInView] = useState(false);
  const [page, setPage] = useState(1);
  const [isListEnd, setIsListEnd] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const TrueIconComponent = icons[boolIconList?.[0]];
  const FalseIconComponent = icons[boolIconList?.[1]];

  const [activeTab, setActiveTab] = useState(() => {
    const storedActiveTab = localStorage.getItem(`activeTab_${listDetail.listType}`);
    return JSON.parse(storedActiveTab) || 1;
  });

  useEffect(() => {
    //save the selected tab in the local storage
    localStorage.setItem(`activeTab_${listDetail.listType}`, JSON.stringify(activeTab));
  }, [activeTab, listDetail?.listType]);

  const onScrollList = () => {
    if (listData?.length >= totalCount && totalCount !== 0) {
      setIsListEnd(true);
      return;
    } else {
      setIsListEnd(false);
    }
    let newPage = page + 1;
    setPage(newPage);
    loadData({ pageNo: page + 1, pageSize: 15, searchKey: searchKey }, false);
  };

  useEffect(() => {
    if (listData && listData[0] && firstLoad) {
      if (window.screen.width > 1100) {
        selectItem(listData[0]._id);
      }
      setFirstLoad(false);
    }
    if (listData?.length > 0) {
      setLoaderInView(true);
    }
    if (listData?.length === totalCount) {
      setIsListEnd(true);
    } else {
      setIsListEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData]);

  useEffect(() => {
    if (listData?.length === totalCount) {
      setIsListEnd(true);
    } else {
      setIsListEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!addAssetModal && !addSiteModal && !addUserModal) {
      loadData({ pageNo: 1, pageSize: 15, searchKey: searchKey }, true);
      setPage(1);
    }
    setLoaderInView(false);
    setFirstLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAccountId, dropdownWorksiteId, dropdownAssetTypeId, dropdownAccessLevel, searchKey]);

  const getFilteredData = () => {
    if (
      listDetail.listType === listType.USERS ||
      listDetail.listType === listType.WORKSITE ||
      listDetail.listType === listType.ASSET ||
      listDetail.listType === listType.USER_TYPE
    ) {
      if (activeTab === 2) {
        return listData?.filter((item) => {
          return item.status === true || item.AssetStatus === true || item.isActive === true;
        });
      } else if (activeTab === 3) {
        return listData?.filter((item) => {
          return item.status === false || item.AssetStatus === false || item.isActive === false;
        });
      } else {
        return listData;
      }
    } else {
      return listData;
    }
  };

  const filteredListData = getFilteredData();
  return (
    <ManagementLeftSidebarMenu>
      {showActiveInactiveTabs && (
        <CheckboxDiv>
          <Tab onClick={() => setActiveTab(1)} isActive={activeTab === 1}>
            <Trans>All</Trans>
          </Tab>
          <Tab onClick={() => setActiveTab(2)} isActive={activeTab === 2}>
            <Trans>Active</Trans>
          </Tab>
          <Tab onClick={() => setActiveTab(3)} isActive={activeTab === 3}>
            <Trans>Inactive</Trans>
          </Tab>
        </CheckboxDiv>
      )}
      <StyledTable hover>
        {!hideHeader && (
          <StyledThead>
            <StyledTr>
              <StyledTh>
                <StyledH5>
                  <Trans>{listDetail.label}</Trans>
                </StyledH5>
              </StyledTh>
            </StyledTr>
          </StyledThead>
        )}
        <StyledTbody
          heightOffset={listDetail?.heightOffset || 336}
          //  className={`${dataLoading && !loaderInView && page === 1 ? "loader-list" : "tableHeight"} ""`}
        >
          <>
            {dataLoading &&
              !loaderInView &&
              page === 1 &&
              ["", "", "", "", "", "", "", "", "", "", ""].map(() => (
                <StyledTr>
                  <StyledTd>
                    <Skeleton count={1} />
                  </StyledTd>
                </StyledTr>
              ))}
            {(!loaderInView ? !dataLoading : true) &&
              filteredListData &&
              filteredListData.length > 0 &&
              filteredListData.map((item, i) => {
                return (
                  <TableTr onClick={() => selectItem(item._id)} key={i}>
                    <StyledTd className={item._id === selectedId && "active-list-item"}>
                      {listType.ASSET_TYPE !== listDetail.listType &&
                        listType.CHECKLIST !== listDetail.listType &&
                        listType.CHECKLIST_TEMPLATE !== listDetail.listType && (
                          <DotStyled
                            isActive={item.AssetStatus || item.status || item.isActive}
                            className="icon icon-dot"
                          />
                        )}
                      <PStyled className={`button-03 ellipsisCSS ${item._id === selectedId && "fontBold"}`}>
                        {iconSpace && (
                          <PermissionCheck
                            section={"DASHBOARD"}
                            permissionName={"ASSET_MANAGEMENT"}
                            actionName={"edit"}
                          >
                            <span
                              className={`icon-space ${iconContainerClass} ${
                                !item[showLockKey] ? falseIconContainerClass : trueIconContainerClass
                              }`}
                            >
                              {(item.AssetStatus || item.status || item.isActive) && (
                                <span onClick={() => onIconClick(item)}>
                                  {item[showLockKey] ? (
                                    <TrueIconComponent className="icon" />
                                  ) : (
                                    <FalseIconComponent className="icon" />
                                  )}
                                </span>
                              )}
                            </span>
                          </PermissionCheck>
                        )}
                        <SpanStyle title={listType.USERS === listDetail.listType && item.fullName}>
                          {listType.USERS === listDetail.listType && item.fullName}
                        </SpanStyle>
                        <SpanStyle title={listType.WORKSITE === listDetail.listType && item.name}>
                          {listType.WORKSITE === listDetail.listType && item.name}
                        </SpanStyle>
                        <SpanStyle title={listType.ASSET === listDetail.listType && item.assetName}>
                          {listType.ASSET === listDetail.listType && item.assetName}
                        </SpanStyle>
                        <SpanStyle title={listType.ASSET_TYPE === listDetail.listType && item.AssetTypeName}>
                          {listType.ASSET_TYPE === listDetail.listType && item.AssetTypeName}
                        </SpanStyle>
                        <SpanStyle title={listType.USER_TYPE === listDetail.listType && item.name}>
                          {listType.USER_TYPE === listDetail.listType && item.name}
                        </SpanStyle>
                        <SpanStyle
                          title={
                            listType.ACCOUNT === listDetail.listType &&
                            (item.companyName ? item.companyName : item.email)
                          }
                        >
                          {listType.ACCOUNT === listDetail.listType &&
                            (item.companyName ? item.companyName : item.email)}
                        </SpanStyle>
                        <SpanStyle title={listType.CHECKLIST === listDetail.listType && item.checklistName}>
                          {listType.CHECKLIST === listDetail.listType && item.checklistName}
                        </SpanStyle>
                        <SpanStyle
                          title={
                            listType.CHECKLIST_TEMPLATE === listDetail.listType && item.checklistTemplateName
                          }
                        >
                          {listType.CHECKLIST_TEMPLATE === listDetail.listType && item.checklistTemplateName}
                        </SpanStyle>
                      </PStyled>

                      <PermissionCheck section="DASHBOARD" permissionName={"INSPECT_ASSET"} actionName="view">
                        {listType.ASSET === listDetail.listType && !isSuperAdmin && (
                          <>
                            {item.assetChecklists && item.assetChecklists.length === 0 && item.AssetStatus ? (
                              <>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      <Trans>Please assign a checklist to this asset</Trans>
                                    </Tooltip>
                                  }
                                >
                                  <InspectButtonWrapper>
                                    <Button disabled>
                                      <Trans>Inspect</Trans>
                                    </Button>
                                  </InspectButtonWrapper>
                                </OverlayTrigger>
                              </>
                            ) : (
                              <InspectButtonWrapper>
                                <Button
                                  className={`${
                                    (item.assetChecklists && item.assetChecklists.length === 0) ||
                                    !item.AssetStatus
                                      ? "buttonInactive"
                                      : "buttonActive"
                                  } float-right`}
                                  onClick={() => handleInspect(item)}
                                  disabled={
                                    (item.assetChecklists && item.assetChecklists.length === 0) ||
                                    !item.AssetStatus ||
                                    item[showLockKey]
                                  }
                                >
                                  <Trans>Inspect</Trans>
                                </Button>
                              </InspectButtonWrapper>
                            )}
                          </>
                        )}
                        {updateEditTemplate && (
                          <ButtonEditTemplate
                            className="icon icon-copy"
                            onClick={() => updateEditTemplate(item._id)}
                          ></ButtonEditTemplate>
                        )}
                      </PermissionCheck>
                    </StyledTd>
                  </TableTr>
                );
              })}
            {/* Below code can be changed to add customized message for the filtered result */}
            {listData?.length > 0 && filteredListData?.length <= 0 && (
              <EmptyListStyle className="label-01-01">{listDetail.emptyListText}</EmptyListStyle>
            )}

            {listData && !dataLoading && listData?.length <= 0 && (
              <EmptyListStyle className="label-01-01">{listDetail.emptyListText}</EmptyListStyle>
            )}
            {listData && listData?.length > 0 && loaderInView && !isListEnd && totalCount !== 0 && (
              <ViewportBlock
                onEnterViewport={() => onScrollList()}
                onLeaveViewport={() => console.log("leave")}
              />
            )}
          </>
        </StyledTbody>
      </StyledTable>
    </ManagementLeftSidebarMenu>
  );
}

const mapStateToProps = (state) => ({
  dropdownAccountId: state.dropdownFilters.accountId,
  dropdownWorksiteId: state.dropdownFilters.worksiteId,
  dropdownAssetTypeId: state.dropdownFilters.assetTypeId,
  dropdownAccessLevel: state.dropdownFilters.dropdownUserAccessLevel,
  addAssetModal: state.modals.addAssetModal,
  addSiteModal: state.modals.addSiteModal,
  addUserModal: state.modals.addUserModal,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(List);
