import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, MobileToggleButton, Content } from "./MoreFilterButton.component.styles";
import Button from "../Button/Button.component";
import styled from "styled-components";

const MoreFilterMobileToggle = ({ children }) => {
  const [isDivVisible, setIsDivVisible] = useState(true);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

  return (
    <Container>
      <MobileToggleButton>
        <Button
          iconClass="filter"
          label="More Filter"
          onClick={toggleDivVisibility}
          showIcon
          buttonType="secondary"
        />
      </MobileToggleButton>
      <Content $isVisible={isDivVisible}>{children}</Content>
    </Container>
  );
};

MoreFilterMobileToggle.propTypes = {
  /**
   * Content to be displayed inside the component
   */
  children: PropTypes.node.isRequired,
};

export default MoreFilterMobileToggle;
