import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, Redirect, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import S3Setting from "../S3Setting/S3Setting.component";
import NotificationSetting from "../Notification/NotificationSetting.component";
import InspectionAlertSettings from "../Notification/InspectionAlertSettings.component";
import ImpactAlertSettings from "../Notification/ImpactAlertSettings.component";
import NearMissAlertSettings from "../Notification/NearMissAlertSettings.component";
import UnauthorizedAlertSetting from "../Notification/UnauthorizedAlertSetting.component";
import AccountSummarySettings from "../Notification/AccountSummarySettings.component";
import WorksiteSummarySettings from "../Notification/WorksiteSummarySettings.component";
import OperatorCertificationExpirySettings from "../Notification/OperatorCertificationExpirySettings.component";
import AccessLockoutSettings from "../AssetLockoutSettings/AccessLockoutSettings.component";
import {
  TabWrapper,
  Container,
  DropdownWrapper,
  MobileViewDropdown,
  ButtonWrapper,
  ContainerDiv,
  ContentWrapper,
  LeftSection,
  RightSection,
} from "./SystemSetting.component.styles";
import MenuBar from "./MenuBar.component";
import { getDropdownAccountList } from "../../../../redux/dropdownFilters/action";
import { setSettingsDropdownAccountId } from "../../../../redux/dropdownFilters/dropdownActions";
import ImpactThresholdComponent from "../S2Settings/ImpactThreshold.component";
import ReportSettings from "../S2Settings/ReportSettings.component";
import AutoLogoutSettings from "../S2Settings/AutoLogoutSettings.component";
import Input from "../../../../components/Input";

function AccountDropdown({ dropdownAccountList, dropdownAccountId, setSettingsDropdownAccountId }) {
  return (
    <DropdownWrapper>
      <label
        htmlFor="Account"
        style={{
          fontSize: "12px",
          color: "rgb(154, 154, 154)",
          fontFamily: "'Open Sans', sans-serif",
          marginBottom: "1px",
          textAlign: "left",
        }}
      >
        Account Name
      </label>
      <Input
        options={dropdownAccountList?.map((data) => ({
          _id: data._id,
          label: data.companyName,
        }))}
        isValidationError={false}
        type="select"
        as="select"
        label="Account"
        value={dropdownAccountId}
        onChange={(value) => {
          setSettingsDropdownAccountId(value);
        }}
      />
    </DropdownWrapper>
  );
}

function CustomizedTabs({
  updateActiveNavItem,
  isSiteAdmin,
  accountListLoading,
  dropdownAccountList,
  loginDetails,
  isSuperAdmin,
  isAccountAdmin,
  isAccountOwner,
  setDropdownAccountId,
  dropdownAccountId,
  setSettingsDropdownAccountId,
  getDropdownAccountList,
  permissions,
}) {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  useEffect(() => {
    updateActiveNavItem("systemSettings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setSettingsDropdownAccountId(loginDetails && loginDetails.accountId);
    }
  }, [loginDetails]);

  useEffect(() => {
    if (!dropdownAccountList || dropdownAccountList?.length === 0) {
      getDropdownAccountList();
    }
  }, []);

  /*   useEffect(() => {
    if (isSuperAdmin && (dropdownAccountId === "all" || !dropdownAccountId)) {
      setSettingsDropdownAccountId(dropdownAccountList?.[0] && dropdownAccountList?.[0]?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAccountList]); */

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className="settings">
      <BreadCrumb />

      <ContainerDiv>
        <ContentWrapper isSidebarVisible={isSidebarVisible}>
          <LeftSection className="sidebar-col" isSidebarVisible={isSidebarVisible}>
            <MobileViewDropdown isSidebarVisible={isSidebarVisible}>
              {isSidebarVisible || (
                <TabWrapper>
                  {isSuperAdmin && (
                    <AccountDropdown
                      dropdownAccountList={dropdownAccountList}
                      dropdownAccountId={dropdownAccountId}
                      setSettingsDropdownAccountId={setSettingsDropdownAccountId}
                    />
                  )}
                </TabWrapper>
              )}
            </MobileViewDropdown>

            {isSidebarVisible && (
              <TabWrapper>
                {isSuperAdmin && (
                  <>
                    <AccountDropdown
                      dropdownAccountList={dropdownAccountList}
                      dropdownAccountId={dropdownAccountId}
                      setSettingsDropdownAccountId={setSettingsDropdownAccountId}
                    />
                  </>
                )}

                <MenuBar history={history} />
              </TabWrapper>
            )}
          </LeftSection>
          <RightSection isSidebarVisible={isSidebarVisible}>
            <ButtonWrapper className="absolute right-4" onClick={toggleSidebar}>
              {isSidebarVisible ? (
                <OverlayTrigger placement="top" overlay={<Tooltip id="expand-tooltip">Expand View</Tooltip>}>
                  <i className="icon icon-fullscreen-expand"></i>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="collapse-tooltip">Collapse View</Tooltip>}
                >
                  <i className="icon icon-fullscreen-collapse"></i>
                </OverlayTrigger>
              )}
            </ButtonWrapper>
            <Switch>
              <Route path={`${match.url}/impact-threshold-settings`} component={ImpactThresholdComponent} />
              <Route path={`${match.url}/report-settings`} component={ReportSettings} />
              <Route path={`${match.url}/auto-logout-settings`} component={AutoLogoutSettings} />
              <Route path={`${match.url}/s3-settings`} component={S3Setting} />
              <Route path={`${match.url}/asset-lockout-settings`} component={AccessLockoutSettings} />
              <Route path={`${match.url}/notification-settings`} component={NotificationSetting} />

              <Route path={`${match.url}/inspection-alert-settings`} component={InspectionAlertSettings} />
              <Route path={`${match.url}/impact-alert-settings`} component={ImpactAlertSettings} />
              <Route path={`${match.url}/near-miss-alert-settings`} component={NearMissAlertSettings} />

              <Route
                path={`${match.url}/unauthorized-access-alert-settings`}
                component={UnauthorizedAlertSetting}
              />
              <Route
                path={`${match.url}/account-summary-alert-settings`}
                component={AccountSummarySettings}
              />
              <Route
                path={`${match.url}/worksite-summary-alert-settings`}
                component={WorksiteSummarySettings}
              />
              <Route
                path={`${match.url}/operator-certification-expiration-alert-settings`}
                component={OperatorCertificationExpirySettings}
              />
              <Redirect to={`${match.url}/impact-threshold-settings`} />
            </Switch>
          </RightSection>
        </ContentWrapper>
      </ContainerDiv>

      <style jsx>{`
        @media (max-width: 768px) {
          .sidebar-col {
            width: 100% !important;
          }
          .content-col {
            width: 100% !important;
          }
        }
      `}</style>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSiteAdmin: state.user.isSiteAdmin,
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  accountListLoading: state.dropdownFilters?.settings?.accountListLoading,
  dropdownAccountList: state.dropdownFilters?.accountList,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomizedTabs));
