import React, { useState, useRef, useEffect } from "react";
import {
  FileUploaderWrapper,
  FileUploaderDiv,
  HeaderLabel,
  FileUploaderCard,
  DragIconBGComponent,
  ErrorSet,
  TableContainerDiv,
  RowDivFile,
  ColumnFile,
  RowsContainerFile,
  DeleteIconDiv,
} from "../BulkUploadComponents/FileUploadCSV.component.styles";

import { Trans, useTranslation } from "react-i18next";

import { Col, Table, Card } from "react-bootstrap";

import { ReactComponent as FileUploadIcon } from "../../assets/fileuploadicon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/AddIcon/Delete Button.svg";
import validationConstant from "../../constant/messages/validation.json";

function FileUploadWithDragAndDrop({ label, csvFile, setcsvFile, isCSVFileError }) {
  const { t } = useTranslation();
  const [noFileCsvErrorMsg, setnoFileCsvErrorMsg] = useState("");
  const [dragText, setDragText] = useState(t("Please upload .CSV file"));
  const csvFileUploadInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText(t("Drop your file here, or browse"));
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          // uploadedFile.push(file)
          const str = file?.name;
          const compareStr = str.slice(str.length - 4, str.length);
          if (compareStr == ".csv") {
            setcsvFile(file);
            setnoFileCsvErrorMsg("");
          } else {
            setcsvFile(null);
            setnoFileCsvErrorMsg(validationConstant.invalid_csv_file);
          }
          // setcsvFile(file)
          // setUploadedFile(uploadedFile)
          console.log("... file[" + i + "].name = " + file.name);
          // console.log(uploadedFile[0], '=====');
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        console.log("... file[" + i + "].name = " + e.dataTransfer.files[i].name);
      }
    }
  };

  const handlecsvFileUpload = (e) => {
    console.log(e?.target?.files[0]?.name);
    const str = e?.target?.files[0]?.name;
    const compareStr = str.slice(str.length - 4, str.length);
    if (compareStr == ".csv") {
      setcsvFile(e.target.files[0]);
      setnoFileCsvErrorMsg("");
    } else {
      setcsvFile(null);
      setnoFileCsvErrorMsg(validationConstant.invalid_csv_file);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragText(t("Drop Here..."));
  };

  useEffect(() => {
    if (isCSVFileError) {
      setnoFileCsvErrorMsg(validationConstant.invalid_csv_file);
    }
  }, [isCSVFileError]);

  return (
    <>
      <FileUploaderWrapper>
        <FileUploaderDiv>
          <HeaderLabel>
            <Trans>{label}</Trans>
          </HeaderLabel>

          <FileUploaderCard
            onDrop={(e) => handleDrop(e)}
            onDragLeave={() => setDragText(t("Drop your file here, or browse"))}
            onDragOver={(e) => handleDrag(e)}
            className={noFileCsvErrorMsg === "" ? "borderBlue" : "borderRed"}
          >
            <span className="containerSpan">
              <label className="labelContainer">
                <input
                  ref={csvFileUploadInputRef}
                  onChange={(e) => handlecsvFileUpload(e)}
                  type="file"
                  accept=".csv"
                  className="d-none"
                  name="uploadFile"
                />
                <div className="icon icon-file-upload-csv pngLogoIcon"></div>
              </label>
            </span>

            <span className="dropSpan">
              <Trans i18nKey="dropFileInstruction">
                Drop your file here, or{" "}
                <span onClick={() => csvFileUploadInputRef?.current?.click()}>browse</span>
              </Trans>
            </span>
            <span className="dragTextFont">
              <Trans>{dragText}</Trans>
            </span>
            {/* <DragIconBGComponent
              className="hoverIcon"
              onClick={() => csvFileUploadInputRef?.current?.click()}
            >
              <div className="icon icon-add"></div>
            </DragIconBGComponent> */}
          </FileUploaderCard>
          <ErrorSet>{noFileCsvErrorMsg}</ErrorSet>
        </FileUploaderDiv>

        <FileUploaderDiv>
          <TableContainerDiv>
            <RowDivFile isHeader={true}>
              <ColumnFile isHeader={true} align={"left"}>
                <Trans>Document</Trans>
              </ColumnFile>
              <ColumnFile isHeader={true} align={"center"}>
                <Trans>Status</Trans>
              </ColumnFile>
              <ColumnFile isHeader={true} align={"center"}>
                <Trans>Actions</Trans>
              </ColumnFile>
            </RowDivFile>
            <RowsContainerFile>
              <RowDivFile>
                {csvFile ? (
                  <>
                    <ColumnFile
                      align={"left"}
                      textleft={"left"}
                      widthResp={"75%"}
                      floatResp={"left"}
                      RmarginResp={"5%"}
                    >
                      <Trans>{csvFile.name}</Trans>
                    </ColumnFile>
                    <ColumnFile
                      align={"center"}
                      textleft={"center"}
                      widthResp={"75%"}
                      floatResp={"left"}
                      RmarginResp={"5%"}
                    >
                      <Trans>Selected</Trans>
                    </ColumnFile>

                    <ColumnFile
                      align={"left"}
                      textleft={"left"}
                      widthResp={"75%"}
                      floatResp={"left"}
                      RmarginResp={"5%"}
                    >
                      <div
                        onClick={() => {
                          setcsvFile(null);
                          csvFileUploadInputRef.current.value = null;
                          setnoFileCsvErrorMsg(t("Please upload a valid csv file"));
                        }}
                        className="text-center"
                        style={{ cursor: "pointer" }}
                      >
                        <DeleteIconDiv className="icon icon-delete" />
                      </div>
                    </ColumnFile>
                  </>
                ) : (
                  ""
                )}
              </RowDivFile>
            </RowsContainerFile>
          </TableContainerDiv>
        </FileUploaderDiv>
      </FileUploaderWrapper>
    </>
  );
}

export default FileUploadWithDragAndDrop;
