//Cleanup this file when time permits and remove this comment

import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import ChecklistOverviewTab from "../ChecklistOverviewTab.component";
import { setDropdownAccountId } from "../../../../redux/dropdownFilters/action";
import { setAddChecklistModal } from "../../../../redux/modals/action";
import { setUpdateEditTemplate } from "../../../../redux/checklists/action";
import DetailsDescriptions from "../../../../components/DetailsDescriptions/DetailsDescriptions.component";
// Button Component
import Button from "../../../../components/Button/Button.component";

import {
  ViewChecklistDiv,
  CardMainContainer,
  CardBodyContainer,
  TopHeaderDetailsDiv,
  ChecklistDropdown,
  ContainerEmpty,
  TextMessage,
  DesktopMessage,
  MobileMessage,
  ButtonDivEdit,
  ChecklistDescEditView,
} from "../ChecklistOverview.component.styles";

import { ChecklistTabDiv } from "../ChecklistOverviewTab.styles";

function ChecklistList({
  history,
  isSuperAdmin,
  checklistTemplateOverview,
  setUpdateEditTemplate,
  setAddChecklistModal,
  currentTimeZone,
}) {
  const { t } = useTranslation();
  const updateEditTemplate = () => {
    if (!checklistTemplateOverview) {
      return;
    }
    let newChecklist = {
      checklistName: checklistTemplateOverview.checklistTemplateName,
      checklistDescription: checklistTemplateOverview.checklistTemplateDescription,
      sections: {},
    };
    let newData = checklistTemplateOverview?.sections.map((section, i) => {
      newChecklist.sections[i] = { ...section, questions: {} };
      let sectionData = section?.questions.map((question, j) => {
        newChecklist.sections[i].questions[j] = {
          ...question,
          failedResponse: question?.failedResponse?.trim(),
          responseTypeId: question?.responseTypeId?._id,
          controlType: question?.responseTypeId?.controlType,
          failedResponseText: question?.responseTypeId?.responseText,
        };
      });
    });
    setUpdateEditTemplate(newChecklist);
    history.push("/app/checklistmanagement");
    setAddChecklistModal(true);
  };
  const timeZone = currentTimeZone || checklistTemplateOverview?.createdAt || "-06:00 Central America";
  return (
    <ViewChecklistDiv>
      <ChecklistDescEditView>
        <CardMainContainer>
          {checklistTemplateOverview ? (
            <CardBodyContainer>
              <TopHeaderDetailsDiv>
                <DetailsDescriptions
                  title={checklistTemplateOverview ? checklistTemplateOverview.checklistTemplateName : ""}
                  details={
                    checklistTemplateOverview ? checklistTemplateOverview.checklistTemplateDescription : ""
                  }
                  dataTitle={t("Added On")}
                  date={moment(checklistTemplateOverview?.createdAt)
                    .utcOffset(timeZone?.split?.(" ")[0])
                    .format(" MMMM DD YYYY")}
                />
              </TopHeaderDetailsDiv>
              <ChecklistTabDiv isSuperAdmin={isSuperAdmin}>
                <ChecklistOverviewTab checklistOverview={checklistTemplateOverview} />
              </ChecklistTabDiv>
              <ButtonDivEdit>
                <Button
                  iconClass="copy"
                  label={t("Use Template")}
                  onClick={() => updateEditTemplate()}
                  showIcon
                  buttonType="primary"
                />
              </ButtonDivEdit>
            </CardBodyContainer>
          ) : (
            <ContainerEmpty>
              <TextMessage className="label-01-01">
                <DesktopMessage>
                  <Trans>There is no checklist in the worksite right now</Trans>
                  <br></br>
                  <Trans>Click '+ ADD CHECKLIST' to add a checklist</Trans>
                </DesktopMessage>
              </TextMessage>
            </ContainerEmpty>
          )}
        </CardMainContainer>
      </ChecklistDescEditView>
    </ViewChecklistDiv>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  checklistTemplateOverview: state.checklist.checklistTemplateOverview,
  dropdownAccountList: state.dropdownFilters.accountList,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setUpdateEditTemplate: (data) => dispatch(setUpdateEditTemplate(data)),
  setAddChecklistModal: (data) => dispatch(setAddChecklistModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistList));
