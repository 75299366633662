import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { toValidateAssetAtrribute } from "../../../utils/validations/checkValidation";
import { apiCall } from "../../../utils/apiCall";
import {
  AssetDetaileForm,
  FormContainer,
  FormItem,
  HeadingContainer,
} from "./AssetOverviewForm.component.styles";
import InputFieldWrapper from "../../../components/InputFieldComponent/InputFieldWrapper.component";

function AssetDetailsForm({ formData, setFormData, handleNext, validated }) {
  const [selectedAssetType, setSelectedAssetType] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (formData.assetType) {
      getAssetDetails(formData.assetType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.assetType]);

  const getAssetDetails = async (assetId) => {
    try {
      const response = await apiCall(`/assettype/getassettype/${assetId}`);
      setIsLoading(false);
      setSelectedAssetType(response?.data?.data[0]?.AssetTypeAttributes || []);
    } catch (error) {
      // Handle error
      console.error("Error fetching asset details:", error);
    }
  };

  const handleNextLocal = (e) => {
    e.preventDefault();
    let isInvalid = false;
    selectedAssetType &&
      selectedAssetType.map((data) => {
        if (
          data.AssetAttributeRequired &&
          validated &&
          toValidateAssetAtrribute(formData[data.AssetAttributeName])
        ) {
          isInvalid = true;
        }
      });
    if (isInvalid) {
      return;
    }
    handleNext(e);
  };

  return (
    <AssetDetaileForm>
      <Container>
        <HeadingContainer>
          <Trans>Asset Details</Trans>
        </HeadingContainer>
        <Form noValidate validated={validated} id="assetAddForm1" onSubmit={handleNextLocal}>
          {isLoading && (
            <div className="text-center">
              <div className="spinner-border table-spin" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <FormContainer>
            {selectedAssetType?.map((data) => (
              <FormItem>
                <InputFieldWrapper
                  label={t(data.AssetAttributeName)}
                  required={data.AssetAttributeRequired}
                  placeholder={t(data.AssetAttributeName)}
                  variant="standard"
                  name={data.AssetAttributeName}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [data.AssetAttributeName]: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    setFormData({
                      ...formData,
                      [data.AssetAttributeName]: e.target.value.trim(),
                    })
                  }
                  value={formData[data.AssetAttributeName]}
                  isInvalid={
                    data.AssetAttributeRequired &&
                    validated &&
                    toValidateAssetAtrribute(formData[data.AssetAttributeName])
                  }
                  feedback={
                    data.AssetAttributeRequired &&
                    validated &&
                    toValidateAssetAtrribute(formData[data.AssetAttributeName])
                  }
                  isError={
                    data.AssetAttributeRequired &&
                    validated &&
                    toValidateAssetAtrribute(formData[data.AssetAttributeName])
                  }
                  errorMessage={
                    data.AssetAttributeRequired &&
                    validated &&
                    toValidateAssetAtrribute(formData[data.AssetAttributeName])
                  }
                />
              </FormItem>
            ))}
            <FormItem>
              {/* Asset UID*/}
              <Form.Group as={Col} md="6" style={{ display: "none" }} className="inputBoxDiv hide-detail">
                <Form.Control
                  id="outlined-basic"
                  label={t("Asset UID")}
                  variant="standard"
                  name="assetUID"
                  value={
                    (formData ? formData.make : "") +
                    "_" +
                    (formData ? formData.model : "") +
                    "_" +
                    (formData ? formData.serialNumber : "")
                  }
                />
              </Form.Group>
            </FormItem>
          </FormContainer>
        </Form>
      </Container>
    </AssetDetaileForm>
  );
}

export default AssetDetailsForm;
