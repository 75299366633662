import styled from "styled-components";
import { InformationIcon } from "../../../../../assets";

export const StyledInformationIcon = styled(InformationIcon)`
  width: 17px;
  margin-left: 7px;
  cursor: pointer;
`;

export const PopoverComponentWrapper = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font4};
`;

export const AddShiftDurationButton = styled.div`
  float: left;
  & button {
    border-radius: 0.2rem;
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    height: 38px;
    letter-spacing: 0.5px;
    box-shadow: none;
    &:hover {
      background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      color: #fff;
    }
  }
`;

export const BackButtonComppnent = styled.div`
  float: right;

  & button {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
    width: 120px;
    height: 42px;
    margin-right: 10px;
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-style: normal;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:disabled {
      display: none;
    }
  }
`;

export const SubmitButtonComppnent = styled.div`
  float: right;
  & button {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    background-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    &:hover {
      background-color: #1e6c1b;
    }
  }
`;

export const NextButtonComppnent = styled.div`
  float: right;
  & button {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    &:hover {
      background-color: #5387dc;
    }
  }
`;

export const StyledButton = styled.button`
  ${({ disabled }) => disabled && "background-color:#ccc !important; cursor: not-allowed"}
`;
