import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import ChecklistOverviewTab from "./ChecklistOverviewTab.component";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import DetailsDescriptions from "../../../components/DetailsDescriptions/DetailsDescriptions.component";
// import DropdownFilter from "../../../components/DropdownFilter.component";
import DropdownFilter from "../../../components/DropdownComponent/DropdownWrapper";
import { Trans, useTranslation } from "react-i18next";
import DropdownFilterSection from "./ChecklistButtonSearch/DropdownAccount.component";
import {
  ViewChecklistDiv,
  CardMainContainer,
  CardBodyContainer,
  TopHeaderDetailsDiv,
  ChecklistDropdown,
  ContainerEmpty,
  TextMessage,
  DesktopMessage,
  MobileMessage,
} from "./ChecklistOverview.component.styles";

const ChecklistOverview = ({
  checklistOverview,
  dropdownAccountList,
  isSuperAdmin,
  setDropdownAccountId,
  accountListById,
  currentTimeZone,
  dropdownAccountId,
}) => {
  const { t } = useTranslation();

  const timeZone = currentTimeZone || checklistOverview?.createdAt || "-06:00 Central America";

  const isDetailsEmpty =
    !checklistOverview?.checklistDescription || checklistOverview?.checklistDescription.trim() === "";
  return (
    <ViewChecklistDiv>
      {/* <DropdownFilterSection
        dropdownAccountList={dropdownAccountList}
        dropdownAccountId={dropdownAccountId}
        setDropdownAccountId={setDropdownAccountId}
        isSuperAdmin={isSuperAdmin}
        t={t}
      /> */}
      <CardMainContainer>
        {checklistOverview ? (
          <CardBodyContainer>
            <TopHeaderDetailsDiv>
              <DetailsDescriptions
                title={checklistOverview ? checklistOverview?.checklistName : ""}
                details={checklistOverview ? checklistOverview?.checklistDescription : ""}
                dataTitle={t("Added On")}
                date={moment(checklistOverview?.createdAt)
                  .utcOffset(timeZone?.split?.(" ")[0])
                  .format(" MMMM DD YYYY")}
              />

              {isSuperAdmin && checklistOverview?.accountId && (
                <DetailsDescriptions
                  dataTitle={t("AccountLabel")}
                  date={accountListById?.[checklistOverview?.accountId]?.companyName}
                />
              )}
            </TopHeaderDetailsDiv>
            <>
              <ChecklistOverviewTab isDetailsEmpty={isDetailsEmpty} checklistOverview={checklistOverview} />
            </>
          </CardBodyContainer>
        ) : (
          <ContainerEmpty isSuperAdmin={isSuperAdmin}>
            <TextMessage className="label-01-01">
              <DesktopMessage>
                <Trans>NO_CHECKLISTS_IN_ACCOUNT</Trans>
                <br />
                <Trans>CLICK_ADD_CHECKLIST_BUTTON</Trans>
              </DesktopMessage>
              <MobileMessage>
                <Trans>NO_CHECKLISTS_IN_ACCOUNT</Trans>
                <br />
                <Trans>CLICK_ADD_CHECKLIST_BUTTON_MOB</Trans>
              </MobileMessage>
            </TextMessage>
          </ContainerEmpty>
        )}
      </CardMainContainer>
    </ViewChecklistDiv>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

const mapStateToProps = (state) => ({
  checklistOverview: state.checklist.checklistOverview,
  accountList: state.accounts.Accounts,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  accountListById: state.dropdownFilters.accountListById,
  currentTimeZone: state.settings.currentTimeZone,
  dropdownAccountId: state.dropdownFilters.accountId,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistOverview));
