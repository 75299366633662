import { userTypeManagement } from "./types";
import { initialState } from "./initialState";

export const userTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    // For User Type List
    case userTypeManagement.GET_USER_TYPE_LIST_LOADING:
      return {
        ...state,
        UserTypeListLoading: true,
        UserTypeListSuccess: false,
        UserTypeListError: false,
        UserTypeList: [],
        UserTypeDetail: "",
      };

    case userTypeManagement.GET_USER_TYPE_LIST_SUCCESS:
      return {
        ...state,
        UserTypeListLoading: false,
        UserTypeListSuccess: true,
        UserTypeListError: false,
        UserTypeList: [...action.data.data],
      };
    // For User Type List Detail
    case userTypeManagement.GET_USER_TYPE_DETAIL_LOADING:
      return {
        ...state,
        UserTypeDetailLoading: true,
        UserTypeDetailSuccess: false,
        UserTypeDetailError: false,
        UserTypeDetail: "",
      };

    case userTypeManagement.GET_USER_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        UserTypeDetailLoading: false,
        UserTypeDetailSuccess: true,
        UserTypeDetailError: false,
        UserTypeDetail: action.data.data,
      };

    case userTypeManagement.GET_USER_TYPE_LIST_ERROR:
      return {
        ...state,
        UserTypeDetailLoading: false,
        UserTypeDetailSuccess: false,
        UserTypeDetailError: true,
        UserTypeDetail: "",
      };

    case userTypeManagement.ADD_USER_TYPE_ERROR:
      return {
        ...state,
        AddUserTypeLoading: false,
        addUserTypeSuccess: false,
        addUserTypeError: true,
        addUserTypeErrorMessage: action.data,
      };

    case userTypeManagement.ADD_USER_TYPE_LOADING:
      return {
        ...state,
        AddUserTypeLoading: true,
        addUserTypeSuccess: false,
        addUserTypeError: false,
      };

    case userTypeManagement.ADD_USER_TYPE_SUCCESS:
      return {
        ...state,
        AddUserTypeLoading: false,
        addUserTypeSuccess: true,
        addUserTypeError: false,
      };

    case userTypeManagement.UPDATE_USER_TYPE_ERROR:
      return {
        ...state,
        UserTypeUpdateLoading: false,
        UserTypeUpdateSuccess: false,
        UserTypeUpdateError: true,
      };

    case userTypeManagement.UPDATE_USER_TYPE_LOADING:
      return {
        ...state,
        UserTypeUpdateLoading: true,
        UserTypeUpdateSuccess: false,
        UserTypeUpdateError: false,
      };

    case userTypeManagement.UPDATE_USER_TYPE_SUCCESS:
      return {
        ...state,
        UserTypeUpdateLoading: false,
        UserTypeUpdateSuccess: true,
        UserTypeUpdateError: false,
        UserTypeDetail: action.data,
      };

    case userTypeManagement.TOGGLE_USER_TYPE_STATUS_SUCCESS:
      return {
        ...state,
        UserTypeUpdateLoading: false,
        toggleUserTypeStatusSuccess: true,
        toggleUserTypeStatusError: false,
        UserTypeDetail: action.data,
      };

    case userTypeManagement.TOGGLE_USER_TYPE_STATUS_ERROR:
      return {
        ...state,
        UserTypeUpdateLoading: false,
        toggleUserTypeStatusSuccess: false,
        toggleUserTypeStatusError: true,
        UserTypeDetail: action.data,
      };

    case userTypeManagement.RESET_USER_TYPE_FLAGS:
      return {
        ...state,
        UserTypeUpdateLoading: false,
        toggleUserTypeStatusSuccess: false,
        toggleUserTypeStatusError: false,
        addUserTypeSuccess: false,
        addUserTypeError: false,
        getSuperAdminUserTypesSuccess: false,
        getSuperAdminUserTypesError: false,
        DeactivateUserTypeSuccess: false,
        DeactivatedUsersLoading: false,
        DeactivatedUsersSuccess: false,
        DeactivatedUsersError: false,
        DeactivatedUsersList: [],
        UserTypeUpdateSuccess: false,
      };

    case userTypeManagement.PRE_DEACTIVATED_USER_TYPE_LOADING:
      return {
        ...state,
        DeactivatedUsersLoading: true,
        DeactivatedUsersSuccess: false,
        DeactivatedUsersError: false,
        DeactivatedUsersList: [],
      };

    case userTypeManagement.PRE_DEACTIVATED_USER_TYPE_SUCCESS:
      return {
        ...state,
        DeactivatedUsersLoading: false,
        DeactivatedUsersSuccess: true,
        DeactivatedUsersError: false,
        DeactivatedUsersList: [...action.data],
      };

    case userTypeManagement.PRE_DEACTIVATED_USER_TYPE_ERROR:
      return {
        ...state,
        DeactivatedUsersLoading: false,
        DeactivatedUsersSuccess: false,
        DeactivatedUsersError: true,
        DeactivatedUsersList: [],
      };

    case userTypeManagement.DEACTIVATE_USER_TYPE_LOADING:
      return {
        ...state,
        DeactivateUserTypeLoading: true,
        DeactivateUserTypeSuccess: false,
        DeactivateUserTypeError: false,
      };

    case userTypeManagement.DEACTIVATE_USER_TYPE_SUCCESS:
      return {
        ...state,
        DeactivateUserTypeLoading: false,
        DeactivateUserTypeSuccess: true,
        DeactivateUserTypeError: false,
      };

    case userTypeManagement.DEACTIVATE_USER_TYPE_ERROR:
      return {
        ...state,
        DeactivateUserTypeLoading: false,
        DeactivateUserTypeSuccess: false,
        DeactivateUserTypeError: true,
      };

    case userTypeManagement.GET_DROPDOWN_USERTYPE_LIST:
      return { ...state, userTypeDetailList: action.data };

    case userTypeManagement.GET_SUPER_ADMIN_USER_TYPES_ERROR:
      return {
        ...state,
        getSuperAdminUserTypesSuccess: false,
        getSuperAdminUserTypesError: true,
      };

    case userTypeManagement.GET_SUPER_ADMIN_USER_TYPES_SUCCESS:
      return {
        ...state,
        getSuperAdminUserTypesSuccess: true,
        superAdminList: action.data,
      };

    default:
      return state;
  }
};
