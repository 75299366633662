import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { HeaderWrapper, FilterWrapper, ItemDiv, ContentDiv } from "./Header.component.styles";
import DropdownComponent from "../../../../../components/DropdownComponent/Dropdown.component";
import { getDropdownAccountList } from "../../../../../redux/dropdownFilters/action";
import { getDropdownWorksiteList, getUsers } from "../../../../../redux/modalDropdownFilters/action";
import { apiCall } from "../../../../../utils/apiCall";
import {
  setSettingsDropdownAccountId,
  getDropdownAssetList,
} from "../../../../../redux/dropdownFilters/dropdownActions";
import DateRangePicker from "../../../../../components/DatePicker.component";
import Button from "../../../../../components/Button/Button.component";
import ExportToCSV from "../ExportToCSV.component";

const Header = (props) => {
  const {
    getDropdownWorksiteList,
    isSuperAdmin,
    dropdownAccountList,
    worksiteList,
    setLockUnlockRecords,
    pageSize,
    pageNo,
    getWorksiteListLoading,
    getDropdownAccountList,
    loginDetails,
    setIsLoading,
    isAccountOwner,
    isSiteAdmin,
    setSettingsDropdownAccountId,
    isAccountAdmin,
    getDropdownAssetList,
    dropdownAssetList,
    assetListLoading,
    accountListLoading,
    setPageNo,
    userList,
    getUsers,
    getUserListLoading,
    downloadReportSet,
    recordSet,
    currentTimeZone,
    resetSelection,
    selectedAccount,
    setSelectedAccount,
    setIsFilterChanged,
  } = props;
  const { t } = useTranslation();

  const [accountListWithAll, setAccountListWithAll] = useState([]);
  const [worksitesListWithAll, setWorksitesListWithAll] = useState([]);

  const [assetListWithAll, setAssetListWithAll] = useState([]);
  const [userListWithAll, setUserListWithAll] = useState([]);

  const [selectedWorksite, setSelectedWorksite] = useState({ value: "", label: "All" });
  const [selectedAsset, setSelectedAsset] = useState({ value: "", label: "All" });
  const [selectedUser, setSelectedUser] = useState({ value: "", label: "All" });
  const [eventCategory, setEventCategory] = useState({ value: "", label: "All" });
  const [event, setEvent] = useState({ value: "", label: "All" });

  const [startDate, setStartDate] = useState(moment().subtract(7, "days").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());

  //check if any of the filter is changed
  /*   useEffect(() => {
    setIsFilterChanged(true);
  }, [
    selectedAccount,
    selectedWorksite,
    selectedAsset,
    selectedUser,
    eventCategory,
    event,
    startDate,
    endDate,
  ]);
 */
  const onDateChange = (date) => {
    setStartDate(date.startDate);
    setEndDate(date.endDate);
  };

  useEffect(() => {
    if (dropdownAccountList?.length > 0 && dropdownAccountList?.[0]?._id !== -1) {
      const optionListTemp = dropdownAccountList.map((option) => ({
        value: option._id,
        label: option.companyName,
      }));
      //optionListTemp.unshift({ value: "", label: "Select an Account" });
      setAccountListWithAll(optionListTemp);
    } else {
      //setAccountListWithAll([{ value: "", label: "Select an Account" }]);
    }
  }, [dropdownAccountList]);

  useEffect(() => {
    if (userList?.length > 0 && userList?.[0]?._id !== -1) {
      const optionListTemp = userList.map((option) => ({
        label: option.fullName,
        value: option._id,
      }));
      // as suggested by Shubham, the id for unauthorized user is 6046645f21ae542b2a42a8d3 in all the envs
      optionListTemp.unshift({ value: "6046645f21ae542b2a42a8d3", label: "Unauthorized" });
      optionListTemp.unshift({ value: "", label: "All" });
      setUserListWithAll(optionListTemp);
    } else {
      setUserListWithAll([{ value: "", label: "All" }]);
    }
  }, [userList]);

  const accountId = isSuperAdmin ? selectedAccount?.value : loginDetails?.accountId;

  const getTroubleshootingLogs = async () => {
    setIsLoading(true);
    const params = `${accountId && accountId !== "all" ? "&accountId=" + accountId : ""}${selectedWorksite.value ? "&siteId=" + selectedWorksite.value : ""}${selectedAsset.value ? "&assetId=" + selectedAsset.value : ""}${eventCategory.value ? "&cat=" + eventCategory.value : ""}${event.value ? "&event=" + event.value : ""}${selectedUser.value ? "&userId=" + selectedUser.value : ""}${startDate ? "&startDate=" + startDate.toISOString() : ""}${endDate ? "&endDate=" + endDate.toISOString() : ""}`;

    try {
      const resp = await apiCall(`/activityLogs/?pageSize=${pageSize}&pageNo=${pageNo}${params}`, {
        method: "GET",
      });
      if (resp.data.success) {
        setLockUnlockRecords(resp?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching login records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAccount.value) {
      getTroubleshootingLogs();
    }
  }, [pageNo, pageSize]);

  useState(() => {
    getDropdownAccountList();
  }, [getDropdownAccountList]);

  useEffect(() => {
    if (selectedAccount.value) {
      setSelectedWorksite({ value: "", label: "All" });
      getDropdownWorksiteList(selectedAccount.value);
      getUsers(selectedAccount.value, "", "", "all", "all", "");
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedWorksite.value) {
      getUsers(selectedAccount.value, selectedWorksite.value, "", "all", "all", "");
    }
  }, [selectedWorksite]);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setSettingsDropdownAccountId(loginDetails && loginDetails.accountId);
    }
  }, [loginDetails]);

  useEffect(() => {
    if (!dropdownAccountList || dropdownAccountList?.length === 0) {
      getDropdownAccountList();
    }
  }, []);

  useEffect(() => {
    if (worksiteList?.length > 0 && worksiteList?.[0]?._id !== -1) {
      const optionListTemp = worksiteList.map((option) => ({
        label: option.name,
        value: option._id,
      }));
      optionListTemp.unshift({ value: "", label: "All" });
      setWorksitesListWithAll(optionListTemp);
    } else {
      setWorksitesListWithAll([{ value: "", label: "All" }]);
    }
  }, [worksiteList]);

  useEffect(() => {
    if (dropdownAssetList?.length > 0 && dropdownAssetList?.[0]?._id !== -1) {
      const optionListTemp = dropdownAssetList.map((option) => ({
        label: option.assetName,
        value: option._id,
      }));

      optionListTemp.unshift({ value: "", label: "All" });
      setAssetListWithAll(optionListTemp);
    } else {
      setAssetListWithAll([{ value: "", label: "All" }]);
    }
  }, [dropdownAssetList]);

  useEffect(() => {
    if (selectedAccount.value) {
      setSelectedAsset({ value: "", label: "All" });
      getDropdownAssetList(selectedAccount?.value || "all", selectedWorksite?.value || "all", "all");
    }
  }, [selectedWorksite, selectedAccount]);

  const applyChange = () => {
    /* setIsFilterChanged(false); */
    setPageNo(1);
    resetSelection();
    getTroubleshootingLogs();
  };

  const catToEventMapping = {
    admin_menu_access: [
      { value: "", label: t("All") },
      { value: "admin_menu_login", label: t("Admin Menu Login") },
    ],
    config_update: [
      { value: "", label: t("All") },
      { value: "config_update", label: t("Config Update") },
    ],
    web_auth: [
      { value: "", label: t("All") },
      { value: "web_login", label: t("Login") },
    ],
    internet_activity: [
      { value: "", label: t("All") },
      { value: "i_connected", label: t("Connected") },
      { value: "i_disconnected", label: t("Disconnected") },
    ],
    lock_unlock: [
      { value: "", label: t("All") },
      { value: "lock", label: t("Lock") },
      { value: "unlock", label: t("Unlock") },
    ],
    device_shutdown: [
      { value: "", label: t("All") },
      { value: "restart", label: t("Restart") },
      { value: "shutdown", label: t("Shutdown") },
    ],
    ota: [
      { value: "", label: t("All") },
      { value: "ota_update", label: t("OTA Update") },
    ],

    web_manage_acc: [
      { value: "", label: t("All") },
      { value: "web_acc_add", label: t("Add Account") },
      { value: "web_acc_edit", label: t("Edit Account") },
      { value: "web_acc_status", label: t("Deactivate Account") },
    ],
    web_manage_site: [
      { value: "", label: t("All") },
      { value: "web_site_add", label: t("Add Worksite") },
      { value: "web_site_edit", label: t("Edit Worksite") },
      { value: "web_site_status", label: t("Deactivate Worksite") },
    ],
    web_manage_asset: [
      { value: "", label: t("All") },
      { value: "web_asset_add", label: t("Add Asset") },
      { value: "web_asset_edit", label: t("Edit Asset") },
      { value: "web_asset_status", label: t("Deactivate Asset") },
    ],
    web_manage_user: [
      { value: "", label: t("All") },
      { value: "web_user_add", label: t("Add User") },
      { value: "web_user_edit", label: t("Edit User") },
      { value: "web_user_status", label: t("Deactivate User") },
    ],
    web_manage_assetType: [
      { value: "", label: t("All") },
      { value: "web_assetType_add", label: t("Add Asset Type") },
      { value: "web_assetType_edit", label: t("Edit Asset Type") },
    ],
    web_manage_userType: [
      { value: "", label: t("All") },
      { value: "web_userType_add", label: t("Add User Type") },
      { value: "web_userType_edit", label: t("Edit User Type") },
      { value: "web_userType_status", label: t("Deactivate User Type") },
    ],
    web_manage_checklist: [
      { value: "", label: t("All") },
      { value: "web_checklist_add", label: t("Add Checklist") },
      { value: "web_checklist_edit", label: t("Edit Checklist") },
      { value: "web_checklist_status", label: t("Deactivate Checklist") },
    ],

    "": [
      { value: "", label: t("All") },
      { value: "admin_menu_login", label: t("Admin Menu Login") },
      { value: "config_update", label: t("Config Update") },
      { value: "i_connected", label: t("Connected") },
      { value: "i_disconnected", label: t("Disconnected") },
      { value: "lock", label: t("Lock") },
      { value: "unlock", label: t("Unlock") },
      { value: "web_login", label: t("Login") },
      { value: "restart", label: t("Restart") },
      { value: "shutdown", label: t("Shutdown") },
      { value: "ota_update", label: t("OTA Update") },

      { value: "web_acc_add", label: t("Add Account") },
      { value: "web_acc_edit", label: t("Edit Account") },
      { value: "web_acc_status", label: t("Deactivate Account") },
      { value: "web_site_add", label: t("Add Worksite") },
      { value: "web_site_edit", label: t("Edit Worksite") },
      { value: "web_site_status", label: t("Deactivate Worksite") },
      { value: "web_asset_add", label: t("Add Asset") },
      { value: "web_asset_edit", label: t("Edit Asset") },
      { value: "web_asset_status", label: t("Deactivate Asset") },
      { value: "web_user_add", label: t("Add User") },
      { value: "web_user_edit", label: t("Edit User") },
      { value: "web_user_status", label: t("Deactivate User") },
      { value: "web_assetType_add", label: t("Add Asset Type") },
      { value: "web_assetType_edit", label: t("Edit Asset Type") },
      { value: "web_userType_add", label: t("Add User Type") },
      { value: "web_userType_edit", label: t("Edit User Type") },
      { value: "web_userType_status", label: t("Deactivate User Type") },
      { value: "web_checklist_add", label: t("Add Checklist") },
      { value: "web_checklist_edit", label: t("Edit Checklist") },
      { value: "web_checklist_status", label: t("Deactivate Checklist") },
    ],
  };

  const eventCategoryOptions = [
    { value: "", label: t("All") },
    { value: "admin_menu_access", label: t("Admin Menu Access") },
    { value: "config_update", label: t("Config Update") },
    { value: "device_shutdown", label: t("Device Shutdown") },
    { value: "internet_activity", label: t("Internet Activity") },
    { value: "lock_unlock", label: t("Lock/Unlock") },
    { value: "web_auth", label: t("Web Auth") },
    { value: "ota", label: t("OTA") },
    { value: "web_manage_acc", label: t("Account Management") },
    { value: "web_manage_site", label: t("Worksite Management") },
    { value: "web_manage_asset", label: t("Asset Management") },
    { value: "web_manage_user", label: t("User Management") },
    { value: "web_manage_assetType", label: t("Asset Type Management") },
    { value: "web_manage_userType", label: t("User Type Management") },
    { value: "web_manage_checklist", label: t("Checklist Management") },
  ];

  return (
    <HeaderWrapper>
      <FilterWrapper>
        <ContentDiv>
          <ItemDiv>
            <DropdownComponent
              label={t("Account")}
              placeholder={"Select an Account"}
              options={accountListWithAll}
              value={selectedAccount}
              handleFilter={(selectedData) => {
                setSelectedAccount(selectedData);
              }}
              size="medium"
              isLoading={accountListLoading}
            />
          </ItemDiv>

          {accountId && (
            <>
              <ItemDiv>
                <DropdownComponent
                  label={t("Worksite")}
                  placeholder={t("Select a worksite")}
                  options={worksitesListWithAll}
                  value={selectedWorksite}
                  handleFilter={(option) => {
                    setSelectedWorksite(option);
                  }}
                  errorMessage={t("This field is required")}
                  size="medium"
                  isLoading={getWorksiteListLoading}
                />
              </ItemDiv>

              {/* Date Range Picker */}
              <ItemDiv>
                <DropdownComponent
                  label={t("Asset")}
                  placeholder={t("Select an Asset")}
                  options={assetListWithAll}
                  value={selectedAsset}
                  handleFilter={(asset) => {
                    setSelectedAsset(asset);
                  }}
                  size="medium"
                  isLoading={assetListLoading}
                />
              </ItemDiv>
              <ItemDiv>
                <DropdownComponent
                  label={t("User")}
                  placeholder={t("Select a User")}
                  options={userListWithAll}
                  value={selectedUser}
                  handleFilter={setSelectedUser}
                  size="medium"
                  isLoading={getUserListLoading}
                />
              </ItemDiv>
              <ItemDiv></ItemDiv>
              <ItemDiv>
                <DropdownComponent
                  label={t("Event Category")}
                  placeholder={t("Select an Event Category")}
                  options={eventCategoryOptions}
                  value={eventCategory}
                  handleFilter={(data) => {
                    setEventCategory(data);
                    setEvent({ value: "", label: t("All") });
                  }}
                  size="medium"
                />
              </ItemDiv>
              <ItemDiv>
                <DropdownComponent
                  label={t("Event")}
                  placeholder={t("Select an Event")}
                  options={catToEventMapping[eventCategory.value] || []}
                  value={event}
                  handleFilter={setEvent}
                  size="medium"
                />
              </ItemDiv>
              <ItemDiv>
                <DateRangePicker
                  setDate={onDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={moment().subtract(730, "days").toDate()}
                />
              </ItemDiv>

              <ItemDiv>
                <Button
                  label={t("Apply Filter")}
                  size="medium"
                  onClick={applyChange}
                  showIcon={false}
                  buttonType="primary"
                />
                <ExportToCSV
                  downloadReportSet={downloadReportSet}
                  reportList={recordSet}
                  currentTimeZone={currentTimeZone}
                />
              </ItemDiv>
            </>
          )}
        </ContentDiv>
        {/* <Row>
          <Col md={12} style={{ textAlign: "right", marginTop: "10px" }}></Col>
        </Row> */}
      </FilterWrapper>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  accountList: state.accounts.Accounts,
  dropdownAccountList: state.dropdownFilters.accountList,

  worksiteList: state.modalDropdownFilters.worksiteList,
  getWorksiteListLoading: state.modalDropdownFilters.getWorksiteListLoading,
  accountListLoading: state.dropdownFilters.accountListLoading,
  isSuperAdmin: state.user.isSuperAdmin,
  loginDetails: state.user.loginDetails,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  assetListLoading: state.dropdownFilters?.settings?.assetListLoading,
  dropdownAssetList: state.dropdownFilters?.settings?.assetList,

  userList: state.modalDropdownFilters.userList,
  getUserListLoading: state.modalDropdownFilters.getUserListLoading,
  dropdownAssetTypeList: state.dropdownFilters?.settings?.assetTypeList,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownWorksiteList: (accountId) => dispatch(getDropdownWorksiteList(accountId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
  getDropdownAssetList: (selectedAccountId, selectedWorksite, assetTypeId) =>
    dispatch(getDropdownAssetList(selectedAccountId, selectedWorksite, assetTypeId)),
  getUsers: (accountId, siteId) => dispatch(getUsers(accountId, siteId)),
});

Header.defaultProps = {
  showFilters: true,
  handleAssetIdFilter: () => {},
  handleAssetTypeIdFilter: () => {},
  handleWorksiteFilter: () => {},
  showWorksiteFilter: true,
  showAssetTypeFilter: true,
  showAssetFilter: true,
  showUserTypeFilter: false,
  showUserFilter: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
