export const initialState = {
  //For Language
  allCount: {},
  inspectionCount: {},
  unauthorizedCount: {},
  getUnauthorizedCountLoading: false,
  getAllCountLoading: false,
  getInspectionCountLoading: false,
  graphData: {
    loading: true,
    count: [],
    passed: [],
    failed: [],
    date: [],
  },
  getIncidentCountLoading: false,
  incidentCount: {},
  impactCount: {},

  isIncidentGraphLoading: false,
  isInspectionGraphLoading: false,
  isUnauthorizedGraphLoading: false,
};
