import styled from "styled-components";
export const ViewChecklistDiv = styled.div``;
export const CardMainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-clip: border-box;
  /*  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  height: calc(100vh - 305px); */
  div {
    span.checklist_over,
    .clickDesktop,
    .tapMobile {
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
      font-size: ${({ theme }) => theme.fontSizes.font7} !important;
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
    }
  }
`;

export const CardBodyContainer = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 8px 16px;
`;

export const TopHeaderDetailsDiv = styled.div`
  width: 100%;
`;

export const ChecklistDropdown = styled.div`
  float: left;
  margin-bottom: 8px;
  display: inline-block;
  width: 100%;
  div[themecontext] {
    width: 30%;
  }
`;
export const ContainerEmpty = styled.div`
  height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 270px)" : "calc(100vh - 204px)")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextMessage = styled.div`
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;

export const DesktopMessage = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileMessage = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const ButtonDivEdit = styled.div`
  float: right;
  padding: 8px 0px 0px 0px;
  width: 100%;
  text-align: right;
`;

export const ChecklistDescEditView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  /*height: calc(100vh - 305px); */
  div {
    span.checklist_over,
    .clickDesktop,
    .tapMobile {
      font-family: ${({ theme }) => theme.fontFamily.primaryFont};
      font-size: ${({ theme }) => theme.fontSizes.font7} !important;
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
    }
  }
`;
