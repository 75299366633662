import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import List from "../../../../components/List";
import {
  selectChecklistTemplateOverview,
  getAllChecklist,
  getAllChecklistTemplates,
  setUpdateEditTemplate,
} from "../../../../redux/checklists/action";
import { setAddChecklistModal } from "../../../../redux/modals/action";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
const CheckListStyled = styled.div`
  @media (max-width: 768px) {
    .searchBox {
      display: none;
    }
  }
`;
const ListingStyle = styled.div`
  height: 100%;
  overflow-x: hidden;
  width: 100%;
  overflow-x: hidden;
  
  .tableHeight {
    display: block !important;
    overflow-y: scroll;
    height: calc(100vh - 260px);
    @media (max-width: 768px) {
      height: calc(100vh - 330px);
    }
  }
  .tableListSec {
    display: block;
    width: 100%;
    thead {
      width: 100%;
      display: block;
    }
  }
  tr.tableTr {
    display: block;
  }

  tr.tableTr td {
    display: flex;
    padding: 8px 16px;
    align-items: center;

    button.add-iconList {
      background-color: #0d5fbe;
      padding: 2px;
      border-radius: 5px;
      height: 30px;
      width: 40px;
      border: none;
      flex: none;
      float: right;
    }
  }

  tr.tableTr p {
    margin: initial;
    width: auto;
    line-height: 18px;
    float: left;
    flex: 1;
    margin-right: 20px;
  }
`;

const listType = require("../../../../constant/listType");

function ChecklistList({
  history,
  checklistTemplates,
  selectChecklistTemplateOverview,
  filterAccountId,
  checklistTemplateOverview,
  getAllChecklist,
  totalCount,
  getAllChecklistTemplates,
  setUpdateEditTemplate,
  setAddChecklistModal,
  checklistTemplatesLoading,
}) {
  //const [SearchText, setSearchText] = useState("");
  const { t } = useTranslation();

  const listDetail = {
    label: t("Checklists Templates"),
    emptyListText: t("No Checklists Templates"),
    listType: listType.CHECKLIST_TEMPLATE,
    heightOffset: 266,
  };

  useEffect(() => {
    selectChecklistTemplateOverview();
  }, [checklistTemplates]);

  const updateEditTemplate = (id) => {
    selectChecklistTemplateOverview(id);
    if (!checklistTemplateOverview) {
      return;
    }
    let newChecklist = {
      checklistName: checklistTemplateOverview.checklistTemplateName,
      checklistDescription: checklistTemplateOverview.checklistTemplateDescription,
      sections: {},
    };
    let newData = checklistTemplateOverview?.sections.map((section, i) => {
      newChecklist.sections[i] = { ...section, questions: {} };
      let sectionData = section?.questions.map((question, j) => {
        newChecklist.sections[i].questions[j] = {
          ...question,
          failedResponse: question?.failedResponse?.trim(),
          responseTypeId: question?.responseTypeId?._id,
          controlType: question?.responseTypeId?.controlType,
          failedResponseText: question?.responseTypeId?.responseText,
        };
      });
    });
    setUpdateEditTemplate(newChecklist);
    history.push("/app/checklistmanagement");
    setAddChecklistModal(true);
  };

  return (
    <ListingStyle>
      <ListingStyle>
        <List
          listDetail={listDetail}
          listData={checklistTemplates}
          selectItem={(id) => selectChecklistTemplateOverview(id)}
          selectedId={checklistTemplateOverview && checklistTemplateOverview._id}
          isFilterChange={filterAccountId}
          loadData={getAllChecklistTemplates}
          totalCount={totalCount}
          updateEditTemplate={updateEditTemplate}
          dataLoading={checklistTemplatesLoading}
        />
      </ListingStyle>
    </ListingStyle>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklistTemplates: state.checklist.checklistTemplates,
  checklistTemplateOverview: state.checklist.checklistTemplateOverview,
  totalCount: state.checklist.templateTotalCount,
  checklistTemplatesLoading: state.checklist.checklistTemplatesLoading,
});

const mapDispatchToProps = (dispatch) => ({
  selectChecklistTemplateOverview: (checklistTempId) =>
    dispatch(selectChecklistTemplateOverview(checklistTempId)),
  getAllChecklistTemplates: (paginationData, isResetState) =>
    dispatch(getAllChecklistTemplates(paginationData, isResetState)),
  getAllChecklist: (paginationData, isResetState) => dispatch(getAllChecklist(paginationData, isResetState)),
  setUpdateEditTemplate: (data) => dispatch(setUpdateEditTemplate(data)),
  setAddChecklistModal: (data) => dispatch(setAddChecklistModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistList));
