import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SwitchComponent from "../../../../components/Switchbox/SwitchComponent";
import "./assetlock.css";
import ActionButtons from "../../common/ActionButtons";
import ApplyToAllModal from "../../common/appyToAll/ApplyToAllModal";
import SettingsHeader from "../common/SettingsHeader.component";
import {
  SettingsWrapper,
  SettingsContainer,
  SettingsFooter,
  SettingsBody,
  AccessControlSetting,
  HeadingTabACS,
  ACSSecondDiv,
  LeftDivACS,
  RightSwitchDiv,
  ThirdACSDiv,
  LeftACSDiv,
  IconTimer,
  RightACSDiv,
  InputStyledACS,
  SelectStyledACS,
  OnOffTextDiv,
  NoAssetWrapper,
  LoaderOverlay,
} from "../Settings.Styles";

import {
  getAccessControlSettingsByAssetId,
  updateAccessControlSettingsByAssetId,
  resetAccountSummaryAlertSettingsByAssetId,
  bulkSettingsUpdate,
  resetNotificationSettingsFlags,
} from "../../../../redux/config/action";

import SnackBar from "../../SnackBar";
import { getSelectedOptions } from "../Notification/common";
import SieraLoader from "../../../../components/SieraLoader/SieraLoader.component";

import ModalComponent from "../../../../components/ModalPopupComponent/ModalPopup.component";

const MIN_BEFORE_ASSET_LOCK = 5;

const AccessLockoutSettings = (props) => {
  const {
    assetTypeList,
    getAccessControlSettingsByAssetId,
    accessControlSettings,
    updateAccessControlSettingsByAssetId,
    resetAccountSummaryAlertSettingsByAssetId,
    assetList,
    bulkSettingsUpdate,

    resetNotificationSettingsFlags,
    notificationUpdateLoading,
    notificationBulkUpdateSuccess,
    isSystemSettingsEditable,
    dropdownAccountId,
    isSuperAdmin,
  } = props;

  const { t } = useTranslation();
  //const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [localAccessControlSettings, setLocalAccessControlSettings] = useState(null);
  const [unTouchedAccessControlSettings, setUnTouchedAccessControlSettings] = useState(null);

  const [showApplyToAllModal, setShowApplyToAllModal] = useState(false);
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [applyAllSelectedAssetTypes, setApplyAllSelectedAssetTypes] = useState([]);

  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [dropdownWorksiteId, setDropdownWorksiteId] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [clearSelection, setClearSelection] = useState(false);

  const [showAlertModal, setShowAlertModal] = useState(false);

  const settingsOptions = [
    {
      label: t("All Worksites"),
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "worksites",
    },
    {
      label: t("Selected Worksites"),
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "name",
      radioGroup: "worksites",
      dropdown: {
        placeholder: "Select a Worksite",
      },
    },
    {
      label: t("All Asset Types"),
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "assetTypes",
    },
    {
      label: t("Selected Asset Types"),
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "AssetTypeName",
      radioGroup: "assetTypes",
      dropdown: {
        placeholder: "Select an Asset Type",
      },
    },
    {
      label: t("All Assets"),
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "assets",
    },
    {
      label: t("Selected Assets"),
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "assetName",
      radioGroup: "assets",
      dropdown: {
        placeholder: "Select an Asset",
      },
    },
  ];

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, []);

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getAccessControlSettingsByAssetId(selectedAssetId, setIsLoading);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    if (accessControlSettings) {
      setLocalAccessControlSettings(accessControlSettings);
      setUnTouchedAccessControlSettings(accessControlSettings);
    }
  }, [accessControlSettings]);

  const toggleApplyToAllModal = () => {
    setShowApplyToAllModal(!showApplyToAllModal);
  };

  const saveHandler = (isApplyAll, selectedItems) => {
    setCheckApplyToAll(isApplyAll || selectedItems.length > 0);
    toggleApplyToAllModal();
  };

  useEffect(() => {
    if (assetTypeList?.length > 0 && applyAllSelectedAssetTypes?.length === assetTypeList?.length) {
      setCheckApplyToAll(true);
    } else if (applyAllSelectedAssetTypes.length > 0) {
      setCheckApplyToAll(false);
    }
  }, [applyAllSelectedAssetTypes]);

  const onCancel = () => {
    setLocalAccessControlSettings(unTouchedAccessControlSettings);
    setApplyToAllObj({});
    setCheckApplyToAll(false);
    setClearSelection(true);
  };

  const [updatableChanges, setUpdatableChanges] = useState(false);

  useEffect(() => {
    if (unTouchedAccessControlSettings) {
      setUpdatableChanges(
        !(JSON.stringify(localAccessControlSettings) === JSON.stringify(unTouchedAccessControlSettings)) ||
          isApplyToAllObjValid()
      );
    }
  }, [applyToAllObj, localAccessControlSettings]);

  const onSubmit = () => {
    if (!updatableChanges) {
      return;
    }

    let selectedWorksites = [];

    if (applyToAllObj.worksites?.all) {
      selectedWorksites = ["all"];
    } else if (applyToAllObj.worksites?.selected) {
      applyToAllObj?.worksites?.selectedItems?.forEach((site) => selectedWorksites.push(site.value));

      if (!selectedWorksites.includes(dropdownWorksiteId)) {
        selectedWorksites = [...selectedWorksites, dropdownWorksiteId];
      }
    } else {
      selectedWorksites = [dropdownWorksiteId];
    }

    if (isApplyToAllObjValid()) {
      const selectedOptions = getSelectedOptions(applyToAllObj);
      const updatableSettings = {
        ...selectedOptions,
        settings: { accessControlSettings: localAccessControlSettings },
      };
      bulkSettingsUpdate(updatableSettings, setIsUpdating);
    } else {
      setIsUpdating(true);
      updateAccessControlSettingsByAssetId(
        selectedAssetId,
        { accessControlSettings: localAccessControlSettings },
        setIsUpdating
      );
    }
  };

  const isApplyToAllObjValid = () => {
    return (
      (applyToAllObj.worksites?.all ||
        (applyToAllObj.worksites?.selected && applyToAllObj.worksites?.selectedItems?.length > 0)) &&
      (applyToAllObj.assetTypes?.all ||
        (applyToAllObj.assetTypes?.selected && applyToAllObj.assetTypes?.selectedItems?.length > 0)) &&
      (applyToAllObj.assets?.all ||
        (applyToAllObj.assets?.selected && applyToAllObj.assets?.selectedItems?.length > 0))
    );
  };

  const handleAssetIdFilter = (assetId, worksiteId, assetTypeId) => {
    setSelectedAssetId(assetId);
    setDropdownWorksiteId(worksiteId);
  };

  useEffect(() => {
    if (selectedAssetId) {
      setIsLoading(true);
      getAccessControlSettingsByAssetId(selectedAssetId, setIsLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  const onReset = () => {
    resetAccountSummaryAlertSettingsByAssetId(selectedAssetId, setIsUpdating);
  };

  const updateLocalAccessControlSettings = (key, value) => {
    if (value && key !== "status") {
      setLocalAccessControlSettings({
        ...localAccessControlSettings,
        [key]: value,
        status: true,
      });
    } else {
      setLocalAccessControlSettings({
        ...localAccessControlSettings,
        [key]: value,
      });
    }
  };

  const handleCloseSnackBar = () => {
    resetNotificationSettingsFlags();
    setApplyToAllObj({});
    setIsUpdating(false);
  };

  const toggleAccessControl = (state) => {
    setLocalAccessControlSettings({
      ...localAccessControlSettings,
      status: state,
      onInspectionFail: state,
      onHighImpact: state,
      onShiftEnd: state,
    });
  };

  const confirmDisablingAccessControl = () => {
    setShowAlertModal(false);
    toggleAccessControl(false);
  };

  const toggleMasterSwitch = () => {
    if (localAccessControlSettings?.status) {
      setShowAlertModal(true);
    } else {
      updateLocalAccessControlSettings("status", true);
      toggleAccessControl(true);
    }
  };

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("Choose the scenarios when the system will lock the asset")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
      />

      {isSuperAdmin && !dropdownAccountId ? (
        <NoAssetWrapper isSuperAdmin={isSuperAdmin}>
          <p>
            <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
          </p>
        </NoAssetWrapper>
      ) : localAccessControlSettings && assetList.length > 0 ? (
        <>
          <SettingsContainer>
            {notificationUpdateLoading || isLoading ? (
              <LoaderOverlay>
                <SieraLoader OnlyLoaderIcon size="small" />
              </LoaderOverlay>
            ) : (
              <SettingsBody>
                <AccessControlSetting>
                  <HeadingTabACS>
                    <Trans>Access Control On/Off</Trans>
                  </HeadingTabACS>

                  <ACSSecondDiv>
                    <LeftDivACS>
                      <Trans>Access Control Master Switch</Trans>
                    </LeftDivACS>
                    <RightSwitchDiv>
                      {notificationUpdateLoading || isLoading ? (
                        <div className="spinner-border" role="status"></div>
                      ) : (
                        <>
                          <OnOffTextDiv>
                            <Trans>{localAccessControlSettings?.status ? "On" : "Off"}</Trans>
                          </OnOffTextDiv>
                          <SwitchComponent
                            disabled={!isSystemSettingsEditable}
                            onChange={toggleMasterSwitch}
                            checked={!!localAccessControlSettings?.status}
                          />
                        </>
                      )}
                    </RightSwitchDiv>
                  </ACSSecondDiv>
                  {/* -- */}
                  <HeadingTabACS>
                    <Trans>Scenario</Trans>
                  </HeadingTabACS>
                  <ACSSecondDiv>
                    <LeftDivACS>
                      <Trans>Failed Inspections</Trans>
                    </LeftDivACS>
                    <RightSwitchDiv>
                      {notificationUpdateLoading || isLoading ? (
                        <div className="spinner-border" role="status"></div>
                      ) : (
                        <>
                          <OnOffTextDiv>
                            <Trans>{localAccessControlSettings.onInspectionFail ? "On" : "Off"}</Trans>
                          </OnOffTextDiv>
                          <SwitchComponent
                            disabled={!isSystemSettingsEditable}
                            onChange={() => {
                              updateLocalAccessControlSettings(
                                "onInspectionFail",
                                !localAccessControlSettings.onInspectionFail
                              );
                            }}
                            checked={localAccessControlSettings.onInspectionFail}
                          />
                        </>
                      )}
                    </RightSwitchDiv>
                  </ACSSecondDiv>
                  <ThirdACSDiv>
                    <LeftACSDiv>
                      <IconTimer className="icon icon-time"></IconTimer>
                      <span>
                        <Trans>User gets</Trans>
                      </span>
                    </LeftACSDiv>
                    <RightACSDiv>
                      {notificationUpdateLoading || isLoading ? (
                        <div className="spinner-border" role="status"></div>
                      ) : (
                        <>
                          <InputStyledACS
                            disabled={
                              !localAccessControlSettings.onInspectionFail || !isSystemSettingsEditable
                            }
                            type="number"
                            value={localAccessControlSettings.onInspectionLockoutTime}
                            className="form-control"
                            minLength={1}
                            maxLength={64}
                            min={0}
                            onChange={(e) => {
                              updateLocalAccessControlSettings(
                                "onInspectionLockoutTime",
                                Math.abs(e.target.value)
                              );
                            }}
                            onBlur={() => {
                              if (localAccessControlSettings.onInspectionLockoutTime <= 0) {
                                updateLocalAccessControlSettings(
                                  "onInspectionLockoutTime",
                                  unTouchedAccessControlSettings.onInspectionLockoutTime
                                );
                              }
                            }}
                          />
                          <SelectStyledACS
                            disabled={
                              !localAccessControlSettings.onInspectionFail || !isSystemSettingsEditable
                            }
                            name="time"
                            id="time"
                            className="form-control"
                            value={localAccessControlSettings.onInspectionLockoutTimeUnit}
                            onChange={(e) => {
                              updateLocalAccessControlSettings("onInspectionLockoutTimeUnit", e.target.value);
                            }}
                          >
                            <option value="seconds">
                              <Trans>seconds</Trans>
                            </option>
                            <option value="minutes">
                              <Trans>minutes</Trans>
                            </option>
                          </SelectStyledACS>
                          <span>
                            <Trans>before an asset gets locked</Trans>
                          </span>
                        </>
                      )}
                    </RightACSDiv>
                  </ThirdACSDiv>
                  <hr></hr>
                  <ACSSecondDiv>
                    <LeftDivACS>
                      <Trans>On High Impacts</Trans>
                    </LeftDivACS>
                    <RightSwitchDiv>
                      {notificationUpdateLoading || isLoading ? (
                        <div className="spinner-border" role="status"></div>
                      ) : (
                        <>
                          <OnOffTextDiv>
                            <Trans>{t(localAccessControlSettings.onHighImpact ? "On" : "Off")}</Trans>
                          </OnOffTextDiv>
                          <SwitchComponent
                            disabled={!isSystemSettingsEditable}
                            onChange={(e) => {
                              updateLocalAccessControlSettings(
                                "onHighImpact",
                                !localAccessControlSettings.onHighImpact
                              );
                            }}
                            checked={localAccessControlSettings.onHighImpact}
                          />
                        </>
                      )}
                    </RightSwitchDiv>
                  </ACSSecondDiv>
                  <ThirdACSDiv>
                    <LeftACSDiv>
                      <IconTimer className="icon icon-time"></IconTimer>
                      <span>
                        <Trans>User gets</Trans>
                      </span>
                    </LeftACSDiv>
                    <RightACSDiv>
                      {notificationUpdateLoading || isLoading ? (
                        <div className="spinner-border" role="status"></div>
                      ) : (
                        <>
                          <InputStyledACS
                            disabled={!localAccessControlSettings.onHighImpact || !isSystemSettingsEditable}
                            type="number"
                            minLength={1}
                            maxLength={64}
                            min={0}
                            className="form-control"
                            value={localAccessControlSettings.onHighImpactLockoutTime}
                            onChange={(e) => {
                              updateLocalAccessControlSettings(
                                "onHighImpactLockoutTime",
                                Math.abs(e.target.value)
                              );
                            }}
                            onBlur={() => {
                              const calculatedValue =
                                localAccessControlSettings.onHighImpactLockoutTimeUnit === "seconds" &&
                                localAccessControlSettings.onHighImpactLockoutTime < MIN_BEFORE_ASSET_LOCK
                                  ? MIN_BEFORE_ASSET_LOCK
                                  : localAccessControlSettings.onHighImpactLockoutTime;
                              updateLocalAccessControlSettings("onHighImpactLockoutTime", calculatedValue);
                            }}
                          />
                          <SelectStyledACS
                            disabled={!localAccessControlSettings.onHighImpact || !isSystemSettingsEditable}
                            name="time2"
                            id="time2"
                            className="input-border form-control"
                            value={localAccessControlSettings.onHighImpactLockoutTimeUnit}
                            onChange={(e) => {
                              updateLocalAccessControlSettings("onHighImpactLockoutTimeUnit", e.target.value);
                            }}
                          >
                            <option value="seconds">
                              <Trans>seconds</Trans>
                            </option>
                            <option value="minutes">
                              <Trans>minutes</Trans>
                            </option>
                          </SelectStyledACS>
                          <span>
                            <Trans>before an asset gets locked</Trans>
                          </span>
                        </>
                      )}
                    </RightACSDiv>

                    <hr></hr>
                  </ThirdACSDiv>
                  <hr></hr>
                  <ACSSecondDiv>
                    <LeftDivACS>
                      <Trans>After Shift Ends</Trans>
                    </LeftDivACS>
                    <RightSwitchDiv>
                      {notificationUpdateLoading || isLoading ? (
                        <div className="spinner-border" role="status"></div>
                      ) : (
                        <>
                          <OnOffTextDiv>
                            <Trans>{localAccessControlSettings.onShiftEnd ? "On" : "Off"}</Trans>
                          </OnOffTextDiv>
                          <SwitchComponent
                            disabled={!isSystemSettingsEditable}
                            onChange={(e) => {
                              updateLocalAccessControlSettings(
                                "onShiftEnd",
                                !localAccessControlSettings.onShiftEnd
                              );
                            }}
                            checked={localAccessControlSettings.onShiftEnd}
                          />
                        </>
                      )}
                    </RightSwitchDiv>
                  </ACSSecondDiv>
                </AccessControlSetting>
              </SettingsBody>
            )}
          </SettingsContainer>
          <SettingsFooter>
            <ActionButtons
              showAppyToAll={true}
              handleApplyToAll={toggleApplyToAllModal}
              applyToAll={isApplyToAllObjValid()}
              updatableChanges={updatableChanges}
              handleUpdate={onSubmit}
              handleCancel={onCancel}
              isLoading={isUpdating}
              showResetButton={true}
              handleReset={onReset}
            />
          </SettingsFooter>
          <ApplyToAllModal
            showModal={showApplyToAllModal}
            setShowModal={toggleApplyToAllModal}
            applyChanges={saveHandler}
            applyAllSelectedAssetTypes={applyAllSelectedAssetTypes}
            setApplyAllSelectedAssetTypes={setApplyAllSelectedAssetTypes}
            setCheckApplyToAll={setCheckApplyToAll}
            settingsOptions={settingsOptions}
            modalHeader={t("Access Control Settings")}
            applyToAllObj={applyToAllObj}
            setApplyToAllObj={setApplyToAllObj}
            clearSelection={clearSelection}
            setClearSelection={setClearSelection}
          />
        </>
      ) : (
        <NoAssetWrapper>
          <p>
            <Trans>NO_ASSET_FOUND</Trans>
          </p>
          <p>
            <Trans>EMPTY_ASSET</Trans>
          </p>
        </NoAssetWrapper>
      )}

      <SnackBar
        isSuccess={notificationBulkUpdateSuccess || isUpdating}
        label={t("Access Control Settings updated successfully")}
        handleClose={handleCloseSnackBar}
      />

      <ModalComponent
        show={showAlertModal}
        variant="AlertModal"
        handleClose={() => {
          setShowAlertModal(false);
        }}
        iconClass="alertsettings"
        message="Disabling Access Control"
        paragraph={
          <>
            Turning off the Access Control Switch will disable all vehicle-locking scenarios, including Failed
            Inspections, High Impacts, after End-Shift and Manual locking.
            <br />
            Are you sure you want to continue?
          </>
        }
        button1Text="Confirm"
        button2Text="Cancel"
        onClickA={() => {
          confirmDisablingAccessControl();
        }}
        onClickB={() => {
          updateLocalAccessControlSettings("status", !localAccessControlSettings.status);
          setShowAlertModal(false);
        }}
        typeA="primary"
        typeB="ghost"
      />
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => ({
  dropdownWorksiteId: state.dropdownFilters.worksiteId,
  assetTypeList: state.dropdownFilters.assetTypeList,
  assetList: state.dropdownFilters?.settings?.assetList,
  accessControlSettings: state.config.accessControlSettings,
  accessControlSettingsLoading: state.config.accessControlSettingsLoading,

  notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
  notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
  isSystemSettingsEditable: state.user.isSystemSettingsEditable,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  getAccessControlSettingsByAssetId: (assetId, setIsLoading) =>
    dispatch(getAccessControlSettingsByAssetId(assetId, setIsLoading)),
  updateAccessControlSettingsByAssetId: (assetId, data, setIsLoading) =>
    dispatch(updateAccessControlSettingsByAssetId(assetId, data, setIsLoading)),
  resetAccountSummaryAlertSettingsByAssetId: (assetId, setIsUpdating) =>
    dispatch(resetAccountSummaryAlertSettingsByAssetId(assetId, setIsUpdating)),
  bulkSettingsUpdate: (data, setUpdateLoading) => dispatch(bulkSettingsUpdate(data, setUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessLockoutSettings);
