import styled from "styled-components";

export const UserReactivationStyle = styled.div`
  overflow: hidden;
`;
export const ParaText = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  width: 100%;
  margin: 10px auto;
`;

export const TableContainerDivUser = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  width: 48%;
  margin: auto;
  float: left;
`;

export const TableContainerDivAsset = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  width: 48%;
  margin: auto;
  float: right;
`;


export const NoDataDiv = styled.div``;
