import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OTPFieldWrapper = styled.div`
  display: flex;
  width: 95%;
  margin: auto;
`;

export const OTPField = styled.input`
  height: 60px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.font3} !important;
  margin: 0 5px;
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-radius: 4px;
`;

export const Container = styled.div``;

export const ButtonsWrapper = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  width: 92%;
  margin: auto;
`;

export const OTPInfo = styled.div`
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;

export const InfoText = styled.div`
  margin: 15px auto;
  text-align: left;
  width: 92%;
`;

export const ErrorMessageWrapper = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  line-height: ${({ theme }) => theme.lineHeights.line5};
  padding-top: 10px;
  text-align: left;
  width: 93%;
  margin: auto;
`;

export const ErrorContainer = styled.div`
  min-height: 30px;
`;
