import moment from "moment";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Trans, useTranslation } from "react-i18next";
import { getTimezoneShortCode } from "../../../../../utils/timezoneConvert";
import {
  RefreshButton,
  RefreshButtonDiv,
  CSVButtonWrapper,
  PDFButtonWrapper,
  PDFCSVREFRESHButton,
  RefreshButtonD,
} from "./../../Reports.styles";
import Button from "../../../../../components/Button/Button.component";
import { CacheRefs } from "../../../../../utils/cache/CacheRefs";
import SnackBar from "../../../SnackBar/SnackBar";

const SaveToOptions = (props) => {
  const {
    downloadReportSet,
    getZipDownloadImpactReport,
    loginDetails,
    exportAllImpactCSV,
    incidentReportFilter,
    getImpactReportListing,
    addDownloadReportSet,
    getZipFileImpactReportLoading,
    isDownloadAllSelected,
    exportAllImpactCSVLoading,
    inspectionReportList,
    currentTimeZone,
    resetZipFileReport,
    getZipFileReportFailed,
  } = props;
  const { t } = useTranslation();

  const csvHeadersData = [
    { label: t("Worksite"), key: "siteName" },
    { label: t("Incident By"), key: "fullName" },
    { label: t("Asset Name"), key: "assetName" },
    { label: t("Asset Type"), key: "assetType" },
    { label: t("Date, Time, Timezone"), key: "createdAt" },
    { label: t("Impact Severity"), key: "incidentType" },
    { label: t("G Values"), key: "gValue" },
  ];

  const handleReportZipDownload = () => {
    if (downloadReportSet.size <= 0) {
      return;
    }
    let reportArray = Array.from(downloadReportSet);
    getZipDownloadImpactReport(reportArray);
  };

  const generateCsv = (reportList) => {
    let newCsv = [];
    reportList &&
      reportList.map((data) => {
        if (downloadReportSet && !downloadReportSet.has(data?._id)) {
          return;
        }
        let newRow = {};
        newRow.siteName = data?.siteId?.name || "NA";
        newRow.fullName = data?.userId?.fullName || "NA";
        newRow.assetName = data?.assetId?.assetName || "NA";
        newRow.assetType = data?.assetTypeId?.AssetTypeName || "NA";
        const timeZone = currentTimeZone || data?.siteId?.timeZone || "NA";

        newRow.createdAt = `${moment(data?.recordedAt)
          .utcOffset(timeZone?.split?.(" ")[0])
          .format("MMM-DD-YYYY hh:mm:ss A")} ${getTimezoneShortCode(timeZone)}`;

        newRow.incidentType = data?.severity;
        newRow.gValue = data?.impactG;
        newRow.distanceToObstacle = "NA";
        newCsv.push(newRow);
      });
    return newCsv;
  };

  const handleDownloadAllImpactReport = () => {
    exportAllImpactCSV();
  };

  const refreshData = () => {
    incidentReportFilter.obstacleType = incidentReportFilter.obstacleType || "all";
    incidentReportFilter.incidentType = incidentReportFilter.incidentType || "all";
    CacheRefs.removeCacheForImpacts();
    getImpactReportListing(incidentReportFilter);
    addDownloadReportSet(new Set());
  };
  const [rotation, setRotation] = useState(0);
  const [isRotating, setIsRotating] = useState(false);

  const onRefresh = () => {
    setIsRotating(true);

    setTimeout(() => {
      setIsRotating(false);
      setRotation(rotation + 360);
    }, 2000);
  };

  const handleCloseSnackBar = () => {
    resetZipFileReport();
  };

  return (
    <PDFCSVREFRESHButton>
      <PDFButtonWrapper disabled={isDownloadAllSelected || downloadReportSet.size <= 0}>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <Trans>PDF_DOWNLOAD</Trans>
            </Tooltip>
          }
        >
          {getZipFileImpactReportLoading ? (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Button
              onClick={handleReportZipDownload}
              iconOnly
              showIcon
              iconClass="pdf-icon"
              buttonType="iconPDF"
              disabled={isDownloadAllSelected || downloadReportSet.size === 0}
            />
          )}
        </OverlayTrigger>
      </PDFButtonWrapper>

      <CSVButtonWrapper>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <Trans>CSV_DOWNLOAD</Trans>
            </Tooltip>
          }
        >
          {isDownloadAllSelected ? (
            <>
              {exportAllImpactCSVLoading ? (
                <>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </>
              ) : (
                <>
                  <Button
                    onClick={handleDownloadAllImpactReport}
                    iconOnly
                    showIcon
                    iconClass="csv-icon"
                    buttonType="iconCSV"
                  />
                </>
              )}
            </>
          ) : (
            <CSVLink
              data={downloadReportSet.size <= 0 ? "" : generateCsv(inspectionReportList)}
              className={`${downloadReportSet.size <= 0 ? "CSVicon_disabled" : "CSVicon_enabled"}`}
              headers={csvHeadersData}
              onClick={true}
              filename={"report.csv"}
            >
              {exportAllImpactCSVLoading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Button
                  iconOnly
                  disabled={downloadReportSet.size == 0}
                  showIcon
                  iconClass="csv-icon"
                  buttonType="iconCSV"
                />
              )}
            </CSVLink>
          )}
        </OverlayTrigger>
      </CSVButtonWrapper>
      <RefreshButtonDiv>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              <Trans>Refresh</Trans>
            </Tooltip>
          }
        >
          <RefreshButton onClick={refreshData}>
            <RefreshButtonD
              rotating={isRotating}
              onClick={onRefresh}
              style={{ transform: `rotate(${rotation}deg)` }}
              className="icon icon-update"
            ></RefreshButtonD>
          </RefreshButton>
        </OverlayTrigger>
      </RefreshButtonDiv>
      <SnackBar
        isFailed={getZipFileReportFailed}
        label={"Error occurred while downloading PDF files"}
        handleClose={handleCloseSnackBar}
      />
    </PDFCSVREFRESHButton>
  );
};

export default SaveToOptions;
