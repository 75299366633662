import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import BreadCrumb from "../../../components/BreadCrumb.component";
import InspectionDashboard from "./Tabs/InspectionDashboard/InspectionDashboard.component";
import IncidentDashboard from "./Tabs/IncidentDashboard/IncidentDashboard.component";
import NearMissDashboard from "./Tabs/nearMissDashboard/NearMissDashboard.component";

import SignUpForm from "../Management/SignupForm.component";
import {
  getDropdownAccountList,
  getDropdownWorksiteList,
  getDropdownAssetList,
  getDropdownAssetTypeList,
} from "../../../redux/dropdownFilters/action";
import {
  setAddAssetModal,
  setAddSiteModal,
  setAddUserModal,
  setModalWaitingToOpen,
  setAddAssetTypeModal,
} from "../../../redux/modals/action";
import { setUpdateAccountPopup } from "../../../redux/config/action";
import { resetLoginStatus } from "../../../redux/user/user.action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import SnackBar from "../SnackBar/SnackBar";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import { WoksiteCentralModal } from "../Management/panels/worksite/worksitePanel.component";
import { UserCentralModal } from "../Management/panels/userPanel.component";
import { AssetCentralModal } from "../Management/panels/assetPanel.component";
import ChecklistAddModal from "../checklist/Modals/ChecklistAddModal.component";
import AddAssetTypeModal from "../Asset/Modal/AddAssetTypeModal/AddAssetTypeModal.component";
/* import UnauthorizedDashboard from "./Tabs/UnauthorizedDashboard/UnauthorizedDashboard"; */
import CommonSuccessMessage from "../common/CommonSuccessMessage.component";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SieraTab from "../../../components/TabComponent/SieraTab.Component";
import Button from "../../../components/Button/Button.component";
import { ModalHeader, ModalTitle, TitleSpan, ModalSpan, ButtonDiv } from "./Dashboard.component.styles";

const TabMenuWrapper = styled.div``;

function Dashboard(props) {
  let {
    loginDetails,
    isSuperAdmin,
    isAccountOwner,
    getDropdownAccountList,
    getDropdownWorksiteList,
    getDropdownAssetList,
    getDropdownAssetTypeList,
    loginSuccess,
    setUpdateAccountPopup,
    resetLoginStatus,
    updateActiveNavItem,
    modalWaitingToOpen,
    setAddAssetModal,
    setAddUserModal,
    worksiteLoading,
    setModalWaitingToOpen,
    addSiteModal,
    addAssetModal,
    addUserModal,
    addAssetTypeModal,
    setAddAssetTypeModal,
    setAddSiteModal,
  } = props;

  const [modalSignupShow, setModalSignupShow] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  useEffect(() => {
    if (worksiteLoading) {
      return;
    }
    if (modalWaitingToOpen && modalWaitingToOpen == "Asset") {
      setAddAssetModal(true);
    } else if (modalWaitingToOpen && modalWaitingToOpen == "User") {
      setAddUserModal(true);
    }
    setModalWaitingToOpen(false);
  }, [worksiteLoading]);

  useEffect(() => {
    if (isSuperAdmin) {
      setModalSignupShow(false);
      return;
    }
    if (loginDetails.formPending && isAccountOwner && loginSuccess) {
      setModalSignupShow(true);
    }
    localStorage.setItem("unitType", loginDetails.unitType);
  }, [loginDetails, isSuperAdmin]);

  const { t } = useTranslation();

  useEffect(() => {
    getDropdownAccountList();
    getDropdownWorksiteList();
    getDropdownAssetList();
    getDropdownAssetTypeList();
    updateActiveNavItem("dashboard");
  }, []);

  const hideSignUp = () => {
    setModalSignupShow(false);
    setUpdateAccountPopup(false);
    resetLoginStatus();
  };

  const handleCloseSnackBar = () => {
    setSuccessMsg(false);
  };

  const handleChange = (key) => {
    updateActiveNavItem(key);
  };

  return (
    <>
      <BreadCrumb />
      <TabMenuWrapper>
        <SieraTabs
          defaultActiveKey={"inspectionReportsDashboard"}
          variant="horizontalNew"
          align="left"
          onSelect={(key) => handleChange(key)}
          size="medium"
        >
          <SieraTab eventKey={"inspectionReportsDashboard"} title={t("Inspection Dashboard")}>
            <InspectionDashboard />
          </SieraTab>
          <SieraTab eventKey={"incidentReportsDashboard"} title={t("Impact Dashboard")}>
            <IncidentDashboard />
          </SieraTab>
          <SieraTab eventKey={"nearMissReportsDashboard"} title={t("Near-Miss Dashboard")}>
            <NearMissDashboard />
          </SieraTab>
          {/* SIER-7169 <SieraTab eventKey={t("Unauthorized Access")} title={t("Unauthorized Access")}>
            <UnauthorizedDashboard />
          </SieraTab> */}
        </SieraTabs>
      </TabMenuWrapper>
      <SignupModal
        show={modalSignupShow}
        onHide={hideSignUp}
        successMsg={successMsg}
        setSuccessMsg={setSuccessMsg}
      />
      <SnackBar isSuccess={successMsg} label={t(successMsg)} handleClose={handleCloseSnackBar} />
      <WoksiteCentralModal
        show={addSiteModal}
        toogleModalClose={() => setAddSiteModal(false)}
        onHide={() => setAddSiteModal(false)}
        setIsSuccess={() => {}}
        setIsError={() => {}}
      />
      <UserCentralModal
        show={addUserModal}
        toogleModalClose={() => {
          setModalWaitingToOpen(false);
          setAddUserModal(false);
        }}
        onHide={() => {
          setModalWaitingToOpen(false);
          setAddUserModal(false);
        }}
        setIsSuccess={() => {}}
        setIsError={() => {}}
      />
      <AssetCentralModal
        show={addAssetModal}
        toogleModalClose={() => {
          setModalWaitingToOpen(false);
          setAddAssetModal(false);
        }}
        onHide={() => {
          setModalWaitingToOpen(false);
          setAddAssetModal(false);
        }}
        setIsSuccess={() => {}}
        setIsError={() => {}}
      />
      <ChecklistAddModal />
      <AddAssetTypeModal
        show={addAssetTypeModal}
        toogleModalClose={() => {
          setAddAssetTypeModal(false);
        }}
        setIsError={() => {}}
        setIsSuccess={() => {}}
      />
      <CommonSuccessMessage />
    </>
  );
}

function SignupModal(props) {
  const { successMsg, setSuccessMsg } = props;
  return (
    <ModalComponent {...props} size="lg" withHeader={false} centered backdrop={true} fade={true}>
      <ModalHeader>
        <TitleSpan>
          <ModalTitle>
            <Trans>Welcome to SIERA Dashboard</Trans>
          </ModalTitle>
          <ModalSpan>
            <Trans>We are almost there! Please tell us a bit more about your organization</Trans>.
          </ModalSpan>
        </TitleSpan>
        <ButtonDiv>
          <Button label="Skip For Now" onClick={props.onHide} buttonType="ghost" />
        </ButtonDiv>
      </ModalHeader>

      <SignUpForm successMsg={successMsg} setSuccessMsg={setSuccessMsg} onHide={props.onHide} />
    </ModalComponent>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
  loginSuccess: state.user.loginSuccess,
  menuList: state.sideMenu.reportMenuState,
  modalWaitingToOpen: state.modals.modalWaitingForOpen,
  worksiteLoading: state.worksites.AddWorksiteLoading,
  addSiteModal: state.modals.addSiteModal,
  addAssetModal: state.modals.addAssetModal,
  addUserModal: state.modals.addUserModal,
  addAssetTypeModal: state.modals.addAssetTypeModal,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getDropdownAssetList: () => dispatch(getDropdownAssetList()),
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  setUpdateAccountPopup: (status) => dispatch(setUpdateAccountPopup(status)),
  resetLoginStatus: () => dispatch(resetLoginStatus()),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setAddUserModal: (data) => dispatch(setAddUserModal(data)),
  setAddAssetTypeModal: (data) => dispatch(setAddAssetTypeModal(data)),
  setAddSiteModal: (data) => dispatch(setAddSiteModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
