import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Header from "./Header.component";
import DataTableWrapper from "./TableWrapper/DataTableWrapper.component";
import { DataTableContainer } from "./TroubleShooting.component.styles";
import { updateActiveNavItem } from "../../../../../redux/sideMenu/menu.action";
import BreadCrumb from "../../../../../components/BreadCrumb.component";

const TroubleShooting = ({ updateActiveNavItem }) => {
  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("activityLogs");
  }, [updateActiveNavItem]);

  const [troubleShootingRecords, setTroubleShootingRecords] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [downloadReportSet, addDownloadReportSet] = useState(new Set());
  const [isAllSelected, setSelectAll] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({ value: "", label: "Select an Account" });
  //const [isFilterChanged, setIsFilterChanged] = useState(false);

  const handlePageLength = (e) => {
    setPageSize(parseInt(e.target.value));
    setPageNo(1);
  };

  const previousPage = () => {
    if (pageNo === 1) {
      return;
    }
    setPageNo(pageNo - 1);
  };

  const nextPage = () => {
    if (!troubleShootingRecords?.count || pageNo * pageSize >= troubleShootingRecords?.count) {
      return;
    }
    setPageNo(pageNo + 1);
  };

  const handleCheckboxClick = (e) => {
    const newDownloadReportSet = new Set(downloadReportSet);

    if (e.target.checked) {
      newDownloadReportSet.add(e.target.value);
    } else {
      newDownloadReportSet.delete(e.target.value);
    }
    addDownloadReportSet(newDownloadReportSet);
    if (troubleShootingRecords?.data?.length === newDownloadReportSet.size) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newDownloadReportSet = new Set(downloadReportSet);
      troubleShootingRecords?.data?.forEach((data, i) => {
        newDownloadReportSet.add(data._id);
      });
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(true);
    } else {
      const newDownloadReportSet = new Set();
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    addDownloadReportSet(new Set());
    setSelectAll(false);
  }, [pageNo, pageSize]);

  const [isLoading, setIsLoading] = useState(false);

  const [showMoreFilter, setShowMoreFilter] = useState(false);

  useEffect(() => {
    updateActiveNavItem("troubleshootingLogs");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetSelection = () => {
    addDownloadReportSet(new Set());
    setSelectAll(false);
  };

  return (
    <>
      <BreadCrumb />
      <Header
        title={""}
        setLockUnlockRecords={setTroubleShootingRecords}
        pageSize={pageSize}
        pageNo={pageNo}
        downloadReportSet={downloadReportSet}
        recordSet={troubleShootingRecords?.data}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showMoreFilter={showMoreFilter}
        setShowMoreFilter={setShowMoreFilter}
        setPageNo={setPageNo}
        resetSelection={resetSelection}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        /*  setIsFilterChanged={setIsFilterChanged} */
      />
      <DataTableContainer>
        <DataTableWrapper
          data={troubleShootingRecords?.data || []}
          rowHeight={370}
          previousPage={previousPage}
          nextPage={nextPage}
          handlePageLength={handlePageLength}
          pageSize={pageSize}
          pageNo={pageNo}
          totalCount={troubleShootingRecords?.count || 0}
          downloadReportSet={downloadReportSet}
          isAllSelected={isAllSelected}
          handleCheckboxClick={handleCheckboxClick}
          handleSelectAll={handleSelectAll}
          isLoadingRecords={isLoading}
          noDataMessage={
            selectedAccount.value ? t("NO_TROUBLESHOOT_LOGS_AVAILABLE") : t("SELECT_AN_ACCOUNT_TO_VIEW_LOGS")
          }
        />
      </DataTableContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.user.loginDetails,
    isAccountAdmin: state.user.isAccountAdmin,
    isAccountOwner: state.user.isAccountOwner,
    isSiteAdmin: state.user.isSiteAdmin,
    dropdownWorksiteList: state.dropdownFilters.worksiteList,
    loading: state.notificationSetting.loading,
    dropdownAssetId: state.dropdownFilters?.settings?.assetId,
    accountListLoading: state.dropdownFilters.accountListLoading,
    isSuperAdmin: state.user.isSuperAdmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TroubleShooting);
