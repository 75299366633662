import React, { useState, useEffect, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  TabWrapper,
  DropdownDiv,
  LeftIcon,
  RightIcon,
  ScrollContainer,
  OuterContainer,
  ScrollButton,
} from "./SieraTab.Component.styles";
import DropdownComponent from "../DropdownComponent/Dropdown.component";
import { ThemeContext } from "styled-components";

const SieraTabs = ({
  children,
  defaultActiveKey,
  onSelect,
  activeKey,
  variant = "horizontal",
  align = "left",
  size = "small",
  enableResponsiveDropdown = true,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);

  const varientStyle = {
    vertical: {
      displayTab: "block",
      flex: "",
      widthNav: "30%",
      widthTab: "70%",
      floatNav: "left",
      floatTab: "right",
      linebar: "0px",
      borderRad: "0px",
      borderRadBotLeft: "4px",
      paddingTab: "0px 0px 0px 10px",
      backgroundColor: themeContext?.colors?.themePrimary || "#0d5fbe",
      colorText: themeContext?.colors?.grayL7 || "#FFFFFF",
      bgColorHover: themeContext?.colors?.grayL6 || "#F2F2F2",
      bgColorHoverN: themeContext?.colors?.grayL5 || "#CCCCCC",
      borderBotoomCo: "none",
      fontWeight: "initial",
      padddingAch: "0.5rem 1rem",
      marginRig: "initial",
      borderBottomNav: `0px solid ${themeContext?.colors?.grayL6 || "#F2f2f2"}`,
    },
    horizontal: {
      displayTab: "flex",
      flex: "1",
      widthNav: "100%",
      widthTab: "100%",
      floatNav: "none",
      floatTab: "none",
      linebar: "2px",
      borderRad: "4px",
      borderRadBotLeft: "0px",
      paddingTab: "8px 0px 0px 0px",
      backgroundColor: themeContext?.colors?.themePrimary || "#0d5fbe",
      colorText: themeContext?.colors?.grayL7 || "#FFFFFF",
      bgColorHover: themeContext?.colors?.grayL6 || "#F2F2F2",
      bgColorHoverN: themeContext?.colors?.grayL5 || "#CCCCCC",
      borderBotoomCo: "none",
      fontWeight: "initial",
      padddingAch: "0.5rem 1rem",
      marginRig: "initial",
      borderBottomNav: `0px solid ${themeContext?.colors?.grayL6 || "#F2f2f2"}`,
    },
    horizontalNew: {
      displayTab: "flex",
      flex: "",
      widthNav: "100%",
      widthTab: "100%",
      floatNav: "none",
      floatTab: "none",
      linebar: "0px",
      borderRad: "0px",
      borderRadBotLeft: "0px",
      paddingTab: "8px 0px 0px 0px",
      backgroundColor: themeContext?.colors?.grayL7 || "#FFFFFF",
      colorText: themeContext?.colors?.grayL1 || "#323232",
      bgColorHover: themeContext?.colors?.grayL7 || "#FFFFFF",
      bgColorHoverN: themeContext?.colors?.grayL7 || "#FFFFFF",
      borderBotoomCo: themeContext?.colors?.themePrimary || "#0d5fbe",
      fontWeight: "500",
      padddingAch: "0px 0px 8px 0px",
      marginRig: "20px",
      borderBottomNav: `1px solid ${themeContext?.colors?.grayL6 || "#F2f2f2"}`,
    },
  };

  // const { t } = useTranslation();

  // const [selectedTab, setSelectedTab] = useState(defaultActiveKey);

  // useEffect(() => {
  //   if (activeKey !== undefined) {
  //     setSelectedTab(activeKey);
  //   }
  // }, [activeKey]);

  // const getContent = (eventKey) => {
  //   const selectedChild = React.Children.toArray(children).find((child) => child.props.eventKey === eventKey);
  //   return selectedChild ? selectedChild.props.children : null;
  // };

  // const handleTabSelect = (eventKey) => {
  //   setSelectedTab(eventKey);
  //   onSelect && onSelect(eventKey);
  //};

  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(defaultActiveKey);
  const outerRef = useRef(null);
  const navRef = useRef(null);
  const [showArrows, setshowArrows] = useState(false);

  useEffect(() => {
    if (activeKey !== undefined) {
      setSelectedTab(activeKey);
    }
  }, [activeKey]);

  useEffect(() => {
    if (outerRef.current) {
      const nav = outerRef.current.querySelector(".nav");
      if (nav) {
        navRef.current = nav;

        setshowArrows(nav.scrollWidth > nav.clientWidth);
      }
    }
  }, [children, selectedTab]);

  const scrollTabs = (direction) => {
    if (navRef.current) {
      navRef.current.scrollBy({ left: direction * 100, behavior: "smooth" });
    }
  };

  const handleTabSelect = (eventKey) => {
    setSelectedTab(eventKey);
    onSelect && onSelect(eventKey);
  };

  const getContent = (eventKey) => {
    const selectedChild = React.Children.toArray(children).find((child) => child.props.eventKey === eventKey);
    return selectedChild ? selectedChild.props.children : null;
  };

  return (
    <TabWrapper variant={variant} $varientStyle={varientStyle} align={align} size={size} {...rest}>
      {window.innerWidth > 768 || !enableResponsiveDropdown ? (
        <ScrollContainer $showArrows={showArrows}>
          {showArrows && (
            <ScrollButton left onClick={() => scrollTabs(-1)} className="tab-scroll-btn left">
              <LeftIcon className="icon icon-arrow-left" />
            </ScrollButton>
          )}
          <OuterContainer ref={outerRef}>
            <Tabs activeKey={selectedTab} onSelect={handleTabSelect} {...rest}>
              {children}
            </Tabs>
          </OuterContainer>
          {showArrows && (
            <ScrollButton onClick={() => scrollTabs(1)} className="tab-scroll-btn right">
              <RightIcon className="icon icon-arrow-right" />
            </ScrollButton>
          )}
        </ScrollContainer>
      ) : (
        enableResponsiveDropdown && (
          <DropdownDiv>
            <DropdownComponent
              options={React.Children.map(children, (child) => ({
                label: child?.props?.title,
                value: child?.props?.eventKey,
              }))}
              value={{
                value: selectedTab,
                label: React.Children.toArray(children).find((child) => child.props.eventKey === selectedTab)
                  ?.props.title,
              }}
              onChange={(selectedOption) => handleTabSelect(selectedOption.value)}
            />
            {getContent(selectedTab)}
          </DropdownDiv>
        )
      )}
    </TabWrapper>
  );
};

SieraTabs.propTypes = {
  /**
   * The default active tab when the component is first rendered.
   * It can be a string or a number corresponding to the eventKey of a tab.
   */
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Defines the layout style of the tabs.
   * Can be "vertical" (side navigation), "horizontal" (standard top navigation), or "horizontalNew" (modified styling for horizontal tabs).
   */
  variant: PropTypes.oneOf(["vertical", "horizontal", "horizontalNew"]),

  /**
   * Defines the alignment of tabs when displayed.
   * Can be "left", "middle", or "right".
   */
  align: PropTypes.oneOf(["left", "middle", "right"]),

  /**
   * Callback function triggered when a tab is selected.
   * Receives the eventKey of the selected tab as an argument.
   */
  onSelect: PropTypes.func,

  /**
   * The currently active tab.
   * Useful for controlled components where the active tab is managed by a parent component.
   * Can be a string or a number.
   */
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Determines the size of the tabs.
   * Can be "small", "medium", or "large".
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Enable or disable responsive dropdown transformation.
   * If true, tabs convert to a dropdown below 768px.
   */
  enableResponsiveDropdown: PropTypes.bool,
};

export default SieraTabs;
