import styled from "styled-components";

export const DashboardContainer = styled.div`
  position: relative;
`;

export const CarouselContainer = styled.div``;

export const CarouselDiv = styled.div``;
export const LeftArrowIcon = styled.div`
  font-size: 10px !important;
  float: right;
  position: relative;
  top: 3px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
`;

export const ImpactSeverty = styled.div`
  border: 1px solid
    ${({ severity, theme }) =>
      severity === "HIGH"
        ? theme.colors.redPrimary || "#DD0000"
        : severity === "MEDIUM"
          ? theme.colors.orangePrimary || "#DD9000"
          : "transparent"};
  padding: 2px;
  border-radius: 4px;
  text-align: center;
  line-height: ${({ theme }) => theme.lineHeights.line8};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
`;

export const ImpactGDiv = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  text-align: center;
`;

export const HighMediumImpactDiv = styled.div`
  .noReportsDiv {
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    overflow-y: scroll;
    height: calc(-565px + 100vh);
  }
  .rowHeight {
    height: auto !important;
    max-height: calc(-565px + 100vh);
  }
`;

export const NoReportsDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  overflow-y: scroll;
  height: calc(-565px + 100vh);
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const GraphContainer = styled.div`
  padding-top: 8px;
  border: 1px solid #eee;
  margin-bottom: 8px;
  .apexcharts-toolbar {
    z-index: 1 !important;
  }
  .apexcharts-menu-item {
    padding: 4px 0px;
    text-align: center;
    margin-bottom: 2px;
    &:hover {
      background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      color: ${({ theme }) => theme.colors.gray7 || "#FFFFFF"};
    }
  }
`;

export const StyledCol = styled.div`
  text-align: left;
  margin-top: 20px;
  display: flex;
  @media screen and (max-width: 1100px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftSection = styled.div`
  flex: 2;
  width: 66%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const RightSection = styled.div`
  flex: 1;
  width: 34%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
