import styled from "styled-components";

export const SettingWithButtonWrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px;
  padding-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);*/
`;

export const ButtonWrapper = styled.div`
  /* margin: 15px 15px 0;*/
`;

export const SettingWrapper = styled.div`
  margin: 8px 16px;
  text-align: left;
`;

export const ActionBar = styled.div`
  /*display: flex;
  justify-content: space-between;
  padding-top: 10px;*/
`;

export const DropdownWrapper = styled.div`
  /*float: left;
  width: 30%;
  margin-left: 15px;*/
`;
