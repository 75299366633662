import styled, { keyframes } from "styled-components";

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;

export const HeaderTitle = styled.div`
  background-image: linear-gradient(30deg, #0d5fbe 0%, #0d5fbe 100%);
  min-height: 38px;
  height: auto;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  text-align: left;
  @media (max-width: 768px) {
    height: auto;
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    padding: 10px;
  }
`;

export const FilterWrapper = styled.div`
  padding: 8px 16px;
  position: relative;
  z-index: 2;
  display: flex;
`;

export const LoginRecordsStyle = styled.div`
  margin: 15px auto;
`;

export const StyledCol = styled.div`
  text-align: left;
  margin-top: 20px;
  margin-bottom: auto;
  display: flex;
  flex: 0 17%;
  @media screen and (max-width: 1100px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  button[title="Apply Filter"] {
    padding: initial;
    width: 100%;
  }
`;

export const RefreshButtonDiv = styled.div`
  overflow: hidden;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0px 24px 0 0px;

  @media (max-width: 800px) {
    height: 100%;
    margin-right: 0;
  }
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RefreshButton = styled.div`
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  background-size: contain;
  background-repeat: no-repeat;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  float: right;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 1s linear;
  transform-origin: center;

  &.rotate {
    animation: ${rotateAnimation} 1s linear;
  }
`;

export const TitleContainer = styled.div`
  background-image: linear-gradient(30deg, #0d5fbe 0%, #0d5fbe 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
`;
