export const filterType = {
  //For filter
  SET_INSPECTION_REPORT_FILTER: "SET_INSPECTION_REPORT_FILTER",
  RESET_INSPECTION_REPORT_FILTER: "RESET_INSPECTION_REPORT_FILTER",

  SET_INCIDENT_REPORT_FILTER: "SET_INCIDENT_REPORT_FILTER",
  RESET_INCIDENT_REPORT_FILTER: "RESET_INCIDENT_REPORT_FILTER",

  SET_NEAR_MISS_REPORT_FILTER: "SET_NEAR_MISS_REPORT_FILTER",
  RESET_NEAR_MISS_REPORT_FILTER: "RESET_NEAR_MISS_REPORT_FILTER",

  SET_UNAUTHORIZED_ACCESS_FILTER: "SET_UNAUTHORIZED_ACCESS_FILTER",
  RESET_UNAUTHORIZED_ACCESS_FILTER: "RESET_UNAUTHORIZED_ACCESS_FILTER",
};
