import styled from "styled-components";
export const DropdownWrapper = styled.div`
  margin-right: 20px;
  float: left;
  width: 15%;
  margin-top: -3px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const S3settingBottomDiv = styled.div`
  display: block;
  width: 100%;
  float: right;
  overflow: hidden;
  text-align: right;
  margin-bottom: 30px;
  .update-button-s2 {
    margin-top: 0px !important;
    float: inherit;
  }
`;

export const ResetToDefaultDiv = styled.div`
  float: right;
  width: 160px;
  text-align: center;
  line-height: 40px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 300px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
