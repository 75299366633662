import React from "react";
import { QRCodeLabel, QRCodeWrapper } from "./QrCode.styles";
import config from "../../config";
const operatorBaseURL = config.operatorBaseUrl; //Base Url
const assetBaseURL = config.redirectAssetQrScan; // Operator asset qr scan redirect
import { QRCodeSVG } from "qrcode.react";

const getTrimmedLabel = (label) => {
  if (label && label.length > 12) {
    return label.substring(0, 8) + "..." + label.substring(label.length - 3);
  } else {
    return label;
  }
};

const QrCode = ({ isUser, qrCodeDetails, size, inputEl, fgColor }) => {
  const { label, id } = qrCodeDetails;
  return (
    <div id="qrCodeIdWrapper">
      <QRCodeWrapper className="" ref={inputEl}>
        <QRCodeSVG
          fgColor={fgColor || "#0D5FBE"}
          size={size || 55}
          value={(isUser ? operatorBaseURL : assetBaseURL) + id}
        />

        <QRCodeLabel className="text-center">
          <span title={label}>{getTrimmedLabel(label)}</span>
        </QRCodeLabel>
      </QRCodeWrapper>
    </div>
  );
};

export default QrCode;
