import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { setManagementActionBar } from "../../../redux/mobileView/action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { setEditAlertModal } from "../../../redux/modals/action";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import managementStyles from "./management.module.css";
import DropdownComponent from "../../../components/DropdownComponent/Dropdown.component";
import {
  ManagementTabHeader,
  DotIcon,
  CancelIcon,
  MobileTabBar,
  TabViewDiv,
  IconActionBar,
  IconDiv,
  CanIconDiv,
  DotIconBack,
  DesktopTab,
} from "./ManagementTabBar.component.style";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SieraTab from "../../../components/TabComponent/SieraTab.Component";

function ManagementTabBar({
  isSuperAdmin,
  isAccountOwner,
  isAccountAdmin,
  activeTab,
  showActionBar,
  setManagementActionBar,
  updateActiveNavItem,
  menuList,
  editForm,
  editFormSecond,
  setEditAlertModal,
  permissions,
}) {
  const { t } = useTranslation();

  const handleSelectDropdown = (selectedOption) => {
    if (activeTab === selectedOption.value) {
      return;
    }
    if (!editForm || !editFormSecond) {
      setEditAlertModal(true);
      return;
    }
    updateActiveNavItem(selectedOption.value);
  };
  const handleSelectTab = (selectedKey) => {
    if (activeTab === selectedKey) return;
    if (!editForm || !editFormSecond) {
      setEditAlertModal(true);
      return;
    }
    updateActiveNavItem(selectedKey);
  };

  return (
    <>
      <ManagementTabHeader>
        <DesktopTab>
          <SieraTabs variant="horizontalNew" size="medium" activeKey={activeTab} onSelect={handleSelectTab}>
            {permissions?.some(
              (p) =>
                p.section === "DASHBOARD" &&
                p.permissions?.some((per) => per.name === "ACCOUNT_MANAGEMENT" && per.action.view)
            ) && <SieraTab eventKey="accounts" title={t("Accounts")}></SieraTab>}
            {permissions?.some(
              (p) =>
                p.section === "DASHBOARD" &&
                p.permissions?.some((per) => per.name === "WORKSITE_MANAGEMENT" && per.action.view)
            ) && <SieraTab eventKey="worksites" title={t("Worksites")}></SieraTab>}
            {permissions?.some(
              (p) =>
                p.section === "DASHBOARD" &&
                p.permissions?.some((per) => per.name === "ASSET_MANAGEMENT" && per.action.view)
            ) && (
              <SieraTab eventKey="assets" title={t("Assets")}>
                {/* <Trans>Assets</Trans> */}
              </SieraTab>
            )}
            {permissions?.some(
              (p) =>
                p.section === "DASHBOARD" &&
                p.permissions?.some((per) => per.name === "USER_MANAGEMENT" && per.action.view)
            ) && (
              <SieraTab eventKey="users" title={t("Users")}>
                {/* <Trans>Users</Trans> */}
              </SieraTab>
            )}
          </SieraTabs>
        </DesktopTab>
        <MobileTabBar>
          <TabViewDiv>
            <DropdownComponent
              value={{
                value: activeTab,
                label: t(
                  activeTab
                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                    .charAt(0)
                    .toUpperCase() + activeTab.replace(/([a-z])([A-Z])/g, "$1 $2").slice(1)
                ),
              }}
              handleFilter={handleSelectDropdown}
              options={[
                ...(isSuperAdmin ? [{ value: "accounts", label: t("Accounts") }] : []),
                ...(isSuperAdmin || isAccountOwner || isAccountAdmin
                  ? [{ value: "worksites", label: t("Worksites") }]
                  : []),
                { value: "assets", label: t("Assets") },
                { value: "users", label: t("Users") },
                { value: "assetType", label: t("Asset Type") },
                { value: "userType", label: t("User Type") },
              ]}
              size="medium"
            />
          </TabViewDiv>
          <IconActionBar>
            <IconDiv onClick={() => setManagementActionBar(!showActionBar)}>
              {showActionBar ? (
                <CanIconDiv>
                  <CancelIcon className="icon icon-cancel"></CancelIcon>
                </CanIconDiv>
              ) : (
                <DotIconBack>
                  <DotIcon className="icon icon-ellipsis-v"></DotIcon>
                </DotIconBack>
              )}
            </IconDiv>
          </IconActionBar>
        </MobileTabBar>
      </ManagementTabHeader>
    </>
  );
}

const mapStateToProps = (state) => ({
  showActionBar: state.mobileView.showManagementActionBar,
  activeTab: state.sideMenu.activeNavItem,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isAccountAdmin: state.user.isAccountAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  menuList: state.sideMenu.menuState,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  setManagementActionBar: (data) => dispatch(setManagementActionBar(data)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementTabBar));
