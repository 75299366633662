import React from "react";
import UserQrTableWrapper from "../../../../components/DataTableComponent/DataTable.component";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import Checkbox from "../../../../components/Checkbox/Checkbox.component";

import styled from "styled-components";
const DownloadStyled = styled.div`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: 18px !important;
`;

const NoReportsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  overflow-y: hidden !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  clear: both;
`;
const AnchorStyled = styled.a`
  cursor: pointer;
  text-align: center;
`;

const StyledSpan = styled.span`
  height: ${(props) => `calc(100vh - ${props.height}px)`};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  clear: both;
`;

const UserQrCodeListTable = ({
  data,
  isSiteAdmin,
  isSuperAdmin,
  handleUserSelectAll,
  isUserAllSelected,
  handleUserCheckboxClick,
  userListDownloadSetIds,
  downloadQrCode,
  paginationOptions,
  noDataMessage,
  loading,
  pageSize,
  pageNo,
  totalPageReport,
  nextPage,
  previousPage,
  handlePageLength,
  rowHeight,
  dropdownAccountId,
  accountNameArray,
}) => {
  const { t } = useTranslation();

  const userTableRowData = [
    { name: "Account", keyName: "accountName", space: 2, fieldName1: "accountId" },
    {
      name: "Worksite",
      keyName: "worksite",
      space: 2,
      isNestedVar3: true,
      fieldName1: "authorizedSites",
      fieldName2: "0",
      fieldName3: "name",
    },
    {
      name: "User Type",
      keyName: "userTypeId",
      space: 1,
      isNestedVar2: true,
      fieldName1: "userTypeId",
      fieldName2: "name",
    },
    { name: "User Name", keyName: "fullName", space: 1, fieldName: "fullName" },
    { name: "Added On", keyName: "createdAt", space: 1, isDate: true, fieldName: "createdAt" },
    { name: "Action", space: 2, fieldName: "inspectionCountMonthly" },
  ];

  const ActionCell = ({ row }) => {
    return (
      <>
        <AnchorStyled name={data?.assetName} onClick={(e) => downloadQrCode(e, row)}>
          <DownloadStyled className="icon icon-download" />
        </AnchorStyled>
      </>
    );
  };

  const columns = userTableRowData
    .filter(
      (data) => !((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite"))
    )
    .map((data) => {
      if (data.name === "Account") {
        return {
          name: t(data.name),
          selector: (row) => {
            const accountName = accountNameArray.get(row?.accountId);
            return accountName || "NA";
          },
          sortable: true,
        };
      }

      if (data.name === "Worksite") {
        return {
          name: t(data.name),
          selector: (row) => row?.authorizedSites?.[0]?.name || "NA",
          sortable: true,
        };
      }

      if (data.name === "User Type") {
        return {
          name: t(data.name),
          selector: (row) => row?.userTypeId?.name || "NA",
          sortable: true,
        };
      }

      if (data.name === "User Name") {
        return {
          name: t(data.name),
          selector: (row) => row?.fullName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Added On") {
        return {
          name: t(data.name),
          selector: (row) => moment(row?.createdAt).format("DD/MM/YYYY") || "NA",
          sortable: true,
        };
      }

      if (data.name === "Action") {
        return {
          name: t(data.name),
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
          title: "Download QR Code",
        };
      }

      return {
        name: t(data.name),
        selector: (row) => row[data.fieldName] || "NA",
        sortable: true,
      };
    });

  const mobileCheckboxDateTime = [
    { columnName: "Account", className: "accountStyle" },
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Added On", className: "worksiteStyle" },
  ];

  const mobileCheckboxAccordion = ["Account", "Worksite", "User Type", "User Name", "Action"];

  if (!Array.isArray(data)) {
    return <NoReportsDiv>{t("No Users List Available")}</NoReportsDiv>;
  }

  const columnsWithCheckboxes = [
    {
      name: <Checkbox type="checkbox" onClick={(e) => handleUserSelectAll(e)} checked={isUserAllSelected} />,
      width: 34,
      selector: (row) => (
        <Checkbox
          onClick={(e) => handleUserCheckboxClick(e, row)}
          value={row}
          checked={userListDownloadSetIds.has(row._id)}
        />
      ),
    },
  ];

  columns.unshift(...columnsWithCheckboxes);

  return (
    <>
      {isSuperAdmin && (!dropdownAccountId || dropdownAccountId === "all") ? (
        <StyledSpan height={rowHeight}>
          <Trans>Please select an account to view details</Trans>
        </StyledSpan>
      ) : (
        <UserQrTableWrapper
          title=""
          columns={columns}
          data={data}
          mobileCheckboxDateTime={mobileCheckboxDateTime}
          mobileCheckboxAccordion={mobileCheckboxAccordion}
          responsive
          dense
          loading={loading}
          rowHeight={rowHeight}
          scrollable={true}
          fixedHeader
          mobileCheckboxOnly
          mobileDateTimeOnly
          mobileActionItemOnly
          noDataMessage={t("No Users List Available")}
          rowsPerPageOptions={[5]}
          pagination={true}
          pageSize={pageSize}
          pageNo={pageNo}
          nextPage={nextPage}
          previousPage={previousPage}
          handlePageLength={handlePageLength}
          paginationOptions={paginationOptions}
          totalPageReport={totalPageReport}
        />
      )}
    </>
  );
};

export default UserQrCodeListTable;
