import styled from "styled-components";

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;

export const HeaderTitle = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary} 0%,
    ${({ theme }) => theme.colors.themePrimary} 100%
  );
  min-height: 38px;
  height: auto;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  text-align: left;
  @media (max-width: 768px) {
    height: auto;
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  }
`;

export const FilterWrapper = styled.div`
  padding: 8px 16px;
  position: relative;
  z-index: 2;
`;
