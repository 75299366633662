import styled from "styled-components";
import { InformationIcon } from "../../../../assets";

export const StyledInformationIcon = styled(InformationIcon)`
  width: 17px;
  margin-left: 7px;
  cursor: pointer;
`;

export const PopoverComponentWrapper = styled.div``;

export const WorksiteCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const StyledLabel = styled.label`
  margin: 0;
  display: flex;
  align-items: center;
`;

export const HeadingComponent = styled.div`
  font-family: "kanit";
  font-size: 1.1rem !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-bottom: 0.2rem;
  @media (min-width: 1470px) {
    font-size: 1.2rem !important;
  }
`;

export const HeadingDetailsComponent = styled.div`
  font-size: 0.8rem !important;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: "Open Sans", sans-serif;
  padding-bottom: 10px;
  @media (min-width: 1470px) {
    font-size: 0.9rem !important;
  }
`;

export const SettingsBody = styled.div`
  margin: 8px 16px;
  text-align: left;
`;

export const SettingsContainer = styled.div`
  height: calc(100vh - 325px);
  overflow: auto;
  overflow-x: hidden;
 
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  @media (max-width: 768px) {
    height: auto;
    overflow: hidden;
  }
`;

export const SettingsFooter = styled.div`
  overflow: hidden;
  margin: 8px 16px;
  text-align: left;
  .update-button-s2 {
    margin: 0px !important;
    padding: 0px !important;
    width: 100%;
    display: flex;
    .worksite-names {
      padding-right: 0px;
      flex: 1;
      float: left;
      text-align: left;
      display: block !important;
      max-width: initial;
      padding: 0px !important;
    }
    .col-md-8 {
      padding-right: 0px;
      flex: 1;
    }

    @media (max-width: 430px) {
      .worksite-names button {
        padding: 6px 7px;
      }
      .col-md-8 button {
        padding: 6px 7px;
      }
      .apptoall {
        position: relative;
        right: 0;
        left: 100px;
        width: 230px;
      }
    }
  }
`;

export const HeadingComponentTwo = styled.div`
  font-size: 16px !important;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: "Open Sans", sans-serif;
  @media (min-width: 1470px) {
    font-size: 13px !important;
  }
`;
export const HeadingDetailsTwoComponent = styled.div`
  font-size: 14px !important;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-family: "Open Sans", sans-serif;
  padding-bottom: 10px;
  @media (min-width: 1470px) {
    font-size: 13px !important;
  }
`;
export const HeadingComponentThree = styled.div`
  font-size: 16px !important;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: "Open Sans", sans-serif;
  @media (min-width: 1470px) {
    font-size: 13px !important;
  }
`;
export const StyledSelect = styled.select`
  height: 35px;
  width: auto;
  font-size: 13px;
  border-radius: 3px;
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  padding: 0px;
  margin-left: 10px;
`;
export const CustomMessage = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  padding-bottom: 5px;
  @media (min-width: 1470px) {
    font-size: 0.7rem !important;
  }
`;
export const TextareaComponent = styled.textarea`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  font-size: 0.9rem !important;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-radius: 3px !important;
  font-family: "Open Sans", sans-serif;
  padding: 5px;
  background-color: #f9f9f9 !important;
  border-top: 1px solid ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  border-left: 1px solid ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  border-right: 1px solid ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  &:active {
    border: 1px solid ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  @media (min-width: 1470px) {
    font-size: 1rem !important;
  }
`;

export const CustomMessageDetails = styled.div`
  font-size: 0.7rem !important;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-family: "Open Sans", sans-serif;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 1470px) {
    font-size: 0.9rem !important;
  }
`;

export const DurationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  margin-right: 15px;
`;

export const StyledInputIcons = styled.div``;

export const StyledInput = styled.input`
  text-align: right;
  width: 90px;
  font-family: "Open Sans", sans-serif;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
`;

export const OnOffText = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin: 0rem 0px 0 0;
  font-family: "Open Sans", sans-serif;
`;

export const HoursWrapper = styled.div`
  padding: 2px 11px;
  margin-left: 10px;
  font-size: 13px;
  height: 35px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  line-height: 29px;
`;

export const CharacterCounter = styled.div`
  color: ${({ maxCount }) => (maxCount ? "#dc3545" : "initial")};
  text-align: right;
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  width: -webkit-fill-available;
  height: calc(100vh - 260px);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;