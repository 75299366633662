import styled from "styled-components";

export const AssetOverviewPage = styled.div``;
export const AssetChecklistTableWrapper = styled.div`
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    .forResponsive {
      display: none;
    }
  }
`;

export const RowDiv = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  background-color: ${({ isHeader, theme }) =>
    isHeader ? theme.colors.grayL6 || "#F2F2F2" : theme.colors.grayL7 || "#FFFFFF"};
  margin-right: 1%;
  @media (max-width: 1100px) {
    display: block;
    margin-bottom: ${({ isHeader }) => (isHeader ? "0px" : "25px")};
  }
`;

export const Column = styled.div`
  border-bottom: ${({ isHeader, theme }) =>
    isHeader
      ? "0px solid " + theme.colors.grayL5 || "#CCCCCC"
      : "1px solid " + theme.colors.grayL5 || "#CCCCCC"};
  padding: 0.35rem 0.75rem 0.35rem 0.75rem;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ isHeader, theme }) =>
    isHeader ? theme.fontSizes.font7 : theme.fontSizes.font7} !important;
  font-family: ${({ isHeader, theme }) =>
    isHeader ? theme.fontFamily.secondaryFont : theme.fontFamily.primaryFont};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: ${({ isHeader }) => (isHeader ? "flex;" : "flow-root;")};
  align-items: center;
  justify-content: ${({ align }) => (align ? align : "left")};
  text-align: ${({ textleft }) => (textleft ? textleft : "left")};
  label {
    margin: 0 !important;
  }
  @media (max-width: 1100px) {
    display: ${({ isHeader }) => (isHeader ? "none;" : "block;")};
    width: ${({ widthResp }) => (widthResp ? widthResp : "75%")};
    float: ${({ floatResp }) => (floatResp ? floatResp : "left")};
    margin-right: ${({ RmarginResp }) => (RmarginResp ? RmarginResp : "5%")};
    padding: 7px;
  }
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
  .vierDeleteButton {
    display: block;
    text-align: center;
    width: 100%;
    @media (max-width: 1100px) {
      text-align: right;
    }
  }

  // Responsive styling
  @media (max-width: 1100px) {
    display: block;
    width: 100%;
    float: none;
    margin-right: 0;
    padding: 7px;

    &:nth-child(odd) {
      text-align: right;
    }

    &:nth-child(even) {
      text-align: right;
    }
  }
`;

export const RowsContainer = styled.div`
  max-height: 200px;
  overflow: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }

  height: calc(100vh - 605px);
  @media (max-width: 1100px) {
    margin-bottom: 50px;
    height: auto;
  }
`;

export const NoSectionContainer = styled.div`
  height: calc(100vh - 555px);
  display: inline-block;
  align-content: center;
  width: 100%;
  @media (max-width: 1100px) {
    height: 150px;
  }
`;
export const SpanDiv = styled.div`
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  line-height: ${({ theme }) => theme.lineHeights.line6};
`;

export const ViewIconButton = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.font4};
  line-height: 26px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  margin-right: 5px;
  &:hover {
    color: ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"} !important;
  }
`;

export const DownloadStyledButton = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.font4};
  cursor: pointer;
  line-height: 26px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  margin-left: 5px;
  &:hover {
    color: ${({ theme }) => theme.colors.themePrimaryDarker || "#004AA0"} !important;
  }
`;

export const MobileResponsive = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: block;
    float: left;
    font-weight: bold;
    margin-right: 20px;
  }
`;

export const ButtonWrapperDiv = styled.div`
  button.PassFail {
    border: 1px solid
      ${({ theme, status }) =>
        ({
          passed: theme.colors.greenPrimary || "#11A70B",
          failed: theme.colors.redPrimary || "#DD0000",
          timedout: theme.colors.grayL1 || "#323232",
        })[status]};
    height: 25px;
    width: 200px;
    font-size: ${({ theme }) => theme.fontSizes.font7};
    position: relative;
    overflow: hidden;
    line-height: ${({ theme }) => theme.lineHeights.line6};
    /* display: flex; */
    align-items: center;
    justify-content: center;
  }

  .status-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
  }

  .status-text,
  .checklist-text {
    position: absolute;
    width: 100%;
    text-align: center;
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
    opacity: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status-text {
    transform: translateX(0);
    opacity: 1;
    text-transform: capitalize;
    i {
      padding: 0px 4px;
      display: flex;
      vertical-align: middle;
      align-items: center;
      float: right;
      height: 22px;
    }
  }

  .checklist-text {
    transform: translateX(100%);
  }

  button.PassFail:hover .status-text {
    transform: translateX(-100%);
    opacity: 0;
  }

  button.PassFail:hover .checklist-text {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const ButtonWrapper = styled.button`
  cursor: default;
`;
