import styled from "styled-components";
// Navigation Menu Items Sub Title Text
export const NavItemSubText = styled.span`
  font-family: "Poppins";
  font-size: ${({ theme }) => theme.fontSizes.font6};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
`;

export const SidebarMenuDiv = styled.div``;
