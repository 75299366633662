let backendTimeZones = [
  { label: "Afghanistan Standard Time", _id: "+04:30 Kabul" },
  { label: "Alaskan Daylight Time", _id: "-08:00 Alaska" },
  { label: "Alaskan Standard Time", _id: "-09:00 Alaska" },
  { label: "Arab Standard Time", _id: "+03:00 Kuwait, Riyadh" },
  { label: "Arabian Standard Time", _id: "+04:00 Abu Dhabi, Muscat" },
  { label: "Arabic Standard Time", _id: "+03:00 Baghdad" },
  { label: "Argentina Standard Time", _id: "-03:00 Buenos Aires" },
  { label: "Arizona Mountain Standard Time", _id: "-07:00 Arizona" },
  { label: "Atlantic Standard Time", _id: "-04:00 Atlantic Time (Canada)" },
  { label: "AUS Central Standard Time", _id: "+09:30 Darwin" },
  { label: "AUS Eastern Standard Time", _id: "+10:00 Canberra, Melbourne, Sydney" },
  { label: "Azerbaijan Standard Time", _id: "+04:00 Baku" },
  { label: "Azores Standard Time", _id: "-01:00 Azores" },
  { label: "Bahia Standard Time", _id: "-03:00 Salvador" },
  { label: "Bangladesh Standard Time", _id: "+06:00 Dhaka" },
  { label: "British Summer Time", _id: "+01:00 Edinburgh, London" },
  { label: "Canada Central Standard Time", _id: "-06:00 Saskatchewan" },
  { label: "Cape Verde Standard Time", _id: "-01:00 Cape Verde Is." },
  { label: "Caucasus Standard Time", _id: "+04:00 Yerevan" },
  { label: "Cen. Australia Standard Time", _id: "+09:30 Adelaide" },
  { label: "Central Asia Standard Time", _id: "+06:00 Nur-Sultan (Astana)" },
  { label: "Central Brazilian Standard Time", _id: "-04:00 Cuiaba" },
  { label: "Central Daylight Time", _id: "-05:00 Central America" },
  {
    label: "Central Europe Daylight Time",
    _id: "+02:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    label: "Central Europe Standard Time",
    _id: "+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  { label: "Central Pacific Standard Time", _id: "+11:00 Solomon Is., New Caledonia" },
  { label: "Central Standard Time", _id: "-06:00 Central Time (US & Canada)" },
  { label: "Central Standard Time (Mexico)", _id: "-06:00 Guadalajara, Mexico City, Monterrey" },
  { label: "China Standard Time", _id: "+08:00 Beijing, Chongqing, Hong Kong, Urumqi" },
  { label: "Dateline Standard Time", _id: "-12:00 International Date Line West" },
  { label: "E. Africa Standard Time", _id: "+03:00 Nairobi" },
  { label: "E. Australia Standard Time", _id: "+10:00 Brisbane" },
  { label: "E. Europe Standard Time", _id: "+02:00 E. Europe" },
  { label: "E. South America Standard Time", _id: "-03:00 Brasilia" },
  { label: "Eastern Daylight Time", _id: "-04:00 Eastern Time (US & Canada)" },
  { label: "Eastern Standard Time", _id: "-05:00 Eastern Time (US & Canada)" },
  { label: "Egypt Standard Time", _id: "+02:00 Cairo" },
  { label: "Fiji Standard Time", _id: "+12:00 Fiji" },
  { label: "FLE Standard Time", _id: "+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
  { label: "Georgian Standard Time", _id: "+04:00 Tbilisi" },
  { label: "GMT Standard Time, Dublin", _id: "00:00 Dubln, Lisbon" },
  { label: "GMT Standard Time, Edinburgh", _id: "00:00 Edinburgh, London" },
  { label: "Greenland Standard Time", _id: "-03:00 Greenland" },
  { label: "Greenwich Standard Time", _id: "00:00 Monrvia, Reykjavik" },
  { label: "GTB Standard Time", _id: "+02:00 Athens, Bucharest" },
  { label: "Hawaiian Standard Time", _id: "-10:00 Hawaii" },
  { label: "India Standard Time", _id: "+05:30 Chennai, Kolkata, Mumbai, New Delhi" },
  { label: "Iran Standard Time", _id: "+03:30 Tehran" },
  { label: "Israel Standard Time", _id: "+02:00 Jerusalem" },
  { label: "Japan Standard Time", _id: "+09:00 Osaka, Sapporo, Tokyo" },
  { label: "Jordan Standard Time", _id: "+03:00 Amman" },
  { label: "Kaliningrad Standard Time", _id: "+02:00 Kaliningrad" },
  { label: "Kamchatka Standard Time", _id: "+12:00 Petropavlovsk-Kamchatsky - Old" },
  { label: "Korea Standard Time", _id: "+09:00 Seoul" },
  { label: "Libya Standard Time", _id: "+02:00 Tripoli" },
  { label: "Magadan Standard Time", _id: "+12:00 Magadan" },
  { label: "Mauritius Standard Time", _id: "+04:00 Port Louis" },
  { label: "Mid-Atlantic Standard Time", _id: "-02:00 Mid-Atlantic - Old" },
  { label: "Middle East Standard Time", _id: "+02:00 Beirut" },
  { label: "Montevideo Standard Time", _id: "-03:00 Montevideo" },
  { label: "Morocco Standard Time", _id: "00:00 Casalanca" },
  { label: "Moscow Standard Time", _id: "+03:00 Moscow, St. Petersburg, Volgograd, Minsk" },
  { label: "Mountain Daylight Time", _id: "-06:00 Mountain Time (US & Canada)" },
  { label: "Mountain Standard Time", _id: "-07:00 Mountain Time (US & Canada)" },
  { label: "Mountain Standard Time (Mexico)", _id: "-07:00 Chihuahua, La Paz, Mazatlan" },
  { label: "Myanmar Standard Time", _id: "+06:30 Yangon (Rangoon)" },
  { label: "N. Central Asia Standard Time", _id: "+07:00 Novosibirsk" },
  { label: "Namibia Standard Time", _id: "+01:00 Windhoek" },
  { label: "Nepal Standard Time", _id: "+05:45 Kathmandu" },
  { label: "New Zealand Standard Time", _id: "+12:00 Auckland, Wellington" },
  { label: "Newfoundland Standard Time", _id: "-03:30 Newfoundland" },
  { label: "North Asia East Standard Time", _id: "+08:00 Irkutsk" },
  { label: "North Asia Standard Time", _id: "+08:00 Krasnoyarsk" },
  { label: "Pacific Daylight Time", _id: "-07:00 Pacific Time (US & Canada)" },
  { label: "Pacific SA Standard Time", _id: "-04:00 Santiago" },
  { label: "Pacific Standard Time", _id: "-08:00 Pacific Time (US & Canada)" },
  { label: "Pacific Standard Time (Mexico)", _id: "-08:00 Baja California" },
  { label: "Pakistan Standard Time", _id: "+05:00 Islamabad, Karachi" },
  { label: "Paraguay Standard Time", _id: "-04:00 Asuncion" },
  { label: "Romance Standard Time", _id: "+01:00 Brussels, Copenhagen, Madrid, Paris" },
  { label: "SA Eastern Standard Time", _id: "-03:00 Cayenne, Fortaleza" },
  { label: "SA Pacific Standard Time", _id: "-05:00 Bogota, Lima, Quito" },
  { label: "SA Western Standard Time", _id: "-04:00 Georgetown, La Paz, Manaus, San Juan" },
  { label: "Samara Time", _id: "+04:00 Samara, Ulyanovsk, Saratov" },
  { label: "Samoa Standard Time", _id: "+13:00 Samoa" },
  { label: "SE Asia Standard Time", _id: "+07:00 Bangkok, Hanoi, Jakarta" },
  { label: "Singapore Standard Time", _id: "+08:00 Kuala Lumpur, Singapore" },
  { label: "South Africa Standard Time", _id: "+02:00 Harare, Pretoria" },
  { label: "Sri Lanka Standard Time", _id: "+05:30 Sri Jayawardenepura" },
  { label: "Syria Standard Time", _id: "+02:00 Damascus" },
  { label: "Taipei Standard Time", _id: "+08:00 Taipei" },
  { label: "Tasmania Standard Time", _id: "+10:00 Hobart" },
  { label: "Tonga Standard Time", _id: "+13:00 Nuku'alofa" },
  { label: "Turkey Standard Time", _id: "+03:00 Istanbul" },
  { label: "Ulaanbaatar Standard Time", _id: "+08:00 Ulaanbaatar" },
  { label: "UTC", _id: "+00:00 Coordinated Universal Time" },
  { label: "UTC-02", _id: "-02:00 Coordinated Universal Time-02" },
  { label: "UTC-11", _id: "-11:00 Coordinated Universal Time-11" },
  { label: "UTC+12", _id: "+12:00 Coordinated Universal Time+12" },
  { label: "Venezuela Standard Time", _id: "-04:30 Caracas" },
  { label: "Vladivostok Standard Time", _id: "+11:00 Vladivostok" },
  { label: "W. Australia Standard Time", _id: "+08:00 Perth" },
  { label: "W. Central Africa Standard Time", _id: "+01:00 West Central Africa" },
  { label: "West Asia Standard Time", _id: "+05:00 Ashgabat, Tashkent" },
  { label: "West Pacific Standard Time", _id: "+10:00 Guam, Port Moresby" },
  { label: "Yakutsk Standard Time", _id: "+09:00 Yakutsk" },
  { label: "Yekaterinburg Time", _id: "+05:00 Yekaterinburg" },
];

export default backendTimeZones;
