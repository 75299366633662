import React from "react";
import * as htmlToImage from "html-to-image";
import { Trans } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";
import styled from "styled-components";
import config from "../config"; //Base Url

const QRCodeStyle = styled.div`
  .QrCodeName {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  a.anchorStyle {
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;

const baseUrlOperator = config.operatorBaseUrl; //Base Url
const redirectAssetQrScan = config.redirectAssetQrScan; // Operator asset qr scan redirect

const QrCode = ({ qrDetail, qrFor, bindUrl, fgColor }) => {
  const downloadQr = (e) => {
    let name = e.target.name;
    htmlToImage.toSvg(document.getElementById("assetQrCode"), { quality: 0.95 }).then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = `${name}.svg`;
      link.href = dataUrl;
      link.click();
    });
  };

  return (
    <QRCodeStyle>
      <div id="assetQrCode" className="bg-white">
        <QRCodeSVG
          fgColor={fgColor}
          size={55}
          value={
            qrDetail ? (bindUrl ? redirectAssetQrScan : baseUrlOperator) + qrDetail[qrFor.qrContent] : ""
          }
        />

        <div
          className="text-center QrCodeName"
          style={{
            fontFamily: "Open Sans",
            fontSize: "12px",
            padding: "0px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span title={qrDetail ? qrDetail[qrFor.qrLabel] : ""}>
            {" "}
            {qrDetail ? qrDetail[qrFor.qrLabel] : ""}
          </span>
        </div>
      </div>
      <a
        onClick={downloadQr}
        name={qrDetail ? qrDetail[qrFor.qrLabel] : ""}
        download
        className="anchorStyle"
        style={{ cursor: "pointer" }}
      >
        <Trans>Download</Trans>
      </a>
    </QRCodeStyle>
  );
};

export default QrCode;
