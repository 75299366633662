import React from "react";
import CarouselComponent from "../../../components/CarouselComponent/Carousel.component";

const CarouselWrapper = ({
  viewReport,
  countData,
  managementCountBanner,
  allCount,
  carouselData,
  carouselDataNoReportView,
  maxItemsInView,
}) => {
  const responsiveL = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: maxItemsInView || 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: maxItemsInView || 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsiveLA = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {viewReport ? (
        <CarouselComponent
          autoPlay={true}
          carouselData={viewReport ? carouselData : carouselDataNoReportView}
          countData={countData}
          size="small"
          responsive={responsiveL}
          pagination={false}
        />
      ) : (
        <CarouselComponent
          autoPlay={true}
          carouselData={managementCountBanner}
          countData={allCount}
          size="small"
          responsive={responsiveLA}
          pagination={false}
        />
      )}
    </>
  );
};

export default CarouselWrapper;
