import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import Styles from "./AddAssetTypeModalStyles.module.css";
import { toValidateAssetTypeName } from "../../../../../utils/validations/checkValidation";
import validationConstant from "../../../../../constant/messages/validation.json";
import Button from "../../../../../components/Button/Button.component";
import { apiCall } from "../../../../../utils/apiCall";
import DropdownFilterWrapper from "../../../../../components/DropdownComponent/DropdownFilterWrapper";
import CustomInputField from "../../../../../components/InputFieldComponent/InputFieldWrapper.component";
import {
  AssetTypeOverviewWrapper,
  HeadingContainer,
  ContainerRow,
  FormContainer,
  FormItem,
} from "./AddAssetTypeOverview.component.styles";

const AddAssetTypeOverview = ({
  loginDetails,
  addAssetTypeModalData,
  setAddAssetTypeModalData,
  activeStep,
  setActiveStep,
  isSuperAdmin,
  dropdownAccountList,
  setAccountId,
  dropdownAccountId,
  validated,
  setValidated,
}) => {
  const { t } = useTranslation();
  const [isAccountValidationError, setIsAccountValidationError] = useState(false);
  const [isDuplicateNameError, setIsDuplicateNameError] = useState(false);

  const checkForAssetTypeDuplicateName = (assetTypeName = "") => {
    assetTypeName = assetTypeName.trim();

    if (!assetTypeName || (isSuperAdmin && !dropdownAccountId)) {
      setIsDuplicateNameError(false);
      return;
    }

    let accountID;
    if (isSuperAdmin) {
      accountID = dropdownAccountId;
    } else {
      accountID = loginDetails.accountId;
    }

    apiCall(`/assettype/isduplicate/${accountID}/${assetTypeName}`)
      .then((resp) => {
        if (resp?.data?.success) {
          setIsDuplicateNameError(false);
        } else {
          setIsDuplicateNameError(validationConstant.asset_type_name_duplicate);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAssetTypeNameChange = (value) => {
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeName: value,
    });
  };

  const handleAssetOnBlur = (e) => {
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeName: e.target.value.trim(),
    });
  };

  const handleAssetTypeDescriptionChange = (value) => {
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeDescription: value,
    });
  };

  const checkForValidation = () => {
    if (!dropdownAccountId && isSuperAdmin) {
      setIsAccountValidationError("Account");
      return true;
    }
    if (!addAssetTypeModalData.AssetTypeName) {
      return true;
    }
    if (isDuplicateNameError) {
      return true;
    }
    setIsAccountValidationError(false);
    return false;
  };

  const handleNext = () => {
    setValidated(true);
    // Handle Validation for step 1 then set active step accordingly
    if (checkForValidation()) {
      return;
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  useEffect(() => {
    if (dropdownAccountId) {
      setIsAccountValidationError(false);
    }
    if (addAssetTypeModalData.AssetTypeName) {
      checkForAssetTypeDuplicateName(addAssetTypeModalData.AssetTypeName);
    }
  }, [dropdownAccountId]);

  return (
    <AssetTypeOverviewWrapper>
      <HeadingContainer>
        <Trans>Asset Type Overview</Trans>
      </HeadingContainer>

      <ContainerRow>
        <FormContainer>
          {isSuperAdmin && (
            <>
              <FormItem>
                <DropdownFilterWrapper
                  options={dropdownAccountList?.map((data) => ({
                    _id: data._id,
                    value: data._id,
                    label: data.companyName,
                  }))}
                  label={t("Account")}
                  isError={isAccountValidationError}
                  value={dropdownAccountId}
                  onChange={(value) => {
                    setAccountId(value);
                  }}
                  placeholder="Select an Account"
                  errorMessage={t("This field is required")}
                />
              </FormItem>
              <FormItem></FormItem>
            </>
          )}
          <FormItem>
            <CustomInputField
              label={t("Asset Type Name")}
              name="assetTypeName"
              value={addAssetTypeModalData?.AssetTypeName || ""}
              onChange={(e) => {
                checkForAssetTypeDuplicateName(e.target.value);
                handleAssetTypeNameChange(e.target.value);
              }}
              type="text"
              onBlur={handleAssetOnBlur}
              placeholder={t("Please enter asset type name")}
              minLength={1}
              maxLength={64}
              isError={
                validated &&
                (toValidateAssetTypeName(addAssetTypeModalData?.AssetTypeName) || isDuplicateNameError)
              }
              errorMessage={
                validated &&
                (toValidateAssetTypeName(addAssetTypeModalData?.AssetTypeName) || isDuplicateNameError)
              }
              required
              autofocus
            />
          </FormItem>
          <FormItem>
            <CustomInputField
              label={t("Asset Type Description")}
              name="assetTypeDescription"
              value={addAssetTypeModalData?.AssetTypeDescription || ""}
              onChange={(e) => {
                handleAssetTypeDescriptionChange(e.target.value);
              }}
              placeholder={t("Please enter asset type description")}
              maxLength={"128"}
            />
          </FormItem>
        </FormContainer>
      </ContainerRow>
      <div className={`${Styles.footerActions}`}>
        <div className={`${Styles.rightSideActions}`}>
          <Button
            label={t("Next")}
            onClick={handleNext}
            iconClass="arrow-right"
            showIcon
            iconPosition="after"
            buttonType="primary"
          />
        </div>
      </div>
    </AssetTypeOverviewWrapper>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddAssetTypeOverview);
