import { notificationSettingsTypes } from "./types";
import { apiCall } from "../../utils/apiCall";

export const resetNotificationSettingsFlags = () => ({
  type: notificationSettingsTypes.RESET_NOTIFICATION_SETTINGS_FLAGS,
});

export const getNotificationSettingsSuccess = (data) => ({
  type: notificationSettingsTypes.GET_NOTIFICATIONS_SETTINGS_SUCCESS,
  data: data,
});

export const getAccountNotificationSettingsSuccess = (data) => ({
  type: notificationSettingsTypes.GET_ACCOUNT_NOTIFICATIONS_SETTINGS_SUCCESS,
  data: data,
});

export const toggleNotificationLoading = (bool) => ({
  type: notificationSettingsTypes.NOTIFICATION_LOADING,
  data: bool,
});

export const getNotificationSettingsByAccount = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = state.user.isSuperAdmin
      ? state.dropdownFilters?.settings?.accountId
      : state && state.user && state.user.loginDetails && state.user.loginDetails.accountId;
    if (!accountId || accountId === "all") {
      return;
    }

    dispatch(toggleNotificationLoading(true));
    apiCall(`/account/summaryalertsettings/${accountId}`)
      .then((resp) => {
        dispatch(getAccountNotificationSettingsSuccess(resp.data?.data?.accountSummaryAlertSettings));
      })
      .catch((err) => {
        dispatch(toggleNotificationLoading(false));
      });
  };
};

export const updateAccountSummaryAlertSettings = (accountId, data, actionAfterSuccess) => {
  return async (dispatch) => {
    apiCall(`/account/summaryalertsettings/${accountId}`, { method: "PUT", data: data })
      .then((response) => {
        //showAlert("success", alertConstant.email_alert_save_successfull, 2500);
        //getNotificationSettings();
        dispatch(getAccountNotificationSettingsSuccess(response?.data?.data));
        actionAfterSuccess && actionAfterSuccess(true);
      })
      .catch((err) => {
        console.log("updateAccountSummaryAlertSettings: error", err);
      });
  };
};

export const resetAccountSummaryAlertSettings = (accountId, actionAfterSuccess) => {
  return async (dispatch) => {
    apiCall(`/account/summaryalertsettings/${accountId}`, { method: "PATCH" })
      .then((response) => {
        dispatch(getAccountNotificationSettingsSuccess(response?.data?.data));
        actionAfterSuccess(true);
      })
      .catch((err) => {
        console.log("resetAccountSummaryAlertSettings: error", err);
      });
  };
};

export const getNotificationSettingsBySite = (id) => {
  const siteId = id || "all";
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/site/getnotificationsettings/${siteId}`)
      .then((resp) => {
        dispatch(getNotificationSettingsSuccess(resp.data?.data));
      })
      .catch((err) => {
        dispatch(toggleNotificationLoading(false));
      });
  };
};

/** Start of Inspection Alert Settings */
export const getAlertSettingsByAssetIdSuccess = (data, alertType, setSuccessFlag) => ({
  type: notificationSettingsTypes.GET_ALERT_SETTINGS_BY_ASSET_ID_SUCCESS,
  data: { data, alertType, setSuccessFlag },
});

export const getInspectionAlertSettingsByAssetIdError = () => ({
  type: notificationSettingsTypes.GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR,
});

export const getInspectionAlertSettingsByAssetId = (assetId) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));

    apiCall(`/systemSettings/inspectionAlertSettings/${assetId}`)
      .then((response) => {
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.inspectionAlertSettings,
            "inspectionAlertSettings"
          )
        );
      })
      .catch((err) => {
        dispatch(getInspectionAlertSettingsByAssetIdError());
        console.log("error", err);
      });
  };
};

export const updateInspectionAlertSettingsByAssetId = (assetId, data, setIsUpdateLoading) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/systemSettings/inspectionAlertSettings/${assetId}`, { method: "PUT", data: data })
      .then((response) => {
        setIsUpdateLoading(true);
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.inspectionAlertSettings,
            "inspectionAlertSettings"
          )
        );
      })
      .catch((err) => {
        console.log("updateAccessControlSettingsByAssetId -- error", err);
      });
  };
};

export const resetInspectionAlertSettingsByAssetId = (assetId, setIsUpdateLoading) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/systemSettings/inspectionAlertSettings/${assetId}`, { method: "PATCH" })
      .then((response) => {
        setIsUpdateLoading(true);
        dispatch(getAlertSettingsByAssetIdSuccess(response.data.data, "inspectionAlertSettings"));
      })
      .catch((err) => {
        console.log("resetInspectionAlertSettingsByAssetId -- error", err);
      });
  };
};

/** End of Inspection Alert Settings */

/**Start of Impact Alert Settings */

export const getImpactAlertSettingsByAssetIdError = () => ({
  type: notificationSettingsTypes.GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR,
});

export const getImpactAlertSettingsByAssetId = (assetId) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));

    apiCall(`/systemSettings/impactAlertSettings/${assetId}`)
      .then((response) => {
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.impactAlertSettings,
            "impactAlertSettings"
          )
        );
      })
      .catch((err) => {
        dispatch(getInspectionAlertSettingsByAssetIdError());
        console.log("error", err);
      });
  };
};

export const updateImpactAlertSettingsByAssetId = (assetId, data, setIsUpdateLoading) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/systemSettings/impactAlertSettings/${assetId}`, { method: "PUT", data: data })
      .then((response) => {
        setIsUpdateLoading(true);
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.impactAlertSettings,
            "impactAlertSettings"
          )
        );
      })
      .catch((err) => {
        console.log("updateImpactAlertSettingsByAssetId -- error", err);
      });
  };
};

export const resetImpactAlertSettingsByAssetId = (assetId, actionAfterSuccess) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/systemSettings/impactAlertSettings/${assetId}`, { method: "PATCH" })
      .then((response) => {
        actionAfterSuccess && actionAfterSuccess(true);
        dispatch(getAlertSettingsByAssetIdSuccess(response.data.data, "impactAlertSettings"));
      })
      .catch((err) => {
        console.log("resetImpactAlertSettingsByAssetId -- error", err);
      });
  };
};

/** End of Impact Alert Settings */

/**Start of Near-Miss Alert Settings */

export const getNearMissAlertSettingsByAssetIdError = () => ({
  type: notificationSettingsTypes.GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR,
});

export const getNearMissAlertSettingsByAssetId = (assetId) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));

    apiCall(`/systemSettings/nearMissAlertSettings/${assetId}`)
      .then((response) => {
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.nearMissAlertSettings,
            "nearMissAlertSettings"
          )
        );
      })
      .catch((err) => {
        dispatch(getInspectionAlertSettingsByAssetIdError());
        console.log("error", err);
      });
  };
};

export const updateNearMissAlertSettingsByAssetId = (assetId, data) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/systemSettings/nearMissAlertSettings/${assetId}`, { method: "PUT", data: data })
      .then((response) => {
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.nearMissAlertSettings,
            "nearMissAlertSettings",
            true
          )
        );
      })
      .catch((err) => {
        console.log("updateNearMissAlertSettingsByAssetId -- error", err);
      });
  };
};

export const resetNearMissAlertSettingsByAssetId = (assetId, actionAfterSuccess) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/systemSettings/nearMissAlertSettings/${assetId}`, { method: "PATCH" })
      .then((response) => {
        actionAfterSuccess && actionAfterSuccess(true);
        dispatch(getAlertSettingsByAssetIdSuccess(response.data.data, "nearMissAlertSettings"));
      })
      .catch((err) => {
        console.log("resetNearMissAlertSettingsByAssetId -- error", err);
      });
  };
};

/**End of Near-Miss Alert Settings */

/**Start of UnAuthorized Alert Settings */

const getUnAuthorizedAccessAlertSettingsByAssetIdError = () => ({
  type: notificationSettingsTypes.GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR,
});

export const getUnAuthorizedAccessAlertSettingsByAssetId = (assetId) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));

    apiCall(`/systemSettings/unauthorizedAlertSettings/${assetId}`)
      .then((response) => {
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.unAuthorizedAccessAlertSettings,
            "unAuthorizedAccessAlertSettings"
          )
        );
      })
      .catch((err) => {
        dispatch(getInspectionAlertSettingsByAssetIdError());
        console.log("error", err);
      });
  };
};

export const updateUnAuthorizedAccessAlertSettingsByAssetId = (assetId, data, actionAfterSuccess) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/systemSettings/unauthorizedAlertSettings/${assetId}`, { method: "PUT", data: data })
      .then((response) => {
        actionAfterSuccess && actionAfterSuccess(true);
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.unAuthorizedAccessAlertSettings,
            "unAuthorizedAccessAlertSettings"
          )
        );
      })
      .catch((err) => {
        console.log("updateUnAuthorizedAccessAlertSettingsByAssetId -- error", err);
      });
  };
};

export const resetUnAuthorizedAlertSettingsByAssetId = (assetId, actionAfterSuccess) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/systemSettings/unauthorizedAlertSettings/${assetId}`, { method: "PATCH" })
      .then((response) => {
        actionAfterSuccess && actionAfterSuccess(true);
        dispatch(getAlertSettingsByAssetIdSuccess(response.data.data, "unAuthorizedAccessAlertSettings"));
      })
      .catch((err) => {
        console.log("resetUnAuthorizedAlertSettingsByAssetId -- error", err);
      });
  };
};

/**End of UnAuthorized Alert Settings */

/**Start of Account Summary Alert Settings */

/* const getAccountSummaryAlertSettingsByAssetIdError = () => ({
  type: notificationSettingsTypes.GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR,
});

export const getAccountSummaryAlertSettingsByAssetId = (assetId) => {
  return async(dispatch) => {
    dispatch(toggleNotificationLoading(true));

    apiCall(`/account/summaryalertsettings/${assetId}`)
      .then((response) => {
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.unAuthorizedAccessAlertSettings,
            "unAuthorizedAccessAlertSettings"
          )
        );
        //console.log("response333", response);
      })
      .catch((err) => {
        dispatch(getInspectionAlertSettingsByAssetIdError());
        console.log("error", err);
      });
  };
}; */

/* export const updateAccountSummaryAlertSettingsByAssetId = (assetId, data) => {
  return async(dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/account/summaryalertsettings/${assetId}`, { method: "PUT", data: data })
      .then((response) => {
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.notificationSettings.unAuthorizedAccessAlertSettings,
            "unAuthorizedAccessAlertSettings"
          )
        );
        //console.log("updateImpactAlertSettingsByAssetId -- Success", response);
      })
      .catch((err) => {
        console.log("updateAccountSummaryAlertSettingsByAssetId -- error", err);
      });
  };
}; */

/* export const resetAccountSummaryAlertSettingsByAssetId = (assetId) => {
  return async(dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/systemSettings/accessControlSettings/${assetId}`, { method: "PATCH" })
      .then((response) => {
        dispatch(getAlertSettingsByAssetIdSuccess(response.data.data, "unAuthorizedAccessAlertSettings"));
        //console.log("resetImpactAlertSettingsByAssetId -- Success", response);
      })
      .catch((err) => {
        console.log("resetAccountSummaryAlertSettingsByAssetId -- error", err);
      });
  };
}; */

/**End of Account Summary Alert Settings */

/** Start of  Worksite Summary Alerts*/

export const getWorksiteSummaryAlertSettingsError = () => ({
  type: notificationSettingsTypes.GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR,
});

export const getWorksiteSummaryAlertSettings = (siteId) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));

    apiCall(`/site/summaryalertsettings/${siteId}`)
      .then((response) => {
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.workSiteSummaryAlertSettings,
            "workSiteSummaryAlertSettings"
          )
        );
        dispatch(toggleNotificationLoading(false));
      })
      .catch((err) => {
        dispatch(getInspectionAlertSettingsByAssetIdError());
        console.log("error", err);
      });
  };
};

export const updateWorksiteSummaryAlertSettings = (assetId, data, actionAfterSuccess) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/site/summaryalertsettings/${assetId}`, { method: "PUT", data: data })
      .then((response) => {
        actionAfterSuccess && actionAfterSuccess(true);
        dispatch(getAlertSettingsByAssetIdSuccess(response.data.data, "workSiteSummaryAlertSettings"));
        dispatch(toggleNotificationLoading(false));
      })
      .catch((err) => {
        console.log("updateNearMissAlertSettingsByAssetId -- error", err);
      });
  };
};

export const resetWorksiteSummaryAlertSettings = (siteId, actionAfterSuccess) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    apiCall(`/site/summaryalertsettings/${siteId}`, { method: "PATCH" })
      .then((response) => {
        actionAfterSuccess && actionAfterSuccess(true);
        dispatch(getAlertSettingsByAssetIdSuccess(response.data.data, "workSiteSummaryAlertSettings"));
        dispatch(toggleNotificationLoading(false));
      })
      .catch((err) => {
        console.log("resetNearMissAlertSettingsByAssetId -- error", err);
      });
  };
};

/** End of  Worksite Summary Alerts*/

/** Start of Operator certification Notifications Settings */
export const getOperatorCertificationAlertSettingsError = () => ({
  type: notificationSettingsTypes.GET_INSPECTION_ALERT_SETTINGS_BY_ASSET_ID_ERROR,
});

export const getOperatorCertificationAlertSettingsByUserId = (userId) => {
  return async (dispatch) => {
    dispatch(toggleNotificationLoading(true));
    if (userId === true || !userId) {
      return;
    }
    apiCall(`/user/operatorCertificateExpiryAlertSettings/${userId}`)
      .then((response) => {
        dispatch(
          getAlertSettingsByAssetIdSuccess(
            response.data.data.operatorCertificateExpiryAlertSettings,
            "operatorCertificateExpiryAlertSettings"
          )
        );
        dispatch(toggleNotificationLoading(false));
      })
      .catch((err) => {
        dispatch(getInspectionAlertSettingsByAssetIdError());
        console.log("error", err);
      });
  };
};

export const updateOperatorCertificationAlertSettings = (userId, data, actionAfterUpdate) => {
  return async (dispatch) => {
    if (userId === true || !userId) {
      return;
    }
    dispatch(toggleNotificationLoading(true));
    apiCall(`/user/operatorCertificateExpiryAlertSettings/${userId}`, { method: "PUT", data: data })
      .then((response) => {
        actionAfterUpdate && actionAfterUpdate(false);
        dispatch(
          getAlertSettingsByAssetIdSuccess(response.data.data, "operatorCertificateExpiryAlertSettings", true)
        );
        dispatch(toggleNotificationLoading(false));
      })
      .catch((err) => {
        console.log("updateNearMissAlertSettingsByAssetId -- error", err);
      });
  };
};

export const resetOperatorCertificationAlertSettings = (siteId, actionAfterSuccess) => {
  return async (dispatch) => {
    if (siteId === true || !siteId) {
      return;
    }
    dispatch(toggleNotificationLoading(true));
    apiCall(`/user/operatorCertificateExpiryAlertSettings/${siteId}`, { method: "PATCH" })
      .then((response) => {
        actionAfterSuccess && actionAfterSuccess(true);
        dispatch(
          getAlertSettingsByAssetIdSuccess(response.data.data, "operatorCertificateExpiryAlertSettings")
        );
        dispatch(toggleNotificationLoading(false));
      })
      .catch((err) => {
        console.log("resetNearMissAlertSettingsByAssetId -- error", err);
      });
  };
};
/**End  of Operator certification Notifications Settings*/

export const getSystemSettingsUserTypesSuccess = (data) => ({
  type: notificationSettingsTypes.GET_SYSTEM_SETTINGS_USER_TYPE_SUCCESS,
  data: { data },
});

export const getSystemSettingsUserTypesError = () => ({
  type: notificationSettingsTypes.GET_SYSTEM_SETTINGS_USER_TYPE_ERROR,
});

//Get user types for use in User type email recipients
export const getSystemSettingsUserTypes = () => {
  return async (dispatch, getState) => {
    const state = await getState();
    const accountId = state.dropdownFilters?.settings?.accountId || "all";

    apiCall(`/systemSettings/getUserTypes?accountId=${accountId}`)
      .then((response) => {
        dispatch(getSystemSettingsUserTypesSuccess(response?.data?.data));
      })
      .catch((err) => {
        dispatch(getInspectionAlertSettingsByAssetIdError());
        console.log("error", err);
      });
  };
};
