import styled from "styled-components";
import { Table } from "react-bootstrap";

export const ManagementLeftSidebarMenu = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
`;

export const CheckboxDiv = styled.div`
  width: 100%;
  margin: 0 0px 0px;
  overflow: hidden;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  display: flex;
`;

export const Tab = styled.div`
  width: 33.333%;
  display: inline-block;
  padding: 5px;
  text-align: center;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.themePrimary || "#0D5FBE" : theme.colors.grayL6 || "#F2F2F2"} !important;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.grayL7 || "#FFFFFF" : theme.colors.grayL4 || "#9A9A9A"};

  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
  :hover {
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.grayL7 || "#FFFFFF" : theme.colors.themePrimary || "#0D5FBE"};
  }
  :nth-child(2) {
    border-right: ${({ isActive, theme }) =>
      isActive
        ? `1px solid ${theme.colors.themePrimary || "#0D5FBE"}`
        : `1px solid ${theme.colors.grayL5 || "#CCCCCC"}`};

    border-left: ${({ isActive, theme }) =>
      isActive
        ? `1px solid ${theme.colors.themePrimary || "#0D5FBE"}`
        : `1px solid ${theme.colors.grayL5 || "#CCCCCC"}`};
  }
`;

export const StyledTable = styled(Table)`
  width: 100%;
  margin-bottom: initial;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: inline-block;
`;

export const StyledThead = styled.thead`
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  border-bottom: 0px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  display: inline;
`;

export const StyledTr = styled.tr`
  /*height: 50px;*/
  display: grid;
`;

export const TableTr = styled.tr`
  height: 45px;
  display: flex;
  cursor: pointer;
  .active-list-item {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .fontBold {
    font-weight: bold;
  }
`;

export const StyledTh = styled.th`
  text-align: left;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  border-top: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
`;

export const StyledH5 = styled.h5`
  margin: 0;
  float: left;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
  line-height: ${({ theme }) => theme.lineHeights.line1};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StyledTbody = styled.tbody`
  position: relative;
  display: block;
  height: ${(props) => `calc(100vh - ${props.heightOffset || 336}px)`};
  overflow-x: hidden;
  overflow-y: scroll;

  &.loader-list {
    height: 100px;
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  &.tableHeight {
    height: auto;
    overflow-y: auto;
  }
`;

export const StyledTd = styled.td`
  padding: 8px 16px !important;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  border-top: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  &.active-list-item {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    font-weight: bold;
  }
  width: 100%;
  display: flex;
`;

export const PStyled = styled.p`
  margin-bottom: 0px;
  flex: 1;
  text-align: justify;
`;

export const DotStyled = styled.i`
  font-size: ${({ theme }) => theme.fontSizes.font8};
  margin-right: 8px;
  color: ${(props) => (props.isActive ? "#8bc34a" : "red")};
  display: flex;
`;

export const SpanStyle = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  display: block;
`;

export const InspectButtonWrapper = styled.div`
  button {
    background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    padding: 2px 10px;
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
    text-transform: uppercase;
    &:disabled {
      opacity: 0.65;
      pointer-events: none;
    }
  }

  button.buttonInactive {
    background: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    pointer-events: none;
  }
`;
export const EmptyListStyle = styled.div`
  align-items: center;
  height: 88%;
  display: grid;
  text-align: center !important;
  width: 100%;
  margin-bottom: 0px;
`;

export const ButtonEditTemplate = styled.div`
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  padding: 2px;
  border-radius: 4px;
  line-height: 34px;
  height: 34px;
  width: 40px;
  border: none;
  flex: none;
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  display: grid;
  align-content: center;
`;
