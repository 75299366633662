import styled from "styled-components";

export const HeadingContainer = styled.div`
  background-image: linear-gradient(
    30deg,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 0%,
    ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} 100%
  );
  border-radius: 4px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  padding: 12px 24px;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
`;

export const ModalPopupWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;
export const DeleteIconStyled = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  margin-top: 20px;
`;

export const ShiftDurationMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 550px);
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  width: 100%;
  text-align: center;
  & p {
    margin: auto;
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;
export const ShiftDurationWrapper = styled.div`
  width: 95%;
  margin: 20px auto 20px auto;
`;

export const ShiftDurationSettingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
`;

export const ShiftDurationHours = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  & label {
    width: 100%;
  }
  & input {
    border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font7} !important;
    opacity: 1;
    margin-top: 3px;
    float: left;
    height: 33px;
    margin-left: 5px;
    padding: 5px;
    width: 145px;
  }
`;

export const ShiftDurButton = styled.div`
  width: 50%;
  float: left;
  display: flex;
  margin-bottom: 10px;
`;
export const ShiftDurationH = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  opacity: 1;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  height: 30px;
  line-height: 30px;
  float: left;
  margin-top: 24px;
  padding: 0px 6px;
`;

export const FormContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  @media (max-width: 1100px) {
    padding: 8px 12px;
    display: block;
  }
`;

export const FormItem = styled.div`
  flex: 1 1 48%;
  width: 50%;
  max-width: 49%;
  position: relative;
  @media (max-width: 1100px) {
    width: 100%;
    max-width: 100%;
  }
`;
