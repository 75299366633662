import styled from "styled-components";

export const ImpactSeverty = styled.div`
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  margin: -4px;
  border: 1px solid
    ${({ severity, theme }) =>
      severity === "LOW"
        ? theme.colors.greenPrimary || "#11A70B"
        : severity === "MEDIUM"
          ? theme.colors.orangePrimary || "#DD9000"
          : severity === "HIGH"
            ? theme.colors.redPrimary || "#DD0000"
            : theme.colors.grayL7 || "#FFFFFF"};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  display: flex;
  align-items: center;
  line-height: ${({ theme }) => theme.lineHeights.line6};

  span {
    flex: 1;
  }

  i {
    font-size: ${({ theme }) => theme.fontSizes.font4};
    color: ${({ severity, theme }) =>
      severity === "LOW"
        ? theme.colors.greenPrimary || "#11A70B"
        : severity === "MEDIUM"
          ? theme.colors.orangePrimary || "#DD9000"
          : severity === "HIGH"
            ? theme.colors.redPrimary || "#DD0000"
            : theme.colors.grayL7 || "#FFFFFF"};
  }
`;

export const ImpactGDiv = styled.div`
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  text-align: center;
`;

export const NoReportsDiv = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  overflow-y: scroll;
  height: calc(-565px + 100vh);
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const ImpactReportTable = styled.div`
  .noReportsDiv {
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  }
`;
