import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Row, Col, Table, Form, Accordion, Card } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import * as htmlToImage from "html-to-image";

import BreadCrumb from "../../../../components/BreadCrumb.component";
import { ReactComponent as ArrowLeft } from "../../../../assets/Gray_Dropdown_Arrow.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/ArrowRight.svg";
import { ReactComponent as ExpandMoreIcon } from "../../../../assets/drawer_assets/ExpandMoreIcon.svg";
import { ReactComponent as ExpandLessIcon } from "../../../../assets/drawer_assets/ExpandLessIcon.svg";
import WorksiteFilter from "../../../../components/DropdownFilter.component";
import QRCodeSizeFilter from "../../../../components/DropdownFilter.component";
import FilterByFilter from "../../../../components/DropdownFilter.component";
import AssetTypeFilter from "../../../../components/DropdownFilter.component";
import UserTypeFilter from "../../../../components/DropdownFilter.component";
import AccountNameFilter from "../../../../components/DropdownFilter.component";
import Checkbox from "../../../../components/Checkbox/Checkbox.component";
import { getUserTypeList } from "../../../../redux/userType/action";
import Button from "../../../../components/Button/Button.component";
import { assetTypeList, defaultAssetTypeOverview } from "../../../../redux/assetType/assetType.action";
import {
  setDropdownWorksiteId,
  setDropdownAccountId,
  getDropdownAssetTypeList,
} from "../../../../redux/dropdownFilters/action";

import { getAllAsset } from "../../../../redux/assets/action";

import { Trans, useTranslation } from "react-i18next";
import { apiCall } from "../../../../utils/apiCall";
import "./QrcodeStyle.css";
import { QRCodeSVG } from "qrcode.react";
import { StyledButtonWrapper, StyledRow, DivListTableStyle } from "./Qrcode.component.styles";
import DropdownWrapper from "../../../../components/DropdownComponent/DropdownWrapper";
import UsersDataTableQRCode from "./UsersDataTableQRCode.component";
import AssetsDataTableQRCode from "./AssetsDataTableQRCode.component";
import config from "../../../../config";

const TableQRCodeStyle = styled.div`
  width: 100%;
  .tableHeadStyle table.table thead {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }
  .tableHeadStyle table.table thead tr th.biling_head {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .AccordReport_Tog .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  a.download-Qrcode svg path.cls-1 {
    fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .pageRow .selectBoxDiv label,
  .pageRow .selectBoxDiv .selectBox {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .pageRow_Num p {
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  }
  .pageRow_Arrow svg,
  .pageRow_Arrow svg .cls-1 {
    fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
`;

const DownloadStyled = styled.div`
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: ${({ theme }) => theme.fontSizes.font5} !important;
`;

const baseUrlOperator = config.operatorBaseUrl; //Base Url
const redirectAssetQrScan = config.redirectAssetQrScan; // Operator asset qr scan redirect
const qrType = require("../../../../constant/qrType");

const QrcodePrint = ({
  loginDetails,
  isSuperAdmin,
  isSiteAdmin,
  dropdownAccountList,
  dropdownWorksiteList,
  dropdownAssetTypeList,
  qrCodeFilterBy,
  setDropdownWorksiteId,
  setDropdownAccountId,
  dropdownAccountId,
  userTypeList,
  getUserTypeList,
}) => {
  const [downloadReportSet, addDownloadReportSet] = useState(new Set());
  const [downloadReportSetIds, addDownloadReportIds] = useState(new Set());
  const [userListDownloadSet, setUserListDownloadSet] = useState(new Set());
  const [userListDownloadSetIds, setUseListDownloadSetIds] = useState(new Set());
  const [currentActiveKey, setCurrentActiveKey] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalAssets, setTotalAssets] = useState(0);
  const [assetListLocal, setAssetListLocal] = useState([]);
  const [assetListLocalLoading, setAssetListLocalLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [userListLocal, setUserListLocal] = useState([]);
  const [userListLocalLoading, setUserListLocalLoading] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isUserAllSelected, setIsUserAllSelected] = useState(false);
  const [accountNameArray, setAccountNameArray] = useState(new Map());

  //Filters State
  const [worksiteFilter, setWorksiteFilter] = useState("all");
  const [filterBy, setFilterBy] = useState(qrCodeFilterBy);
  const [qrCodeSize, setQrCodeSize] = useState("96");
  const [assetTypeFilter, setAssetTypeFilter] = useState("all");
  const [userTypeFilter, setUserTypeFilter] = useState("all");

  let tableHeader = [
    { name: "Account", space: 2, fieldName: "accountName" },
    { name: "Worksite", space: 1, fieldName: "worksite" },
    { name: "Asset Type", space: 1, fieldName: "assetType" },
    { name: "Asset Name", space: 1, fieldName: "assetName" },
    { name: "Added On", space: 1, fieldName: "createdAt" },
    { name: "Action", space: 2, fieldName: "inspectionCountMonthly" },
  ];

  let tableRowData = [
    {
      name: "Account",
      keyName: "accountName",
      space: 2,
      isNestedVar2: true,
      fieldName1: "accountId",
      fieldName2: "email",
    },
    {
      name: "Worksite",
      keyName: "worksite",
      space: 1,
      isNestedVar2: true,
      fieldName1: "siteId",
      fieldName2: "name",
    },
    {
      name: "Asset Type",
      keyName: "assetType",
      space: 1,
      isNestedVar2: true,
      fieldName1: "assetTypeId",
      fieldName2: "AssetTypeName",
      fieldName3: "AssetTypeName",
    },
    { name: "Asset Name", keyName: "assetName", space: 1, fieldName: "assetName" },
    { name: "Date", keyName: "createdAt", space: 1, isDate: true, fieldName: "createdAt" },
  ];

  let mobileRowHeader = [
    { name: "Asset Name", keyName: "assetName", space: 1, fieldName: "assetName" },
    {
      name: "Asset Type",
      keyName: "assetType",
      space: 1,
      isNestedVar2: true,
      fieldName1: "assetTypeId",
      fieldName2: "AssetTypeName",
      fieldName3: "AssetTypeName",
    },
    { name: "Added on", keyName: "createdAt", space: 1, isDate: true, fieldName: "createdAt" },
  ];

  let userTableHeader = [
    { name: "Account", space: 2, fieldName: "accountId" },
    { name: "Worksite", space: 2, fieldName: "worksite" },
    { name: "User Type", space: 1, fieldName: "userTypeId" },
    { name: "User Name", space: 1, fieldName: "fullName" },
    { name: "Added On", space: 1, fieldName: "accountId" },
    { name: "Action", space: 2, fieldName: "createdAt" },
  ];

  let userTableRowData = [
    { name: "Account", keyName: "accountName", space: 2, fieldName1: "accountId" },
    {
      name: "Worksite",
      keyName: "worksite",
      space: 2,
      isNestedVar3: true,
      fieldName1: "authorizedSites",
      fieldName2: "0",
      fieldName3: "name",
    },
    {
      name: "User Type",
      keyName: "userTypeId",
      space: 1,
      isNestedVar2: true,
      fieldName1: "userTypeId",
      fieldName2: "name",
    },
    { name: "User Name", keyName: "fullName", space: 1, fieldName: "fullName" },
    { name: "Added On", keyName: "createdAt", space: 1, isDate: true, fieldName: "createdAt" },
  ];

  let usermobileRowHeader = [
    { name: "First and Last Name", keyName: "fullName", space: 1, fieldName: "fullName" },
    { name: "Access Level", space: 1, fieldName: "permissions" },
    { name: "Added On", keyName: "createdAt", space: 1, isDate: true, fieldName: "createdAt" },
  ];

  const toggleDropdown = (eventKey) => {
    if (eventKey != currentActiveKey) {
      setCurrentActiveKey(eventKey);
    } else {
      setCurrentActiveKey("");
    }
  };

  const handleCheckboxClick = (e, data) => {
    const newDownloadReportSet = new Set(downloadReportSet);
    const newDownloadReportSetIds = new Set(downloadReportSetIds);
    if (e.target.checked) {
      newDownloadReportSet.add(data);
      newDownloadReportSetIds.add(data._id);
      // addDownloadReportIds.add(e.target.value._id);
    } else {
      newDownloadReportSet.delete(data);
      newDownloadReportSetIds.delete(data._id);
      // addDownloadReportIds.add(e.target.value._id);
    }
    addDownloadReportSet(newDownloadReportSet);
    addDownloadReportIds(newDownloadReportSetIds);
  };

  const handleUserCheckboxClick = (e, data) => {
    const newUserDownloadSet = new Set(userListDownloadSet);
    const newUserDownloadSetIds = new Set(userListDownloadSetIds);

    if (e.target.checked) {
      newUserDownloadSet.add(data);
      newUserDownloadSetIds.add(data._id);
    } else {
      newUserDownloadSet.delete(data);
      newUserDownloadSetIds.delete(data._id);
    }
    setUserListDownloadSet(newUserDownloadSet);
    setUseListDownloadSetIds(newUserDownloadSetIds);
  };

  const { t } = useTranslation();

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newDownloadReportSet = new Set(downloadReportSet);
      const newDownloadReportSetIds = new Set(downloadReportSetIds);
      assetListLocal.forEach((data, i) => {
        newDownloadReportSet.add(data);
        newDownloadReportSetIds.add(data._id);
      });
      addDownloadReportSet(newDownloadReportSet);
      addDownloadReportIds(newDownloadReportSetIds);
      setIsAllSelected(true);
    } else {
      const newDownloadReportSet = new Set();
      const newDownloadReportSetIds = new Set();
      addDownloadReportSet(newDownloadReportSet);
      addDownloadReportIds(newDownloadReportSetIds);
      setIsAllSelected(false);
    }
  };

  const handleUserSelectAll = (e) => {
    setIsUserAllSelected(true);

    if (e.target.checked) {
      const newUserListDownloadSet = new Set();
      const newUserListDownloadSetIds = new Set();

      userListLocal.forEach((data) => {
        newUserListDownloadSet.add(data);
        newUserListDownloadSetIds.add(data._id);
      });

      setUserListDownloadSet(newUserListDownloadSet);
      setUseListDownloadSetIds(newUserListDownloadSetIds);
      setIsUserAllSelected(true);
    } else {
      setUserListDownloadSet(new Set());
      setUseListDownloadSetIds(new Set());
      setIsUserAllSelected(false);
    }
  };

  const handlePageLength = (e) => {
    addDownloadReportSet(new Set());
    setPageSize(e.target.value);
  };

  const previousPage = () => {
    if (pageNo <= 1) {
      return;
    }
    addDownloadReportSet(new Set());
    setPageNo(pageNo - 1);
  };

  const nextPage = () => {
    if (pageNo > Math.ceil((totalAssets || 0) / pageSize) - 1) {
      return;
    }
    setPageNo(pageNo + 1);
    addDownloadReportSet(new Set());
  };

  const userNextPage = () => {
    if (pageNo > Math.ceil((totalUsers || 0) / pageSize) - 1) {
      return;
    }
    setPageNo(pageNo + 1);
    addDownloadReportSet(new Set());
  };

  const userPreviousPage = () => {
    if (pageNo <= 1) {
      return;
    }
    addDownloadReportSet(new Set());
    setPageNo(pageNo - 1);
  };

  const downloadQrCode = (e, asset) => {
    let name = filterBy == "assets" ? asset?.assetName || "unknown" : asset?.fullName || "unknown";
    let qrDetail = asset;
    let bindUrl = filterBy == "assets" ? true : false;

    let newDiv = document.createElement("div");
    htmlToImage
      .toSvg(
        ReactDOM.render(
          <div style={{ display: "inline-block" }}>
            <div
              className="text-center"
              style={{
                display: "inline-block",
              }}
            >
              <QRCodeSVG
                fgColor={filterBy === "assets" ? "#323232" : "#0D5FBE"}
                size={qrCodeSize / 2}
                value={
                  qrDetail
                    ? (bindUrl ? redirectAssetQrScan : baseUrlOperator) + qrDetail[qrType.ASSET.qrContent]
                    : ""
                }
              />
              <p
                style={{
                  textAlign: "center",
                  fontSize: (qrCodeSize * 12) / 96,
                  color: "#000",
                  margin: "0",
                  width: `${qrCodeSize / 2}px`,
                }}
              >
                {name}
              </p>
            </div>
          </div>,
          newDiv
        ),
        { quality: 0.1, width: `${qrCodeSize}px`, height: `${qrCodeSize}px` }
      )
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = `${name}.svg`;
        link.href = dataUrl;
        link.click();
      });
  };

  const getAssetListNew = () => {
    setAssetListLocalLoading(true);
    let accountId = loginDetails && loginDetails.accountId;
    apiCall(
      `/asset/filter/assetList/${isSuperAdmin ? dropdownAccountId : accountId}/${
        assetTypeFilter ? assetTypeFilter : "all"
      }/${
        isSiteAdmin ? loginDetails.userData.authorizedSites[0] : worksiteFilter ? worksiteFilter : "all"
      }/${pageNo}/${pageSize}/all`
    )
      .then((resp) => {
        // dispatch(getAllAssetSuccess(resp.data, isResetState, pageNo));
        addDownloadReportSet(new Set());
        addDownloadReportIds(new Set());
        setTotalAssets(resp.data.count);
        setAssetListLocalLoading(false);
        setAssetListLocal(resp.data.data);
      })
      .catch((err) => {
        // dispatch(getAllAssetError(err));
        console.log(err);
      });
  };

  const getUserListNew = () => {
    setUserListLocalLoading(true);
    let accountId = loginDetails && loginDetails.accountId;
    apiCall(
      `/user/getUserbyRole/${userTypeFilter || "all"}/${isSuperAdmin ? dropdownAccountId : accountId}/${
        isSiteAdmin ? loginDetails?.userData?.authorizedSites[0] : worksiteFilter
      }/${pageNo}/${pageSize}/all`
    )
      .then((resp) => {
        // dispatch(getAllAssetSuccess(resp
        setUserListDownloadSet(new Set());
        setUseListDownloadSetIds(new Set());
        setTotalUsers(resp.data.count);
        setUserListLocalLoading(false);
        setUserListLocal(resp.data.data);
      })
      .catch((err) => {
        // dispatch(getAllAssetError(err));
        console.log(err);
      });
  };

  const handleWorksiteFilter = (value) => {
    setDropdownWorksiteId(value);
    setWorksiteFilter(value);
  };

  const handleQrCodeSizeFilter = (value) => {
    setQrCodeSize(value);
  };

  const handleFilterByFilter = (value) => {
    setFilterBy(value);
  };

  const handleAssetTypeFilter = (value) => {
    // alert(e.target.value)
    setAssetTypeFilter(value);
  };

  const handleUserTypeFilter = (value) => {
    setUserTypeFilter(value);
  };

  const handleDownloadAll = () => {
    downloadReportSet.forEach((data) => {
      downloadQrCode("", data);
    });
  };

  const handleDownloadAllUser = () => {
    userListDownloadSet.forEach((data) => {
      downloadQrCode("", data);
    });
  };

  function prepareAccountNameArray() {
    const newMap = new Map();
    dropdownAccountList.forEach((d) => {
      if (!d.companyName) {
        if (!d.email) {
          newMap.set(d._id, "NA");
        } else {
          newMap.set(d._id, d.email);
        }
      } else {
        newMap.set(d._id, d.companyName);
      }
    });
    setAccountNameArray(newMap);
  }

  async function handlePrintAll(filterBy) {
    // alert(filterBy)
    let allData;
    if (filterBy == "users") {
      allData = userListDownloadSet;
    } else {
      allData = downloadReportSet;
    }

    let containerDiv = document.createElement("div");
    containerDiv.style.display = "inline-block";
    let count = 0;

    let forEachPromise = new Promise((resolve, reject) => {
      let counter = 0;
      allData.forEach((data) => {
        let name = filterBy == "assets" ? data?.assetName || "unknown" : data?.fullName || "unknown";
        let qrDetail = data;
        let bindUrl = filterBy == "assets" ? true : false;

        let newDiv = document.createElement("div");
        newDiv.style.display = "inline-block";
        newDiv.style.border = "solid 1px #c3c3c3";
        newDiv.style.padding = "10px";
        newDiv.style.verticalAlign = "top";
        newDiv.style.marginBottom = "5px";
        ReactDOM.render(
          <div style={{ display: "inline" }}>
            <div
              className="text-center"
              style={{
                display: "inline-block",
              }}
            >
              <QRCodeSVG
                fgColor={filterBy === "assets" ? "#323232" : "#0D5FBE"}
                size={qrCodeSize / 2}
                value={
                  qrDetail
                    ? (bindUrl ? redirectAssetQrScan : baseUrlOperator) + qrDetail[qrType.ASSET.qrContent]
                    : ""
                }
              />
              <p
                style={{
                  margin: "5px",
                  textAlign: "center",
                  width: `${qrCodeSize}px`,
                  wordBreak: "break-word",
                }}
                title={name}
              >
                {name}
              </p>
            </div>
          </div>,
          newDiv
        );
        containerDiv.appendChild(newDiv);
        counter++;
        if (counter == allData.size) {
          resolve();
        }
      });
    });

    forEachPromise.then(() => {
      let printWindow = window.open();
      const content = `<html><head><style></style></head><body><div>${containerDiv.innerHTML}</div><script>setTimeout(() => {
                try {
                    // Print for Safari browser
                    document.execCommand('print', false, null)
                  } catch {
                    window.print()
                  }
            }, 100) </script></body></html>`;
      printWindow.document.write(content);
    });

    // print(printWindow)

    setTimeout(() => {
      // printWindow.print();
      // printWindow.close();
    }, 100);
  }

  useEffect(() => {
    prepareAccountNameArray();
  }, [dropdownAccountList]);

  useEffect(() => {
    const accountId = isSuperAdmin ? dropdownAccountId : loginDetails?.accountId;
    if (accountId && accountId !== "all") {
      if (filterBy === "assets") {
        getAssetListNew();
      } else if (filterBy === "users") {
        getUserListNew();
      }
    }
  }, [dropdownAccountId, worksiteFilter, pageNo, pageSize, assetTypeFilter, userTypeFilter, filterBy]);

  useEffect(() => {
    const accountId = isSuperAdmin ? dropdownAccountId : loginDetails?.accountId;
    if (accountId && accountId !== "all") {
      getDropdownAssetTypeList();
      getUserTypeList();
    }
  }, [dropdownAccountId]);

  useEffect(() => {
    const accountId = isSuperAdmin ? dropdownAccountId : loginDetails?.accountId;
    if (accountId && accountId !== "all") {
      setDropdownWorksiteId(worksiteFilter);
    }
  }, [worksiteFilter, pageNo]);

  useEffect(() => {
    setPageNo(1);
  }, [pageSize]);

  useEffect(() => {
    setAssetTypeFilter("all");
    setUserTypeFilter("all");
  }, [filterBy]);

  useEffect(() => {
    setPageNo(1);
  }, [userTypeFilter]);

  useEffect(() => {
    const startResultNumber = pageSize * (pageNo - 1);
    const endResultNumber =
      parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalUsers
        ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
        : totalUsers;
    const currentTotalReports = endResultNumber - startResultNumber;

    if (userListDownloadSet.size == currentTotalReports && currentTotalReports != 0) {
      setIsUserAllSelected(true);
    } else {
      setIsUserAllSelected(false);
    }
  }, [userListDownloadSet]);

  useEffect(() => {
    const startResultNumber = pageSize * (pageNo - 1);
    const endResultNumber =
      parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalAssets
        ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
        : totalAssets;
    const currentTotalReports = endResultNumber - startResultNumber;

    if (downloadReportSet.size == currentTotalReports && currentTotalReports != 0) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [downloadReportSet]);

  const getAuthorizedSitesStr = (authSitesArr) => {
    const siteNames = authSitesArr.map((site) => site.name);
    return siteNames.length > 0 ? siteNames.join(", ") : "All";
  };

  const defaultFilters = {
    worksite: "all",
    assetType: "all",
    qrCodeSize: "96",
    userType: "all",
  };

  // const handleResetFilters = () => {
  //   setWorksiteFilter(defaultFilters.worksite);
  //   setAssetTypeFilter(defaultFilters.assetType);
  //   setQrCodeSize(defaultFilters.qrCodeSize);
  //   setUserTypeFilter(defaultFilters.userType);
  // };

  // const isAnyFilterApplied =
  //   worksiteFilter !== defaultFilters.worksite ||
  //   assetTypeFilter !== defaultFilters.assetType ||
  //   qrCodeSize !== defaultFilters.qrCodeSize ||
  //   userTypeFilter !== defaultFilters.userType;
  return (
    <>
      <BreadCrumb />
      <StyledRow>
        <StyledButtonWrapper>
          <Button
            label={t("Download All")}
            disabled={userListDownloadSet.size === 0 && downloadReportSet.size === 0}
            onClick={() => (filterBy === "assets" ? handleDownloadAll() : handleDownloadAllUser())}
            showIcon
            iconClass="download"
            buttonType="secondary"
          />
        </StyledButtonWrapper>
        <StyledButtonWrapper>
          <Button
            label={t("Print All")}
            disabled={userListDownloadSet.size === 0 && downloadReportSet.size === 0}
            onClick={() => handlePrintAll(filterBy)}
            showIcon
            iconClass="print"
            buttonType="secondary"
          />
        </StyledButtonWrapper>

        {/* {isAnyFilterApplied && (
          <Button label={t("Reset Filters")} buttonType="secondary" onClick={handleResetFilters} />
        )} */}
      </StyledRow>

      {/* FILTERS :  */}

      <Row style={{ paddingTop: "8px" }}>
        {isSuperAdmin && (
          <Col md={3}>
            <DropdownWrapper
              filter={{
                type: "Account",
                name: "companyName",
                key: "companyName",
                data: dropdownAccountList,
                placeholder: t("Please select an Account"),
              }}
              useAsDropdown={true}
              label={t("filter")}
              handleFilter={(value) => setDropdownAccountId(value)}
              value={dropdownAccountId}
            />
          </Col>
        )}
        {isSuperAdmin && (!dropdownAccountId || dropdownAccountId === "all")
          ? null
          : !isSiteAdmin && (
              <>
                <Col md={3}>
                  <DropdownWrapper
                    filter={{
                      type: "Worksite",
                      name: "authorizedSites",
                      key: "name",
                      data: dropdownWorksiteList,
                    }}
                    value={worksiteFilter}
                    label={t("filter")}
                    handleFilter={handleWorksiteFilter}
                    userFilter="Worksite"
                  />
                </Col>

                <Col md={3}>
                  <DropdownWrapper
                    filter={{
                      type: "QR Size",
                      name: "qrcodeSize",
                      key: "size",
                      data: [
                        { size: "1x1in", _id: "96" },
                        { size: "2x2in", _id: "192" },
                        { size: "3x3in", _id: "288" },
                        { size: "4x4in", _id: "384" },
                        { size: "5x5in", _id: "480" },
                        { size: "6x6in", _id: "576" },
                      ],
                    }}
                    label={t("Filter")}
                    handleFilter={handleQrCodeSizeFilter}
                    value={qrCodeSize}
                  />
                </Col>
              </>
            )}

        {isSuperAdmin && <Col md={3}></Col>}

        {isSuperAdmin && (!dropdownAccountId || dropdownAccountId === "all") ? null : (
          <>
            <Col md={3} className="mt-2">
              <DropdownWrapper
                filter={{
                  type: "Filter By",
                  name: "filterby",
                  key: "name",
                  data: [
                    { name: "Assets", _id: "assets" },
                    { name: "Users", _id: "users" },
                  ],
                }}
                label={t("Filter By")}
                handleFilter={handleFilterByFilter}
                useAsDropdown="true"
                value={filterBy}
              />
            </Col>
            {filterBy == "all" ? <Col md={3}></Col> : ""}
            {filterBy == "assets" ? (
              <Col md={3} className="mt-2">
                <DropdownWrapper
                  filter={{
                    type: "Asset Type",
                    name: "AssetTypeName",
                    key: "AssetTypeName",
                    data: dropdownAssetTypeList,
                  }}
                  label={t("Filter By")}
                  value={assetTypeFilter}
                  handleFilter={handleAssetTypeFilter}
                />
              </Col>
            ) : (
              ""
            )}
            {filterBy == "users" ? (
              <Col md={3} className="mt-2">
                <DropdownWrapper
                  filter={{
                    type: "User Type",
                    name: "userType",
                    key: "name",
                    data: userTypeList,
                  }}
                  label={t("Filter By")}
                  value={userTypeFilter}
                  handleFilter={handleUserTypeFilter}
                />
              </Col>
            ) : (
              ""
            )}
          </>
        )}
      </Row>

      <TableQRCodeStyle>
        {/* TABLE */}
        {filterBy == "assets" ? (
          <DivListTableStyle>
            <AssetsDataTableQRCode
              data={assetListLocal}
              isSuperAdmin={isSuperAdmin}
              isSiteAdmin={isSiteAdmin}
              handleSelectAll={handleSelectAll}
              isAllSelected={isAllSelected}
              handleCheckboxClick={handleCheckboxClick}
              downloadReportSetIds={downloadReportSetIds}
              downloadQrCode={downloadQrCode}
              pageSize={pageSize}
              pageNo={pageNo}
              totalPageReport={totalAssets}
              nextPage={nextPage}
              previousPage={previousPage}
              handlePageLength={handlePageLength}
              paginationOptions={[10, 25, 50, 100].map((value) => ({ name: value, value }))}
              loading={assetListLocalLoading}
              dropdownAccountId={dropdownAccountId}
              rowHeight={isSuperAdmin ? "390" : "330"}
              accountNameArray={accountNameArray}
            />
            {/* <>
              <Table borderless className="text-kanit d-block">
                <thead
                  style={{
                    backgroundColor: "#F2F2F2",
                    fontSize: "28px",
                    color: "#333333",
                    border: "0px solid",
                    width: "100%",
                    display: "inline-table",
                  }}
                >
                  <tr>
                    <th className="biling_head">
                      <Row className="ml-2 mr-2">
                        <div className="text-left pr-2 hidden_call">
                          <Checkbox
                            onClick={(e) => handleSelectAll(e)}
                            value={isAllSelected}
                            checked={isAllSelected}
                          />
                        </div>
                        {tableHeader.map((data) =>
                          (!isSuperAdmin && data.name === "Account") ||
                          (isSiteAdmin && data.name === "Worksite") ? (
                            ""
                          ) : (
                            <Col className="text-left pl-3 m-0 p-0">
                              <Trans>{data.name}</Trans>
                            </Col>
                          )
                        )}
                      </Row>
                    </th>
                  </tr>
                </thead>

                {isSuperAdmin && (!dropdownAccountId || dropdownAccountId === "all") ? (
                  <div
                    style={{
                      minHeight: "300px",
                      display: "flex",
                      verticalAlign: "middle",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Trans>Please select an account to view details</Trans>
                  </div>
                ) : (
                  <>
                    {assetListLocalLoading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <div className="Scroll_Report_B">
                        <Accordion activeKey={currentActiveKey} className="AccordReport_Tog">
                          {assetListLocal && assetListLocal.length > 0 ? (
                            assetListLocal.map((data, i) => (
                              <Card>
                                <Accordion.Toggle
                                  className="border-0 padding-accord"
                                  as={Card}
                                  eventKey={i + 1}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => toggleDropdown(i + 1)}
                                >
                                  <Row className="ml-2 mr-2 RespoFieldReport">
                                    <div className="text-left pr-2 hidden_call">
                                      <Checkbox
                                        onClick={(e) => handleCheckboxClick(e, data)}
                                        value={data}
                                        checked={downloadReportSetIds.has(data._id)}
                                      />
                                    </div>
                                    {tableRowData.map((tableData) =>
                                      (!isSuperAdmin && tableData.fieldName1 === "accountId") ||
                                      (isSiteAdmin && tableData.fieldName1 === "siteId") ? (
                                        ""
                                      ) : (
                                        <Col className="text-left pl-3 m-0 p-0 text_Repo_elips">
                                          <span
                                            title={
                                              tableData.isNestedVar2 &&
                                              data[tableData.fieldName1] &&
                                              data[tableData.fieldName1][tableData.fieldName2]
                                            }
                                          >
                                            {tableData.isNestedVar2 &&
                                              data[tableData.fieldName1] &&
                                              data[tableData.fieldName1][tableData.fieldName2]}
                                          </span>
                                          <span
                                            title={
                                              tableData.isNestedVar2 &&
                                              data[tableData.fieldName1] &&
                                              data[tableData.fieldName1][tableData.fieldName2]
                                            }
                                          >
                                            {tableData.isNestedVar3 &&
                                              data[tableData.fieldName1] &&
                                              data[tableData.fieldName1][tableData.fieldName2] &&
                                              data[tableData.fieldName1][tableData.fieldName2][
                                                tableData.fieldName3
                                              ]}
                                          </span>
                                          {tableData.isStatus &&
                                            (data[tableData.fieldName] ? (
                                              <button className="btn PassFail btn-outline-Pass">Pass</button>
                                            ) : (
                                              <button className="btn PassFail btn-outline-Fail">Fail</button>
                                            ))}
                                          {!(tableData.isNestedVar2 && tableData.isNestedVar3) && (
                                            <span
                                              title={
                                                !tableData.isDate
                                                  ? data[tableData.fieldName]
                                                  : new Date(data[tableData.fieldName]).toLocaleDateString()
                                              }
                                            >
                                              {" "}
                                              {!tableData.isDate
                                                ? tableData.keyName == "accountName"
                                                  ? accountNameArray.get(data["accountId"])
                                                  : data[tableData.fieldName]
                                                : new Date(data[tableData.fieldName]).toLocaleDateString()}
                                            </span>
                                          )}
                                        </Col>
                                      )
                                    )}
                                    <Col className="text-left pl-3 m-0 p-0 Down_Icon">
                                      <a
                                        style={{ textDecoration: "none" }}
                                        className="download-Qrcode"
                                        name={data?.assetName}
                                        onClick={(e) => downloadQrCode(e, data)}
                                      >
                                       
                                        <DownloadStyled className="icon icon-download" />
                                      </a>
                                       </Col>
                                    <Col className="m-0 p-2 expand_icon">
                                      {i + 1 == currentActiveKey ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                    </Col>
                                  </Row>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={i + 1} className="Accord_Hidden">
                                  <Card.Body style={{ backgroundColor: "#F6F6F6" }} className="border-0">
                                    <Row>
                                      <Col md={12}>
                                        {mobileRowHeader.map((tableData) => (
                                          <>
                                            <div className="mb-0" style={{ textAlign: "left" }}>
                                              {tableData.name}
                                            </div>
                                            <div
                                              style={{
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: "5px",
                                                textAlign: "left",
                                              }}
                                              className="p-2 mb-3"
                                            >
                                              <div>
                                                {tableData.isNestedVar2 &&
                                                  data[tableData.fieldName1] &&
                                                  data[tableData.fieldName1][tableData.fieldName2]}
                                                {tableData.isNestedVar3 &&
                                                  data[tableData.fieldName1] &&
                                                  data[tableData.fieldName1][tableData.fieldName2] &&
                                                  data[tableData.fieldName1][tableData.fieldName2][
                                                    tableData.fieldName3
                                                  ]}
                                                {tableData.isStatus &&
                                                  (data[tableData.fieldName] ? (
                                                    <button className="text-white border-0 bg-success">
                                                      Pass
                                                    </button>
                                                  ) : (
                                                    <button className="text-white border-0 bg-danger">
                                                      Fail
                                                    </button>
                                                  ))}
                                                {!(tableData.isNestedVar2 && tableData.isNestedVar3) &&
                                                  (!tableData.isDate
                                                    ? data[tableData.fieldName]
                                                    : new Date(
                                                        data[tableData.fieldName]
                                                      ).toLocaleDateString())}
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ))
                          ) : (
                            <div className="m-4">
                              <Trans>No Assets</Trans>
                            </div>
                          )}
                        </Accordion>
                        <div className="text-center pagina_Report">
                          <div className="pageRow">
                            <Form.Group className="selectBoxDiv" onChange={(e) => handlePageLength(e)}>
                              <Form.Label>
                                <Trans>Rows Per Page</Trans> :{" "}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                value={pageSize}
                                className="selectBox"
                                onChange={(e) => setPageSize(e.target.value)}
                              >
                                {[
                                  { name: 10, value: 10 },
                                  { name: 25, value: 25 },
                                  { name: 50, value: 50 },
                                  { name: 100, value: 100 },
                                ].map((option) => (
                                  <option key={option.name} value={option.value}>
                                    {option.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                          </div>
                          <div className="pageRow_Num">
                            <p className="text-muted">{`${t("Showing")} ${pageSize * (pageNo - 1) || 0} - ${
                              parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalAssets
                                ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
                                : totalAssets || 0
                            } of ${totalAssets || 0}`}</p>
                          </div>
                          <div className="pageRow_Arrow">
                            <ArrowLeft
                              onClick={() => previousPage()}
                              style={{
                                cursor: "pointer",
                                width: 20,
                                marginRight: 10,
                                transform: "rotate(180deg)",
                              }}
                              fill="#0d5fbe"
                            />
                            <ArrowRight
                              onClick={() => nextPage()}
                              style={{ cursor: "pointer", width: 20, marginRight: 10 }}
                              fill="#0d5fbe"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Table>
            </> */}
          </DivListTableStyle>
        ) : (
          ""
        )}

        {/* Do not delete or rename this div it's used to generate QR code when downloading :) */}

        {filterBy == "users" ? (
          <DivListTableStyle>
            <UsersDataTableQRCode
              data={userListLocal}
              isSuperAdmin={isSuperAdmin}
              isSiteAdmin={isSiteAdmin}
              handleUserSelectAll={handleUserSelectAll}
              isUserAllSelected={isUserAllSelected}
              handleCheckboxClick={handleCheckboxClick}
              downloadReportSet={downloadReportSet}
              handleUserCheckboxClick={handleUserCheckboxClick}
              userListDownloadSetIds={userListDownloadSetIds}
              downloadQrCode={downloadQrCode}
              pageSize={pageSize}
              pageNo={pageNo}
              totalPageReport={totalUsers}
              nextPage={userNextPage}
              previousPage={userPreviousPage}
              handlePageLength={handlePageLength}
              paginationOptions={[10, 25, 50, 100].map((value) => ({ name: value, value }))}
              loading={userListLocalLoading}
              dropdownAccountId={dropdownAccountId}
              rowHeight={isSuperAdmin ? "400" : "360"}
              accountNameArray={accountNameArray}
            />

            {/* <Table borderless className="text-kanit">
              <thead
                style={{
                  backgroundColor: "#F2F2F2",
                  fontSize: "28px",
                  color: "#333333",
                  border: "0px solid",
                }}
              >
                <tr>
                  <th className="biling_head">
                    <Row className="ml-2 mr-2">
                      <div className="text-left pr-2 hidden_call">
                        <Checkbox
                          onClick={(e) => handleUserSelectAll(e)}
                          value={isUserAllSelected}
                          checked={isUserAllSelected}
                        />
                      </div>
                      {userTableHeader.map((data) =>
                        //   data.name=="Account" ? "" :
                        (!isSuperAdmin && data.name === "Account") ||
                        (isSiteAdmin && data.name === "Worksite") ? (
                          ""
                        ) : (
                          <Col className="text-left pl-3 m-0 p-0">
                            <Trans>{data.name}</Trans>
                          </Col>
                        )
                      )}
                    </Row>
                  </th>
                </tr>
              </thead>
              {userListLocalLoading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div className="Scroll_Report_B">
                  <Accordion activeKey={currentActiveKey} className="AccordReport_Tog">
                    {userListLocal && userListLocal.length > 0 ? (
                      userListLocal.map((data, i) => (
                        <Card>
                          <Accordion.Toggle
                            className="border-0 padding-accord"
                            as={Card}
                            eventKey={i + 1}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => toggleDropdown(i + 1)}
                          >
                            <Row className="ml-2 mr-2 RespoField">
                              <div className="text-left pr-2 hidden_call">
                                <Checkbox
                                  onClick={(e) => handleUserCheckboxClick(e, data)}
                                  value={data}
                                  checked={userListDownloadSetIds.has(data._id)}
                                />
                              </div>
                              {userTableRowData.map((tableData) =>
                                // tableData.keyName=="accountId" ? "" :
                                (!isSuperAdmin && tableData.fieldName1 === "accountId") ||
                                (isSiteAdmin &&
                                  (tableData.fieldName1 === "siteId" ||
                                    tableData.fieldName1 === "authorizedSites")) ? (
                                  ""
                                ) : (
                                  <Col className="text-left pl-3 m-0 p-0 text_Repo_elips">
                                    <span></span>
                                    <span
                                      title={
                                        tableData.isNestedVar2 &&
                                        data[tableData.fieldName1] &&
                                        data[tableData.fieldName1][tableData.fieldName2]
                                      }
                                    >
                                      {tableData.isNestedVar2 &&
                                        data[tableData.fieldName1] &&
                                        data[tableData.fieldName1][tableData.fieldName2]}
                                    </span>
                                    <span
                                      title={
                                        tableData.isNestedVar2 &&
                                        data[tableData.fieldName1] &&
                                        data[tableData.fieldName1][tableData.fieldName2]
                                      }
                                    ></span>
                                    {tableData.isStatus &&
                                      (data[tableData.fieldName] ? (
                                        <button className="btn PassFail btn-outline-Pass">Pass</button>
                                      ) : (
                                        <button className="btn PassFail btn-outline-Fail">Fail</button>
                                      ))}

                                    {tableData.keyName === "worksite" ? (
                                      <span title={getAuthorizedSitesStr(data["authorizedSites"])}>
                                        {getAuthorizedSitesStr(data["authorizedSites"])}
                                      </span>
                                    ) : (
                                      !(tableData.isNestedVar2 && tableData.isNestedVar3) && (
                                        <span
                                          title={
                                            !tableData.isDate
                                              ? data[tableData.fieldName]
                                              : new Date(data[tableData.fieldName]).toLocaleDateString()
                                          }
                                        >
                                          {" "}
                                          {!tableData.isDate
                                            ? data[tableData.fieldName] == "account_admin"
                                              ? "Account Admin"
                                              : data[tableData.fieldName] == "account_owner"
                                                ? "Account Owner"
                                                : data[tableData.fieldName] == "operator"
                                                  ? "Operator"
                                                  : data[tableData.fieldName] == "site_admin"
                                                    ? "Site Admin"
                                                    : data[tableData.fieldName] == "account_viewer"
                                                      ? "Account Viewer"
                                                      : tableData.keyName == "accountName"
                                                        ? data["permissions"] == "super_admin"
                                                          ? "SIERA.AI"
                                                          : accountNameArray.get(data["accountId"])
                                                        : data[tableData.fieldName]
                                            : new Date(data[tableData.fieldName]).toLocaleDateString()}
                                        </span>
                                      )
                                    )}
                                  </Col>
                                )
                              )}
                              <Col className="text-left pl-3 m-0 p-0 Down_Icon">
                                <a
                                  style={{ textDecoration: "none" }}
                                  className="download-Qrcode"
                                  name={data?.assetName}
                                  onClick={(e) => downloadQrCode(e, data)}
                                >
                                  <DownloadStyled className="icon icon-download" />
                                </a>
                              </Col>
                              <Col className="m-0 p-2 expand_icon">
                                {i + 1 == currentActiveKey ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                              </Col>
                            </Row>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={i + 1} className="Accord_Hidden">
                            <Card.Body style={{ backgroundColor: "#F6F6F6" }} className="border-0">
                              <Row>
                                <Col md={12}>
                                  {usermobileRowHeader.map((tableData) => (
                                    <>
                                      <div className="mb-0" style={{ textAlign: "left" }}>
                                        {tableData.name}
                                      </div>
                                      <div
                                        style={{
                                          backgroundColor: "#FFFFFF",
                                          borderRadius: "5px",
                                          textAlign: "left",
                                        }}
                                        className="p-2 mb-3"
                                      >
                                        <div>
                                          {tableData.isNestedVar2 &&
                                            data[tableData.fieldName1] &&
                                            data[tableData.fieldName1][tableData.fieldName2]}
                                          {tableData.isNestedVar3 &&
                                            data[tableData.fieldName1] &&
                                            data[tableData.fieldName1][tableData.fieldName2] &&
                                            data[tableData.fieldName1][tableData.fieldName2][
                                              tableData.fieldName3
                                            ]}
                                          {tableData.isStatus &&
                                            (data[tableData.fieldName] ? (
                                              <button className="text-white border-0 bg-success">Pass</button>
                                            ) : (
                                              <button className="text-white border-0 bg-danger">Fail</button>
                                            ))}
                                          {!(tableData.isNestedVar2 && tableData.isNestedVar3) &&
                                            (!tableData.isDate
                                              ? tableData.fieldName == "permissions"
                                                ? data[tableData.fieldName] == "account_admin"
                                                  ? "Account Admin"
                                                  : data[tableData.fieldName] == "account_owner"
                                                    ? "Account Owner"
                                                    : data[tableData.fieldName] == "operator"
                                                      ? "Operator"
                                                      : data[tableData.fieldName] == "site_admin"
                                                        ? "Site Admin"
                                                        : data[tableData.fieldName] == "account_viewer"
                                                          ? "Account Viewer"
                                                          : data[tableData.fieldName] == "super_admin"
                                                            ? "SIERA.AI"
                                                            : "NA"
                                                : data[tableData.fieldName]
                                              : new Date(data[tableData.fieldName]).toLocaleDateString())}
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </Col>
                              </Row>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ))
                    ) : (
                      <div className="m-4">
                        <Trans>No Users</Trans>
                      </div>
                    )}
                  </Accordion>
                  <div className="text-center pagina_Report">
                    <div className="pageRow">
                      <Form.Group className="selectBoxDiv" onChange={(e) => handlePageLength(e)}>
                        <Form.Label>
                          <Trans>Rows Per Page</Trans> :{" "}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={pageSize}
                          className="selectBox"
                          onChange={(e) => setPageSize(e.target.value)}
                        >
                          {[
                            { name: 10, value: 10 },
                            { name: 25, value: 25 },
                            { name: 50, value: 50 },
                            { name: 100, value: 100 },
                          ].map((option) => (
                            <option key={option.name} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <div className="pageRow_Num">
                      <p className="text-muted">{`${t("Showing")} ${pageSize * (pageNo - 1) || 0} - ${
                        parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalUsers
                          ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
                          : totalUsers || 0
                      } of ${totalUsers || 0}`}</p>
                    </div>
                    <div className="pageRow_Arrow">
                      <ArrowLeft
                        onClick={() => userPreviousPage()}
                        style={{ cursor: "pointer", width: 20, marginRight: 10, transform: "rotate(180deg)" }}
                        fill="#0d5fbe"
                      />
                      <ArrowRight
                        onClick={() => userNextPage()}
                        style={{ cursor: "pointer", width: 20, marginRight: 10 }}
                        fill="#0d5fbe"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Table> */}
          </DivListTableStyle>
        ) : (
          ""
        )}
        <Row>
          <div id="tempqrcodediv"></div>
        </Row>
      </TableQRCodeStyle>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  assetList: state.assets.Assets,
  assetLoading: state.assets.AssetLoading,
  dropdownAccountId: state.dropdownFilters.accountId,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  qrCodeFilterBy: state.dropdownFilters.qrCodeFilterBy,
  userTypeList: state.userType.UserTypeList,
});

const mapDispatchToProps = (dispatch) => ({
  setAssetTypeList: (assetTypeLists) => dispatch(assetTypeList(assetTypeLists)),
  defaultAssetTypeOverview: () => dispatch(defaultAssetTypeOverview()),
  getAllAsset: (paginationData, isResetState) => dispatch(getAllAsset(paginationData, isResetState)),
  setDropdownWorksiteId: (data) => dispatch(setDropdownWorksiteId(data)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  getUserTypeList: () => dispatch(getUserTypeList()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QrcodePrint));
