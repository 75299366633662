import React from "react";
import SearchBox from "../../../../components/SearchBox/Searchbox.component";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

const SearchBoxDiv = styled.div``;

const ChecklistSearchBox = ({ searchText, setSearchText }) => {
  const { t } = useTranslation();

  return (
    <SearchBoxDiv className="searchBox">
      <SearchBox
        label={t("Your Checklists")}
        placeholder={t("Search a checklist")}
        onChange={(e) => setSearchText(e.target.value)}
        searchText={searchText}
        setSearchText={setSearchText}
      />
    </SearchBoxDiv>
  );
};

export default ChecklistSearchBox;
