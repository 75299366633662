import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import { Row, Col } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";

import ModalComponent from "../components/ModalPopupComponent/ModalPopup.component";
import Button from "../components/Button/Button.component";

import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

const HiddenInput = styled.input`
  display: none;
`;

const OverlayImage = styled.label`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: height 0.3s ease;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.font8};
  display: flex;
  align-items: start;
  line-height: ${({ theme }) => theme.lineHeights.line1};
  justify-content: center;
  margin-bottom: 0;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 95px;
  width: 95px;
  border-radius: 50%;
  overflow: hidden;
  &:hover ${OverlayImage} {
    height: 50%;
  }
`;

const Image = styled.img`
  display: block;
  height: 95px;
  object-fit: cover;
  width: 95px;
  border-radius: 50%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95px;
  width: 95px;
  border-radius: 50%;
  i {
    font-size: 96px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

const ModalBodyHeight = styled.div`
  height: calc(100vh - 260px);
  align-content: center;
  text-align: center;
  .ReactCrop {
    align-items: center;
    justify-content: center;
  }
  img.ReactCrop__image {
    /*  height: calc(100vh - 260px); */
    height: 100%;
    max-height: calc(100vh - 280px);
  }
`;

const ImageUpload = ({ id, src, updateImage, imageLoading }) => {
  const [PreviewImage, setPreviewImage] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [crop, setCrop] = useState({ unit: "%", width: 80, aspect: 1 });
  const [modalOpen, setModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const imageRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setPreviewImage(src);
    setImageLoaded(false);
  }, [src]);

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setModalOpen(true);
    }
  };

  const handleCropComplete = () => {
    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      imageRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    canvas.toBlob((blob) => {
      setPreviewImage(URL.createObjectURL(blob));
      updateImage(blob, id);
      setModalOpen(false);
    }, "image/jpeg");
  };

  return (
    <>
      <HiddenInput accept="image/*" id={`file-input-${id}`} type="file" onChange={handleImageSelect} />
      <ImageContainer>
        {PreviewImage ? (
          <Image src={PreviewImage} alt="" />
        ) : (
          <IconContainer>
            <i className="icon icon-user-photo" />
          </IconContainer>
        )}
        <OverlayImage htmlFor={`file-input-${id}`}>
          {PreviewImage ? t("Change Photo") : t("Add Photo")}
        </OverlayImage>
        {imageLoading && (
          <SpinnerContainer>
            <Spinner animation="border" role="status" size="sm" />
          </SpinnerContainer>
        )}
      </ImageContainer>

      <ModalComponent
        style={{ textAlign: "center" }}
        show={modalOpen}
        handleClose={() => setModalOpen(false)}
        title="Crop Image"
        size="lg"
        withHeader={true}
      >
        <ModalBodyHeight>
          {image && (
            <ReactCrop
              src={image}
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onImageLoaded={(img) => (imageRef.current = img)}
            />
          )}
        </ModalBodyHeight>
        <Row className="text-right">
          <Col md={12}>
            <Button label="Cancel" onClick={() => setModalOpen(false)} buttonType="ghost" />
            <Button label="Done" onClick={handleCropComplete} buttonType="primary" />
          </Col>
        </Row>
      </ModalComponent>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  success: state.asset.Success,
});

export default connect(mapStateToProps)(ImageUpload);
