import styled from "styled-components";

// Request Access Title Styling
export const RegisterTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font1};
  font-style: bold;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  padding-top: 50px;
  padding-bottom: 12px;
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
`;
// Request Access Sub Title Styling
export const RegisterSubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7};
  font-style: Regular;
  color:  ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  padding-top: 3px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;

// Request Access Sub Title2 Styling
export const RegisterSubTitle2 = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.font7};
  font-style: Regular;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  padding-top: 3px;
  padding-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;
