/**
 * TODO:  Create a common filter component for all the reports/Dashboard pages when time permits
 * Move the actions to action file
 * Do code optimization
 * Fix lint issues
 * Once completed, please remove this comment
 */

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DateRangePicker from "../../../../../components/DatePicker.component";
import { useTranslation } from "react-i18next";
import { filterByTypeData, FilterByTypeLabelData } from "./../constants";
import { apiCall } from "../../../../../utils/apiCall";
import DropdownComponent from "../../../../../components/DropdownComponent/Dropdown.component";
import DropdownWrapper from "../../../../../components/DropdownComponent/DropdownWrapper";
import Button from "../../../../../components/Button/Button.component";
import {
  setDropdownAccountId,
  setDropdownWorksiteId,
  getDropdownWorksiteList,
} from "../../../../../redux/dropdownFilters/action";
import { setInspectionReportFilter } from "../../../../../redux/filters/action";
import { getAllChecklist } from "../../../../../redux/checklists/action";
import MoreFilterMobileToggle from "../../../../../components/More Filter Button/MoreFilterButton.component";
import { StyledCol } from "../../Reports.styles";

const Filters = ({
  isSuperAdmin,
  isSiteAdmin,
  setDropdownAccountId,
  setInspectionReportFilter,
  inspectionReportFilter,
  setDropdownWorksiteId,
  dropdownAccountList,
  dropdownWorksiteList,
  startDate,
  endDate,
  setLastFilterData,
  dropdownAssetList,
  dropdownAssetTypeList,
  loginDetails,
  lastFilterData,
  dropdownFilterWorksiteId,
  selectedAccount,
  setSelectedAccount,
  getAllChecklist,
  checklists,
  applyChange,
  showMoreFilter,
  setShowMoreFilter,
  getDropdownWorksiteList,
  activeNavItem,
}) => {
  const [filterByTypeLabel, setFilterByTypeLabel] = useState("all");
  const { t } = useTranslation();
  const { filterByType, accountId, worksiteId } = inspectionReportFilter;
  const [accountsList, setAccountsList] = useState([]);

  useEffect(() => {
    //console.log("activeNavItem-----", activeNavItem, inspectionReportFilter);
    if (activeNavItem === "inspectionReports") {
      setDropdownAccountId(inspectionReportFilter?.accountId);
      getDropdownWorksiteList();
      getAllChecklist();
    }
  }, [activeNavItem]);

  const changeLastFilter = (value) => {
    if (filterByType === "asset") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        assetId: value,
        pageNo: 1,
      });
    } else if (filterByType === "assetType") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        assetTypeId: value,
        pageNo: 1,
      });
    } else if (filterByType === "inspectedBy") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        inspectorId: value,
        pageNo: 1,
      });
    } else if (filterByType === "inspectionResult") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        status: value,
        pageNo: 1,
      });
    } else if (filterByType === "checklist") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        checklistId: value,
        pageNo: 1,
      });
    }
  };

  useEffect(() => {
    if (inspectionReportFilter.filterByType === "checklist") {
      setLastFilterData(checklists);
    }
  }, [selectedAccount, checklists]);

  const changeFilterByType = (value) => {
    setInspectionReportFilter({
      ...inspectionReportFilter,
      filterByType: value,
      pageNo: 1,
    });
    setFilterByTypeLabel(FilterByTypeLabelData[value]);
    if (value === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (value === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (value === "inspectedBy") {
      apiCall(
        `/dropdown/users?accid=${isSuperAdmin ? accountId : loginDetails.accountId}&siteid=${
          isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownFilterWorksiteId
        }&status=true`
      ).then((resp) => {
        setLastFilterData(resp.data?.data?.data);
      });
    } else if (value === "inspectionResult") {
      setLastFilterData([
        { fullName: "Pass", _id: "passed" },
        { fullName: "Fail", _id: "failed" },
        { fullName: "Timed out", _id: "timedout" },
      ]);
    } else if (value === "checklist") {
      setLastFilterData(checklists);
    }
  };

  useEffect(() => {
    const accounts = [];
    dropdownAccountList.forEach((acc) => {
      accounts.push({
        value: acc._id,
        label: acc.companyName,
      });

      if (acc._id === inspectionReportFilter.accountId) {
        setSelectedAccount({
          value: acc._id,
          label: acc.companyName,
        });
      }
    });
    setAccountsList(accounts);
  }, [dropdownAccountList]);

  useEffect(() => {
    if (!selectedAccount) {
      setShowMoreFilter(false);
    } else {
      getAllChecklist(selectedAccount?.value);
      inspectionReportFilter.worksiteId = "all";
    }
  }, [selectedAccount]);

  // const handleClearAll = () => {
  //   setDropdownAccountId(null);
  //   setDropdownWorksiteId(null);
  //   setInspectionReportFilter({});
  //   setSelectedAccount(null);
  //   setLastFilterData([]);
  // };

  return (
    <MoreFilterMobileToggle>
      <Row>
        {isSuperAdmin && (
          <Col lg={2}>
            <DropdownComponent
              label={t("Account")}
              placeholder={t("Select an Account")}
              options={accountsList}
              value={selectedAccount}
              handleFilter={(selectedData) => {
                setDropdownAccountId(selectedData.value);
                setSelectedAccount(selectedData);
                setInspectionReportFilter({
                  ...inspectionReportFilter,
                  accountId: selectedData.value,
                });
              }}
              size="medium"
            />
          </Col>
        )}

        {((isSuperAdmin && selectedAccount) || !isSuperAdmin) && (
          <>
            {!isSiteAdmin && (
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: t("Worksite"),
                    name: "worksiteName",
                    key: "name",
                    data: dropdownWorksiteList,
                  }}
                  value={worksiteId}
                  // label={t("filter")}
                  handleFilter={(value) => {
                    setDropdownWorksiteId(value);
                    setInspectionReportFilter({
                      ...inspectionReportFilter,
                      worksiteId: value,
                      assetId: null,
                      assetTypeId: null,
                    });
                  }}
                />
              </Col>
            )}
            <Col lg={4}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setDate={setInspectionReportFilter}
                filterState={inspectionReportFilter}
              />
            </Col>
            <StyledCol>
              <Button
                iconClass="filter"
                title={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                onClick={() => setShowMoreFilter(!showMoreFilter)}
                iconOnly
                buttonType={showMoreFilter ? "primary" : "secondary"}
              />
              <span style={{ width: "10px", display: "inline-block" }}>&nbsp;</span>
              <Button
                label={t("Apply Filter")}
                size="medium"
                onClick={applyChange}
                showIcon={false}
                buttonType="primary"
              />
            </StyledCol>
          </>
        )}
      </Row>
      {showMoreFilter && (
        <Row className="mt-2">
          <Col lg={2}>
            <DropdownWrapper
              filter={{
                type: t("Filter By"),
                name: "filterBy",
                placeholder: t("Select a Filter"),
                key: "name",
                data: filterByTypeData,
              }}
              value={filterByType}
              //  label={t("filter")}
              handleFilter={(e) => changeFilterByType(e)}
              useAsDropdown={true}
            />
          </Col>
          {filterByTypeLabel !== "all" && filterByType !== "all" && (
            <Col lg={2}>
              <DropdownWrapper
                sortData={true}
                filter={{
                  type: `${filterByTypeLabel === t("Inspected By") ? t("User") : filterByTypeLabel}`,
                  name: "worksiteName",
                  key:
                    filterByType === "checklist"
                      ? "checklistName"
                      : filterByType === "asset"
                        ? "assetName"
                        : filterByType === "assetType"
                          ? "AssetTypeName"
                          : "fullName",
                  data: lastFilterData,
                }}
                value={
                  inspectionReportFilter[
                    filterByType === "checklist"
                      ? "checklistId"
                      : filterByType === "asset"
                        ? "assetId"
                        : filterByType === "assetType"
                          ? "assetTypeId"
                          : filterByType === "inspectionResult"
                            ? "status"
                            : "inspectorId"
                  ]
                }
                label={t("filter")}
                handleFilter={(e) => changeLastFilter(e)}
              />
            </Col>
          )}
        </Row>
      )}
    </MoreFilterMobileToggle>
  );
};

Filters.defaultProps = {
  inspectionReportFilter: {},
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  inspectionReportFilter: state.filters.inspectionReportFilter,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  checklists: state.checklist.checklists,
  activeNavItem: state.sideMenu.activeNavItem,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setInspectionReportFilter: (filterData) => dispatch(setInspectionReportFilter(filterData)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  getAllChecklist: (accountId) =>
    dispatch(getAllChecklist({ pageNo: "all", pageSize: "all", searchKey: "all" }), false, accountId),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Filters));
