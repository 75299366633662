import styled from "styled-components";

export const CarouselWrapperDiv = styled.div``;

export const AssetIconconDiv = styled.div`
  display: initial;
  font-size: ${({ theme }) => theme.fontSizes.font4} !important;
  position: relative;
  right: 10px;
  top: 1px;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;

export const StyledCard = styled.div`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  box-shadow: 0px 3px 12px -7px ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
`;

export const AssetDiv = styled.div`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: ${({ theme }) => theme.fontSizes.font3} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  font-weight: 600;
`;

export const StyledRow = styled.div``;

export const StyledCol = styled.div`
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-size: ${({ theme }) => theme.fontSizes.font7} !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
`;
