import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import Button from "../../../../components/Button/Button.component";
import { setEditForm } from "../../../../redux/forms/action";
import { AddCertificateDiv } from "./operatorCertificate/OperatorCertificatesTab.styles";
import ActionButtons from "../../common/ActionButtons";
import ApplyToAllModal from "../../common/appyToAll/ApplyToAllModal";
import { bulkAlertSettingsUpdate } from "../../../../redux/config/action";

import SnackBar from "../../SnackBar";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";
import { getUserDetailById } from "../../../../redux/users/action";

export const SettingsFooter = styled.div`
  overflow: hidden;
  
  text-align: left;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  .update-button-s2 {
    margin: 0px !important;
    padding: 0px !important;
    .worksite-names {
      padding-left: 0px;
    }
    .col-md-8 {
      padding-right: 0px;
    }
  }
`;

const UserEditFormStyle = styled.div`
  width: 100%;
  @media (max-width: 1100px) {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;
const ButtonEditStyle = styled.div`
  text-align: right;
`;
function UserEditComponent({
  setEditForm,
  editForm,
  handleUpdateUser,
  addCertificate,
  handleAddCertificate,
  bulkAlertSettingsUpdate,
  userDetails,
  activeTab,
  userOverviewDetail,
  getUserDetailById,
  isWorksiteMandatory,
}) {
  const { t } = useTranslation();
  const [showApplyToAllModal, setShowApplyToAllModal] = useState(false);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  const settingsOptions = [
    {
      label: "All User Types",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "userTypes",
    },
    {
      label: "Selected User Types",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "label",
      radioGroup: "userTypes",
      dropdown: {
        placeholder: "Select a User Type",
      },
    },
    {
      label: "All Worksites",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "worksites",
    },
    {
      label: "Selected Worksites",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "name",
      radioGroup: "worksites",
      dropdown: {
        placeholder: "Select a Worksite",
      },
    },
    {
      label: "All Users",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "users",
    },
    {
      label: "Selected Users",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "fullName",
      radioGroup: "users",
      dropdown: {
        placeholder: "Select a User",
      },
    },
  ];

  const toggleApplyToAllModal = () => {
    setShowApplyToAllModal(!showApplyToAllModal);
  };

  const saveHandler = () => {
    toggleApplyToAllModal();
  };

  const isApplyToAllObjValid = () => {
    return (
      (applyToAllObj.worksites?.all ||
        (applyToAllObj.worksites?.selected && applyToAllObj.worksites?.selectedItems?.length > 0)) &&
      (applyToAllObj.userTypes?.all ||
        (applyToAllObj.userTypes?.selected && applyToAllObj.userTypes?.selectedItems?.length > 0)) &&
      (applyToAllObj.users?.all ||
        (applyToAllObj.users?.selected && applyToAllObj.users?.selectedItems?.length > 0))
    );
  };

  const onUpdate = () => {
    if (isApplyToAllObjValid() && activeTab === "assetPermissions") {
      const siteIds = applyToAllObj?.worksites?.all
        ? "all"
        : applyToAllObj?.worksites?.selectedItems?.map((site) => site.value);
      const userTypeIds = applyToAllObj?.userTypes?.all
        ? "all"
        : applyToAllObj?.userTypes?.selectedItems?.map((userType) => userType.value);
      const userIds = applyToAllObj?.users?.all
        ? "all"
        : applyToAllObj?.users?.selectedItems?.map((user) => user.value);

      const updatableSettings = {
        sites: siteIds,
        userTypes: userTypeIds,
        users: userIds,
        settings: { unauthorizedAssetTypes: userDetails?.unauthorizedAssetTypes },
      };
      bulkAlertSettingsUpdate(updatableSettings, bulkUpdateCallback);
    } else {
      handleUpdateUser();
    }
  };

  const bulkUpdateCallback = (flag) => {
    getUserDetailById(userDetails?._id, false);
    setIsUpdateLoading(flag);
  };

  const handleCloseSnackBar = () => {
    setEditForm(true);
    setApplyToAllObj({});
    setClearSelection(true);
    setIsUpdateLoading(false);
  };

  const [isIconOnly, setIsIconOnly] = useState(window.innerWidth <= 1100);
  useEffect(() => {
    const handleResize = () => {
      setIsIconOnly(window.innerWidth <= 1100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <UserEditFormStyle>
      <PermissionCheck section={"DASHBOARD"} permissionName={"USER_MANAGEMENT"} actionName={"edit"}>
        <>
          {addCertificate && (
            <AddCertificateDiv>
              <Button
                label={t("Add Certificate")}
                onClick={handleAddCertificate}
                showIcon
                buttonType="primary"
                disabled={!userOverviewDetail.status}
                iconClass="add"
                {...(isIconOnly && { iconOnly: true })}
              />
            </AddCertificateDiv>
          )}

          <ButtonEditStyle>
            {editForm && (
              <Button
                iconClass="edit"
                label={t("Edit")}
                onClick={() => {
                  setEditForm(false);
                }}
                showIcon
                buttonType="primary"
                disabled={!userOverviewDetail.status}
              />
            )}
            {!editForm && (
              <>
                <SettingsFooter>
                  <ActionButtons
                    showAppyToAll={activeTab === "assetPermissions"}
                    handleApplyToAll={toggleApplyToAllModal}
                    applyToAll={isApplyToAllObjValid()}
                    updatableChanges={!isWorksiteMandatory}
                    handleUpdate={onUpdate}
                    handleCancel={() => {
                      setEditForm(true);
                      setApplyToAllObj({});
                      setClearSelection(true);
                      setIsUpdateLoading(false);
                    }}
                    isLoading={false}
                    showResetButton={false}
                  />
                </SettingsFooter>
              </>
            )}
          </ButtonEditStyle>

          <ApplyToAllModal
            showModal={showApplyToAllModal}
            setShowModal={toggleApplyToAllModal}
            applyChanges={saveHandler}
            setCheckApplyToAll={() => {}}
            settingsOptions={settingsOptions}
            modalHeader={"Asset Permissions"}
            applyToAllObj={applyToAllObj}
            setApplyToAllObj={setApplyToAllObj}
            pageType={"assetPermissions"}
            setClearSelection={setClearSelection}
            clearSelection={clearSelection}
            isAssetPermission={true}
          />
        </>
      </PermissionCheck>
      <SnackBar
        isSuccess={isUpdateLoading}
        label={t("User Details Updated Successfully")}
        handleClose={handleCloseSnackBar}
      />
    </UserEditFormStyle>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setEditForm: (data) => dispatch(setEditForm(data)),
  bulkAlertSettingsUpdate: (data, setUpdateLoading) =>
    dispatch(bulkAlertSettingsUpdate(data, setUpdateLoading)),
  getUserDetailById: (id, setIsLoading) => dispatch(getUserDetailById(id, setIsLoading)),
});

const mapStateToProps = (state) => ({
  editForm: state.forms.editForm,
  userOverviewDetail: state.users.UserOverview,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEditComponent));
