import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";

import DataTable from "../../../../components/DataTableComponent/DataTable.component";
import { toDownloadFiles } from "../../../../redux/config/action";
import PopoverCustom from "../../../../components/PopoverCustom.component";
import ReportDownload from "../../Report/ReportShare/ReportShareModal.component";
import { getTimezoneShortCode } from "../../../../utils/timezoneConvert";
import awsConstant from "../../../../constant/awsFolder.json";
import { Severity, NoReportsDiv, NearMissReportTable } from "./NearMissTableWrapper.component.styles";
import { getNearMissReports } from "../../../../redux/reports/action";

const NearMissTableWrapper = ({
  data,
  loginDetails,
  isSiteAdmin,
  toDownloadFiles,
  fileDownloadLoading,
  isSingleAccountMultiSiteUser,
  setIsSuccess,
  setIsError,
  currentTimeZone,
  loading,
  getNearMissReports,
  nearMissReportList,
  assetOverview,
  dropdownAccountId,
  isSuperAdmin,
}) => {
  const { t } = useTranslation();
  const tableHeader = [
    { name: "Account", space: 2, fieldName: "accountName" },
    { name: "Worksite", space: 1, fieldName: "worksite" },
    { name: "Asset Type", space: 1, fieldName: "assetType" },
    { name: "Asset Name", space: 1, fieldName: "assetName" },
    { name: "Incident By", space: 1, fieldName: "fullName" },
    { name: "Date, Time", space: 1, fieldName: "createdAt" },
    { name: "Obstacle Type", space: 1, fieldName: "nearMissCategory" },
    { name: "Actions", space: 2, fieldName: "inspectionCountMonthly" },
  ];

  const [loadingReportId, setLoadingReportId] = useState("");

  const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.impactReport, `${id}.pdf`);
  };

  const viewReportClickHandler = (e, row) => {
    if (
      row?.impactSeverity?.toLowerCase?.() === "low" ||
      row?.impactSeverity?.toLowerCase?.() === "medium" ||
      row?.impactSeverity?.toLowerCase?.() === "high"
    ) {
      localStorage.setItem("impactReportObject", JSON.stringify(row));
      window.open("/#/app/impact-report", "_blank");
    } else {
      localStorage.setItem("nearMissReportObject", JSON.stringify(row));
      window.open("/#/app/near-miss-report", "_blank");
    }
  };

  const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  };

  useEffect(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 3);
    const bodyData = {
      accountId: isSuperAdmin ? dropdownAccountId : "all",
      worksiteId: "all",
      assetId: assetOverview?._id || "all",
      assetTypeId: assetOverview?.assetTypeId?._id || "all",
      inspectorId: "all",
      startDate: date,
      endDate: new Date(),
      pageNo: 1,
      pageSize: 10,
      obstacleType: "all",
      incidentType: "all",
    };
    getNearMissReports(bodyData);
  }, []);

  const ActionCell = ({ row }) => {
    return (
      <>
        {fileDownloadLoading && row._id === loadingReportId ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <div className="action-div">
            <i
              title="View"
              onClick={(e) => viewReportClickHandler(e, row)}
              className="icon icon-view"
              style={{ textAlign: "right", marginRight: "5px" }}
            ></i>
            <i
              title="Download"
              onClick={() => downloadReportApiCall(row._id)}
              className="icon icon-download"
              style={{ marginLeft: "5px" }}
            ></i>

            {/* <i className="icon icon-share"></i> */}
            {/* <i title="Share">
              <span style={{ display: "block" }}>
                <PopoverCustom
                  children={
                    <ReportDownload
                      userData={userData}
                      reportDetails={{
                        accountId: data?.accountId,
                        accountName: data?.accountId?.companyName,
                        reportType: 2,
                        reportId: data?._id,
                      }}
                      setIsSuccess={setIsSuccess}
                      setIsError={setIsError}
                    />
                  }
                />
              </span>
            </i>*/}
          </div>
        )}
      </>
    );
  };

  const columns = tableHeader
    .filter(
      (data) =>
        !(
          (isSingleAccountMultiSiteUser && data.name === "Account") ||
          (isSiteAdmin && (data.name === "Worksite" || data.name === "Account"))
        )
    )

    .map((data) => {
      if (data.name === "Account") {
        return {
          name: t(data.name),
          selector: (row) => row?.accountId?.companyName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Worksite") {
        return {
          name: t(data.name),
          selector: (row) => row?.siteId?.name || "NA",
          sortable: true,
        };
      }

      if (data.name === "Incident By") {
        return {
          name: t(data.name),
          selector: (row) => row?.userId?.fullName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Asset Name") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetId?.assetName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Asset Type") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetTypeId?.AssetTypeName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Date, Time") {
        return {
          name: t(data.name),
          selector: (row) => {
            const timeZone = currentTimeZone || row?.siteId?.timeZone || "NA";
            const formattedDate = !row?.createdAt
              ? "NA"
              : `${moment(row?.createdAt)
                  .utcOffset(timeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm:ss A")} ${getTimezoneShortCode(timeZone)}`;

            return <span title={formattedDate}>{formattedDate}&nbsp;</span>;
          },
          sortable: true,
        };
      }

      if (data.name === "Obstacle Type") {
        return {
          name: t(data.name),
          selector: (row) => {
            const severity = row?.nearMissCategory || "NA";
            return (
              <Severity title={severity} severity={severity}>
                <span>{severity}</span>
                {severity === "PEDESTRIAN" && <i className="icon icon-pedestrian"></i>}
                {severity === "OBJECT" && <i className="icon icon-assets"></i>}
              </Severity>
            );
          },
          sortable: true,
        };
      }

      if (data.name === "Actions") {
        return {
          name: t(data.name),
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }

      return {
        name: data.name,
        selector: (row) => row[data.fieldName],
        sortable: true,
      };
    });
  // columns.unshift(...columnsWithCheckboxes);

  const mobileCheckboxDateTime = [
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Incident By", className: "inspectedByStyle" },
    { columnName: "Asset Name", className: "assetNameStyle" },
  ];

  const mobileCheckboxAccordion = ["Asset Name", "Impact (g)", "Obstacle Type"];

  if (!Array.isArray(nearMissReportList)) {
    return (
      <NoReportsDiv>
        <Trans>No_NEAR_MISS_REPORT_AVAILABLE</Trans>
      </NoReportsDiv>
    );
  }

  return (
    <NearMissReportTable>
      <DataTable
        title=""
        columns={columns}
        data={nearMissReportList}
        mobileCheckboxDateTime={mobileCheckboxDateTime}
        mobileCheckboxAccordion={mobileCheckboxAccordion}
        responsive
        dense
        loading={loading}
        rowHeight={565}
        scrollable={true}
        fixedHeader
        mobileCheckboxOnly
        mobileDateTimeOnly
        mobileActionItemOnly
        noDataMessage={t("No Near-Miss Reports Available")}
        rowsPerPageOptions={[5]}
      />
    </NearMissReportTable>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  fileDownloadLoading: state.config.fileDownloadLoading,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
  currentTimeZone: state.settings.currentTimeZone,
  nearMissReportList: state.reports.nearMissReportList,
  dropdownAccountId: state.dropdownFilters.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
  getNearMissReports: (filterData) => dispatch(getNearMissReports(filterData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NearMissTableWrapper));
