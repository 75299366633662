import { userTypeManagement } from "./types";
import { apiCall } from "../../utils/apiCall";

export const resetUserTypeFlags = () => ({
  type: userTypeManagement.RESET_USER_TYPE_FLAGS,
});

const getUserTypeListLoading = () => ({
  type: userTypeManagement.GET_USER_TYPE_LIST_LOADING,
});

const getUserTypeListSuccess = (data, isResetState, pageNo) => ({
  type: userTypeManagement.GET_USER_TYPE_LIST_SUCCESS,
  data,
  isResetState,
  pageNo,
});

const getUserTypeListError = (err) => ({
  type: userTypeManagement.GET_USER_TYPE_LIST_ERROR,
  err,
});

// To get all asset types
const getUserTypeDetailLoading = () => ({
  type: userTypeManagement.GET_USER_TYPE_DETAIL_LOADING,
});

const getUserTypeDetailSuccess = (data, isResetState, pageNo) => ({
  type: userTypeManagement.GET_USER_TYPE_DETAIL_SUCCESS,
  data,
});

const getUserTypeDetailError = (err) => ({
  type: userTypeManagement.GET_USER_TYPE_DETAIL_ERROR,
  err,
});

export const getUserTypeDetailById = (userTypeId) => {
  //console.log("Called");

  return (dispatch, getState) => {
    dispatch(getUserTypeDetailLoading());
    apiCall(`/usertype/${userTypeId}`)
      .then((resp) => {
        dispatch(getUserTypeDetailSuccess(resp.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getUserTypeDetailError(err));
      });
  };
};

// To add user type
const addUserTypeLoading = () => ({
  type: userTypeManagement.ADD_USER_TYPE_LOADING,
});

const addUserTypeSuccess = (data, isResetState, pageNo) => ({
  type: userTypeManagement.ADD_USER_TYPE_SUCCESS,
  data,
});

const addUserTypeError = (err) => ({
  type: userTypeManagement.ADD_USER_TYPE_ERROR,
  err,
});

export const addUserType = (userTypeData) => {
  return (dispatch, getState) => {
    dispatch(addUserTypeLoading());
    const state = getState();
    const accountId = state?.user?.loginDetails?.accountId;
    const dropdownAccountId = state?.dropdownFilters?.accountId;
    const isSuperAdmin = state?.user?.isSuperAdmin;

    userTypeData.accountId =
      userTypeData.accountId && userTypeData.accountId !== "all"
        ? userTypeData.accountId
        : isSuperAdmin
          ? dropdownAccountId
          : accountId;

    apiCall(`/usertype/add`, { method: "POST", data: userTypeData })
      .then((response) => {
        console.log("userTypeData", userTypeData);
        if (response.success) {
          if (userTypeData?.accessLevel === "ALL_ACCOUNTS") {
            dispatch(getSuperAdminUserTypes());
          } else {
            dispatch(getUserTypeList());
          }
          dispatch(addUserTypeSuccess(response.data));
        } else {
          dispatch(addUserTypeError(response.message));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(addUserTypeError(err));
      });
  };
};

const updateUserTypeLoading = () => ({
  type: userTypeManagement.UPDATE_USER_TYPE_LOADING,
});

const updateUserTypeSuccess = (data) => ({
  type: userTypeManagement.UPDATE_USER_TYPE_SUCCESS,
  data,
});

const updateUserTypeError = (err) => ({
  type: userTypeManagement.UPDATE_USER_TYPE_ERROR,
  err,
});

export const updateUserType = (userTypeId, updatedUserType) => {
  return async (dispatch, getState) => {
    dispatch(updateUserTypeLoading());

    const updatedUserTypeLocal = { ...updatedUserType };

    delete updatedUserTypeLocal._id;
    delete updatedUserTypeLocal.createdAt;
    delete updatedUserTypeLocal.updatedAt;
    delete updatedUserTypeLocal.__v;

    try {
      const response = await apiCall(`/usertype/${userTypeId}`, {
        method: "PUT",
        data: { ...updatedUserTypeLocal },
      });

      if (!response.data.success) {
        dispatch(updateUserTypeError({ err: response.message }));
      } else {
        //dispatch(getUserTypeList());
        dispatch(updateUserTypeSuccess(response.data.data));
      }
    } catch (err) {
      dispatch(updateUserTypeError({ err }));
    }
  };
};

const toggleUserTypeStatusSuccess = (data) => ({
  type: userTypeManagement.TOGGLE_USER_TYPE_STATUS_SUCCESS,
  data,
});

const toggleUserTypeStatusError = (err) => ({
  type: userTypeManagement.TOGGLE_USER_TYPE_STATUS_ERROR,
  err,
});

export const toggleUserTypeStatus = (userTypeId, updatedStatus) => {
  return async (dispatch, getState) => {
    dispatch(updateUserTypeLoading());

    try {
      const response = await apiCall(`/usertype/${userTypeId}`, {
        method: "PUT",
        data: { ...updatedStatus },
      });

      if (!response.data.success) {
        dispatch(toggleUserTypeStatusError({ err: response.message }));
      } else {
        dispatch(toggleUserTypeStatusSuccess(response.data.data));
      }
    } catch (err) {
      dispatch(updateUserTypeError({ err }));
    }
  };
};

export const getUserTypeBySearchText = (searchText) => {
  return (dispatch, getState) => {
    const state = getState();
    const accountId = state.user?.isSuperAdmin
      ? state?.dropdownFilters?.accountId
      : state?.user?.loginDetails?.accountId;

    if (searchText) {
      apiCall(`/usertype/search/${accountId}/${searchText ? encodeURIComponent(searchText) : "all"}`)
        .then((response) => {
          dispatch(getUserTypeListSuccess(response.data));
        })
        .catch((err) => {
          console.log(err);
          dispatch(getUserTypeDetailError(err));
        });
    } else {
      dispatch(getUserTypeList(true));
    }
  };
};

export const getUserTypeList = (hideLoading, accId) => {
  return (dispatch, getState) => {
    if (!hideLoading) {
      dispatch(getUserTypeListLoading());
    }

    const state = getState();
    const accountId = state?.user?.loginDetails?.accountId;
    const dropdownAccountId = state?.dropdownFilters?.accountId;
    const isSuperAdmin = state?.user?.isSuperAdmin;

    apiCall(`/usertype/list/${accId ? accId : isSuperAdmin ? dropdownAccountId : accountId}`)
      .then((response) => {
        dispatch(getUserTypeListSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getUserTypeListError(err));
      });
  };
};

// UserType detail LIST
export const getUserTypeDetailList = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const accountId = state.user.isSuperAdmin
      ? state?.dropdownFilters?.accountId
      : state?.user?.loginDetails?.accountId;

    if (!accountId) {
      return;
    }
    const url = `/dropdown/userTypes?accid=${accountId}`;
    apiCall(url)
      .then((response) => {
        return dispatch({
          type: userTypeManagement.GET_DROPDOWN_USERTYPE_LIST,
          data: response.data.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const getSuperAdminUserTypesSuccess = (data) => ({
  type: userTypeManagement.GET_SUPER_ADMIN_USER_TYPES_SUCCESS,
  data,
});

const getSuperAdminUserTypesError = (err) => ({
  type: userTypeManagement.GET_SUPER_ADMIN_USER_TYPES_ERROR,
  err,
});

export const getSuperAdminUserTypes = (superAdminUserTypeId) => {
  return async (dispatch, getState) => {
    apiCall(`/dropdown/superadmins?accessLevel=ALL_ACCOUNTS`)
      .then((response) => {
        dispatch(getSuperAdminUserTypesSuccess(response?.data?.data?.data));
      })
      .catch((err) => {
        dispatch(getSuperAdminUserTypesError());
        console.log(err);
      });
  };
};

const preDeactivatedUserTypeLoading = () => ({
  type: userTypeManagement.PRE_DEACTIVATED_USER_TYPE_LOADING,
});

const preDeactivatedUserTypeSuccess = (data) => ({
  type: userTypeManagement.PRE_DEACTIVATED_USER_TYPE_SUCCESS,
  data,
});

const preDeactivatedUserTypeError = (err) => ({
  type: userTypeManagement.PRE_DEACTIVATED_USER_TYPE_ERROR,
  err,
});

export const preDeactivatedUserType = (userTypeId) => {
  return async (dispatch, getState) => {
    dispatch(preDeactivatedUserTypeLoading());
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const accountId = isSuperAdmin ? state?.dropdownFilters?.accountId : state?.user?.loginDetails?.accountId;

    try {
      const response = await apiCall(
        `/usertype/predeactivation?userTypeId=${userTypeId}&accountId=${accountId}`
      );
      if (response.data.data.length == 0) {
        dispatch(deactivateUserType(userTypeId));
      } else {
        dispatch(preDeactivatedUserTypeSuccess(response.data.data));
      }
      // dispatch(preDeactivatedUserTypeSuccess(response.data.data));
    } catch (err) {
      dispatch(preDeactivatedUserTypeError(err));
    }
  };
};

const deactivatedUserTypeLoading = () => ({
  type: userTypeManagement.DEACTIVATE_USER_TYPE_LOADING,
});

const deactivatedUserTypeSuccess = (data) => ({
  type: userTypeManagement.DEACTIVATE_USER_TYPE_SUCCESS,
  data,
});

const deactivatedUserTypeError = (err) => ({
  type: userTypeManagement.DEACTIVATE_USER_TYPE_ERROR,
  err,
});

export const deactivateUserType = (userTypeId) => {
  return async (dispatch, getState) => {
    dispatch(deactivatedUserTypeLoading());
    const state = getState();
    const isSuperAdmin = state?.user?.isSuperAdmin;
    const accountId = isSuperAdmin ? state?.dropdownFilters?.accountId : state?.user?.loginDetails?.accountId;

    try {
      const response = await apiCall(`/usertype/deactivate?userTypeId=${userTypeId}&accountId=${accountId}`, {
        method: "PUT",
      });
      dispatch(deactivatedUserTypeSuccess(response.data.success));
    } catch (err) {
      dispatch(deactivatedUserTypeError(err));
    }
  };
};
