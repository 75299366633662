import React, { useEffect, useState } from "react";
import moment from "moment";
import config from "../../../../config";
import { getTimezoneShortCode } from "../../../../utils/timezoneConvert";
import ScoutLogo from "../../../../assets/logo/Scout_Logo.png";
import { ReactComponent as ReportArrowDown } from "../../../../assets/Report_Arrow_Down.svg";
import { Trans } from "react-i18next";
import MapView from "./MapView.component";
import "./report.css";
import {
  ReportDivWrap,
  ReportPageWrapper,
  ReportTopSection,
  PageNumber,
  SieraLogo,
  EmailHelpText,
  Anchor,
  ReportName,
  ReportDataSection,
  ReportHeaderTop,
  ReportStatus,
  ReportCenterData,
  ReportRightSec,
  VehicleDetailsData,
  VehicleDatNA,
  ReportPageFooter,
  ImpactSeverityLow,
  ImpactSeverityMedium,
  ImpactSeverityHigh,
  PageMoreDetails,
  ReportCenterDataTableImg,
} from "../../../../components/ReportViewComponent/ReportViewComponent.Style";

const s3BucketImpactImage = config.s3BucketImpactImage;

function IncidentReportView() {
  const [reportData, setReportData] = useState(null);
  const [position, setPosition] = useState(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("impactReportObject"));

    setReportData(data);
    setPosition(data?.location?.coordinates || null);
    document.title = data?.userId?.fullName + "(Asset Impact Report)";
  }, []);

  const timeZone = localStorage.getItem("currentTimezone") || reportData?.timeZone;

  return (
    <ReportDivWrap>
      {reportData ? (
        <>
          <ReportPageWrapper>
            <ReportTopSection>
              <PageNumber>Page 1</PageNumber>
              <SieraLogo src={ScoutLogo} alt="" />
              <EmailHelpText>
                For support email us at
                <Anchor href="mailto:support@siera.ai"> support@siera.ai</Anchor>
              </EmailHelpText>
              <EmailHelpText>
                or visit our Helpdesk&nbsp;
                <Anchor href="https://support.siera.ai" target="_blank">
                  https://support.siera.ai
                </Anchor>
              </EmailHelpText>
            </ReportTopSection>

            <ReportName>
              <Trans>Asset Impact Report</Trans>
            </ReportName>

            <ReportDataSection>
              <ReportHeaderTop>
                <Trans>Summary</Trans>
              </ReportHeaderTop>

              <ReportCenterData>
                <Trans>Impact Severity</Trans>
                <ReportStatus>
                  {reportData?.severity?.toLowerCase() === "low" && (
                    <ImpactSeverityLow>LOW</ImpactSeverityLow>
                  )}
                  {reportData?.severity?.toLowerCase() === "medium" && (
                    <ImpactSeverityMedium>MEDIUM</ImpactSeverityMedium>
                  )}
                  {reportData?.severity?.toLowerCase() === "high" && (
                    <ImpactSeverityHigh>HIGH</ImpactSeverityHigh>
                  )}
                </ReportStatus>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Worksite</Trans>
                <ReportRightSec>{reportData?.siteId?.name || "NA"}</ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Date | Time | Timezone</Trans>
                <ReportRightSec>
                  {moment(reportData?.impactRecordedAt)
                    .utcOffset(timeZone?.split?.(" ")[0])
                    .format("MMMM DD, YYYY | hh.mm.ss A")}{" "}
                  | {getTimezoneShortCode(timeZone)}
                </ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Asset Name</Trans>
                <ReportRightSec>{reportData?.assetId?.assetName || "NA"}</ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Impact By</Trans>
                <ReportRightSec>{reportData?.userId?.fullName || "NA"}</ReportRightSec>
              </ReportCenterData>

              <br />

              <ReportHeaderTop>
                <Trans>Details</Trans>
              </ReportHeaderTop>

              <ReportCenterData>
                <Trans>Impact Value</Trans>
                <ReportRightSec>{reportData?.impactG + "g" || "NA"}</ReportRightSec>
              </ReportCenterData>

              <br />

              <ReportHeaderTop>
                <Trans>Vehicle Details</Trans>
              </ReportHeaderTop>
              <VehicleDetailsData>
                <>
                  <ReportCenterData>
                    <Trans>Vehicle Location</Trans>
                    <ReportRightSec>
                      {position && position?.length === 2 && (position[0] !== 0 || position[1] !== 0)
                        ? position.join(", ")
                        : "NA"}
                    </ReportRightSec>
                  </ReportCenterData>
                  {position && position?.length === 2 && (position[0] !== 0 || position[1] !== 0) && (
                    <MapView position={position} />
                  )}
                </>
              </VehicleDetailsData>
            </ReportDataSection>

            {(typeof reportData?.impactImage == "string" && reportData?.impactImage !== "") ||
            (typeof reportData?.impactImage == "object" && reportData?.impactImage?.length === 0) ? (
              ""
            ) : (
              <PageMoreDetails>
                <ReportArrowDown />
                <p>
                  <Trans>Please proceed to Page 2 for more details</Trans>
                </p>
                <ReportArrowDown />
              </PageMoreDetails>
            )}

            <ReportPageFooter>
              <Trans>Proudly Built in Austin, TX. Copyright </Trans>
              {new Date().getFullYear()}.&nbsp;
              <Trans>Stocked Robotics, Inc. dba SIERA.AI</Trans>
            </ReportPageFooter>
          </ReportPageWrapper>

          {/* If the image of the operator is empty do not show the image page */}
          {(typeof reportData?.impactImage == "string" && reportData?.impactImage !== "") ||
          (typeof reportData?.impactImage == "object" && reportData?.impactImage?.length === 0) ? (
            ""
          ) : (
            <ReportPageWrapper>
              <ReportTopSection>
                <PageNumber>Page 2</PageNumber>
                <SieraLogo src={ScoutLogo} alt="" />
                <EmailHelpText>
                  For support email us at
                  <Anchor href="mailto:support@siera.ai"> support@siera.ai</Anchor>
                </EmailHelpText>
                <EmailHelpText>
                  or visit our Helpdesk&nbsp;
                  <Anchor href="https://support.siera.ai" target="_blank">
                    https://support.siera.ai
                  </Anchor>
                </EmailHelpText>
              </ReportTopSection>
              <ReportDataSection>
                <ReportHeaderTop>
                  <Trans>Photo of Operator</Trans>
                </ReportHeaderTop>
              </ReportDataSection>
              <ReportCenterDataTableImg>
                {typeof reportData?.impactImage == "string" ? (
                  <img src={s3BucketImpactImage + "" + reportData?.impactImage} width="100%" alt="" />
                ) : (
                  reportData?.impactImage?.map((image) => (
                    <>
                      <img src={s3BucketImpactImage + "" + image} alt="" />
                    </>
                  ))
                )}

                {reportData?.impactMedia?.map?.((image) => (
                  <>
                    <img
                      src={s3BucketImpactImage + "" + image?.impactImageFile}
                      alt={image?.impactImageFile}
                      width="100%"
                    />
                  </>
                ))}
              </ReportCenterDataTableImg>

              <ReportPageFooter>
                <Trans>Proudly Built in Austin, TX. Copyright </Trans>
                {new Date().getFullYear()}.&nbsp;
                <Trans>Stocked Robotics, Inc. dba SIERA.AI</Trans>
              </ReportPageFooter>
            </ReportPageWrapper>
          )}
        </>
      ) : (
        <VehicleDatNA>No Reports</VehicleDatNA>
      )}
    </ReportDivWrap>
  );
}

export default IncidentReportView;
