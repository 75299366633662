import styled from "styled-components";

export const AddAssetStepperDiv = styled.div``;

export const IconSuccess = styled.div`
  color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  font-size: ${({ theme }) => theme.fontSizes.font3} !important;
  font-weight: bold;
  position: relative;
  top: 8px;
`;
