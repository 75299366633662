import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Input from "../../../../components/Input";
import { toValidatePin } from "../../../../utils/validations/checkValidation";
import { apiCall } from "../../../../utils/apiCall";
import InputField from "../../../../components/InputFieldComponent/InputFieldWrapper.component";
import { DropContainer } from "./CustomFeilds.styles";

const PIN = (props) => {
  const {
    validated,
    formData,
    setFormData,
    isPinAvailable,
    setIsPinAvailable,
    selectedUserType,
    lastValidatedPin,
    setLastValidatedPin,
    accountId,
    setIsPinValidating,
    pinErrorMessage,
    setPinErrorMessage,
  } = props;
  const { t } = useTranslation();
  const customField = selectedUserType?.customFields.find((field) => field.type === "PIN");

  const handlePinChange = (e) => {
    setFormData({
      ...formData,
      pin: e?.target?.value,
    });
  };

  const throttleCall = () => {
    let timer = null;
    return (cb, pin, accountId) => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        cb(pin, accountId);
      }, 500);
    };
  };
  const [throttler] = useState(() => throttleCall());

  useEffect(() => {
    if (!formData._id && formData.pin?.length >= 4) {
      setPinErrorMessage("");
      if (lastValidatedPin === formData.pin) {
        setIsPinAvailable(true);
      } else if (formData.pin && formData.pin.trim() !== "") {
        setIsPinValidating(true);
        throttler(checkUserPinAvailability, formData.pin, formData.accountId);
      }
    }
  }, [formData.pin]);

  const checkUserPinAvailability = (userPin, userFormaccountId) => {
    const data = {
      accountId: userFormaccountId || accountId,
      pin: userPin,
    };
    setIsPinValidating(true);
    apiCall(`/user/checkpinavailability`, {
      method: "POST",
      data,
    })
      .then((response) => {
        setIsPinValidating(false);
        if (response.data.success === true) {
          setIsPinAvailable(true);
          setLastValidatedPin(formData.pin);
        } else if (response.data.success === false) {
          setIsPinAvailable(false);
          setPinErrorMessage("PIN is not available.");
        }
      })
      .catch((error) => {
        setIsPinValidating(false);
      });
  };

  return (
    <DropContainer>
      <InputField
        label={t("PIN")}
        required={customField.isRequired}
        //  className="inputBox"
        id="pin"
        placeholder={t("PIN")}
        variant="standard"
        minLength={"4"}
        maxLength={"32"}
        type="text"
        name="pin"
        autocomplete="off"
        pattern="^[a-zA-Z0-9]{1,50}$"
        onChange={(e) => handlePinChange(e)}
        isInvalid={(validated && toValidatePin(formData.pin)) || !isPinAvailable}
        value={formData?.pin}
        isError={(validated && toValidatePin(formData.pin)) || !isPinAvailable}
        errorMessage={pinErrorMessage}
      />

      {/* {customField?.isRequired ? <p className="asterisk_user">*</p> : null} */}
    </DropContainer>
  );
};

export default PIN;
