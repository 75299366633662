import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Container, Row, Col, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import DefaultAvatar from "../../../assets/Gray_Asset Photo.png";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import { toValidateAssetName } from "../../../utils/validations/checkValidation";
import { toAddAssetImage } from "../../../redux/assets/action";

import {
  AssetOverviewContainer,
  HeadingContainer,
  HeaderLabel,
  InputUploader,
  ImagePreview,
  ProfilePicUpload,
  ImageWrapper,
  HoverText,
  ModalBodyHeight,
  FormContainer,
  FormItem,
  DropContainer,
} from "./AssetOverviewForm.component.styles";
import InputFieldWrapper from "../../../components/InputFieldComponent/InputFieldWrapper.component";
import DropdownFilterWrapper from "../../../components/DropdownComponent/DropdownFilterWrapper";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import Button from "../../../components/Button/Button.component";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function AssetOverviewForm({
  formData,
  setFormData,
  handleNext,
  toAddAssetImage,
  validated,
  isSuperAdmin,
  dropdownFilterAccountList,
  dropdownFilterWorksiteList,
  dropdownFilterProductList,
  dropdownAssetTypeList,
  setDropdownAccountId,
  PreviewImage,
  setPreviewImage,
  isValidationError,
  isSiteAdmin,
}) {
  const { t } = useTranslation();
  //const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    height: 100,
  });
  const imageRef = useRef(null);
  const changeAccountDropdown = (accountId) => {
    setDropdownAccountId(accountId);
    setFormData({
      accountIdForSuperAdmin: accountId,
      assetType: "",
      assetName: "",
    });
  };

  // const handleImage = (e) => {
  //   setPreviewImage(URL.createObjectURL(e.target.files[0]));
  //   toAddAssetImage(e.target.files[0]);
  // };

  const handleImageSelect = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
    toAddAssetImage(e.target.files[0]);
  };

  const handleCropComplete = () => {
    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      imageRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    canvas.toBlob((blob) => {
      setCroppedImage(blob);
      setModalOpen(false);
    }, "image/jpeg");
  };
  return (
    <AssetOverviewContainer>
      <Container>
        <HeadingContainer>
          <Trans>Asset Overview</Trans>
        </HeadingContainer>

        <Form noValidate validated={validated} onSubmit={handleNext} id="assetAddForm1">
          <FormContainer>
            <FormItem>
              <>
                {isSuperAdmin && (
                  <DropContainer>
                    <DropdownFilterWrapper
                      value={formData.accountIdForSuperAdmin}
                      onChange={(value) => changeAccountDropdown(value)}
                      options={dropdownFilterAccountList?.map((option) => ({
                        _id: option._id,

                        label: option.companyName,
                      }))}
                      label={t("Account")}
                      isValidationError={isValidationError}
                      placeholder="Select an Account"
                      errorMessage={t("This field is required")}
                    />
                  </DropContainer>
                )}
                <DropContainer>
                  <DropdownFilterWrapper
                    label={t("Asset Type")}
                    isValidationError={isValidationError}
                    value={formData.assetType}
                    displayEmpty
                    onChange={(value) => setFormData({ ...formData, assetType: value })}
                    options={dropdownAssetTypeList?.map((option) => ({
                      _id: option._id,
                      value: option._id,
                      label: option.AssetTypeName,
                    }))}
                    errorMessage={t("This field is required")}
                  />
                </DropContainer>

                {!isSiteAdmin && (
                  <DropContainer>
                    <DropdownFilterWrapper
                      label={t("Worksite")}
                      isValidationError={isValidationError}
                      displayEmpty
                      value={formData.siteId}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          siteId: value,
                        })
                      }
                      errorMessage={t("This field is required")}
                      options={dropdownFilterWorksiteList?.map((option) => ({
                        _id: option._id,
                        value: option._id,
                        label: option.name,
                      }))}
                    />
                  </DropContainer>
                )}
                <DropContainer>
                  <InputFieldWrapper
                    label={t("Asset Name")}
                    required={true}
                    name="assetName"
                    value={formData.assetName}
                    onChange={(e) => setFormData({ ...formData, assetName: e.target.value })}
                    placeholder={t("Please enter your Asset Name")}
                    feedback={validated && t(toValidateAssetName(formData.assetName))}
                    errorMessage={validated && t(toValidateAssetName(formData.assetName)) + "."}
                    isError={validated && toValidateAssetName(formData.assetName)}
                    isInvalid={validated && toValidateAssetName(formData.assetName)}
                    onBlur={(e) => setFormData({ ...formData, assetName: e.target.value.trim() })}
                  />
                </DropContainer>
                <DropContainer>
                  <InputFieldWrapper
                    label={t("Asset Description")}
                    name="assetDescription"
                    value={formData.assetDescription}
                    onChange={(e) => setFormData({ ...formData, assetDescription: e.target.value })}
                    placeholder={t("Please enter Asset Description")}
                    maxLength={"128"}
                    onBlur={(e) => setFormData({ ...formData, assetDescription: e.target.value.trim() })}
                  />
                </DropContainer>

                <DropContainer>
                  <DropdownFilterWrapper
                    label={t("SIERA.AI Product")}
                    options={dropdownFilterProductList?.map((option) => ({
                      _id: option._id,
                      value: option._id,
                      label: option.name,
                    }))}
                    isValidationError={isValidationError}
                    value={formData.productType}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        productType: value,
                      });
                    }}
                    errorMessage={t("Please select a Product")}
                  />
                </DropContainer>
              </>
            </FormItem>

            <FormItem>
              <DropContainer className="profilePic">
                <ProfilePicUpload>
                  <HeaderLabel>{t("Asset Image")}</HeaderLabel>
                  <ImageWrapper>
                    <HoverText>
                      {isDragging ? (
                        <i className="icon icon-plus"></i>
                      ) : PreviewImage ? (
                        t("Change Asset Image")
                      ) : (
                        t("Add Asset Image")
                      )}
                    </HoverText>
                    <InputUploader
                      accept="image/*"
                      id="contained-asset-file"
                      type="file"
                      onChange={handleImageSelect}
                    />

                    <ModalComponent
                      style={{ textAlign: "center" }}
                      show={modalOpen}
                      handleClose={() => setModalOpen(false)}
                      title="Crop Image"
                      size="lg"
                      withHeader={true}
                    >
                      <ModalBodyHeight>
                        <ReactCrop
                          src={PreviewImage}
                          crop={crop}
                          onChange={(newCrop) => setCrop(newCrop)}
                          onImageLoaded={(image) => (imageRef.current = image)}
                        />
                      </ModalBodyHeight>
                      <Row className="text-right">
                        <Col md={12}>
                          <Button label="Cancel" onClick={() => setModalOpen(false)} buttonType="ghost" />
                          <Button label="Done" onClick={handleCropComplete} buttonType="primary" />
                        </Col>
                      </Row>
                    </ModalComponent>

                    <ImagePreview
                      //   src={PreviewImage ? PreviewImage : DefaultAvatar}
                      src={croppedImage ? URL.createObjectURL(croppedImage) : DefaultAvatar}
                    />
                  </ImageWrapper>
                </ProfilePicUpload>
              </DropContainer>
            </FormItem>
          </FormContainer>
        </Form>
      </Container>
    </AssetOverviewContainer>
  );
}

const mapStateToProps = (state) => ({
  workSiteList: state.worksites.Worksites,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  accountList: state.accounts.Accounts,
  dropdownFilterAccountList: state.dropdownFilters.accountList,
  dropdownFilterWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownFilterProductList: state.dropdownFilters.productList,
});

const mapDispatchToProps = (dispatch) => ({
  toAddAssetImage: (data) => dispatch(toAddAssetImage(data)),
  setDropdownAccountId: (id) => dispatch(setDropdownAccountId(id)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetOverviewForm));
