import React from "react";
import CarouselComponent from "../../../../../components/CarouselComponent/Carousel.component";

const CarouselData = ({
  viewReport,
  inspectionReportFilter,
  inspectionCount,
  updateActiveNavItem,
  setInspectionReportFilter,
  managementCountBanner,
  allCount,
  carouselData,
  carouselDataNoReportView,
}) => {
  const responsiveL = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const responsiveLA = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {viewReport ? (
        <CarouselComponent
          key={viewReport ? "viewReportMode" : "defaultMode"}
          carouselData={viewReport ? carouselData : carouselDataNoReportView}
          countData={inspectionCount}
          size="small"
          pagination={false}
          responsive={responsiveL}
        />
      ) : (
        <CarouselComponent
          autoPlay={true}
          carouselData={managementCountBanner}
          countData={allCount}
          size="small"
          pagination={false}
          responsive={responsiveLA}
        />
      )}
    </>
  );
};

export default CarouselData;
