import React from "react";
import styled from "styled-components";
import InputFieldWrapper from "../../../components/InputFieldComponent/InputFieldWrapper.component";
import { Trans } from "react-i18next";
import { InputGroup } from "react-bootstrap";
import { ReactComponent as IconRight } from "../../../assets/AddIcon/IconRight.svg";

const Wrapper = styled.div`
  margin-top: 12px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  div[name="enterpriseUrl"] {
    label {
      display: none;
    }
  }
`;

const HelperText = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  position: relative;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  font-size: ${({ theme }) => theme.fontSizes.font8};
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  left: 3px;
`;

const ErrorText = styled.div`
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};;
`;

const AvailabilityMessage = styled.div`
  margin-top: 8px;
  color: ${({ available, theme }) =>
    available ? theme.colors.greenPrimary || "#11A70B" : theme.colors.redPrimary || "#DD0000"};
`;

const LabelDiv = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  margin-bottom: 2px;
  font-size: ${({ theme }) => theme.fontSizes.font8} !important;
  float: left;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  display: contents;
`;

const StyledInputGroup = styled(InputGroup.Text)`
  font-size: ${({ theme }) => theme.fontSizes.font7};
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-family: ${({ theme }) => theme.fontFamily.primaryFont};
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  height: 32px;
  position: relative;
`;

const UniversalInputWrapper = ({
  label,
  value,
  onChange,
  type = "text",
  minLength,
  maxLength,
  placeholder,
  isError,
  errorMessage,
  required,
  autoFocus,
  size,
  startAdornment,
  id,
  fullWidth,
  helperText,
  errors,
  successMessage = "You are good to go",
  ...rest
}) => {
  const showAvailability = value && errors === "Available";
  const showUnavailable = value && errors === "NotAvailable";

  return (
    <Wrapper fullWidth={fullWidth}>
      <LabelDiv>{label}</LabelDiv>
      <InputContainer>
        {startAdornment && (
          <InputGroup.Prepend>
            <StyledInputGroup id="basic-addon3">{startAdornment}</StyledInputGroup>
          </InputGroup.Prepend>
        )}

        <InputFieldWrapper
          id={id}
          value={value}
          onChange={onChange}
          type={type}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          fullWidth={fullWidth}
          required={required}
          autoFocus={autoFocus}
          {...rest}
        />
      </InputContainer>

      {/* Helper text */}
      {helperText && (
        <HelperText>
          <Trans>{helperText}</Trans>
        </HelperText>
      )}

      {/* Error message */}
      {isError && errorMessage && (
        <ErrorText>
          <Trans>{errorMessage}</Trans>
        </ErrorText>
      )}

      {/* URL availability messages */}
      {showAvailability && (
        <AvailabilityMessage available>
          <Trans>URL Availability</Trans>:{" "}
          <span>
            <IconRight />
            {successMessage}
          </span>
        </AvailabilityMessage>
      )}
      {showUnavailable && (
        <AvailabilityMessage>
          <Trans>URL Availability</Trans>: <span>Enterprise URL is not available</span>
        </AvailabilityMessage>
      )}
    </Wrapper>
  );
};

export default UniversalInputWrapper;
