export const tableHeader = [
  { name: "Account", fieldName: "companyName", show: true },
  { name: "Worksite", fieldName: "siteName", show: true },
  { name: "Asset Name", fieldName: "assetName", show: true },
  { name: "User", fieldName: "fullName", show: true },
  { name: "Login Time", fieldName: "loginTime", show: true },
  { name: "Logout Time", fieldName: "logoutTime", show: true },
  { name: "Duration", fieldName: "duration", show: true },
  { name: "Asset Type", fieldName: "assetType" },
  { name: "# Inspections", fieldName: "inspection" },
  { name: "1st Inspection Start Time", fieldName: "inspectionStartTime" },
  { name: "1st Inspection Response Time", fieldName: "inspectionResponseTime" },
  { name: "# Failed Inspections", fieldName: "failedInspection" },
  { name: "# Near-Misses (Objects)", fieldName: "nearMissObjects" },
  { name: "# Near-Misses (Pedestrians)", fieldName: "nearMissPedestrians" },
  { name: "# Low Impacts", fieldName: "lowImpacts" },
  { name: "# Medium Impacts", fieldName: "mediumImpacts" },
  { name: "# High Impacts", fieldName: "highImpacts" },
];

export const mobileRowHeader = [
  { name: "Worksite", keyName: "worksite", space: 1, fieldName: "siteName" },
  {
    name: "Incident By",
    keyName: "incidentBy",
    space: 1,
    fieldName: "fullName",
  },
  {
    name: "Asset Impacted",
    keyName: "assetImpacted",
    space: 1,
    fieldName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 1,
    isDate: true,
    fieldName: "impactRecordedAt",
  },
];

export const tableRowData = [
  {
    name: "Account",
    keyName: "accountName",
    space: 0,
    fieldName: "companyName",
  },
  { name: "Worksite", keyName: "worksite", space: 0, fieldName: "siteName" },
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 0,
    fieldName: "AssetTypeName",
  },
  {
    name: "Incident By",
    keyName: "incidentBy",
    space: 0,
    fieldName: "fullName",
  },

  {
    name: "Asset Name",
    keyName: "assetImpacted",
    space: 0,
    fieldName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 0,
    isDate: true,
    fieldName: "impactRecordedAt",
  },
  {
    name: "Impact Severity",
    keyName: "impactSeverity",
    isStatus: true,
    space: 0,
    fieldName: "impactSeverity",
    fieldName2: "incidentObject",
  },
];

export const rowPerPageDropdown = [
  { name: 10, value: 10 },
  { name: 25, value: 25 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
];

export const filterByTypeData = [
  { name: "Asset", _id: "asset" },
  { name: "Asset Type", _id: "assetType" },
  { name: "User", _id: "inspectedBy" },
  { name: "Incident Type", _id: "incidentType" },
];

export const FilterByTypeLabelData = {
  asset: "Asset",
  assetType: "Asset Type",
  inspectedBy: "User",
  incidentType: "Incident Type",
};

// This below code is for mobile accordian view render
export const mobileCheckboxAccordian = [
  "Asset Type",
  "# Inspections",
  "1st Inspection Start Time",
  "1st Inspection Response Time",
  "# Failed Inspections",
  "# Near-Misses (Objects)",
  "# Near-Misses (Pedestrians)",
  "# Low Impacts",
  "# Medium Impacts",
  "# High Impacts",
];

//This is for desktop Accordian only
export const accordianColumns = [
  "Asset Type",
  "# Inspections",
  "1st Inspection Start Time",
  "1st Inspection Response Time",
  "# Failed Inspections",
  "# Near-Misses (Objects)",
  "# Near-Misses (Pedestrians)",
  "# Low Impacts",
  "# Medium Impacts",
  "# High Impacts",
];
// This below code is for mobile view render
export const mobileCheckboxDateTime = [
  { columnName: "Account", className: "accountStyle" },
  { columnName: "Worksite", className: "worksiteStyle" },
  { columnName: "Asset Name", className: "worksiteStyle" },
  { columnName: "User", className: "worksiteStyle" },
  { columnName: "Login Time", className: "reportStatusStyle" },
  { columnName: "Logout Time", className: "reportStatusStyle" },
  { columnName: "Duration", className: "worksiteStyle" },
];
