import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import TemplateList from "./ChecklistTemplatesList.component";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import ChecklistTemplatesOverview from "./ChecklistTemplatesOverview.complete";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { useTranslation } from "react-i18next";

// Button Component
import Button from "../../../../components/Button/Button.component";

import SieraLoader from "../../../../components/SieraLoader/SieraLoader.component";
import {
  LoaderOverlay,
  AddChecklistButton,
  ContainerDiv,
  ContentWrapper,
  LeftSection,
  RightSection,
  DesktopView,
  ActionBarButtons,
  ChecklistDetailHeading,
  ActionBarWrapper,
  RightIconImage,
  MobileView,
  ButtonDivEdit,
  ButtonLink,
} from "../ChecklistManagement.component.styles";
import ActionBarView from "../ChecklistButtonSearch/ActionBarView.component";

function ChecklistList({ updateActiveNavItem, checklistTemplatesLoading, checklistTemplateOverview }) {
  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("checklistTemplates");
  }, []);

  const [showLoader, setShowLoader] = useState(false);
  const [hasShownOnce, setHasShownOnce] = useState(false);

  useEffect(() => {
    if (checklistTemplatesLoading && !hasShownOnce) {
      setShowLoader(true);
      setHasShownOnce(true);
    } else if (!checklistTemplatesLoading) {
      setShowLoader(false);
    }
  }, [checklistTemplatesLoading, hasShownOnce]);

  const [showAccountDetail, setShowAccountDetail] = useState(false);
  const [isActionBarVisible, setIsActionBarVisible] = useState(false);

  return (
    <div>
      <BreadCrumb />
      <DesktopView>
        <ButtonLink>
          <NavLink to="/app/checklistmanagement">
            <Button iconClass="icon icon-arrow-left" label={t("Your Checklists")} showIcon buttonType="primary" />
          </NavLink>
        </ButtonLink>

        <ContainerDiv>
          <ContentWrapper>
            {showLoader && (
              <LoaderOverlay>
                <SieraLoader OnlyLoaderIcon size="small" />
              </LoaderOverlay>
            )}
            <LeftSection>
              <TemplateList />
            </LeftSection>
            <RightSection>
              <ChecklistTemplatesOverview />
            </RightSection>
          </ContentWrapper>
        </ContainerDiv>
      </DesktopView>

      <MobileView>
        {!showAccountDetail ? (
          <ActionBarView
            isActionBarVisible={isActionBarVisible}
            setIsActionBarVisible={setIsActionBarVisible}
          />
        ) : (
          ""
        )}

        <ActionBarWrapper>
          <ActionBarButtons>
            {!isActionBarVisible ? null : (
              <NavLink to="/app/checklistmanagement">
                <Button
                  iconClass="icon-angle-left"
                  label={t("Your Checklists")}
                  showIcon
                  buttonType="primary"
                />
              </NavLink>
            )}
          </ActionBarButtons>
        </ActionBarWrapper>

        {!showAccountDetail ? (
          <></>
        ) : (
          <>
            <ActionBarView
              isActionBarVisible={isActionBarVisible}
              setIsActionBarVisible={setIsActionBarVisible}
            />
          </>
        )}

        {!showAccountDetail ? (
          <div onClick={() => setShowAccountDetail(true)}>
            <TemplateList />
          </div>
        ) : (
          <>
            <ChecklistDetailHeading>
              <span onClick={() => setShowAccountDetail(false)}>Your Checklist</span>
              <RightIconImage className="icon icon-arrow-right"></RightIconImage>
              {checklistTemplateOverview ? checklistTemplateOverview.checklistTemplateName : ""}
            </ChecklistDetailHeading>

            <ChecklistTemplatesOverview />
          </>
        )}
      </MobileView>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklistTemplatesLoading: state.checklist.checklistTemplatesLoading,
  checklistTemplateOverview: state.checklist.checklistTemplateOverview,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistList);
